import React from "react";
import Sidebar from "../components/AdminSideBar";
import PageHeader from "../../../components/Headers/PageHeader";
import CompanyForm from "./components/CompanyForm";
import { useMutation } from "@tanstack/react-query";
import { postCreateCompany } from "../../../api/companies";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const CreateCompanyPage = () => {
  const navigate = useNavigate();

  const createCompanyMutation = useMutation({
    mutationFn: (formData) => {
      // Create a FormData object to handle file uploads
      console.log(formData);
      const submitData = new FormData();

      // Add regular form fields
      submitData.append("name", formData.name);
      submitData.append("units", formData.units);
      submitData.append("phone", formData.phone || "");
      submitData.append("email", formData.email || "");
      submitData.append("description", formData.description || "");
      submitData.append("users", formData.users || "");

      // Add nested address fields
      if (formData.address) {
        Object.keys(formData.address).forEach((key) => {
          if (formData.address[key]) {
            submitData.append(`address[${key}]`, formData.address[key]);
          }
        });
      }

      // Add file uploads if they exist
      if (formData.logoUrl && formData.logoUrl.length > 0) {
        submitData.append("logoUrl", formData.logoUrl[0]);
      }

      if (formData.idImage && formData.idImage.length > 0) {
        submitData.append("idImage", formData.idImage[0]);
      }
      return postCreateCompany(submitData);
    },
    onSuccess: () => {
      toast.success("Company created successfully!");
      navigate("/admin/company/list");
    },
    onError: (error) => {
      toast.error(`Failed to create company: ${error.message}`);
    },
  });

  const onSubmit = (data) => {
    createCompanyMutation.mutate(data);
  };

  return (
    <Sidebar>
      <PageHeader>
        <h6>Create Company</h6>
      </PageHeader>
      <div className="px-lg-5 py-3">
        <CompanyForm
          onSubmit={onSubmit}
          isLoading={createCompanyMutation.isPending}
        />
      </div>
    </Sidebar>
  );
};

export default CreateCompanyPage;
