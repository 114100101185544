import React from "react";
import { Form } from "react-bootstrap";
import { useBuildingList } from "../../../../hooks/buildings/useAdminBuildingsList";
import { Controller } from "react-hook-form";

const ParentBuildingSelect = ({ control, watch, error }) => {
  const userId = watch("user");
  const {
    data: buildings = [],
    isLoading: isLoadingBuildings,
  } = useBuildingList({ userId });

  return (
    <Controller
      name="parentStructure"
      control={control}
      render={({ field }) => (
        <>
          <Form.Select disabled={isLoadingBuildings} {...field}>
            <option value="">Select parent building</option>
            {buildings.map((building) => (
              <option key={building._id} value={building._id}>
                {building.type} | {building.name}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {error?.message}
          </Form.Control.Feedback>
        </>
      )}
    />
  );
};

export default ParentBuildingSelect;
