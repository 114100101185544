import { Auth } from "aws-amplify";
import axios from "axios";

// Reusable Function for API Calls

const fetchDashboardData = async (
    endpoint: string,
    startDate?: Date,
    endDate?: Date
) => {
    try {
        const auth = await Auth.currentSession();
        const headers = {
            Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
        };

        const queryParams = new URLSearchParams();
        if (startDate && endDate) {
            const startTimestamp = startDate.setHours(0, 0, 0, 0);
            const endTimestamp = endDate.setHours(23, 59, 59, 999);
            queryParams.append("startDate", startTimestamp.toString());
            queryParams.append("endDate", endTimestamp.toString());

            if (endpoint === "booking/showings") {
                queryParams.append(
                    "timezone",
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                );
            }
        }

        const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/dashboard/${endpoint}`;
        const url = queryParams.toString()
            ? `${baseUrl}?${queryParams}`
            : baseUrl;

        const res = await axios.get(url, { headers });
        return res.data || null;
    } catch (error) {
        console.error(`Error fetching dashboard data: ${endpoint}:`, error);
        return null;
    }
};

// API Fetch Functions

export const getPropertiesStatus = () => fetchDashboardData("property/status");

export const getPropertyAmount = (startDate: Date, endDate: Date) =>
    fetchDashboardData("property/amount", startDate, endDate);

export const getScheduledTours = (startDate: Date, endDate: Date) =>
    fetchDashboardData("booking/scheduled-tours", startDate, endDate);

export const getLeads = (startDate: Date, endDate: Date) =>
    fetchDashboardData("contact/leads", startDate, endDate);

export const getShowings = (startDate: Date, endDate: Date) =>
    fetchDashboardData("booking/showings", startDate, endDate);

export const getShowingsTotal = (startDate: Date, endDate: Date) =>
    fetchDashboardData("booking/showings-total", startDate, endDate);

export const getNextShowings = () =>
    fetchDashboardData("booking/next-showings");

export const getDoorOpened = (startDate: Date, endDate: Date) =>
    fetchDashboardData("notifications/door-unlock", startDate, endDate);

export const getKitsInstalledRemoved = (startDate: Date, endDate: Date) =>
    fetchDashboardData("kit/installed-removed", startDate, endDate);

export const getTopProperties = (startDate: Date, endDate: Date) =>
    fetchDashboardData("property/top", startDate, endDate);
