import React, { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import useViewport from "./../../../hooks/useViewport";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import EZUIKit from 'ezuikit-js';

import Logo from "../../../assests/images/Logo.svg";

const EzvizCamera = ({ camera, updateTrigger = 0, index, startTime, stopTime, type = 1, protocol = 2, ...props }) => {
  const [playUrl, setPlayUrl] = useState();
  const [accessToken, setAccessToken] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const { width: screenWidth } = useViewport();

  const [searchParams] = useSearchParams();

  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set("recordings", camera.serial);
  newSearchParams.set("selectedCamera", index)
  newSearchParams.set("selectedDay", DateTime.now().toFormat("yyyy-MM-dd"))

  const deviceSerial = camera.model;

  const refresh = useCallback(async () => {
    async function getPlayUrl() {
      const token = await Auth.currentSession();
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API_URL}/api/camera/${deviceSerial}/ezviz-play-url`,
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
          params: {
            startTime,
            stopTime,
            protocol,
            type
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        const errorData = response.data;
        throw new Error(
          JSON.stringify({ message: errorData.message, code: errorData.code })
        );
      }
    }
    setIsLoading(true);
    try {
      const { url, accessToken } = await getPlayUrl();
      setAccessToken(accessToken)
      setPlayUrl(url);
    } catch (error) {
      setIsError(true);
      setErrMsg(error.response.data.message);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  }, [deviceSerial, protocol, startTime, stopTime, type]);

  useEffect(() => {
    let timeoutId;

    async function init() {
      timeoutId = await refresh();
    }

    if (deviceSerial) init();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [deviceSerial, refresh]);

  useEffect(() => {
    refresh();
  }, [refresh, updateTrigger]);

  useEffect(() => {
    if (playUrl && accessToken) {
      const player = new EZUIKit.EZUIKitPlayer({
        id: 'ezuikit-player',
        url: playUrl,
        accessToken: accessToken,
      });

      return () => {
        player.destroy();
      };
    }
  }, [accessToken, playUrl]);

  if (!deviceSerial) return null;

  return (
    <>
      {isLoading ? (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : isError ? (
        <>{errMsg}</>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: screenWidth < 755 ? "200px" : "100%",
            }}
            className="rounded-3"
          >
            <div id="ezuikit-player"></div>
            {/* <div
              style={{
                position: "absolute",
                top: "2%",
                left:
                  screenWidth < 430 ? "2%" : screenWidth < 750 ? "5%" : "2%",
                zIndex: 3,
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: screenWidth < 755 ? 50 : 55,
                  height: "auto",
                  opacity: 0.8,
                }}
              />
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default EzvizCamera;
