import { useSearchParams } from "react-router-dom";
import KeypadCodeForm from "../../../../../../components/Keypad";
import DeletKitHeader from "./components/DeletKitHeader";
import DeletKitTabs from "./components/DeletKitTabs";
import DeletKitContent from "./components/DeletKitContent";

export default function DeletKit({
    property,
    setProperty,
    reloadHardware,
    setModal,
    handleCloseModal,
}) {
    const [searchParams] = useSearchParams();
    const currentTab = searchParams.get("systemTab") || "info";

    let lockVendor = property.kit?.hardware.find(
        (hardware) => hardware.category === "lock"
    )?.lock?.vendor;

    const lockId = property.kit?.hardware.find(
        (hardware) => hardware.category === "lock"
    )?._id;

    const keypadId =
        lockVendor === "august"
            ? lockId
            : property.kit?.hardware.find(
                  (hardware) => hardware.category === "keypad"
              )?._id;

    const systemTabUrl = (param, value) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("activeTab", "system");
        newSearchParams.set(param, value);
        return `?${newSearchParams.toString()}`;
    };

    const handleCreateCode = () => {
        setModal({
            show: true,
            body: (
                <KeypadCodeForm
                    handleCancel={handleCloseModal}
                    keypadId={keypadId}
                    isAugust={lockVendor === "august"}
                />
            ),
            title: "ACCESS CODE CONFIGURATION",
            hideCloseButton: true,
        });
    };

    return (
        <>
            <DeletKitHeader
                property={property}
                setProperty={setProperty}
                reloadHardware={reloadHardware}
            />

            {property?.kit && (
                <div className="d-flex flex-column flex-md-row">
                    <DeletKitTabs
                        currentTab={currentTab}
                        systemTabUrl={systemTabUrl}
                    />
                    <DeletKitContent
                        property={property}
                        lockId={lockId}
                        lockVendor={lockVendor}
                        keypadId={keypadId}
                        currentTab={currentTab}
                        handleCreateCode={handleCreateCode}
                    />
                </div>
            )}
        </>
    );
}
