import Select from "react-select";
import { Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import toast from "react-hot-toast";

export default function HardwareSelect({ control, error }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [hardwareOptions, setHardwareOptions] = useState([]);

    useEffect(() => {
        const getHardwareList = async () => {
            try {
                setIsLoading(true);
                const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware?format=list`;
                const token = await Auth.currentSession();
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token
                            .getIdToken()
                            .getJwtToken()}`,
                    },
                });
                if (response.status === 200) {
                    setHardwareOptions(
                        response.data.filter(
                            (hardware) => hardware.category === "lockbox"
                        )
                    );
                } else {
                    toast.error("Error getting hardware list.");
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.status === 401) {
                    Auth.signOut()
                        .then(() => {
                            navigate("/admin/login");
                        })
                        .catch((error) => {
                            console.log("Error signing out: ", error);
                        });
                } else {
                    toast.error("Error getting hardware list.");
                }
            } finally {
                setIsLoading(false);
            }
        };
        getHardwareList();
    }, [navigate]);

    return (
        <>
            <Controller
                name="hardware"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <Select
                        {...field}
                        isMulti
                        options={hardwareOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        placeholder="Select hardware..."
                        isClearable
                        isSearchable
                        isDisabled={isLoading}
                    />
                )}
            />
            {error && (
                <div className="text-danger small mt-1">{error.message}</div>
            )}
        </>
    );
}
