import { Form, Button, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropertiesSelect from "../Admin/Buildings/components/PropertiesSelect";
import HardwareSelect from "../Admin/Buildings/components/HardwareSelect";
import ParentBuildingSelect from "../Admin/Buildings/components/ParentBuildingSelect";

export default function BuildingForm({
  users = [],
  isLoadingUsers = true,
  onSubmit,
  submitting,
  building,
  loadingBuilding = false,
  fetchingBuilding = false,
  disabled = true,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (!loadingBuilding && !fetchingBuilding && building) {
      reset(building);
      setValue("user", building.user?._id);
    }
  }, [building, loadingBuilding, fetchingBuilding, reset, setValue]);

  // Handle user
  const selectedUser = watch("user");
  const handleUserChange = (e) => {
    const newUser = e.target.value;
    if (!newUser) return;
    setValue("user", newUser);

    if (newUser !== selectedUser) {
      setValue("properties", []);
      setValue("parentStructure", "");
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled || (submitting && loadingBuilding)}
    >
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          {...register("name")}
          placeholder="Enter name"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Address</Form.Label>
        <Form.Control
          {...register("address.street")}
          placeholder="Street"
          className="mb-2"
        />
        <Row>
          <Col>
            <Form.Control {...register("address.city")} placeholder="City" />
          </Col>
          <Col>
            <Form.Control {...register("address.state")} placeholder="State" />
          </Col>
          <Col>
            <Form.Control
              {...register("address.zipCode")}
              placeholder="Zip Code"
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>
          Type <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          {...register("type", { required: "Type is required" })}
          isInvalid={!!errors.type}
        >
          <option value="">Select type</option>
          {["BUILDING", "FLOOR", "ROOM", "COMPLEX", "AREA"].map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Select>
        {errors.type && (
          <Form.Control.Feedback type="invalid">
            {errors.type.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>
          User <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          {...register("user", { required: "User is required" })}
          isInvalid={!!errors.user}
          disabled={isLoadingUsers}
          value={selectedUser}
          onChange={handleUserChange}
        >
          <option value="" disabled>
            Select user
          </option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.email}
            </option>
          ))}
        </Form.Select>
        {errors.user && (
          <Form.Control.Feedback type="invalid">
            {errors.user.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Parent Building</Form.Label>
        <ParentBuildingSelect
          control={control}
          watch={watch}
          error={errors.parentBuilding}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Properties</Form.Label>
        <PropertiesSelect
          control={control}
          watch={watch}
          setValue={setValue}
          error={errors.properties}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Hardware Search</Form.Label>
        <HardwareSelect control={control} error={errors.hardware} />
      </Form.Group>

      <Button variant="primary" type="submit" disabled={disabled || (submitting && loadingBuilding)}>
        Submit
      </Button>
    </Form>
  );
}
