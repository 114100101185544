import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LazyImgWithPlaceholder from "../../../components/Images/LazyImgWithPlaceholder";
import CopyButton from "./CopyButton";
import propertyDummyImage from "../../../assests/images/6-thumb.png";
import getImageUrl from "../../../helper/checkImageFormat";
import RouterStatus from "./RouterStatus";
import { FiUpload } from "react-icons/fi";
import { format } from "date-fns-tz";
import PropertyStatusSelect from "./PropertyStatusSelect";
import UnstyledButton from "../../../components/UnstyledButton";
import CustomDropdown from "../../../components/Dropdown";
import copy from "copy-to-clipboard";
import {
  propertyArchivedListMenuItems,
  propertyListMenuItems,
} from "../../../static/Data";
import { propertyBookingLink } from "../../../utils/Properties";
import { BsThreeDotsVertical } from "react-icons/bs";

const PropertyListCard = ({
  property,
  handleFormModal,
  queryKey,
  setShowArchiveModal,
  setPropertyIdToArchiveOrRestore,
  setShowRestoreModal,
  setShowDuplicateModal,
}) => {
  const navigate = useNavigate();
  const handleMenuItemClick = async (action, id) => {
    if (action === "Edit property details") {
      navigate(`/property/edit/${id}`);
    } else if (action === "Open prospect booking page") {
      const link = await propertyBookingLink(property);
      window.open(link, "_blank");
    } else if (action === "Copy prospect booking link") {
      const link = await propertyBookingLink(property);
      copy(link);
    } else if (action === "View all property leads") {
      navigate(`/property/${id}?activeTab=leads`);
    } else if (action === "Archive property") {
      setShowArchiveModal(true);
      setPropertyIdToArchiveOrRestore(id);
    } else if (action === "Restore property") {
      setShowRestoreModal(true);
      setPropertyIdToArchiveOrRestore(id);
    } else if (action === "Duplicate property") {
      setShowDuplicateModal(true);
      setPropertyIdToArchiveOrRestore(id);
    }
  };
  return (
    <div className="row justify-content-between py-3 border-bottom">
      <div className="col-lg-4 col-xxl-3 d-flex gap-3">
        <Link
          to={`/property/${property._id}?activeTab=details`}
          className="text-decoration-none text-black d-flex gap-3 align-items-center"
          style={{ display: "contents" }}
        >
          <div className="d-none d-lg-flex flex-column">
            {property.status === "archived" ? (
              <div style={{ width: "16px" }}></div>
            ) : (
              <>
                <Button
                  className="bg-transparent border-0 p-0 py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleFormModal(property);
                  }}
                >
                  <FiUpload color="#5271FF" />
                </Button>

                <CopyButton property={property} />
              </>
            )}
          </div>
          <div className="position-relative d-inline-block ms-0 py-2">
            <LazyImgWithPlaceholder
              width={80}
              height={80}
              border={10}
              placeholder={propertyDummyImage}
              src={
                getImageUrl(property.images?.[0]) ??
                getImageUrl(property.primaryImage)
              }
              alt="property"
            />
          </div>
        </Link>

        <div className="d-flex flex-column justify-content-center gap-1 flex-grow-1 flex-lg-grow-0">
          <div className="d-flex align-items-center">
            <p className="col m-0">
              <span className="mw-100 text-truncate">
                {property.shortAddress
                  ? `${property.shortAddress}${
                      property.unit ? ` Unit ${property.unit}` : ""
                    }`
                  : ""}
              </span>
              <br className="d-lg-none" />
              <span className="small-text text-body-tertiary w-100 d-block d-lg-none">
                {property.city || property.state || property.zipCode
                  ? `${property.city}, ${property.state} ${property.zipCode}`
                  : ""}
              </span>
            </p>
            <CustomDropdown
              menuItems={
                property.status === "archived"
                  ? propertyArchivedListMenuItems
                  : propertyListMenuItems
              }
              onMenuItemClick={(action) =>
                handleMenuItemClick(action, property._id)
              }
              icon={<BsThreeDotsVertical size={16} />}
              className="z-1 h-100 text-secondary d-lg-none col-2"
            />
          </div>

          <span className="small-text text-body-tertiary d-none d-lg-block">
            {property.city || property.state || property.zipCode
              ? `${property.city}, ${property.state} ${property.zipCode}`
              : ""}
          </span>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-1 align-items-center">
              <div className="d-lg-none">
                <PropertyStatusSelect property={property} queryKey={queryKey} />
              </div>
              <RouterStatus kit={property.kit} property={property._id} />
            </div>
            <div className="d-flex d-lg-none align-items-center gap-1">
              {property.status === "archived" ? (
                <div style={{ width: "16px" }}></div>
              ) : (
                <>
                  <Button
                    variant="light"
                    className="border-0 p-2 bg-delet rounded-circle d-flex justify-content-center align-items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFormModal(property);
                    }}
                  >
                    <FiUpload color="white" size={12} />
                  </Button>

                  <CopyButton
                    property={property}
                    className="border-0 p-2 bg-delet rounded-circle d-flex justify-content-center align-items-center"
                    iconColor="white"
                    iconSize={12}
                  />
                </>
              )}
            </div>
          </div>
          <div className="d-flex d-lg-none justify-content-between pt-1">
            <span className="xs-text">
              {" "}
              Price:{" "}
              {property.price
                ? `$${new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(property.price)}`
                : "N/A"}
            </span>
            <span className="xs-text">Leads: {property.leadsCount}</span>
            <span className="xs-text">
              Listed on:{" "}
              {property.listedTs
                ? format(property.listedTs, "MM/dd/yyyy")
                : "No Listed Date"}
            </span>
          </div>
        </div>
      </div>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        {property.price
          ? `$${new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(property.price)}`
          : "N/A"}
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        {property.leadsCount}
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        {property.listedTs
          ? format(property.listedTs, "MM/dd/yyyy")
          : "No Listed Date"}
      </span>

      <span className="col-lg-2 align-items-center justify-content-center d-none d-lg-flex">
        <PropertyStatusSelect property={property} queryKey={queryKey} />
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        <UnstyledButton className="w-100 h-100">
          <CustomDropdown
            menuItems={
              property.status === "archived"
                ? propertyArchivedListMenuItems
                : propertyListMenuItems
            }
            onMenuItemClick={(action) =>
              handleMenuItemClick(action, property._id)
            }
            icon={<BsThreeDotsVertical size={22} />}
            className="z-1 h-100"
          />
        </UnstyledButton>
      </span>
    </div>
  );
};

export default PropertyListCard;
