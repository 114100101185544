import axios from "axios";
const { Auth } = require("aws-amplify");

export const getCodeHistory = async (hardware: {
    id: string;
    vendor: string;
    type: string;
}) => {
    // Validate hardware info
    if (!hardware.id || !hardware.vendor || !hardware.id) {
        throw new Error("Missing hardware required fields.");
    }

    // Build request url
    const url = `${process.env.REACT_APP_NODE_API_URL}/api/${hardware.vendor}/${hardware.type}/${hardware.id}/codes`;

    try {
        const token = await Auth.currentSession();
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
                "Content-Type": "application/json",
            },
        });

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status;
            const message =
                error.response?.data?.response?.message || error.message;
            throw new Error(
                `Error: ${message}, StatusCode: ${statusCode ?? "Unknown"}`
            );
        }
        throw new Error("An unexpected error occurred getting code history.");
    }
};
