import { Button } from "react-bootstrap";
import { IoIosAlert } from "react-icons/io";
import { RiArrowDropRightLine } from "react-icons/ri";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { unassignKit } from "../../../../../../../api/kits";
import { useState } from "react";
import SelectKit from "../../../../../components/Kit/SelectKit";
import GenericModal from "../../../../../../../components/Modals";
import { useProfile } from "../../../../../../../context/UserProfileContext";

export default function DeletKitHeader({
  property,
  setProperty,
  reloadHardware,
}) {
  const MySwal = withReactContent(Swal);
  const [modal, setModal] = useState({ show: false });
  const session = useProfile();

  const handleCloseModal = () => {
    setModal({ ...modal, show: false });
  };

  const updateProperty = (updatedFields) => {
    setProperty({ ...property, ...updatedFields });
  };

  const handleOpenChangeKit = () => {
    setModal({
      show: true,
      body: (
        <SelectKit
          labelText={`You are about to select a kit for ${property.address}`}
          handleCancel={handleCloseModal}
          updateProperty={updateProperty}
          propertyId={property._id}
          reloadHardware={reloadHardware}
        />
      ),
      hideCloseButton: true,
      size: "lg",
    });
  };

  return (
    <header>
      <div className="d-flex bg-delet-pink justify-content-between align-items-center rounded-top w-100 p-3">
        <div className="d-flex align-items-center justify-content-between w-100">
          {property?.kit?.name ? (
            <>
              <h6 className="color-gray fw-semibold text-capitalize mb-0">
                {property.kit.name}
              </h6>

              <div
                className={`gap-2 gap-md-3 ${
                  session?.data?.roles.includes("agent") ? "d-none" : "d-flex"
                }`}
              >
                <Button
                  className="bg-delet border-0 px-2 px-md-4 fw-bold"
                  onClick={handleOpenChangeKit}
                >
                  Change kit
                </Button>

                <Button
                  variant="dark"
                  className="bg-light-black border-0 px-2 px-md-4 fw-bold"
                  onClick={() =>
                    MySwal.fire({
                      title:
                        "<h4 class='fs-5'>Are you sure you want to unassign this kit?</h4>",
                      html: "<p class='text-body-tertiary fs-6'>The kit will be uninstalled and made available for reassignment to another property",
                      iconHtml: <IoIosAlert color="#5271ff" />,
                      showCancelButton: true,
                      cancelButtonText: "Close",
                      confirmButtonText: "Unassign",
                      confirmButtonColor: "#5271ff",
                      reverseButtons: true,
                      customClass: {
                        icon: "border-0 my-0 py-2",
                        popup: "px-5 pt-2",
                        title: "pt-0",
                        htmlContainer: "pt-2",
                        actions: "gap-3 w-100 flex-nowrap",
                        confirmButton: "w-100 py-2 rounded-3",
                        cancelButton:
                          "w-100 bg-white text-black border border-secondary-subtle rounded-3",
                      },
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await unassignKit(property.kit._id);
                          setProperty({
                            ...property,
                            kit: undefined,
                          });
                          reloadHardware([]);
                        } catch (error) {
                          console.log("error unassign kit: ", error);
                        }
                      }
                    })
                  }
                >
                  Unassign Kit
                </Button>
              </div>
            </>
          ) : (
            <div className="d-flex gap-3 justify-content-between align-items-center w-100">
              <div className="d-flex gap-1 align-items-center text-body-secondary">
                <IoIosAlert
                  className="flex-shrink-0"
                  color="#929292"
                  size={30}
                />
                <span className="d-none d-md-inline">
                  It looks like you haven’t selected a kit for this property yet
                </span>
                <span className="d-inline d-md-none small-text color-gray">
                  You haven’t selected a kit yet
                </span>
              </div>
              {session?.data?.isAdmin ? (
                <Button
                  className="text-white text-nowrap bg-delet border-0 d-flex align-items-center px-4 rounded-3"
                  onClick={handleOpenChangeKit}
                >
                  Select kit <RiArrowDropRightLine size={25} />
                </Button>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <GenericModal {...modal} handleClose={handleCloseModal} />
    </header>
  );
}
