import React from 'react'
import Camera3dEye from '../../../components/Camera3dEye'

const SelectedCameraCard = ({ camera, ...props  }) => {
    return (
        <div className='d-flex' {...props}>
            <p className='col-6'>
                <span>{camera.name}</span>
            </p>
            <Camera3dEye camera={camera} playing={false} controls={false} playIcon={<></>} />
        </div>
    )
}

export default SelectedCameraCard