import React from "react";
import {
  ActionIcon,
  Button,
  Card,
  Center,
  Container,
  Group,
  Stack,
  Text,
  Timeline,
  Title,
} from "@mantine/core";
import {
  FaShippingFast,
  FaMoneyCheckAlt,
  FaRegCheckCircle,
} from "react-icons/fa";
import { FaHouseCircleCheck } from "react-icons/fa6";
import { IoChevronBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../components/SideBar";

const ShipmentDetail = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Sidebar>
        <Container mt={30} w={"50vw"}>
          <Group align="center" mb={"md"}>
            <ActionIcon
              variant="transparent"
              c={"black"}
              onClick={handleGoBack}
            >
              <IoChevronBackSharp />
            </ActionIcon>
            <Title order={4}>Details</Title>
          </Group>
          <Card withBorder radius="md" p="md" shadow="sm">
            <Card.Section p={40} withBorder>
              <Center>
                <Timeline active={2} lineWidth={3} bulletSize={27}>
                  <Timeline.Item
                    bullet={<FaMoneyCheckAlt size={12} />}
                    title="Order placed"
                  >
                    {/* <Text c="dimmed" size="sm"></Text> */}
                    <Text size="xs" mt={4}>
                      April 12th, 2024
                    </Text>
                  </Timeline.Item>
                  <Timeline.Item
                    bullet={<FaRegCheckCircle size={12} />}
                    title="Order confirmed"
                  >
                    {/* <Text c="dimmed" size="sm"></Text> */}
                    <Text size="xs" mt={4}>
                      April 12th, 2024
                    </Text>
                  </Timeline.Item>
                  <Timeline.Item
                    bullet={<FaShippingFast size={12} />}
                    title="Shipped"
                  >
                    {/* <Text c="dimmed" size="sm"></Text> */}
                    <Text size="xs" mt={4}>
                      April 13th, 2024
                    </Text>
                  </Timeline.Item>
                  <Timeline.Item
                    bullet={<FaHouseCircleCheck size={12} />}
                    title="Delivered"
                    c={"dimmed"}
                  >
                    {/* <Text c="dimmed" size="sm"></Text> */}
                    {/* <Text size="xs" mt={4}>April 14th, 2024</Text> */}
                  </Timeline.Item>
                </Timeline>
              </Center>
            </Card.Section>

            <Card.Section
              p={40}
              styles={{ borderTop: "1px solid black" }}
              withBorder
            >
              <Stack align="center">
                <Text size="sm">Tracking number: 12243243</Text>
                <Button variant="light" m={10}>
                  Track shipment
                </Button>
              </Stack>
            </Card.Section>
          </Card>
        </Container>
      </Sidebar>
    </>
  );
};
export default ShipmentDetail;
