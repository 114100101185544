import { DateInput } from "@mantine/dates";
import { FormEvent, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useCodeCreation } from "../hooks/useCodeCreation";
import { validateEndDate, validateForm } from "../utils/validation";
import TimePicker from "./TimePicker";
import ErrorMessage from "./ErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import axios from "axios";
import { createAccessCode } from "../../../../../../../api/accessCode";
import { BsFillInfoCircleFill } from "react-icons/bs";

interface CodeCreationProps {
    hardware: {
        id: string;
        vendor: string;
        type: string;
    };
    handleCloseModal: () => void;
}

export default function CodeCreation({
    hardware,
    handleCloseModal,
}: CodeCreationProps) {
    const {
        type,
        setType,
        name,
        setName,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        minStartTime,
        error,
        setError,
        isLoading,
        setIsLoading,
    } = useCodeCreation();
    const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);

    // Tanstack
    const queryClient = useQueryClient();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (!validateForm(type, name, startDate, startTime, setError)) {
                return;
            }

            // Get startDateTime
            if (!startDate || !startTime) return;
            const startDateTime = new Date(startDate);
            startDateTime.setHours(parseInt(startTime, 10), 0, 0, 0);

            // Check endDate and endTime validity
            if (
                !validateEndDate(
                    type,
                    startDateTime,
                    endDate,
                    endTime,
                    setError
                )
            ) {
                return;
            }

            // Get endDateTime if exists
            let endDateTime: Date | null = null;
            if (endDate && endTime) {
                endDateTime = new Date(endDate);
                endDateTime.setHours(parseInt(endTime, 10), 0, 0, 0);
            }

            const accessCode = {
                codeType: type,
                codeName: name,
                startTimestamp: startDateTime.getTime(),
                endTimestamp: endDateTime?.getTime() ?? null,
            };

            // Create PIN code
            await createAccessCode(hardware, accessCode);
            const queryKey = [hardware.type, hardware.id, "code-history"];
            queryClient.invalidateQueries({ queryKey });
            handleCloseModal();
            toast.success(
                "New code created, it should appear in the next 5 seconds, if not, click on the reload button",
                {
                    duration: 5000,
                }
            );
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error(
                    "Axios Error:",
                    error.response?.data?.message ||
                        "Failed to create access code."
                );
            } else if (error instanceof Error) {
                console.error("Error:", error.message);
            } else {
                console.error("Unknown error:", error);
            }
            toast.error("Oops! There was an error creating access code.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Form id="formCodeCreation" onSubmit={handleSubmit}>
            {/* Type */}
            <Form.Group className="mb-4" controlId="formCodeType">
                <div className="d-flex align-items-center gap-2 mb-2">
                    <Form.Label className="mb-0">Select Type</Form.Label>
                    <BsFillInfoCircleFill
                        className="d-flex align-items-center text-secondary opacity-75 cursor-pointer"
                        onMouseOver={() => setShowInfoPopup(true)}
                        onMouseOut={() => setShowInfoPopup(false)}
                    />
                    {showInfoPopup && (
                        <div
                            className="z-1 position-relative flex-grow-1"
                            style={{ marginTop: "-1rem" }}
                        >
                            <div className="position-absolute top-0 left-0 right-0 w-100 h-100">
                                <div
                                    className="p-3 shadow rounded-1 border border-dark-subtle small-text text-secondary"
                                    style={{ backgroundColor: "#F9F9F9" }}
                                >
                                    <p className="mb-3">
                                        <span className="text-dark">
                                            Permanent:
                                        </span>{" "}
                                        This is a passcode that is valid at all
                                        times.
                                    </p>
                                    <p className="mb-3">
                                        <span className="text-dark">
                                            Temporary:
                                        </span>{" "}
                                        This is a passcode that can only be
                                        available for a certain period. The
                                        maximum period is 1 year.
                                    </p>
                                    <p>
                                        <span className="text-dark">
                                            One Time:
                                        </span>{" "}
                                        This is a passcode that is valid only
                                        once and it lasts only 24 hours from
                                        start.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Form.Select
                    className="shadow-none"
                    value={type}
                    onChange={(e) => setType(e.currentTarget.value)}
                >
                    <option value="" disabled>
                        Select
                    </option>
                    <option value="permanent">Permanent</option>
                    <option value="temporary">Temporary</option>
                    <option value="onetime">One-Time</option>
                </Form.Select>
                <ErrorMessage error={error} field="codeType" />
            </Form.Group>

            {/* Name */}
            <Form.Group className="mb-4" controlId="formCodeName">
                <Form.Label>Code Name</Form.Label>
                <Form.Control
                    className="shadow-none"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    placeholder="My passcode"
                />
                <Form.Text className="pt-1 fs-8">
                    Can't be repeated from a previous code.
                </Form.Text>
                <ErrorMessage error={error} field="codeName" />
            </Form.Group>

            {/* Dates */}
            <Form.Group className="mb-4" controlId="formCodeName">
                <Form.Label>Set Duration</Form.Label>
                <Row className="g-2">
                    <Col>
                        <DateInput
                            className="border-secondary"
                            value={startDate}
                            valueFormat="MMMM D, YYYY"
                            onChange={setStartDate}
                            minDate={new Date()}
                            placeholder="Start"
                            popoverProps={{
                                // Bootstrap modal z-index is 1055
                                zIndex: 1060,
                                position: "right-start",
                            }}
                            styles={{
                                input: { cursor: "pointer" },
                            }}
                        />
                        <ErrorMessage error={error} field="startDate" />
                    </Col>
                    <Col hidden={type !== "temporary"}>
                        <DateInput
                            className="border-secondary"
                            value={endDate}
                            valueFormat="MMMM D, YYYY"
                            onChange={setEndDate}
                            minDate={startDate || new Date()}
                            placeholder="End"
                            popoverProps={{
                                // Bootstrap modal z-index is 1055
                                zIndex: 1060,
                                position: "right-start",
                            }}
                            styles={{
                                input: { cursor: "pointer" },
                            }}
                        />
                        <ErrorMessage error={error} field="endDate" />
                    </Col>
                </Row>
            </Form.Group>

            {/* Times */}
            <Form.Group className="mb-4" controlId="formCodeName">
                <Form.Label>Set Time</Form.Label>
                <Row className="g-2">
                    <Col>
                        <TimePicker
                            time={startTime}
                            setTime={setStartTime}
                            placeholder="Start"
                            minHour={minStartTime}
                        />
                        <ErrorMessage error={error} field="startTime" />
                    </Col>
                    <Col hidden={type !== "temporary"}>
                        <TimePicker
                            time={endTime}
                            setTime={setEndTime}
                            placeholder="End"
                            minHour={0}
                        />
                        <ErrorMessage error={error} field="endTime" />
                    </Col>
                </Row>
            </Form.Group>

            {/* Igloo requirement */}
            <p className="mb-4 text-dark">
                <span className="fw-medium text-danger">Warning:</span> All
                codes must be used at least once within 24 hours of their start,
                otherwise they will expire.
            </p>

            <div className="d-flex gap-3">
                <Button
                    className="w-100 py-2 border fw-semibold border-secondary-subtle"
                    variant="light"
                    onClick={handleCloseModal}
                    disabled={isLoading}
                >
                    Cancel
                </Button>

                <Button
                    className="w-100 py-2 bg-delet fw-semibold border-0"
                    type="submit"
                    disabled={isLoading}
                >
                    Save Changes
                </Button>
            </div>
        </Form>
    );
}
