import { Dropdown } from "react-bootstrap";

interface TimePickerProps {
    time: string;
    setTime: (_time: string) => void;
    placeholder: string;
    minHour: number;
}

export default function TimePicker({
    time,
    setTime,
    placeholder,
    minHour,
}: TimePickerProps) {
    // Generate an array of available hours from 00 to totalHours-1
    const availableHours = Array.from({ length: 24 }, (_, i) => i);

    // Format hours to 12-hour format with AM/PM
    const formatHour = (hour: number) => {
        const period = hour < 12 ? "am" : "pm";
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        return `${formattedHour}:00 ${period}`;
    };

    // Store in 24-hour format
    const handleSelect = (hour: number) => {
        const timeString = `${hour.toString().padStart(2, "0")}:00`;
        setTime(timeString);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle
                className="w-100 ps-3 fs-7 text-dark text-start bg-white"
                aria-label="Select time"
            >
                {time
                    ? formatHour(parseInt(time.split(":")[0], 10)) // Convert selected time to 12-hour format
                    : placeholder}
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="w-100 px-2"
                style={{ maxHeight: "216px", overflowY: "auto" }}
            >
                {availableHours
                    .filter((hour) => hour >= minHour)
                    .map((hour) => (
                        <Dropdown.Item
                            key={hour}
                            className="fs-7 px-2 py-0 rounded"
                            style={{ height: "40px", lineHeight: "40px" }}
                            onClick={() => handleSelect(hour)}
                        >
                            {formatHour(hour)}
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
