import React, { useEffect, useRef, useState } from "react";
import { propertyStatus } from "../../../static/Data";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchStatus } from "../../../api/properties";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import UnstyledButton from "../../../components/UnstyledButton";

const PropertyStatusSelect = ({ queryKey, property }) => {
  const { status, _id: propertyId } = property;
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync: handleChangeStatus, isPending } = useMutation({
    mutationFn: ({ propertyId, newStatus }) =>
      patchStatus(propertyId, newStatus),
    onSuccess: (_, { newStatus }) => {
      queryClient.setQueryData(queryKey, (prev) => {
        const newObjRes = { ...prev };
        const newPropertiesArray = [...newObjRes.properties];
        const index = newPropertiesArray.findIndex(
          (property) => property._id === propertyId
        );
        if (index !== -1) {
          newPropertiesArray[index] = {
            ...newPropertiesArray[index],
            status: newStatus,
          };
        }
        newObjRes.properties = newPropertiesArray;
        return newObjRes;
      });
    },
  });

  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className={`position-relative ${
        isPending ? "pe-none opacity-50" : ""
      } border rounded-5 px-1 py-lg-2 property-${status
        .split(" ")
        .join("-")} h-content`}
      ref={selectRef}
    >
      <UnstyledButton
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer d-flex gap-2 align-items-center booking-option bg-transparent ${status} text-capitalize mw-select`}
      >
        <span className="flex-grow-1 text-truncate small-text">{status}</span>{" "}
        <span className="w-content">
          {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </span>
      </UnstyledButton>

      {isOpen && (
        <div className="position-absolute bg-white shadow rounded z-3 property-status-options-menu top-100">
          {propertyStatus.map((option) => (
            <div
              key={option.status}
              onClick={() => {
                setIsOpen(false);
                handleChangeStatus({ newStatus: option.status, propertyId });
              }}
              className={`p-2 hover:bg-gray-100 cursor-pointer d-flex gap-2 align-items-center color-gray`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PropertyStatusSelect;
