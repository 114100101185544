import { Dropdown } from "react-bootstrap";

const sortByInitState = {
    name: "",
    type: "asc",
};

export default function TableHeader({
    filters,
    handleFilters,
    sortBy,
    setSortBy,
}) {
    return (
        <div className="container-fluid p-0">
            <div className="row row-cols-6 align-items-center gy-0 gx-3 py-2 border-bottom">
                {/* Name */}
                <div className="col">
                    <span className="table-h fw-semibold fs-6">Name</span>
                </div>

                {/* Code */}
                <div className="col">
                    <span className="table-h fw-semibold fs-6">Code</span>
                </div>

                {/* Status */}
                <Dropdown className="col">
                    <Dropdown.Toggle className="bg-white border-0 table-h fw-semibold fs-6 p-0">
                        Status
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ps-2">
                        <div className="d-flex gap-2">
                            <input
                                type="checkbox"
                                value="active"
                                id="active"
                                onChange={(e) =>
                                    handleFilters(
                                        "status",
                                        e.target.value,
                                        e.target.checked
                                    )
                                }
                                checked={filters.status.includes("active")}
                            />
                            <label htmlFor="active" className="fw-normal">
                                Active
                            </label>
                        </div>

                        <div className="d-flex gap-2">
                            <input
                                type="checkbox"
                                value="expired"
                                id="expired"
                                onChange={(e) =>
                                    handleFilters(
                                        "status",
                                        e.target.value,
                                        e.target.checked
                                    )
                                }
                                checked={filters.status.includes("expired")}
                            />
                            <label
                                className="form-check-label fw-normal"
                                htmlFor="expired"
                            >
                                Expired
                            </label>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                {/* Type */}
                <Dropdown className="col">
                    <Dropdown.Toggle className="bg-white border-0 table-h fw-semibold fs-6 p-0">
                        Type
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ps-2">
                        <div className="d-flex gap-2">
                            <input
                                type="checkbox"
                                value="temporary"
                                id="temporary"
                                onChange={(e) =>
                                    handleFilters(
                                        "type",
                                        e.target.value,
                                        e.target.checked
                                    )
                                }
                                checked={filters.type.includes("temporary")}
                            />
                            <label htmlFor="temporary" className="fw-normal">
                                Temporary
                            </label>
                        </div>

                        <div className="d-flex gap-2">
                            <input
                                type="checkbox"
                                value="permanent"
                                id="permanent"
                                onChange={(e) =>
                                    handleFilters(
                                        "type",
                                        e.target.value,
                                        e.target.checked
                                    )
                                }
                                checked={filters.type.includes("permanent")}
                            />
                            <label
                                className="form-check-label fw-normal"
                                htmlFor="permanent"
                            >
                                Permanent
                            </label>
                        </div>

                        <div className="d-flex gap-2">
                            <input
                                type="checkbox"
                                value="one-time"
                                id="one-time"
                                onChange={(e) =>
                                    handleFilters(
                                        "type",
                                        e.target.value,
                                        e.target.checked
                                    )
                                }
                                checked={filters.type.includes("one-time")}
                            />
                            <label htmlFor="one-time" className="fw-normal">
                                One-time
                            </label>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                {/* Start */}
                <Dropdown className="col">
                    <Dropdown.Toggle
                        className="bg-white border-0 table-h fw-semibold fs-6 p-0"
                        onDoubleClick={() => setSortBy(sortByInitState)}
                    >
                        Start
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ps-2">
                        <div className="d-flex gap-2 fw-normal">
                            <input
                                type="radio"
                                id="startLatest"
                                onChange={() =>
                                    setSortBy({ name: "start", type: "desc" })
                                }
                                checked={
                                    sortBy.name === "start" &&
                                    sortBy.type === "desc"
                                }
                            />
                            <label htmlFor="startLatest" className="fw-normal">
                                Latest first
                            </label>
                        </div>

                        <div className="d-flex gap-2">
                            <input
                                type="radio"
                                id="startRecent"
                                onChange={() =>
                                    setSortBy({ name: "start", type: "asc" })
                                }
                                checked={
                                    sortBy.name === "start" &&
                                    sortBy.type === "asc"
                                }
                            />
                            <label htmlFor="startRecent" className="fw-normal">
                                Earliest first
                            </label>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                {/* End */}
                <Dropdown className="col">
                    <Dropdown.Toggle
                        className="bg-white border-0 table-h fw-semibold fs-6 p-0"
                        onDoubleClick={() => setSortBy(sortByInitState)}
                    >
                        End
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ps-2">
                        <div className="d-flex gap-2">
                            <input
                                type="radio"
                                id="endLatest"
                                onChange={() =>
                                    setSortBy({ name: "end", type: "desc" })
                                }
                                checked={
                                    sortBy.name === "end" &&
                                    sortBy.type === "desc"
                                }
                            />
                            <label htmlFor="endLatest" className="fw-normal">
                                Latest first
                            </label>
                        </div>

                        <div className="d-flex gap-2">
                            <input
                                type="radio"
                                id="endRecent"
                                onChange={() =>
                                    setSortBy({ name: "end", type: "asc" })
                                }
                                checked={
                                    sortBy.name === "end" &&
                                    sortBy.type === "asc"
                                }
                            />
                            <label htmlFor="endRecent" className="fw-normal">
                                Earliest first
                            </label>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}
