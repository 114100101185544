import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "../Steps/style.module.scss";
import rescheduleStyle from "../RescheduleSteps/style.module.scss";
import check from "../../../../assests/images/check.svg";
import meeting from "../../../../assests/images/Step1.svg";
import greeting from "../../../../assests/images/Step0.svg";
import keys from "../../../../assests/images/Step2.svg";
import checklist from "../../../../assests/images/Step3.svg";
import innovation from "../../../../assests/images/Step4.svg";
import movein from "../../../../assests/images/Step5.svg";
import Logo from "../../../../assests/images/logo-dark.svg";
import { StepNumberLabel } from "../Steps/StepNumberLabel";
import Step1 from "../Steps/Step1/Step1";
import Step2 from "../Steps/Step2/Step2";
import { useMutation } from "@tanstack/react-query";
import {
  createStep1,
  createStep2,
  createStep3,
  createStep4,
  createStep5,
} from "../../../../api/bookings";
import { AppButton } from "../../../../designSystem/AppButton";
import { PropertyDetail } from "../PropertyDetail";
import { DrawerModalContainer } from "../../../../designSystem/DrawerModalContainer";
import { SelectedPropertyCard } from "../RescheduleSteps/SelectedProperyCard";
import Step3 from "../Steps/step3/Step3";
import Step4 from "../Steps/Step4/Step4";
import Step5 from "../Steps/Step5/Step5";
import { Modal } from "react-bootstrap";
import { RiErrorWarningFill } from "react-icons/ri";
import IdVerification from "../IdVerification";

export const OnlyOnePropertySteps = ({ property }) => {
  const { idVerification } = property;
  const { propertyId, agentId } = useParams();
  const [inputValues, setInputValues] = useState({
    propertyId: property?._id,
    agent: agentId,
  });
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [alertModal, setAlertModal] = useState({
    show: false,
    title: "",
    content: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    phone: "",
    email: "",
    document: "",
    privacyPolicy: "",
  });
  const [isLoadingStep, setIsLoadingStep] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false)

  const onOpen = () => {
    navigate(`/tour-property/${property?._id}`);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      [e.target.name]: "",
    }));
    setInputValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onPrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
    setErrors({
      fullName: "",
      phone: "",
      email: "",
      document: "",
      privacyPolicy: "",
    });
  };

  // Step 1 Mutation
  const { mutateAsync: handleStep1 } = useMutation({
    mutationFn: (input) => createStep1(input),
    onSuccess: (data) => {
      setInputValues((prev) => ({
        ...prev,
        bookingId: data.bookingId,
        contactId: data.contactId,
      }));
      setIsLoadingStep(true);
      setLoaderMessage(
        `Welcome ${inputValues.firstName} ${inputValues.lastName}`
      );
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep((prevStep) => prevStep + 1);
      }, 1000);
    },
    onError: () => {
      setErrors((prev) => ({
        ...prev,
        fullName: "A First and Last Name is required",
      }));
    },
  });

  // Step 2 Mutation
  const { mutateAsync: handleStep2 } = useMutation({
    mutationFn: (input) => createStep2(input),
    onSuccess: () => {
      setIsLoadingStep(true);
      setLoaderMessage(
        `Thanks ${inputValues.firstName}! Just a few more steps.`
      );
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep((prevStep) => prevStep + 1);
      }, 1000);
    },
    onError: () => {
      setErrors((prev) => ({
        ...prev,
        phone: "Incomplete or invalid phone number e.g. 3109137313",
      }));
    },
  });

  // Step 3 Mutation
  const { mutateAsync: handleStep3 } = useMutation({
    mutationFn: (input) => createStep3(input),
    onSuccess: () => {
      setIsLoadingStep(true);
      setLoaderMessage(`You're doing amazing ${inputValues.firstName}!`);
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep((prevStep) => prevStep + 1);
      }, 1000);
    },
    onError: () => {
      setErrors((prev) => ({ ...prev, email: "Incomplete or invalid email" }));
    },
  });

  // Step 4 Mutation
  const { mutateAsync: handleStep4 } = useMutation({
    mutationFn: (input) => createStep4(input),
    onSuccess: () => {
      setIsLoadingStep(true);
      setLoaderMessage(`Perfect! We've almost got you booked!`);
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep((prevStep) => prevStep + 1);
      }, 1000);
    },
    onError: () => {
      setErrors((prev) => ({
        ...prev,
        general: "There was an issue with Step 4. Please try again.",
      }));
    },
  });

  // Step 5 Mutation
  const { mutateAsync: handleStep5, isPending: isLoadingBooking } = useMutation(
    {
      mutationFn: (input) => createStep5(input),
      onSuccess: (booking) => {
        navigate(`/book/confirmation/${booking.booking}`);
      },
      onError: () => {
        // setErrors(prev => ({ ...prev, document: 'An ID is required to schedule the tour' }));
        // setErrors(prev => ({ ...prev, privacyPolicy: 'Please check the Terms & Conditions in order to proceed' }));
      },
    }
  );

  const onNext = async () => {
    setButtonLoading(true);
    setTimeout(() => setButtonLoading(false), 500)

    if (step === 0) {
      setStep((prevStep) => prevStep + 1);
    }
    if (step === 1) {
      if (!inputValues.firstName || !inputValues.lastName) {
        setErrors((prev) => ({
          ...prev,
          firstName: "First Name is required",
          lastName: "Last Name is required",
        }));
        setButtonLoading(false);
        return;
      }
      await handleStep1(inputValues);
    } else if (step === 2) {
      const phoneRegex = /^\d{10}$/i;
      if (inputValues.phone && !phoneRegex.test(inputValues.phone)) {
        setErrors((prev) => ({
          ...prev,
          phone: "Incomplete or invalid phone number e.g. 3109137313",
        }));
        setButtonLoading(false);
        return;
      }
      await handleStep2(inputValues);
    } else if (step === 3) {
      if (!inputValues.email) {
        setErrors((prev) => ({ ...prev, email: "Email is required" }));
        setButtonLoading(false);
        return;
      }
      await handleStep3(inputValues);
    } else if (step === 4) {
      if (!inputValues.localTimeRequested) {
        setErrors((prev) => ({
          ...prev,
          localTimeRequested: "Time is required",
        }));
        setButtonLoading(false);
        return;
      }
      await handleStep4(inputValues);
    } else if (step === 5) {
      if (!inputValues.document) {
        setErrors((prev) => ({ ...prev, document: "Document is required" }));
        setButtonLoading(false);
        return;
      }
      await handleStep5(inputValues);
    }
  };

  const defineCircleContent = (labelStep) => {
    if (labelStep < step) {
      return <img src={check} alt="" />;
    } else {
      return String(labelStep);
    }
  };

  const handleNextDisable = () => {
    if (!inputValues) return true;
    if (step === 1 && !inputValues.firstName && !inputValues.lastName) {
      return true;
    }
    if (step === 3 && !inputValues.email) {
      return true;
    }
    if (step === 4 && !inputValues.localTimeRequested) {
      return true;
    }
    if (step === 5 && idVerification.active === true) {
      if(inputValues.verified !== true){
        return true;
      }
    }
    return false;
  };

  const isPhoneNumberValid = () => {
    const phoneRegex = /^\d{10}$/;
    const phoneWithDashesRegex = /^(\d{3}-\d{3}-\d{4})$/;

    if (!inputValues.phone) {
      handlePhoneNumberConfirmation();
      return;
    }

    if (phoneRegex.test(inputValues.phone)) {
      onNext();
    } else if (phoneWithDashesRegex.test(inputValues.phone)) {
      setErrors((prev) => ({
        ...prev,
        phone:
          "Phone number with dashes is not allowed. Please enter 10 digits without dashes.",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        phone:
          "Invalid phone number format. Please enter a valid 10-digit number (no dashes).",
      }));
    }
  };

  // Show Confirmation Popup for Phone Number
  const handlePhoneNumberConfirmation = () => {
    if (!inputValues.phone) {
      setAlertModal({
        show: true,
        title: "Are you sure?",
        content:
          "Please note that the information will be sent to you via email only.",
      });
    } else {
      onNext();
    }
  };

  // Close Modal and proceed as if email is entered
  const handleProceedWithEmail = () => {
    setAlertModal({ ...alertModal, show: false });
    onNext(); // Proceed as if the user selected "Proceed with Email"
  };

  // const { mutateAsync: meetingMutation, isPending: isLoadingBooking } =
  //   useMutation({
  //     mutationFn: (booking) =>
  //       createMeeting({
  //         ...booking,
  //         propertyId: propertyId ?? property._id,
  //         agent: agentId,
  //       }),
  //     onSuccess: (data) => {
  //       navigate(`/book/confirmation/${data.booking}`);
  //     },
  //   });

  //MARK: Inidividual Booking
  return (
    <div className={style.layoutGrid}>
      <div className={`${style.layout} ${rescheduleStyle.layout}`}>
        <div>
          {/* <img
              src='https://developmentapi.delet.com/images/delet-logo.png'
              alt='delet-logo'
              className='logo'
            /> */}
          <button
            className={`${step === 1 ? "d-none" : style.backButton}`}
            as="button"
            onClick={onPrev}
            disabled={step === 1}
            hierarchy="secondary"
            type="button"
          >
            {"< Back"}
          </button>
        </div>

        <div className={style.layoutSteps}>
          {isLoadingStep || buttonLoading ? (
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                marginTop: "300px",
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              ></div>
              <p
                className="mt-3"
                style={{ fontFamily: "Poppins", fontSize: "16px" }}
              >
                {loaderMessage}
              </p>
            </div>
          ) : (
            <>
              {step === 1 ? (
                <>
                  <p className={style.headerSubtitle}>
                    First Name and Last Name
                  </p>
                </>
              ) : (
                ""
              )}
              {step === 2 ? (
                <>
                  <p className={style.headerSubtitle}>Phone Number</p>
                </>
              ) : (
                ""
              )}
              {step === 3 ? (
                <>
                  <p className={style.headerSubtitle}>Email</p>
                </>
              ) : (
                ""
              )}
              {step === 4 ? (
                <>
                  <p className={style.headerSubtitle}>Pick a Date & Time</p>
                </>
              ) : (
                ""
              )}
              {step === 5 ? (
                <>
                  <p className={style.headerSubtitle}>Upload Your ID</p>
                </>
              ) : (
                ""
              )}

              <div className={style.stepLabels}>
                <StepNumberLabel
                  className={`${step !== 1 && style.inactive} `}
                  circleContent={defineCircleContent(1)}
                  state={
                    step === 1 ? "selected" : step > 1 ? "completed" : "default"
                  }
                  stepNumber={1}
                />
                <StepNumberLabel
                  className={`${step !== 2 && style.inactive} `}
                  circleContent={defineCircleContent(2)}
                  state={
                    step === 2 ? "selected" : step > 2 ? "completed" : "default"
                  }
                  stepNumber={2}
                />
                <StepNumberLabel
                  className={`${step !== 3 && style.inactive} `}
                  circleContent={defineCircleContent(3)}
                  state={
                    step === 3 ? "selected" : step > 3 ? "completed" : "default"
                  }
                  stepNumber={3}
                />
                <StepNumberLabel
                  className={`${step !== 4 && style.inactive} `}
                  circleContent={defineCircleContent(4)}
                  state={
                    step === 4 ? "selected" : step > 4 ? "completed" : "default"
                  }
                  stepNumber={4}
                />
                <StepNumberLabel
                  className={`${step !== 5 && style.inactive} `}
                  circleContent={defineCircleContent(5)}
                  state={
                    step === 5 ? "selected" : step > 5 ? "completed" : "default"
                  }
                  stepNumber={5}
                />
              </div>

              <div className={`${step === 1 && style.onlyOneProp}`}>
                {step === 1 ? (
                  <SelectedPropertyCard
                    className={`${rescheduleStyle.card}`}
                    property={property[0] || property}
                    onSeeDetails={onOpen}
                  />
                ) : null}

                {step === 1 ? (
                  <Step1
                    className={style.containerRes}
                    currentStep={step}
                    propertyId={property[0]?._id || property?._id}
                    onChange={handleInputChange}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ) : step === 2 ? (
                  <Step2
                    className={style.container}
                    currentStep={step}
                    propertyId={property[0]?._id || property?._id}
                    onChange={handleInputChange}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ) : step === 3 ? (
                  <Step3
                    document={inputValues.document}
                    documentBack={inputValues.documentBack}
                    face={inputValues.face}
                    className={style.container}
                    currentStep={step}
                    onChange={handleInputChange}
                    idVerification={idVerification}
                    verified={inputValues.verified}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ) : step === 4 ? (
                  <Step4
                    className={style.container}
                    currentStep={step}
                    propertyId={inputValues.propertyId}
                    onChange={handleInputChange}
                    timezone={property?.timezone ?? "America/Los_Angeles"}
                  />
                ) : step === 5 ? (
                  <Step5
                    document={inputValues.document}
                    documentBack={inputValues.documentBack}
                    face={inputValues.face}
                    className={style.container}
                    currentStep={step}
                    onChange={handleInputChange}
                    idVerification={idVerification}
                    verified={inputValues.verified}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ) : null}


                <div className={`${style.buttons} ${style.container}`}>
                  {/* <AppButton
                    className={style.button}
                    as="button"
                    onClick={onPrev}
                    disabled={step === 2}
                    hierarchy="secondary"
                    type="button"
                  >
                    {"< Go Back"}
                  </AppButton> */}
                  <AppButton
                    disabled={handleNextDisable() || isLoadingBooking}
                    className={style.button}
                    as="button"
                    type="button"
                    onClick={step !== 2 ? onNext : isPhoneNumberValid}
                  >
                    {isLoadingBooking ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <span className="d-flex fs-6 align-items-center">
                        {step !== 5 ? "Continue >" : "Schedule My Tour"}
                      </span>
                    )}
                  </AppButton>
                </div>
                <DrawerModalContainer
                  onOpen={onOpen}
                  onClose={onClose}
                  isOpen={open}
                >
                  <PropertyDetail property={property[0] || property} />
                </DrawerModalContainer>
              </div>
              {/* React Bootstrap Modal */}
              <Modal
                show={alertModal.show}
                onHide={() => setAlertModal({ ...alertModal, show: false })}
                centered
              >
                <div className={style.procModal}>
                  <RiErrorWarningFill size={50} color="#5271FF" />
                  <h2 className={style.modalTitle}>
                    You're about to schedule a tour without providing your phone
                    number
                  </h2>
                  <p className={style.modalDesc}>{alertModal.content}</p>

                  <div className="d-flex flex-row gap-3">
                    <AppButton
                      as="button"
                      className={style.modalButton}
                      hierarchy="secondary"
                      onClick={() =>
                        setAlertModal({ ...alertModal, show: false })
                      }
                    >
                      Cancel
                    </AppButton>
                    <AppButton
                      as="button"
                      className={style.modalButton}
                      onClick={handleProceedWithEmail}
                    >
                      Proceed with Email
                    </AppButton>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
      <div className={style.layoutImages}>
        <div
          className={step === 0 ? style.boxBackgroundZero : style.boxBackground}
        >
          {step === 0 ? (
            <>
              <img src={greeting} alt="" />
            </>
          ) : step === 1 ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-5">
              <img src={meeting} alt="" />
              <img src={Logo} width={107} height={25} alt="" />
            </div>
          ) : step === 2 ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-5">
              <img src={keys} alt="" />
              <img src={Logo} width={107} height={25} alt="" />
            </div>
          ) : step === 3 ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-5">
              <img src={checklist} alt="" />
              <img src={Logo} width={107} height={25} alt="" />
            </div>
          ) : step === 4 ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-5">
              <img src={innovation} alt="" />
              <img src={Logo} width={107} height={25} alt="" />
            </div>
          ) : step === 5 ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-5">
              <img src={movein} alt="" />
              <img src={Logo} width={107} height={25} alt="" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
