import { useDashboardContext } from "./DashboardContext";
import { useState, useEffect } from "react";
import { LineChart } from "@mantine/charts";
import { Card, Text, Flex, Center, Loader, Space, Grid } from "@mantine/core";
import { TbBuildingCommunity } from "react-icons/tb";
import { getShowings } from "../../api/dashboard";
import { calculatePercentageChange } from "./utils";
import CardHeader from "./CardHeader";
import CardInsight from "./CardInsight";
import CardLineChartCounts from "./CardLineChartCounts";

interface ChartData {
    dailyCount: {
        date: string;
        scheduled: number;
        show: number;
        missed: number;
        cancelled: number;
    }[];
    totalCount: {
        scheduled: number;
        show: number;
        missed: number;
        cancelled: number;
    };
}

export default function CardLineChart() {
    // Data fetching states for Line Chart
    const [chartData, setChartData] = useState<ChartData | null>(null);
    const [isChartLoading, setIsChartLoading] = useState<boolean>(true);

    // Get dashboard metrics data
    const { dateValue, rangeValue, metrics, isLoading } = useDashboardContext();

    // Filter dates
    const startDate = dateValue[0];
    const endDate = dateValue[1];

    useEffect(() => {
        // Validate dates input before fetching
        if (!startDate || !endDate) return;

        // Ensure dates range is valid
        const MAX_RANGE_DAYS = 60;
        const diffInDays = (endDate.getTime() - startDate.getTime()) / 86400000;
        if (diffInDays > MAX_RANGE_DAYS) return;

        // Fetch showing rate data
        const fetchData = async () => {
            setIsChartLoading(true);
            const data: ChartData = await getShowings(startDate, endDate);
            setChartData(data);
            setIsChartLoading(false);
        };

        fetchData();
    }, [startDate, endDate]);

    // Get rate percentage
    const metricData = metrics["showings"] ?? {
        current: 0,
        past: 0,
    };
    const { current, past } = metricData;
    const rate = calculatePercentageChange(current ?? 0, past ?? 0);

    return (
        <Card
            shadow="md"
            px={{ base: 15, md: "xl" }}
            py={{ base: 20, md: "xl" }}
            radius="md"
            withBorder
        >
            {/* Render the Chart */}
            <Grid gutter="lg">
                <Grid.Col span={{ base: 12, md: 8, xl: 9 }}>
                    {/* Render the Title */}
                    <CardHeader
                        Icon={TbBuildingCommunity}
                        iconColor="orange.6"
                        iconBgColor="orange.0"
                        title="Showings"
                    />

                    <Space h={{ base: "md", md: "xl" }} />

                    {/* Render the Metric Value */}
                    {isLoading ? (
                        <Center style={{ height: "100px" }}>
                            <Loader size="sm" color="indigo" />
                        </Center>
                    ) : rate ? (
                        <Flex gap="md" direction="column" align="flex-start">
                            {/* Metric number mobile */}
                            <Text
                                className="d-block d-md-none"
                                size="28px"
                                fw={600}
                                c="gray.8"
                            >
                                {current}
                            </Text>

                            {/* Metric number desktop */}
                            <Text
                                className="d-none d-md-block"
                                size="40px"
                                fw={600}
                                c="gray.8"
                            >
                                {current}
                            </Text>

                            <CardInsight rate={rate} rangeValue={rangeValue} />
                        </Flex>
                    ) : (
                        // No Data Available Message
                        <Center style={{ height: "100px" }}>
                            <Text ta="center" c="gray.6">
                                No data available
                            </Text>
                        </Center>
                    )}

                    <Space h={{ base: "lg", md: "xl" }} />

                    {/* Render the bookings count */}
                    <div className="d-block d-md-none">
                        <CardLineChartCounts chartData={chartData} />
                    </div>

                    <Space h={{ base: "lg", md: "0" }} />

                    {/* Render the Area Chart */}
                    {isChartLoading ? (
                        <Center style={{ height: "330px" }}>
                            <Loader color="indigo" />
                        </Center>
                    ) : chartData && chartData.dailyCount ? (
                        <LineChart
                            style={{
                                marginLeft: "-25px",
                                width: "calc(100% + 20px)",
                            }}
                            h={272}
                            data={chartData.dailyCount}
                            dataKey="date"
                            series={[
                                {
                                    name: "scheduled",
                                    label: "Scheduled",
                                    color: "#5271FF",
                                },
                                {
                                    name: "show",
                                    label: "Showed Up",
                                    color: "#9499A1",
                                },
                                {
                                    name: "missed",
                                    label: "Didn't Show Up",
                                    color: "#6D6C80",
                                },
                                {
                                    name: "cancelled",
                                    label: "Cancelled",
                                    color: "#BCC5E9",
                                },
                            ]}
                            curveType="monotone"
                            gridAxis="none"
                            withDots={false}
                        />
                    ) : (
                        <Center style={{ height: "300px" }}>
                            <Loader color="indigo" />
                        </Center>
                    )}
                </Grid.Col>

                {/* Render the bookings count */}
                <Grid.Col
                    className="d-none d-md-flex justify-content-center align-items-center"
                    span={{ base: 12, md: 4, xl: 3 }}
                >
                    <CardLineChartCounts chartData={chartData} />
                </Grid.Col>
            </Grid>
        </Card>
    );
}
