import {
  Table,
  Container,
  Button,
  Form,
  InputGroup,
  Pagination,
} from "react-bootstrap";
import { FaSort, FaSortUp, FaSortDown, FaSearch, FaSync } from "react-icons/fa";
import PageHeader from "../../../../components/Headers/PageHeader";
import { Link } from "react-router-dom";

const CompanyListTable = ({
  companies = [],
  totalCompanies = 0,
  page = 1,
  limit = 10,
  sortField = "name",
  sortDirection = "asc",
  searchTerm = "",
  onPageChange = () => {},
  onLimitChange = () => {},
  onSort = () => {},
  onSearch = () => {},
  onRefresh = () => {},
  isLoading,
  isSuperadmin,
}) => {
  const handleSort = (field) => {
    const newDirection =
      field === sortField && sortDirection === "asc" ? "desc" : "asc";
    onSort(field, newDirection);
  };

  const getSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalCompanies / limit);

  // Generate page items
  const renderPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5;

    // Always show first page
    items.push(
      <Pagination.Item
        key={1}
        active={page === 1}
        onClick={() => onPageChange(1)}
      >
        1
      </Pagination.Item>
    );

    // Calculate start and end pages to show
    let startPage = Math.max(2, page - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxPagesToShow - 3);

    // Adjust if we're near the beginning
    if (startPage > 2) {
      items.push(<Pagination.Ellipsis key="ellipsis-1" />);
    }

    // Add middle pages
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={page === i}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add ellipsis if needed
    if (endPage < totalPages - 1) {
      items.push(<Pagination.Ellipsis key="ellipsis-2" />);
    }

    // Always show last page if there is more than one page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item
          key={totalPages}
          active={page === totalPages}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Container fluid className={isLoading && "opacity-50 pe-none"}>
        <PageHeader>
          <div className="d-flex justify-content-between align-items-center ">
              <h4>Companies</h4>
            <div className="d-flex gap-2 align-items-center">
              <Button
                as={Link}
                variant="outline-secondary"
                size="sm"
                to="/admin/company/create"
              >
                Create
              </Button>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={onRefresh}
                title="Refresh data"
              >
                <FaSync />
              </Button>
              <InputGroup>
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search companies..."
                  value={searchTerm}
                  onChange={(e) => onSearch(e.target.value)}
                />
              </InputGroup>
            </div>
          </div>
        </PageHeader>

      <Table striped bordered hover responsive className="mt-3">
        <thead>
          <tr>
            <th onClick={() => handleSort("name")} className="cursor-pointer">
              Company Name {getSortIcon("name")}
            </th>
            <th onClick={() => handleSort("email")} className="cursor-pointer">
              Email {getSortIcon("email")}
            </th>
            <th onClick={() => handleSort("phone")} className="cursor-pointer">
              Phone {getSortIcon("phone")}
            </th>
            <th
              onClick={() => handleSort("description")}
              className="cursor-pointer"
            >
              Description {getSortIcon("description")}
            </th>
            <th>#Users</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies.length > 0 ? (
            companies.map((company, index) => (
              <tr key={index}>
                <td className="align-middle">
                  <div className="d-flex align-items-center">
                    {company.logoUrl && (
                      <img
                        src={company.logoUrl}
                        alt={`${company.name} logo`}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    {company.name}
                  </div>
                </td>
                <td className="align-middle">{company.email}</td>
                <td className="align-middle">{company.phone}</td>
                <td className="align-middle">
                  {company.description && company.description.length > 100
                    ? `${company.description.substring(0, 100)}...`
                    : company.description}
                </td>
                <td className="align-middle text-center">
                  {company.usersCount ?? 0}
                </td>
                <td className="align-middle">
                  <div className="d-flex gap-2">
                    <Button
                      as={Link}
                      variant="outline-primary"
                      size="sm"
                      to={`/admin/property/list?company=${company._id}`}
                    >
                      Properties
                    </Button>
                    <Button
                      as={Link}
                      variant="outline-info"
                      size="sm"
                      to={`/admin/user/list?company=${company._id}`}
                    >
                      Users
                    </Button>
                    <Button
                      as={Link}
                      variant="outline-secondary"
                      size="sm"
                      to={`/admin/showings?companies=${company._id}`}
                    >
                      Bookings
                    </Button>
                    <Button
                      as={Link}
                      variant="outline-secondary"
                      size="sm"
                      className={!isSuperadmin ? "d-none" : ""}
                      to={`/admin/company/edit/${company._id}`}
                    >
                      Edit
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center py-3">
                {isLoading ? "Loading..." : "No companies found"}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center mt-3 mx-2">
        <div>
          <span className="me-2">Rows per page:</span>
          <Form.Select
            style={{ width: "auto", display: "inline-block" }}
            value={limit}
            onChange={(e) => onLimitChange(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Select>
        </div>

        <div>
          <span className="me-2">
            Showing {companies.length > 0 ? (page - 1) * limit + 1 : 0} -{" "}
            {Math.min(page * limit, totalCompanies)} of {totalCompanies}
          </span>

          <Pagination className="d-inline-flex mb-0">
            <Pagination.Prev
              disabled={page === 1}
              onClick={() => onPageChange(page - 1)}
            />
            {renderPaginationItems()}
            <Pagination.Next
              disabled={page === totalPages}
              onClick={() => onPageChange(page + 1)}
            />
          </Pagination>
        </div>
      </div>
    </Container>
  );
};

export default CompanyListTable;
