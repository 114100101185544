import { DateTime } from "luxon";
import ExpandableText from "./ExpandableText";
import { IoIosAlert } from "react-icons/io";

function List({ kit = {}, kitHistory = [], hideFields = [] }) {
  return (
    <table className="table">
      <thead className="">
        <tr className="w-100">
          <th className="fw-semibold fs-6">Date</th>
          <th className="fw-semibold fs-6">Action</th>
          <th className="fw-semibold fs-6">Message</th>

          {!hideFields.includes("user") && <th className="fw-semibold fs-6">User</th>}

          <th className="d-none d-lg-table-cell fw-semibold fs-6">Duration</th>
        </tr>
      </thead>
      <tbody>
        {kitHistory.length === 0 ? (
          <div className="d-flex bg-delet-pink justify-content-between align-items-center rounded-top w-100 p-1 m-3">
              <div className="d-flex gap-3 bg-delet-pink justify-content-between align-items-center w-100">
                  <div className="d-flex gap-1 align-items-center text-body-secondary">
                      <IoIosAlert
                          className="flex-shrink-0"
                          color="#929292"
                          size={25}
                      />
                      <span className="d-none d-md-inline">
                          It looks like there is no kit history yet.
                      </span>
                      <span className="d-inline d-md-none small-text color-gray">
                      There is no history yet
                  </span>
                  </div>
              </div>
          </div>
        ) : (
          kitHistory.map((history, idx) => {
            let actionText, actionBg;
            switch (history?.action) {
              case "UNASSIGN_PROPERTY_FROM_KIT":
                actionText = "Unassigned Property";
                actionBg = "kha-unassign-property";
                break;
              case "ASSIGN_PROPERTY_TO_KIT":
                actionText = "Assigned Property";
                actionBg = "kha-assign-property";
                break;
              case "UNASSIGN_USER_FROM_KIT":
                actionText = "Unassigned User";
                actionBg = "kha-unassign-user";
                break;
              case "ASSIGN_USER_TO_KIT":
                actionText = "Assigned User";
                actionBg = "kha-assign-user";
                break;
              default:
                actionText = "Unknown Action";
                actionBg = "kha-unknown";
            }

            const duration = history?.lastPropertyAssignmentDuration;
            return (
              <tr key={idx} className="">
                <td className="small-text text-wrap align-middle">
                  {history.datetime
                    ? DateTime.fromMillis(history.datetime).toLocaleString(
                        DateTime.DATETIME_FULL
                      )
                    : "Date Error"}
                </td>

                <td className="align-middle">
                  <div className="d-flex align-items-center h-100">
                    <span
                      className={`rounded-5 py-1 px-3 ${actionBg} text-truncate bg-kit flex-grow-1 fw-medium text-center`}
                    >
                      {actionText}
                    </span>
                  </div>
                </td>

                <td className="position-relative align-middle">
                  <ExpandableText text={history.message} />
                </td>

                {!hideFields.includes("user") && (
                  <td className="">{history?.actionByUser?.email}</td>
                )}

                <td className="d-none d-lg-table-cell align-middle">
                  <span>
                    {duration
                      ? `${
                          duration?.days > 0
                            ? `${Math.ceil(duration?.days)} days`
                            : ""
                        } ${
                          duration?.hours > 0
                            ? `${Math.ceil(duration?.hours)} hours`
                            : ""
                        } ${
                          duration?.minutes > 0
                            ? `${Math.ceil(duration?.minutes)} minutes`
                            : ""
                        }`
                      : "-"}
                  </span>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default List;
