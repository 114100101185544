import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getBookings = async ({ monthOffset, companies = "", properties = "", agents = "", isAdmin = false, firstName = "", lastName = "" }) => {
  const token = await Auth.currentSession();
  const adminApi = isAdmin ? "/admin" : "";

  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking${adminApi}/calendar/${monthOffset}?companies=${companies}&properties=${properties}&agents=${agents}&firstName=${firstName}&lastName=${lastName}`
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      }
    });
    if (response.status !== 200) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const bookings = response.data
    return bookings
  } catch (error) {
    console.error("Error getting bookings: ", error)
    throw new Error(error.response ? error.response.data.message : "Failed to fetch bookings");
  }
};

export const getMeeting = async (meetingId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/${meetingId}`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log('ERROR GETTING MEETING: ', error);
  }
};

export const getMeetingWithToken = async (token) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/reschedule?token=${token}`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log('ERROR GETTING MEETING: ', error);
  }
};

export const getMonthAvailability = async (id, agent, month, year) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${id}/month-availability?month=${month}&year=${year}&agent=${agent}`;
  const res = await fetch(url);
  const bookingInfo = await res.json();

  return bookingInfo;
};

export const rescheduleMeeting = async (token, rescheduledDate) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/reschedule?token=${token}`;
  const body = rescheduledDate
  const res = await axios.patch(url, body);
  return res.data;
};

export const createMeeting = async (booking) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public`;
  const res = await axios.post(url, booking);
  return res.data;
};

// Step 1: Create the initial booking and contact
export const createStep1 = async (data) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/step1`;
  const response = await axios.post(url, data);
  return response.data;  // Returns bookingId and contactId
};

// Step 2: Update the phone number
export const createStep2 = async (data) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/step2`;
  const response = await axios.post(url, { bookingId: data.bookingId, contactId: data.contactId, phone: data.phone });
  return response.data;
};

// Step 3: Update the email
export const createStep3 = async (data) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/step3`;
  const response = await axios.post(url, { bookingId: data.bookingId, contactId: data.contactId, email: data.email });
  return response.data;
};

// Step 4: Update the booking date and time
export const createStep4 = async (data) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/step4`;
  const response = await axios.post(url, { bookingId: data.bookingId, localTimeRequested: data.localTimeRequested, timezone: data.timezone });
  return response.data;
};

// Step 5: Finalize the booking with the document and other details
export const createStep5 = async (data) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/step5`;
  const response = await axios.post(url, { bookingId: data.bookingId, contactId: data.contactId, document: data.document });
  return response.data;
};


export const postBooking = async (booking) => {
  const token = await Auth.currentSession();
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking`;
  const res = await axios.post(url, booking, {
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    }
  });
  return res.data;
};

export const updateBooking = async (bookingId, updatedFields) => {
  const token = await Auth.currentSession();
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/${bookingId}`;
  const res = await axios.patch(url, updatedFields, {
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    }
  });
  return res.data;
};

export const updateBookingStatus = async (bookingId, newStatus) => {
  const token = await Auth.currentSession();
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/status/${bookingId}`;
  const res = await axios.patch(url, { newStatus }, {
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    }
  });
  return res.data;
};
