import { Auth } from "aws-amplify";
import axios from "axios";

export const getPropertyChangesHistory = async ({ pageParam = 0, queryKey }) => {
  const [_, documentId] = queryKey;
  const limit = 2;

  try {
    const token = await Auth.currentSession()
    const response = await axios.get(
      `${process.env.REACT_APP_NODE_API_URL}/api/history/admin/list/${documentId}?page=${pageParam}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching property history:', error);
    throw new Error(
      error.response?.data?.message ||
      'Failed to fetch property history. Please try again later.'
    );
  }
};