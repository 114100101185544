import { IoIosInformationCircle } from "react-icons/io";
import { AppButton } from "../designSystem/AppButton";

const Alert = ({ show = false, title = '', content = '', setShow, navigateTo }) => {
  return (
    show &&
    <div className="modal d-block" tabindex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-2">
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center gap-2 pt-1">
              <IoIosInformationCircle size={50} color='#5271ff' />
              <p className="modal-title">{title}</p>
              <div
                className="expired-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
          <div className="modal-footer pt-0 border-top-0">
            <div className="d-flex justify-content-center gap-3 w-100">
              {navigateTo ?
                <>
                  <AppButton
                    as="button"
                    className="bg-white border text-dark remove-btn"
                    // style={{color:"#4C4D52"}}
                    onClick={() => window.location.replace(navigateTo)}
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    as="button"
                    className="remove-btn"
                    onClick={setShow}
                  >
                    Save
                  </AppButton>
                </> :
                <AppButton
                  className="remove-btn px-4 py-1"
                  as="button"
                  onClick={setShow}
                >
                  Close
                </AppButton>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;

