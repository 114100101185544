interface ErrorMessageProps {
    error: { field: string; message: string } | null;
    field: string;
}

export default function ErrorMessage({ error, field }: ErrorMessageProps) {
    return error?.field === field ? (
        <p className="pt-1 fs-8 text-danger">{error.message}</p>
    ) : null;
}
