import React from "react";
import useViewport from "../../../hooks/useViewport";

const PropertyFormPanelHeader = ({ title, description, saveFormData }) => {
  const { width: screenWidth } = useViewport();
  return (
    <div>
      <div
        className={
          screenWidth > 755
            ? "px-5 py-3 d-flex justify-content-between"
            : "px-2 py-3 d-flex justify-content-between"
        }
        style={{
          background: "rgb(234, 234, 235)",
          color: "rgb(67, 68, 73)",
        }}
      >
        <h4 className="d-flex align-items-center">{title}</h4>
      </div>
      <p className={`${screenWidth > 755 ? "px-5 py-3" : "px-2 py-3"} body-s`}>
        {description}
      </p>
    </div>
  );
};

export default PropertyFormPanelHeader;
