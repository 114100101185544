import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import Sidebar from "../components/AdminSideBar";
import { Center, Loader } from "@mantine/core";

const states = [
  "checking stock",
  "awaiting stock",
  "assembling kit",
  "ready to ship",
  "shipped",
  "awaiting confirmation",
  "confirmed",
  "cancelled",
];

const EditKit = () => {
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState("");
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [kit, setKit] = useState({});
  const [updatedFields, setUpdatedFields] = useState({});
  const [isKitAssignedToUser, setIsKitAssignedToUser] = useState({});
  const params = useParams();

  useEffect(() => {
    async function getToken() {
      try {
        return await Auth.currentSession();
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }

    async function getOrderList(token) {
      try {
        const url =
          process.env.REACT_APP_NODE_API_URL + "/api/order/admin/list";
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setOrders(response.data);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut();
              navigate("/admin/login");
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          });
      } catch (eror) {
        console.log(error);
        navigate("/");
      }
    }

    async function getUsersList(token) {
      try {
        const url = `${
          process.env.REACT_APP_NODE_API_URL
        }/api/users/admin/list?format=minimal&role=${JSON.stringify({
          $regex: "owner|superadmin",
          $options: "i",
        })}`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setUsers(response.data);
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut();
              navigate("/admin/login");
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          });
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    }
    async function getUserAndKit() {
      const id = params.id;
      const token = await getToken();
      if (id && token) {
        getOrderList(token);
        getUsersList(token);
        gethardwarekit(token);
        fetchKit(id, token);
      } else {
        navigate("/admin/login");
      }
    }
    getUserAndKit();
  }, [error, navigate, params.id]);

  function gethardwarekit(token) {
    //hardware search
    var url = process.env.REACT_APP_NODE_API_URL + "/api/hardware?format=list";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
          setLoader(true);
        } else {
          setError("Error Getting Hardware");
          setShow(true);
        }
      })
      .catch((error) => {
        setError("Error Getting Hardware");
        setShow(true);
      });
  }

  function fetchKit(id, token) {
    var url = process.env.REACT_APP_NODE_API_URL + "/api/kits/" + id;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setKit(data);
          setIsKitAssignedToUser({ assignedDate: data.asignedDate });
          setLoader(false);
          setSkills(data.hardware);
        } else {
          setError("Error Getting Kit");
          setShow(true);
        }
      })
      .catch((error) => {
        setError("Error Getting Kit");
        setShow(true);
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setKit((prev) => ({
      ...prev,
      [name]: value,
    }));

    setUpdatedFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    setSelectValue(e);
  };

  const handleSkills = (skills) => {
    setSkills(skills || []);
    const hardwareIdList = skills.map((hardware) => hardware._id);
    setKit((prev) => {
      const updatedKit = { ...prev };
      updatedKit.hardware = hardwareIdList;
      return updatedKit;
    });

    setUpdatedFields((prev) => ({
      ...prev,
      hardware: hardwareIdList,
    }));
  };

  const onDelete = (e) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = skills.find((val) => val.name === buttonName);
    let filteredArray = skills.filter((item) => item !== removedValue);
    setSkills(filteredArray);
    setUpdatedFields((prev) => ({
      ...prev,
      hardware: filteredArray,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("These are the updatedFields", updatedFields);

    async function getToken() {
      try {
        return await Auth.currentSession();
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }

    if (Object.keys(updatedFields).length > 0) {
      const url = process.env.REACT_APP_NODE_API_URL + "/api/kits/" + params.id;
      const token = await getToken();
      axios
        .patch(url, updatedFields, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(
              "this si thge response after a kit is assigned",
              response
            );
            setUpdatedFields({});
            setError("Kit updated successfully");
            setShow(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Editing Kit");
            setShow(true);
          }
        });
    } else {
      setError("Change at least one field in order to update");
      setShow(true);
    }
  };

  const handleOrderChange = (e) => {
    const index = orders.findIndex((order) => order._id === e.target.value);
    setKit((prev) => {
      const updatedKit = { ...prev };
      updatedKit.order = orders[index]._id;
      updatedKit.user = orders[index].user._id;
      return updatedKit;
    });

    setUpdatedFields((prev) => {
      const updatedKit = { ...prev };
      updatedKit.order = orders[index]._id;
      updatedKit.user = orders[index].user._id;
      return updatedKit;
    });
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setKit((prev) => {
      const updatedKit = { ...prev };
      updatedKit.order = "disabled";
      updatedKit.user = value;
      return updatedKit;
    });

    setUpdatedFields((prev) => {
      const updatedKit = { ...prev };
      updatedKit.order = null;
      updatedKit.user = value === "" ? null : value;
      return updatedKit;
    });
  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Edit Kit</h1>
        </div>
        {loading === false ? (
          <Center h={100}>
            <Loader />
          </Center>
        ) : (
          <Form>
            <Row>
              <Col xxl={12} xl={12} lg={12}>
                <Card>
                  <Card.Header as="h5">Kit Details</Card.Header>
                  <Card.Body>
                    <Form method="post" onSubmit={handleSubmit}>
                      <Form.Group className="mb-20" controlId="Full-name">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label> Name</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Control
                              name="name"
                              type="text"
                              value={kit.name}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-20" controlId="Lockbox-code">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Description</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Control
                              name="description"
                              type="text"
                              value={kit.description}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-20" controlId="Lockbox-code">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Notes</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Control
                              name="notes"
                              type="text"
                              value={kit.notes}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-20" controlId="Mobile">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>tags</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Control
                              name="tags"
                              type="text"
                              value={kit.tags}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-20" controlId="Mobile">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Device ID</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Control
                              name="device_id"
                              type="text"
                              value={kit.device_id}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="mb-20" controlId="Email">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>State</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Select
                              name="state"
                              onChange={handleInputChange}
                              required
                              className="text-capitalize"
                              value={kit.state}
                            >
                              <option selected={true} disabled="disabled">
                                Select state
                              </option>
                              {states.map((state) => (
                                <>
                                  <option id={state} value={state}>
                                    {state}
                                  </option>
                                </>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="mb-20 d-none" controlId="order">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Allocate Order</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Select
                              name="order"
                              onChange={handleOrderChange}
                              value={updatedFields.order ?? kit.order}
                            >
                              <option
                                selected={true}
                                disabled="disabled"
                                value="disabled"
                              >
                                Select Order
                              </option>
                              {orders.map((order, index) => (
                                <>
                                  <option id={order?._id} value={order._id}>
                                    {order.user?.firstName}{" "}
                                    {order.user?.lastName} |{" "}
                                    {order.user?.company
                                      ? order.user?.company?.name
                                      : ""}{" "}
                                    |
                                    {" " +
                                      moment(order.createdAt).format(
                                        "MMM Do YY"
                                      )}{" "}
                                    |{" " + order._id}
                                  </option>
                                </>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="mb-20" controlId="Email">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Allocate User</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Select
                              name="user"
                              onChange={handleUserChange}
                              value={updatedFields.user ?? kit.user?._id}
                            >
                              <option selected={true} disabled="disabled">
                                Select User
                              </option>
                              <option value="">Unassign</option>
                              {users.map((user, index) => (
                                <>
                                  <option id={user?._id} value={user._id}>
                                    {user.firstName} {user.lastName} |{" "}
                                    {user.email} |{" "}
                                    {user.company ? user.company?.name : ""}{" "}
                                  </option>
                                </>
                              ))}
                            </Form.Select>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group
                        className="mb-20"
                        controlId="Building-manager"
                      >
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Service Type</Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Select
                              name=""
                              onChange={(event) =>
                                handleChange(event.target.value)
                              }
                              value={selectValue ? selectValue : kit.service}
                            >
                              <option value="Kit">Kit</option>
                              <option value="Kit plus installation">
                                Kit Plus Installation
                              </option>
                              <option value="Leasing Agency">
                                Leasing Agency
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className="mb-20" controlId="Mobile">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Associate Hardware</Form.Label>
                          </Col>
                          <Col lg={5}>
                            {skills === "" ? (
                              <Form.Label>No hardware selected</Form.Label>
                            ) : (
                              skills.map((element, index) => (
                                <>
                                  <Form.Control
                                    placeholder="Enter text"
                                    value={element.name}
                                  />

                                  <Button
                                    className="btn btn-primary waves-effect waves-light"
                                    name={element.name}
                                    onClick={onDelete}
                                  >
                                    Delete
                                  </Button>
                                </>
                              ))
                            )}
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-20" controlId="Buzzer-code">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Hardware Search </Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Select
                              options={data}
                              onChange={handleSkills}
                              getOptionValue={(option) => option.name}
                              getOptionLabel={(option) =>
                                option.kit
                                  ? option.kit.name
                                    ? option.name +
                                      " ( " +
                                      option.kit.name +
                                      " )"
                                    : option.name
                                  : option.name + " ( Available )"
                              }
                              value={skills}
                              isMulti
                            ></Select>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-20" controlId="Lockbox-code">
                        <Row className="align-items-center">
                          <Col lg={2}>
                            <Form.Label>Date Assigned </Form.Label>
                          </Col>
                          <Col lg={10}>
                            <Form.Control
                              name="date"
                              type="date"
                              value={moment(
                                isKitAssignedToUser.assignedDate
                              ).format("YYYY-MM-DD")}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <div className="col-lg-12">
                        <div className="text-center">
                          <Button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Save Kit
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Kit Updated </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default EditKit;
