import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";

const VideoLoader = ({ file, externalLoading, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="position-relative h-100 w-100 video-loader-container rounded">
      {(externalLoading || isLoading) && (
        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-black bg-opacity-75 z-1">
          <Spinner animation="border" variant="light" />
        </div>
      )}

      <ReactPlayer
        url={file.url}
        onReady={() => setIsLoading(false)}
        {...props}
      />
    </div>
  );
};

export default VideoLoader;
