import "../Leads.scss";
import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { MultiselectSearch } from "../../../../components/Selects/MultiselectSearch";
import { useQuery } from "@tanstack/react-query";
import { getAllAdminProperties } from "../../../../api/properties";
import DatePicker from "react-datepicker";
import { getCompanies } from "../../../../api/companies";

export const FiltersModal = ({
  show,
  handleClose,
  handleApplyFilters,
  byProperty = true,
  ...props
}) => {
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showDatePickerFrom, setShowDatePickerFrom] = useState(false);
  const [showDatePickerTo, setShowDatePickerTo] = useState(false);

  const {
    data: properties,
    isLoading,
  } = useQuery({
    queryKey: ["all-my-properties"],
    queryFn: () => getAllAdminProperties({ fields: "address shortAddress unit kit" }),
    enabled: byProperty,
  });

  const { 
    data: companies, 
     }
     = useQuery({
      queryKey: ['all-companies'],
      queryFn: () => getCompanies( {isAdmin: true, limit: 1000} )
  })

  const transformedCompanies = companies?.companies?.map((company) => ({
    ...company,
    displayValue: company?.name,  
  }));


  if (!show) return;

  const transformedProperties = properties?.map((property) => ({
    ...property,
    displayValue: property.unit ? `${property.shortAddress} Unit ${property.unit}` : `${property.shortAddress}`,
  }));

  return (
    <div className="filter-popup" {...props}>
      <div className="d-flex flex-column">
        <label htmlFor="date-range" className="input-label">
          Date
        </label>
        <div className="d-flex gap-2">
          <div className="input-icon">
            <CiCalendar color="rgb(153, 153, 153)" />
            <input
              id="date-range"
              className="date-inputs"
              placeholder="From"
              onClick={() => {
                setShowDatePickerFrom(!showDatePickerFrom);
                setShowDatePickerTo(false);
              }}
              value={startDate?.toLocaleDateString()}
            ></input>
          </div>

          <div className="input-icon">
            <CiCalendar color="rgb(153, 153, 153)" />
            <input
              id="date-range"
              className="date-inputs"
              placeholder="To"
              onClick={() => {
                setShowDatePickerTo(!showDatePickerTo);
                setShowDatePickerFrom(false);
              }}
              value={endDate?.toLocaleDateString()}
            ></input>
          </div>
        </div>
      </div>
      {byProperty && (
        <div className="d-flex flex-column">
          <label htmlFor="property" className="input-label">
            Property
          </label>
          <MultiselectSearch
            data={transformedProperties}
            selected={selectedProperties}
            handleSelectedProperties={(properties) =>
              setSelectedProperties(properties)
            }
            disable={isLoading}
            onClick={() => {
              setShowDatePickerFrom(false);
              setShowDatePickerTo(false);
            }}
          />
        </div>
      )}
      <div className="d-flex flex-column">
          <label htmlFor="company" className="input-label">
            Company
          </label>
          <MultiselectSearch
            data={transformedCompanies}
            selected={selectedCompanies}
            handleSelectedProperties={(transformedCompanies) => {
              setSelectedCompanies(transformedCompanies)
            }
            }
            disable={isLoading}
          />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center my-2">
        <button
          className="filter-button"
          onClick={() =>
            handleApplyFilters({ selectedProperties, selectedCompanies, startDate, endDate })
          }
        >
          Apply Filters
        </button>
        <button className="close-button" onClick={handleClose}>
          Close
        </button>
      </div>
      {showDatePickerFrom && (
        <div className="ms-3 datePickerWrapper">
          <DatePicker
            onChange={(date) => {
              setStartDate(date);
              setShowDatePickerFrom(false);
            }}
            inline
            selected={startDate}
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}
      {showDatePickerTo && (
        <div className="ms-3 datePickerWrapperTo">
          <DatePicker
            onChange={(date) => {
              setEndDate(date);
              setShowDatePickerTo(false);
            }}
            inline
            selected={endDate}
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
      )}
    </div>
  );
};
