import style from "./style.module.scss";
import { RiErrorWarningFill } from "react-icons/ri";
import PropTypes from 'prop-types';

function Step2({
  currentStep,
  onChange,
  className,
  errors,
}) {
  const handleKeyDown = (e) => {
    // Allow backspace, delete, arrow keys, etc.
    if (
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'Tab' ||
      e.key === 'Enter' ||
      e.key === 'v' || // Allow 'v' key for paste (Command + V / Ctrl + V)
      e.key === 'V'  // Allow 'V' key for paste (Command + V / Ctrl + V)
    ) {
      return; // Allow these keys
    }

    // Block letter keys (A-Z, a-z), but allow 'V'
    if (/[a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };
    
  

  return (
    <div
      className={`${currentStep !== 2 ? "d-none" : ""} ${className} ${
        style.container
      }`}
    >
      <h3 className={style.headerTitle}>Your Digits, Please?</h3>
      <p className={style.headerSubtitle}>Stay connected, stay informed</p>
        <div className={style.inputContainer}>

        <label className={style.label}>Phone Number</label>
          <input
            className={!errors?.phone ? style.input : style.inputError}
            name="phone"
            onChange={onChange}
            onKeyDown={handleKeyDown}
            placeholder="Phone Number"
            type="string"
          />
          {errors?.phone ? <div className={style.error}><RiErrorWarningFill size={12} color='red' style={{marginRight: '5px'}}/>{errors?.phone}</div> : ''}
        </div>
        
    </div>
  );
}

Step2.propTypes = {
  document: PropTypes.object,
  documentBack: PropTypes.object,
  face: PropTypes.object,
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  idVerification: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
  errors: PropTypes.shape({
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    privacy: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default Step2;
