import { rem, TextInput } from "@mantine/core";
import { Button, CloseButton } from "react-bootstrap";
import { IoIosAlert, IoMdSearch } from "react-icons/io";
import { CiExport } from "react-icons/ci";
import OpenFilterButton from "./OpenFilterButton";
import UnstyledButton from "../../../components/UnstyledButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { design } from "../../../constants";

const LeadsFilter = ({
  handleKeyDown,
  searchParam,
  handleSearchTerm,
  handleReset,
  handleResetFilters,
  handleExport,
  handleApplyFilters,
  byProperty,
  isFiltered,
}) => {
  const MySwal = withReactContent(Swal);
  const exportPopUp = (e) =>
    MySwal.fire({
      title: "Export Leads Information",
      html: "<span class='text-body-tertiary'>You are about to download the leads that match with the applied filters information. Do you wish to proceed?</span>",
      iconHtml: <IoIosAlert color="#5271ff" />,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Export",
      confirmButtonColor: design.deleteMainColor,
      reverseButtons: true,
      customClass: {
        icon: "border-0 my-0 py-2",
        popup: "px-5 pt-2",
        title: "pt-0 text-center text-nowrap fs-5 fw-bold color-gray",
        htmlContainer: "pt-2 px-0",
        actions: "gap-3 w-100 flex-nowrap",
        confirmButton: "w-100 rounded-3 fw-bold fs-7",
        cancelButton:
          "w-100 bg-white text-black border border-secondary-subtle rounded-3 fw-bold text-secondary fs-7",
      },
    }).then((res) => {
      if (res.isConfirmed) handleExport(e);
    });
  return (
    <div className="d-flex gap-2 gap-lg-5 justify-content-between">
      {handleKeyDown && (
        <TextInput
          placeholder="Search..."
          className="col col-lg-6"
          radius={6}
          name="search"
          onKeyDown={handleKeyDown}
          leftSection={
            <IoMdSearch
              style={{ width: rem(16), height: rem(16), cursor: "pointer" }}
              stroke={1.5}
              onClick={(e) => handleSearchTerm(e, true)}
              className="d-none d-lg-block"
            />
          }
          value={searchParam}
          onChange={handleSearchTerm}
          rightSection={
            <CloseButton onClick={handleReset} className="d-none d-lg-block" />
          }
        />
      )}

      <div className="d-flex justify-content-end gap-3 col-lg">
        <UnstyledButton
          onClick={handleResetFilters}
          className={`border-0 no-bg small-text ${
            isFiltered ? "text-delet-blue" : "text-body-tertiary"
          }`}
        >
          Clear All
        </UnstyledButton>

        <OpenFilterButton
          handleApplyFilters={handleApplyFilters}
          className="px-lg-5 bg-delet-secondary border border-dark-subtle position-relative"
          variant="light"
          text="Filter by"
          byProperty={byProperty}
        />

        {handleExport && (
          <Button
            onClick={exportPopUp}
            variant="light"
            className="border bg-delet-secondary border-dark-subtle"
          >
            <CiExport size="14px" className="mr-4" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default LeadsFilter;
