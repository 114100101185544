import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Card, FormSubTitle, FormTitle } from "../PropertyFormTab";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useGeneral } from "../../../context/GeneralContext";
import { useNavigate } from "react-router-dom";
import InputSelectComponent from "../../../components/InputSelectComponent";
import { Loader } from "react-feather";
import LableComponent from "../../../components/Lable";
import InputSwitchComponent from "../../../components/InputSwitchComponent";
import { Col, Row } from "react-bootstrap";
import UnstyledButton from "../../../components/UnstyledButton";
import SecurityLevelOption from "./securityLevelOption";
import { IoShield, IoShieldOutline } from "react-icons/io5";
import { useProperty } from "../../../context/PropertyContext";
import InputTextComponent from "../../../components/InputTextComponent";
import InputTextAreaComponent from "../../../components/InputTextAreaComponent";
import useViewport from "../../../hooks/useViewport";
import PropertyFormPanelHeader from "./PropertyFormPanelHeader";
import InputImages from "../../../components/InputImages";
import { useProfile } from "../../../context/UserProfileContext";

const SystemsTabPanel = (props) => {
  const {
    children,
    value,
    register,
    setValue,
    errors,
    trigger,
    watch,
    index,
    saveFormData,
    isSaving,
    setError,
    clearErrors,
    ...other
  } = props;

  const session = useProfile();

  const [kits, setkits] = useState([]);
  const [kitsLoaded, setKitsLoaded] = useState(false);
  const { setAlertModal } = useGeneral();
  const { property } = useProperty();
  const navigate = useNavigate();
  const { width: screenWidth } = useViewport();

  const getHardwareKits = useCallback(() => {
    Auth.currentSession().then((token) => {
      axios
        .get(`${process.env.REACT_APP_NODE_API_URL}/api/kits/user`, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setkits(response.data);
            setKitsLoaded(true);
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error Getting Kits",
            });
          }
        })
        .catch((error) => {
          console.log("ERROR...");
          if (error.response === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                console.log("error signing out: ", error);
              });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error Getting Kits",
            });
          }
        });
    });
  }, [navigate, setAlertModal]);

  useEffect(() => {
    getHardwareKits();
  }, [getHardwareKits]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={screenWidth > 755 ? { p: 3 } : { p: 1 }}>
          <div>
            <div xl={12} style={{ border: "1px solid rgb(234, 234, 235)" }}>
              <PropertyFormPanelHeader
                saveFormData={saveFormData}
                title={"DELET SYSTEM SETTINGS"}
                description={
                  "These settings allow you to specify the Delet Hardware Kit used for this property and what kinds of information they receive in automated email and SMS communications prior to their self-guided tour appointments."
                }
              />
              <div
                className={`${
                  screenWidth > 755 ? "px-4" : ""
                } flex-column d-flex`}
              >
                {session?.data?.roles.some((role) =>
                  ["admin", "owner"].includes(role)
                ) && (
                  <Card id="petPolicies">
                    <FormTitle>DELET HARDWARE KIT</FormTitle>
                    <FormSubTitle>
                      NOTE: Skip this item if you’re not ready to select a
                      Hardware Kit at this time. You can always change or modify
                      your Hardware Kit settings after submitting this form.
                    </FormSubTitle>
                    {kitsLoaded ? (
                      <InputSelectComponent
                        variantType="secondary"
                        label={`Select Hardware Kit used for this property (Optional)`}
                        name="kit"
                        register={register}
                        error={errors}
                        options={
                          kits &&
                          kits.map((kit) => {
                            return {
                              description: kit.name,
                              id: kit._id,
                            };
                          })
                        }
                        disablePlaceholder={false}
                        placeholder="Select"
                      />
                    ) : (
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Loader />
                        <span className="body-s-bold ms-2">
                          Loading kits...
                        </span>
                      </div>
                    )}
                  </Card>
                )}
                <Card>
                  <FormTitle>PROSPECT IDENTITY VERIFICATION</FormTitle>
                  <FormSubTitle>
                    Prospect identity verification is highly recommended and
                    enabled by default as an enhanced security measure.{" "}
                    <span className="fw-bold">Disabling</span> this setting will
                    allow prospects to book appointments without submitting a
                    photo ID.
                  </FormSubTitle>
                  <LableComponent
                    variantType="primary"
                    label="ID/Driver’s License Verification"
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                      className="body col-10"
                      style={
                        screenWidth > 755
                          ? { fontSize: "17px" }
                          : { fontSize: "14px" }
                      }
                    >
                      Require prospects to submit a photo ID or driver’s license
                      for verification prior to booking an appointment.
                    </span>
                    <InputSwitchComponent
                      register={register}
                      watch={watch}
                      name="idVerification.active"
                    />
                  </div>
                  <Row
                    className={`${
                      watch("idVerification.active") === true ? "" : "d-none"
                    }`}
                  >
                    <Col
                      xl={10}
                      className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row"
                    ></Col>
                    <Col xl={2} className="d-flex align-items-center"></Col>
                    <div className="d-flex flex-column gap-2 pt-2">
                      <h4>Request</h4>

                      <div className="d-flex justify-content-between ">
                        <span
                          className="body col-10"
                          style={
                            screenWidth > 755
                              ? { fontSize: "17px" }
                              : { fontSize: "14px" }
                          }
                        >
                          Front of ID {"(Mandatory)"}
                        </span>
                        <InputSwitchComponent
                          register={register}
                          watch={watch}
                          name="idVerification.frontbackID"
                          checked={true}
                          disabled={true}
                        />
                      </div>

                      <div className="d-flex justify-content-between">
                        <span
                          className="body col-10"
                          style={
                            screenWidth > 755
                              ? { fontSize: "17px" }
                              : { fontSize: "14px" }
                          }
                        >
                          Selfie
                        </span>
                        <InputSwitchComponent
                          register={register}
                          watch={watch}
                          name="idVerification.requireFace"
                        />
                      </div>

                      <h4>Security Level Analysis</h4>

                      <UnstyledButton
                        onClick={(e) =>
                          setValue("idVerification.profile", "high", {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                        }
                      >
                        <SecurityLevelOption
                          badge={<IoShield size={30} color="#5271FF" />}
                          level="High Security"
                          selected={watch("idVerification.profile") === "high"}
                          description="Strictly checks for fakes and verifies selfie against ID photo (if selfie photo is required)"
                        />
                      </UnstyledButton>

                      {/* <UnstyledButton
                        onClick={(e) =>
                          setValue("idVerification.profile", "medium", {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                        }
                      >
                        <SecurityLevelOption
                          badge={<IoShieldHalf size={30} color="#5271FF" />}
                          level="Medium"
                          selected={
                            watch("idVerification.profile") === "medium"
                          }
                        />
                      </UnstyledButton> */}

                      <UnstyledButton
                        onClick={(e) =>
                          setValue("idVerification.profile", "low", {
                            shouldValidate: true,
                            shouldDirty: true,
                          })
                        }
                      >
                        <SecurityLevelOption
                          badge={<IoShieldOutline size={30} color="#5271FF" />}
                          level="Low Security"
                          selected={watch("idVerification.profile") === "low"}
                          description="Checks for fakes, doesn't compare selfie with ID"
                        />
                      </UnstyledButton>
                    </div>
                  </Row>
                </Card>
                <Card id="">
                  <FormTitle>
                    Access Controls and Automated Communications
                  </FormTitle>
                  <FormSubTitle>
                    Use the following settings to specify the information
                    prospects receive in automated email and SMS communications
                    prior to their appointments. Please note that prospects will
                    receive all enabled information in a single email and text
                    message.
                  </FormSubTitle>
                  <LableComponent
                    variantType="primary"
                    label="Select the information to include in automated email & SMS communications:"
                    name="accessInstructions"
                  />

                  <div className="d-flex justify-content-between">
                    <span className="body col-10" style={{ fontSize: "17px" }}>
                      Door Unlock Link (for Delet SmartLock)
                      <span
                        className="tags px-1 pt-1"
                        style={{ color: "#575656", fontSize: "14px" }}
                      >
                        Recommended
                      </span>
                    </span>
                    <InputSwitchComponent
                      register={register}
                      watch={watch}
                      name="doorUnlockLink"
                      disabled={!property.kit}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <span className="body col-10" style={{ fontSize: "17px" }}>
                      Property Access Codes & Instructions
                      <span
                        className="tags px-1 pt-1"
                        style={{ color: "#575656", fontSize: "14px" }}
                      >
                        Recommended
                      </span>
                    </span>
                    <InputSwitchComponent
                      register={register}
                      watch={watch}
                      name="propertyAccessCodes.enable"
                    />
                  </div>
                  {watch("propertyAccessCodes.enable") && (
                    <div className="d-flex flex-column gap-2">
                      <span className="body-s-bold">
                        Do prospects need a security code to access the front
                        entrance or use the elevators in this building?
                      </span>
                      <InputTextComponent
                        label="Building Access Code (Optional)"
                        name="propertyAccessCodes.buildingCode"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        error={errors}
                        placeholder="Security code for the front entrance"
                      />
                      <InputTextComponent
                        label="Elevator Code (Optional)"
                        name="propertyAccessCodes.elevatorCode"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        error={errors}
                        placeholder="Security code for the elevator"
                      />
                      <InputTextAreaComponent
                        label="Please provide any additional instructions that prospects may need to find and access the property (Optional)"
                        name="propertyAccessCodes.instructions"
                        register={register}
                        placeholder="Enter additional access information here"
                      />
                      <Card id="instructionsFiles" className="m-0">
                        <InputImages
                          name="instructionsFiles"
                          register={register}
                          setValue={setValue}
                          watch={watch}
                          description="Upload images or videos for the instructions steps"
                          filesMessage="Total size of all uploaded files must not exceed 50MB. If you exceed this limit, try uploading your files in separate requests."
                          isSaving={isSaving}
                          setError={setError}
                          clearErrors={clearErrors}
                          errors={errors}
                        />
                      </Card>
                    </div>
                  )}
                  <div className="d-flex justify-content-between">
                    <span className="body col-10" style={{ fontSize: "17px" }}>
                      Rental Application Form & Instructions
                      <span
                        className="tags px-1 pt-1"
                        style={{ color: "#575656", fontSize: "14px" }}
                      >
                        Recommended
                      </span>
                    </span>
                    <InputSwitchComponent
                      register={register}
                      watch={watch}
                      name="rentalApplicationForm.enable"
                    />
                  </div>
                  <div
                    className={
                      watch("rentalApplicationForm.enable")
                        ? "d-flex flex-column gap-3 pt-3"
                        : "d-none"
                    }
                  >
                    <InputTextComponent
                      label="Application URL"
                      name="rentalApplicationForm.url"
                      type="text"
                      register={register}
                      trigger={trigger}
                      setValue={setValue}
                      placeholder="https://applicationlink.com"
                    />
                    <InputTextComponent
                      textAtBeginning="$"
                      labelType="secondary"
                      label="Application Fee (if applicable)"
                      name="rentalApplicationForm.fee"
                      type="number"
                      register={register}
                      trigger={trigger}
                      setValue={setValue}
                      placeholder="Enter application fee"
                    />
                    <InputTextAreaComponent
                      labelType="secondary"
                      label="Application Instructions (Optional)"
                      name="rentalApplicationForm.instructions"
                      register={register}
                      placeholder="Enter application instructions here..."
                    />
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default SystemsTabPanel;
