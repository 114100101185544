import { IoIosAlert } from "react-icons/io";

export default function EntrySystemHeader({ hardware }) {
    return (
        <header>
            <div className="d-flex bg-delet-pink justify-content-between align-items-center rounded-top w-100 p-3">
                <div className="d-flex align-items-center justify-content-between w-100">
                    {hardware?.name ? (
                        <>
                            <h6 className="color-gray fw-semibold text-capitalize mb-0">
                                {hardware.name}
                            </h6>
                        </>
                    ) : (
                        <div className="d-flex gap-3 justify-content-between align-items-center w-100">
                            <div className="d-flex gap-1 align-items-center text-body-secondary">
                                <IoIosAlert
                                    className="flex-shrink-0"
                                    color="#929292"
                                    size={30}
                                />
                                <span className="d-none d-md-inline">
                                    It looks like there is no entry system assigned to
                                    your property
                                </span>
                                <span className="d-inline d-md-none small-text color-gray">
                                    There is no entry system assigned yet
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}
