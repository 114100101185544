import useCodeHistory from "./hooks/useCodeHistory";
import useCodeFilters from "./hooks/useCodeFilters";
import CodeToolbar from "./components/CodeToolbar";
import TableHeader from "./components/TableHeader";
import TableSkeleton from "./components/TableSkeleton";
import TableList from "./components/TableList";
import { getFilteredAndSortedCodes } from "./utils/filters";

interface CodeHistoryProps {
    hardware: {
        id: string;
        vendor: string;
        type: string;
    };
}

export default function CodeHistory({ hardware }: CodeHistoryProps) {
    // Get access codes
    const { codeList, isLoading, isFetching, error, refetch } =
        useCodeHistory(hardware);

    // Handle filtering and sorting
    const { filters, handleFilters, sortBy, setSortBy } = useCodeFilters();

    if (error) {
        return (
            <div>
                Error loading code history
                <button onClick={() => refetch()}>Retry</button>
            </div>
        );
    }

    // Get filtered and sorted code list
    const processedCodeList = getFilteredAndSortedCodes(
        codeList,
        filters,
        sortBy
    );

    return (
        <section>
            {codeList.length > 90 && <p>Your code capacity is almost full!</p>}

            <CodeToolbar
                hardware={hardware}
                filters={filters}
                handleFilters={handleFilters}
                refetch={refetch}
                isLoading={isLoading}
            />

            {/* Codes History Table */}
            <div className="overflow-x-auto">
                <div style={{ minWidth: "768px" }}>
                    {/* Table header */}
                    <TableHeader
                        filters={filters}
                        handleFilters={handleFilters}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    />

                    {/* Table content */}
                    {isLoading || isFetching ? (
                        <TableSkeleton />
                    ) : (
                        <TableList codeList={processedCodeList} />
                    )}
                </div>
            </div>
        </section>
    );
}
