import { useRef, useState } from "react";
import "./Showings.scss";
import "react-datepicker/dist/react-datepicker.css";

import dayGridPlugin from "@fullcalendar/daygrid/index.js";
import interactionPlugin from "@fullcalendar/interaction/index.js";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid/index.js";

import Sidebar from "../components/AdminSideBar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getBookings, postBooking } from "../../../api/bookings";
import BookingInfo from "../../Showings/components/BookingInfo";
import NewBookingForm from "../../Showings/components/NewBookingForm";
import useViewport from "../../../hooks/useViewport";
import PageHeader from "../../../components/Headers/PageHeader";

import SelectCompaniesDropdown from "./SelectCompaniesDropdown";
import SelectPropertiesDropdown from "../../Showings/components/Filters/SelectPropertiesDropdown";
import SelectAgentsDropdown from "../../Showings/components/Filters/SelectAgentsDropdown";
import SelectDateDropdown from "../../Showings/components/Filters/SelectDateDropdown";

import { useSearchParams } from "react-router-dom";
import { useProfile } from "../../../context/UserProfileContext";
import { formatAddress } from "../../../helper";
import ProspectNameInputs from "./components/ProspectNameInput";
import { Button } from "react-bootstrap";

const AdminShowings = () => {
  const queryClient = useQueryClient();
  const profile = useProfile();
  const [searchParams, setSearchParams] = useSearchParams();

  // Dropdown filters
  const [selectedCompanies, setSelectedCompanies] = useState(
    searchParams.get("companies")
  );
  const [selectedProperties, setSelectedProperties] = useState(
    searchParams.get("properties")
  );

  const [selectedAgents, setSelectedAgents] = useState(
    searchParams.get("agents")
  );

  const { width: screenWidth } = useViewport();
  const [selectedBooking, setSelectedBooking] = useState({});
  const [showBookingInfo, setShowBookingInfo] = useState(false);
  const [showNewBooking, setShowNewBooking] = useState(false);
  const [monthOffset, setMonthOffset] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [agents, setAgents] = useState(null);
  const [currentViewType, setCurrentViewType] = useState(
    screenWidth > 775 ? "dayGridMonth" : "timeGridDay"
  );

  const [initialDate, setInitialDate] = useState(new Date());

  const calendarRef = useRef(null);

  const handleClickViewTypeButton = (viewType) => {
    let calendarApi = calendarRef.current.getApi();
    switch (viewType) {
      case "day":
        calendarApi.changeView("timeGridDay");
        setCurrentViewType("timeGridDay");
        break;
      case "week":
        calendarApi.changeView("timeGridWeek");
        setCurrentViewType("timeGridWeek");
        break;
      case "month":
        calendarApi.changeView("dayGridMonth");
        setCurrentViewType("dayGridMonth");
        break;
      case "today":
        setMonthOffset(0);
        calendarApi.changeView("timeGridDay");
        setCurrentViewType("timeGridDay");
        calendarApi.today();

        // Set date picker to today
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        handleDateSelect(today);
        break;
      default:
        break;
    }
  };

  // UPDATE LOGIC TO HANDLE START-END DATES INSTEAD OF MONTH OFFSET
  const {
    data: bookings = [],
    isPending: isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "bookings",
      monthOffset,
      selectedCompanies,
      selectedProperties,
      selectedAgents,
    ],
    queryFn: async () =>
      await getBookings({
        monthOffset,
        companies: selectedCompanies ?? "",
        properties: selectedProperties ?? "",
        agents: selectedAgents ?? "",
        isAdmin: true,
      }),
  });

  const {
    mutateAsync: createBooking,
    isPending: creatingBook,
    error: errorCreatingBooking,
  } = useMutation({
    mutationFn: async (booking) => await postBooking(booking),
    onSuccess: (data) => {
      queryClient.setQueryData(["bookings"], (prev) => [...prev, data]);
      setShowNewBooking(false);
    },
  });

  const renderEventContent = (eventInfo) => {
    return (
      <>
        {eventInfo.view.type === "timeGridDay" ? (
          <div
            className="d-flex flex-column px-2 py-1 event-container"
            style={{ zIndex: eventInfo.event.extendedProps.index }}
          >
            <h6 className="color-gray text-nowrap text-truncate">
              <span className="small-text">
                {new Date(eventInfo.event.startStr).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}{" "}
              </span>
              <span className="text-nowrap small-text">
                {eventInfo.event.extendedProps.name}
              </span>
            </h6>
            <span className="text-nowrap day-description text-truncate fs-7">
              {eventInfo.event.title}
            </span>
          </div>
        ) : (
          <div className="d-flex align-items-center overflow-hidden event-container">
            <div className="fc-daygrid-event-dot mx-1"></div>
            <div className="fc-event-time text-center text-truncate d-block w-100">
              {new Date(eventInfo.event.startStr).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
              {eventInfo.event.title}
            </div>
          </div>
        )}
      </>
    );
  };


  const handleClickBooking = (bookingId) => {
    setSelectedBooking(filteredBookings.find((booking) => booking._id === bookingId));
    setShowBookingInfo(true);
  };

  // Handlers for Companies filter
  const handleCompaniesApplyFilter = () =>
    setSelectedCompanies(searchParams.get("companies"));

  const handleCompaniesClearFilter = () => {
    setSelectedCompanies("");
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("companies");
      return newParams;
    });
  };

  // Handlers for Properties filter
  const handleApplyFilters = () =>
    setSelectedProperties(searchParams.get("properties"))


  const handleClearAllFilters = () => {
    setSelectedProperties("");
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("properties");
      return newParams;
    });
  };

  // Handlers for Agents filter
  const handleAgentsApplyFilter = () =>
    setSelectedAgents(searchParams.get("agents"));

  const handleAgentsClearFilter = () => {
    setSelectedAgents("");
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("agents");
      return newParams;
    });
  };

  const getAgentIndex = (data, agentId) => {
    let index = 0;
    if (data) {
      for (const page of data.pages) {
        const agentIndex = page.agents.findIndex(
          (agent) => agent._id === agentId
        );
        if (agentIndex !== -1) {
          return index + agentIndex;
        }
        index += page.agents.length;
      }
    }
    return 5;
  };

  const filteredBookings = isLoading ? [] : bookings?.filter(booking => booking.contact.firstName.includes(searchParams.get("firstName")?.trim() ?? "") && booking.contact.lastName.includes(searchParams.get("lastName")?.trim() ?? ""))

  const events =
    filteredBookings?.map((booking, index) => ({
      id: booking._id,
      title:
        formatAddress({
          shortAddress: booking.property?.shortAddress,
          unit: booking.property?.unit,
          city: booking.property?.city,
          state: booking.property?.state,
          zipCode: booking.property?.zipCode,
        }) || booking.property?.address,
      description:
        formatAddress({
          shortAddress: booking.property?.shortAddress,
          unit: booking.property?.unit,
          city: booking.property?.city,
          state: booking.property?.state,
          zipCode: booking.property?.zipCode,
        }) || booking.property?.address,
      extendedProps: {
        name: `${booking.contact.firstName} ${booking.contact.lastName}`,
        index,
      },
      start: new Date(booking.startTime * 1000),
      end: new Date((booking.startTime + 15 * 60) * 1000),
      classNames: [
        `fc-event-bg-${getAgentIndex(agents, booking.user)}`,
        booking.user === profile?.data?._id ? "active" : "",
        `z-${index}`,
        "position-relative",
        booking.status === "cancelled" ? "cancelled-event" : "",
      ]
        .filter(Boolean)
        .join(" "),
    })) ?? [];

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setInitialDate(date);

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const formattedDate = date.toISOString().split("T")[0];
      calendarApi.gotoDate(formattedDate);

      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      const selectedMonth = date.getMonth();
      const selectedYear = date.getFullYear();

      // Calculate month difference including year offset
      const monthOffset =
        (selectedYear - currentYear) * 12 + (selectedMonth - currentMonth);

      setMonthOffset(monthOffset);
    }
  };

  return (
    <Sidebar>
      <BookingInfo
        show={showBookingInfo}
        handleClose={() => setShowBookingInfo(false)}
        booking={selectedBooking}
        isAdmin={true}
      />

      <NewBookingForm
        show={showNewBooking}
        handleClose={() => setShowNewBooking(false)}
        handleSubmit={createBooking}
        isLoading={creatingBook}
        error={errorCreatingBooking}
      />
      <PageHeader>
        <div className="d-flex justify-content-between">
          <h1 className="color-black w-content fs-4">Calendar</h1>
        </div>
      </PageHeader>
      <div className="pt-2">
        <div className="panels px-4 px-lg-5 py-3">
          <div className="d-flex justify-content-between flex-wrap gap-3">
            <div className="d-flex gap-3 flex-grow-1 align-items-center justify-content-between justify-content-lg-start">
              <SelectDateDropdown
                className="no-arrow flex-grow-1 flex-lg-grow-0"
                handleDateSelect={handleDateSelect}
                selectedDate={selectedDate}
                level={currentViewType === "dayGridMonth" ? "year" : ""}
              />
              <SelectCompaniesDropdown
                isAdmin={true}
                title="Companies"
                toggleClassName="bg-white border border-1 text-body-tertiary fw-medium rounded-2 h-100"
                handleCompaniesApplyFilter={handleCompaniesApplyFilter}
                handleCompaniesClearFilter={handleCompaniesClearFilter}
              />
              <SelectPropertiesDropdown
                isAdmin={true}
                title="Properties"
                handleApplyFilters={handleApplyFilters}
                handleClearAllFilters={handleClearAllFilters}
              />
              <SelectAgentsDropdown
                isAdmin={true}
                title="Agents"
                handleAgentsApplyFilter={handleAgentsApplyFilter}
                handleAgentsClearFilter={handleAgentsClearFilter}
                gettingBookings={isLoading}
                setAgents={setAgents}
              />
              <ProspectNameInputs />
            </div>

            <div className="d-flex col-12">
              <button
                type="button"
                title="Today"
                aria-pressed="false"
                className="bg-white border border-1 p-2 text-body-tertiary fw-medium rounded-start-2 col fs-7"
                onClick={() => handleClickViewTypeButton("today")}
                disabled=""
              >
                Today
              </button>
              <button
                type="button"
                title="day view"
                aria-pressed="true"
                className="bg-white border border-1 p-2 text-body-tertiary fw-medium col fs-7"
                onClick={() => handleClickViewTypeButton("day")}
              >
                Day
              </button>
              <button
                type="button"
                title="week view"
                aria-pressed="false"
                className="bg-white border border-1 p-2 text-body-tertiary fw-medium col fs-7"
                onClick={() => handleClickViewTypeButton("week")}
              >
                Week
              </button>
              <button
                type="button"
                title="month view"
                aria-pressed="false"
                className="bg-white border border-1 p-2 text-body-tertiary fw-medium rounded-end-2 col fs-7"
                onClick={() => handleClickViewTypeButton("month")}
              >
                Month
              </button>
            </div>

          </div>
          {isError ? (
            <div className="d-flex p-5 justify-content-center align-items-center">
              <div className="text-center">
                <h2 className="text-danger">Error Getting Bookings</h2>
                <p className="text-muted">
                  There was an issue fetching your bookings. Please try again
                  later.
                </p>
                <button
                  className="btn btn-primary my-3"
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </button>
              </div>
            </div>
          ) : (
            <div className={isLoading ? "pe-none opacity-50" : ""}>
              <FullCalendar
                ref={calendarRef}
                key={initialDate.toString()}
                height={`${screenWidth > 755 ? "100vh" : "800px"} `}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={currentViewType}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: "",
                }}
                selectMirror={true}
                dayMaxEvents={true}
                themeSystem="Simplex"
                eventClick={(calEvent) => handleClickBooking(calEvent.event.id)}
                eventContent={(eventInfo) => renderEventContent(eventInfo)}
                events={events}
                initialDate={initialDate}
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                }}
                // timeZone="America/Los_Angeles"
                dayHeaderFormat={
                  currentViewType === "dayGridMonth"
                    ? {
                      weekday: "short",
                      // day: "numeric",
                      // month: "short",
                      // separator: "/",
                    }
                    : {
                      weekday: "short",
                      day: "numeric",
                      month: "short",
                      separator: "/",
                    }
                }
              />
            </div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default AdminShowings;
