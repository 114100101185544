import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";

const USER_NOT_FOUND_EXCEPTION = "UserNotFoundException";

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  useEffect(() => {
    // if (localStorage.getItem('platformDashToken')) {
    //   navigate('/properties')
    // }
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        navigate("/admin/user/list");
      })
      .catch((err) => {});
  }, []);
  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (form.email === "" || form.password === "") {
      setError("Please Enter Email Address and Password");
      setModalShow(true);
    } else if (!emailRegex.test(form.email)) {
      setError("Please enter a valid email address");
      setModalShow(true);
    } else {
      const doLogin = () => {
        setIsLoading(true);
        Auth.signIn(form.email, form.password)
          .then(async (response) => {
            const roles =
              response.signInUserSession.idToken.payload["cognito:groups"];
            setIsLoading(false);
            if (
              roles.some((role) =>
                ["superadmin", "manager", "leasingAgent"].includes(role)
              )
            ) {
              navigate("/admin/user/list");
            } else {
              setError("You don't have admin permissions");
              setModalShow(true);
              await Auth.signOut();

              return navigate("/admin/login");
            }
          })
          .catch((error) => {
            setIsLoading(false);
            if (error.code === USER_NOT_FOUND_EXCEPTION) {
              axios
                .post(
                  `${process.env.REACT_APP_NODE_API_URL}/api/users/login`,
                  form
                )
                .then(() => {
                  doLogin();
                })
                .catch((error) => {
                  console.log(error);

                  setModalShow(true);
                  setError("Invalid email / password");
                });
            } else {
              setError(error.message);
              setModalShow(true);
            }
          });
      };
      doLogin();
    }
  };
  return (
    <>
      <div className="align-items-center d-flex w-100 justify-content-center h-100vh flex-column">
        <div className="bg-image">
          <div className="center-container">
            <div className="main-login">
              <form>
                <img
                  alt="Brand logo"
                  className="mx-auto w-50 h-10"
                  src={require("../../assests/images/delet-logo.png")}
                />
                <p className="text-center fs-3 fw-bold">Admin</p>
                <div className="form-group pb-4">
                  <label htmlFor="exampleInputEmail1" className="text-start">
                    User name
                  </label>
                  <input
                    type="email"
                    className="form-control login-input"
                    id="exampleInputEmail1"
                    value={form.email}
                    onChange={onFormInputChange}
                    name="email"
                    placeholder="Name@company.com"
                  />
                </div>
                <div className="form-group pb-3">
                  <label htmlFor="exampleInputPassword1" className="text-start">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control login-input"
                    id="exampleInputPassword1"
                    value={form.password}
                    onChange={onFormInputChange}
                    name="password"
                    placeholder="Enter Your Password"
                  />
                </div>
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="login-btn btn w-90 px-3 my-3 py-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    <span className="login-text">Login</span>
                  )}
                </button>
              </form>
              <p>
                Don't have an account? <Link to={"/admin/signup"}>Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {modalShow ? (
        <Modal show={modalShow}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModalShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            ""
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default Login;
