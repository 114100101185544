import style from "./style.module.scss";
import { RiErrorWarningFill } from "react-icons/ri";
import PropTypes from "prop-types";

function Step1({ currentStep, onChange, className, errors }) {
  return (
    <div
      className={`${currentStep !== 1 ? "d-none" : ""} ${className} ${
        style.container
      }`}
    >
      <h3 className={style.headerTitle}>What is your First and Last Name?</h3>
      <p className={style.headerSubtitle}>
        Your name is the beginning of your story
      </p>

      <div className={style.inputContainer}>
        <label className={style.label}>First Name</label>
        <input
          className={!errors.fullName ? style.input : style.inputError}
          onChange={onChange}
          name="firstName"
          placeholder="First Name"
          type="text"
        />
        {errors.fullName ? (
          <div className={style.error}>
            {" "}
            <RiErrorWarningFill
              size={12}
              color="red"
              style={{ marginRight: "5px" }}
            />
            {errors.fullName}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={style.inputContainer}>
        <label className={style.label}>Last Name</label>
        <input
          className={!errors.fullName ? style.input : style.inputError}
          onChange={onChange}
          name="lastName"
          placeholder="Last Name"
          type="text"
        />
        {errors.fullName ? (
          <div className={style.error}>
            {" "}
            <RiErrorWarningFill
              size={12}
              color="red"
              style={{ marginRight: "5px" }}
            />
            {errors.fullName}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

Step1.propTypes = {
  face: PropTypes.object,
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  idVerification: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};
Step1.defaultProps = {
  errors: {},
};

export default Step1;
