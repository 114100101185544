import React, { useEffect, useState } from "react";

import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { TfiReload } from "react-icons/tfi";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";

import Sidebar from "../components/AdminSideBar";
import { Center, Loader } from "@mantine/core";
import { createNextTwoMonthsCodes } from "../../../api/keypad";

import toast, { Toaster } from "react-hot-toast";
import { cleanExpiredAccessCodes } from "../../../api/lockbox";
import { useProfile } from "../../../context/UserProfileContext";

const HardwareDetails = () => {
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const [statusLoading, setStatusLoading] = useState(false);
  const [loading, setLoader] = useState(false);
  const [modelshow, setModelShow] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [status, setStatus] = useState({
    lock: "N/A",
    battery: "N/A",
    bridge: "N/A",
  });
  const params = useParams();

  const getLockStatus = async (id) => {
    try {
      setStatusLoading(true);
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/${id}/lock/status`;
      const token = await Auth.currentSession();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      const currentStatus = response.data;
      setStatus({
        lock:
          currentStatus.LockStatus.status === "locked" ? "LOCKED" : "UNLOCKED",
        battery: `${Math.floor(
          currentStatus.batteryInfo.level * 100
        ).toString()}%`,
        bridge:
          currentStatus.Bridge.status.current === "online"
            ? "ONLINE"
            : "OFFLINE",
        door: currentStatus.LockStatus.doorState.toUpperCase(),
      });
      setStatusLoading(false);
    } catch (error) {
      console.log(error);
      setStatusLoading(false);
    }
  };

  useEffect(() => {
    const id = params.id;
    if (id) {
      const getHardwareDetails = async (id) => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/${id}`;
          const token = await Auth.currentSession();
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          });
          if (response.status === 200) {
            setList(response.data);
            setLoader(true);
          } else {
            setError("Error Getting hardware");
            setModelShow(true);
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            setError("Error Getting hardware");
            setModelShow(true);
          }
        }
      };
      getHardwareDetails(id);
      getLockStatus(id);
    } else {
      navigate("/admin/login");
    }
  }, [navigate, params.id]);

  const handleDelete = async (e) => {
    try {
      e.preventDefault();

      const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/${params.id}`;
      const token = await Auth.currentSession();

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      if (response.status === 200) {
        setModelShow(false);
        setDelete(true);
      } else {
        setError("Error Deleting hardware");
        setModelShow(true);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        setError("Error Deleting hardware");
        setModelShow(true);
      }
    }
  };

  return (
    <>
      <Toaster />
      <Sidebar>
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={8}>
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h1 className="h4 color-black mr-20">Hardware Details</h1>
              </div>
            </Col>
            <Col xl={2}>
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="d-flex">
                  <Link
                    to={`/admin/hardware/edit/${list._id}`}
                    size="sm"
                    className={`btn btn-primary btn-sm mr-30 ${
                      !profile?.isManager && "d-none"
                    }`}
                  >
                    Edit Hardware
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl={2}>
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="d-flex">
                  <Link
                    // to={`/admin/user/edit-user/${list._id}`}
                    // size="sm"
                    onClick={() => setModelShow(true)}
                    className={`btn btn-danger  mr-30 ${
                      !profile?.isManager && "d-none"
                    }`}
                  >
                    Delete Hardware
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {list.lock?.vendor && list.lock?.vendor === "august" && (
          <Row className="mt-30">
            <Col xl={12} lg={12}>
              <Card>
                <Card.Header as="h5" className="d-flex justify-content-between">
                  <h5>Hardware Status</h5>
                  <Button
                    className="btn-outline-dark col-1"
                    onClick={() => getLockStatus(params.id)}
                    disabled={statusLoading}
                  >
                    <TfiReload />
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Lock Status</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{status.lock}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Battery Level</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{status.battery}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>WiFi (Bridge)</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{status.bridge}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Door Status</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{status.door}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Row className="mt-30">
          {loading === false ? (
            <Center h={100}>
              <Loader />
            </Center>
          ) : (
            <Col xl={12} lg={12}>
              <Card>
                <Card.Header as="h5" className="d-flex justify-content-between">
                  Hardware information{" "}
                  {list.category === "keypad" ||
                  (list.category === "lock" &&
                    list.lock.vendor === "august") ? (
                    <Button
                      className="btn-outline-dark col-1"
                      onClick={() => {
                        createNextTwoMonthsCodes(list._id);
                        toast.success(
                          "Request sent, it should be done in 6 minutes"
                        );
                      }}
                      disabled={!profile?.isManager}
                    >
                      Create codes
                    </Button>
                  ) : list?.category === "lockbox" &&
                    list?.lockbox?.vendor === "igloo" &&
                    list?.lockbox?.id ? (
                    <Button
                      className="btn-outline-dark col-1"
                      onClick={() => {
                        cleanExpiredAccessCodes(list.lockbox.id);
                        toast.success("Cleaned expired access codes");
                      }}
                    >
                      Clean expired codes
                    </Button>
                  ) : null}
                </Card.Header>
                <Card.Body>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.name}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Mobile"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.description}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Notes</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>{list.notes}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>InventoryId</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>{list.inventoryId}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Tags</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.tags}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Username</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.username}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Category</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.category}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Serial Number</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.serial}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Protocol</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.protocol}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Make</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.make}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Model</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.model}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Sidebar>
      {modelshow && (
        <Modal show={modelshow}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Delete Hardware</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>

              <Modal.Body>
                Are you sure you want to delete hardware ?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      )}
      {Delete && (
        <Modal show={Delete}>
          <>
            <Modal.Header>
              <Modal.Title>Deleted</Modal.Title>
            </Modal.Header>

            <Modal.Body>Hardware Successfully</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate("/admin/hardware/list");
                  setDelete(false);
                }}
              >
                Okay
              </Button>
            </Modal.Footer>
          </>
        </Modal>
      )}
    </>
  );
};

export default HardwareDetails;
