import { format } from "date-fns";
import { AccessCode, Filters, SortBy } from "./types";

const codeTypes: Record<string, string> = {
    urgent: "emergency",
    timeLimit: "temporary",
    hourly: "temporary",
    daily: "temporary",
    onetime: "one-time",
};

/**
 * Removes spaces and converts text to lowercase before searching.
 * @param mainText The main text to search in.
 * @param searchText The text being searched for.
 * @returns Whether `searchText` exists in `mainText` after normalization.
 */
const isSubstringIgnoringSpaces = (
    mainText: string,
    searchText: string
): boolean => {
    const cleanMainText = mainText.toLowerCase().replace(/\s+/g, "");
    const cleanSearchText = searchText.toLowerCase().replace(/\s+/g, "");

    return cleanMainText.includes(cleanSearchText);
};

/**
 * Filters access codes based on search text, status, and type.
 * @param codes The list of access codes to filter.
 * @param filters The applied filters.
 * @param codeTypes A mapping for type conversion.
 * @returns The filtered list of access codes.
 */
const filterAccessCodes = (
    codes: AccessCode[],
    filters: Filters
): AccessCode[] => {
    const { status, type, text } = filters;

    return codes.filter((code) => {
        // Check text search
        const matchesSearch =
            isSubstringIgnoringSpaces(code.name, text) ||
            isSubstringIgnoringSpaces(code.code, text) ||
            isSubstringIgnoringSpaces(
                format(new Date(code.start), "MM/dd/yyyy"),
                text
            ) ||
            (code.end
                ? isSubstringIgnoringSpaces(
                      format(new Date(code.end), "MM/dd/yyyy"),
                      text
                  )
                : false);

        if (!matchesSearch) return false;

        // Check status filter
        if (status.length > 0 && !status.includes(code.status ?? "active"))
            return false;

        // Check type filter
        if (
            type.length > 0 &&
            !type.includes(codeTypes[code.type] ?? code.type)
        )
            return false;

        return true;
    });
};

/**
 * Sorts access codes based on selected sorting criteria.
 * @param codes The list of filtered access codes.
 * @param sortBy The sorting criteria.
 * @returns The sorted list of access codes.
 */
const sortAccessCodes = (codes: AccessCode[], sortBy: SortBy): AccessCode[] => {
    if (!sortBy.name) return codes;

    return [...codes].sort((a, b) => {
        const dateA = sortBy.name === "start" ? a.start : a.end || Infinity;
        const dateB = sortBy.name === "start" ? b.start : b.end || Infinity;

        return sortBy.type === "asc" ? dateA - dateB : dateB - dateA;
    });
};

/**
 * Filters and sorts the access code list.
 * @param codes The list of access codes.
 * @param filters The applied filters.
 * @param sortBy The sorting criteria.
 * @param codeTypes A mapping for type conversion.
 * @returns The final processed list of access codes.
 */
export const getFilteredAndSortedCodes = (
    codes: AccessCode[],
    filters: Filters,
    sortBy: SortBy
): AccessCode[] => {
    const filteredCodes = filterAccessCodes(codes, filters);
    return sortAccessCodes(filteredCodes, sortBy);
};
