import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getFullAddress } from "../../../helper";
import { getProperties } from "../../../api/properties";

interface Property {
    shortAddress: string;
    unit: string;
    city: string;
    state: string;
}

interface GetPropertiesResponse {
    totalPages: number;
    properties: Property[];
}

interface UsePropertiesDropdownProps {
    isAdmin: boolean;
}

export const usePropertiesDropdown = ({
    isAdmin,
}: UsePropertiesDropdownProps) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] =
        useState<string>(searchTerm);

    useEffect(() => {
        const delay = 300;
        const handler = setTimeout(
            () => setDebouncedSearchTerm(searchTerm),
            delay
        );
        return () => clearTimeout(handler);
    }, [searchTerm]);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
    } = useInfiniteQuery<GetPropertiesResponse>({
        queryKey: ["properties", "calendar", debouncedSearchTerm],
        queryFn: ({ pageParam = 1 }) =>
            getProperties({
                page: pageParam,
                limit: 10,
                filters: { search: debouncedSearchTerm },
                sort: { by: "shortAddress", order: 1 },
                select: ["shortAddress", "unit", "city", "state"],
                isAdmin,
            }),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) =>
            lastPage.totalPages > pages.length ? pages.length + 1 : undefined,
    });

    // Combine all pages into a single array
    const allProperties = useMemo(() => {
        return data?.pages.flatMap((page) => page.properties) || [];
    }, [data]);

    // Pre-compute full addresses once per property and memoize the result
    const propertiesWithFullAddress = useMemo(() => {
        return allProperties.map((property) => ({
            ...property,
            fullAddress: getFullAddress(property),
        }));
    }, [allProperties]);

    // Auto-fetch more pages when search is active and filtered result count is below threshold
    useEffect(() => {
        if (
            searchTerm.trim() &&
            hasNextPage &&
            !isFetchingNextPage &&
            propertiesWithFullAddress.length < 10
        ) {
            fetchNextPage();
        }
    }, [
        searchTerm,
        propertiesWithFullAddress,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    ]);

    return {
        searchTerm,
        setSearchTerm,
        propertiesWithFullAddress,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
    };
};
