import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box } from "@mui/material";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useGeneral } from "../../../../context/GeneralContext";
import { useNavigate } from "react-router-dom";
import { USERPROFILE_DEFAULT_VALUES } from "../../../../static/Data";
import useViewport from "../../../../hooks/useViewport";
import InputTextComponent from "../../../../components/InputTextComponent";
import InputImage from "../../../../components/InputImage.jsx";
import ChangePassword from "./ChangePassword";
import UseHttp from "../../../../hooks/UseHttp";
import LoaderRing from "../../../../components/LoaderRing";
import { Center, Loader } from "@mantine/core";
import { StyledGrayButton } from "../SettingTab";
import InputPhoneNumberComponent from "../../../../components/InputPhoneNumberComponent";
import { useProfile } from "../../../../context/UserProfileContext";
import { emailPattern } from "../../utils";

const ProfilePanel = forwardRef((props, ref) => {
  const { children, value, index, next, prev, setIsDirty, ...other } = props;
  const { width: screenWidth } = useViewport();
  const { setAlertModal } = useGeneral();
  const [currentRole, setCurrentRole] = useState("");
  const [userData, setUserData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDirtyPassword, setIsDirtyPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { error, requestData: changePassword } = UseHttp();
  const { refetch } = useProfile();
  const formMethods = useForm({ defaultValues: USERPROFILE_DEFAULT_VALUES });
  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors, isDirty },
    watch,
    reset,
  } = formMethods;

  const filterUserProfileData = (receivedData) => {
    const filteredData = {};

    for (const key in USERPROFILE_DEFAULT_VALUES) {
      if (receivedData.hasOwnProperty(key)) {
        filteredData[key] = receivedData[key];
      }
    }
    return filteredData;
  };

  useImperativeHandle(ref, () => ({
    saveForm() {
      onSubmit(getValues());
    },
  }));

  const getSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      setIsLoading(false);
      if (response.status === 200) {
        setUserData(response.data);
        reset(filterUserProfileData(response.data));
        setCurrentRole(response.data.role);
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Getting Settings",
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.log("error signing out: ", error);
          });
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Getting Settings",
        });
      }
    }
  }, []);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    getSettings();
    // getStripeSub();
  }, [getSettings]);

  const handleChangePasswordSubmit = async (data) => {
    if (!isValidPassword)
      setAlertModal({
        show: true,
        title: "Warning",
        content:
          "Please enter the correct password and ensure that the confirmation matches the password",
      });
    else {
      Auth.currentSession().then((token) => {
        changePassword(
          {
            method: "POST",
            url: `${process.env.REACT_APP_NODE_API_URL}/api/users/change-password/${userData._id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
            body: {
              password: newPassword,
            },
          },
          (response) => {
            setAlertModal({
              show: true,
              title: "Success",
              content: "Your password was changed",
            });
            return 1;
          }
        );
      });
    }
  };

  useEffect(() => {
    if (error) {
      setAlertModal({
        show: true,
        title: "Error",
        content: "There was an error when trying to change your password",
      });
    }
  }, [error, setAlertModal]);

  function formatPhoneNumber(phoneNumber) {
    // Remove dashes and convert to a string
    const cleanedNumber = phoneNumber.replace(/-/g, "");

    // Return the formatted number with a leading '+'
    return `+${cleanedNumber}`;
  }
  const handleProfileSubmit = async (data) => {
    const form = new FormData();
    form.append("companyLogo", data.company.idImage);
    data.phone = formatPhoneNumber(data.phone);
    form.append("data", JSON.stringify(data));

    console.log(form.get("companyLogo"));

    try {
      const token = await Auth.currentSession();
      setIsSaving(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_NODE_API_URL}/api/users/settings/profile`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsSaving(false);
      if (response.status === 200) {
        setAlertModal({
          show: true,
          title: "Saved",
          content: "Settings Updated Successfully",
        });
        setIsDirty(false);
        getSettings();
      } else {
        throw new Error("Error Updating Profile");
      }
    } catch (error) {
      setIsSaving(false);
      handleAuthError(error);
    }
  };

  const onSubmit = async (data) => {
    if (isDirtyPassword) {
      if (await handleChangePasswordSubmit()) await handleProfileSubmit(data);
    } else {
      await handleProfileSubmit(data);
      await refetch();
    }
  };

  const handleAuthError = (error) => {
    if (error?.response?.status === 401) {
      Auth.signOut()
        .then(() => {
          navigate("/");
        })
        .catch((signOutError) => {
          console.log("Error signing out:", signOutError);
        });
    } else {
      setAlertModal({
        show: true,
        title: "Error",
        content: "Error Updating Profile",
      });
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="pt-4"
    >
      {isLoading === true ? (
        <>
          <Center h={100}>
            <Loader />
          </Center>
        </>
      ) : (
        value === index &&
        userData && (
          <>
            <Box className="px-4 px-lg-5">
              <Card className="border">
                <Card.Header as={"h3"}>PROFILE</Card.Header>
                <Card.Body>
                  <form>
                    <Row className="gap-3 ">
                      <Col
                        className={`${
                          screenWidth > 755
                            ? "d-flex justify-content-center gap-3"
                            : "d-flex flex-column gap-3"
                        }`}
                      >
                        <InputTextComponent
                          label="First Name"
                          name="firstName"
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          error={errors}
                          placeholder="Enter first name"
                        />
                        <InputTextComponent
                          label="Last Name"
                          name="lastName"
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          error={errors}
                          placeholder="Enter last name"
                        />
                      </Col>
                      <InputTextComponent
                        label="Company Name"
                        name="company.name"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        error={errors}
                        placeholder="Company Name"
                        disabled={currentRole === "agent"}
                      />
                      <InputTextComponent
                        required={true}
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        requiredField={{
                          pattern: emailPattern,
                        }}
                        label="Email Adress"
                        name="email"
                        error={errors}
                        placeholder="example@email.com"
                      />
                      <div className="px-3">
                        <InputImage
                          name="company.idImage"
                          register={register}
                          setValue={setValue}
                          watch={watch}
                          description="Company Logo"
                          disabled={currentRole !== "owner"}
                        />
                      </div>
                      <ChangePassword
                        isProfile={true}
                        setIsDirtyPassword={setIsDirtyPassword}
                        setIsValidPassword={setIsValidPassword}
                        setNewPassword={setNewPassword}
                      />
                      <InputPhoneNumberComponent
                        required={true}
                        label="Phone Number"
                        name="phone"
                        register={register}
                        trigger={trigger}
                        watch={watch}
                        setValue={setValue}
                        error={errors}
                        placeholder="1-xxx-xxx-xxxx"
                      />
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Box>
            {screenWidth > 755 ? (
              <div className="position-sticky bottom-0 bg-white py-3 px-4 d-flex justify-content-between">
                <Col xs="auto">
                  <Button
                    className={`px-5 tab-footer-button`}
                    onClick={prev}
                    disabled={value === 0}
                  >
                    {"< Back"}
                  </Button>
                </Col>
                <Col xs="auto gap-2 d-flex">
                  <Button
                    as={StyledGrayButton}
                    className="px-4"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save and close
                  </Button>
                  <Button className={`px-5 tab-footer-button`} onClick={next}>
                    {"Continue >"}
                  </Button>
                </Col>
              </div>
            ) : (
              <div className="d-flex flex-column gap-1 position-sticky bottom-0 bg-white py-3 px-4">
                <Col xs={12}>
                  <Button
                    className="tab-footer-button "
                    style={{ width: "100%" }}
                    onClick={next}
                  >
                    {"Continue >"}
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    as={StyledGrayButton}
                    className="px-4 "
                    onClick={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                  >
                    Save and close
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    className="tab-footer-button"
                    onClick={prev}
                    style={{ width: "100%" }}
                    disabled={value === 0}
                  >
                    {"< Back"}
                  </Button>
                </Col>
              </div>
            )}
          </>
        )
      )}
      {isSaving && (
        <div className="modal-outside">
          <LoaderRing height="100" width="100" />
        </div>
      )}
    </div>
  );
});

export default ProfilePanel;
