import React from 'react';
import { Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import style from './style.module.scss';
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";



function OrderCard() {
  const location = useLocation();
  const { order } = location.state || {}; // Get the order from state
  const navigate = useNavigate()

  console.log('This is the data from the order', order)

  if (!order) {
    return <div>No order data available.</div>; // Handle case when order is not available
  }

  return (
    <div>
      <p className={style.pageHeader}><IoIosArrowBack onClick={() => navigate('/kits/orders')}/> Order Summary</p>
       <Card className={style.card}>
          <>
          <header className={style.headerRow}>
            <p className="pt-0">
                CONTACT INFORMATION
            </p>
           
          </header>
          
            <table>
                <tbody>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}> Property Address</td>
                        <td className={style.rightTd}>{order?.property ?? 'NA'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Full Name</td>
                        <td className={style.rightTd}>{order?.fullName ?? 'NA'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Contact Phone Number</td>
                        <td className={style.rightTd}>{order?.phoneNumber ?? 'NA'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Email Address</td>
                        <td className={style.rightTd}>{order?.email ?? 'NA'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Company Name (Optional)</td>
                        <td className={style.rightTd}>{order?.company !== '' ? order?.company : 'NA'}</td>
                    </tr>
                </tbody>
            </table>
        
          
          </>
      </Card>

      <Card className={style.card}>
          <>
          <header className={style.headerRow}>
            <p className="pt-0">
                APPOINTMENT INFORMATION
            </p>
          </header>
          <table>
                <tbody>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Preferred Service Date (Optional)</td>
                        <td className={style.rightTd}>{order?.date ? order?.date : 'No preferred date'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Preferred Service Time</td>
                        <td className={style.rightTd}>{order?.time ? `${order?.time}` : 'No preferred time'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Additional Notes (Optional)</td>
                        <td className={style.rightTd} style={{maxWidth:'50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{order?.details[0].notes ? order?.details[0].notes : 'No additional notes'}</td>
                    </tr>
                    
                </tbody>
            </table>
          
          </>
      </Card>
      <Card className={style.orderCard}>
          <>
          <header className={style.headerRow}>
            <p>
                ORDER DETAIL
            </p>
            
          </header>
          <table>
                <tbody>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Installation</td>
                        <td className={style.midTd}>{order.details[0].amount > 1 ? `${order.details[0].amount} Items` : `${order.details[0].amount} Item`}</td>
                        <td className={style.rightTd}>{`$${order?.details[0].price}.00`}</td>
                    </tr>
                  
                    <tr className={style.bottomRow}>
                        <td className={style.leftTdTot}>Total</td>
                        <td></td>
                        <td className={style.rightTdTot}>{`$${order?.details[0].price}.00`}</td>
                    </tr>
                    
                </tbody>
            </table>
          
          </>
      </Card>
    </div>
  );
}

export default OrderCard;
