import { Auth } from "aws-amplify";
import axios from "axios";

export const getUsersList = async ({ role = "" }) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/list?role=${role}`;

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    if (!res.data) {
      throw new Error('No data received');
    }

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const getAgents = async ({ page, limit, filters, sort, select, isAdmin = false }) => {
  const adminApi = isAdmin ? "/admin" : "";
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/users${adminApi}/agents/all`;

  const requestBody = {
    page,
    limit,
    filters,
    sort,
    select,
  };

  try {
    const auth = await Auth.currentSession();
    const response = await axios.post(baseUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};