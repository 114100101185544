import InfoBanner from "../../../../../../../components/Banners/InfoBanner";
import HardwareList from "../../../../../components/HardwareList";
// Desktop version
import CodeHistory from "../../CodeHistory";
// Mobile version
// import MobileCodeHistory from "../../../../../components/Keypad/Mobile-CodeHistory";

export default function EntrySystemContent({
    hardware,
    hardwareList,
    currentTab,
}) {
    // Mapping components for both desktop and mobile views
    const ComponentMap = {
        info: {
            desktop: <HardwareList hardwareList={hardwareList} />,
            mobile: <HardwareList hardwareList={hardwareList} />,
        },
        codes: {
            desktop: hardware ? (
                <CodeHistory hardware={hardware} />
            ) : (
                <NoKeypadMessage />
            ),
            mobile: hardware ? (
                <CodeHistory hardware={hardware} />
            ) : (
                <NoKeypadMessage />
            ),
        },
    };

    // Default Component (fallback)
    const FallbackComponent = <p className="fs-6 fw-normal">Coming soon...</p>;

    return (
        <div className="w-100 overflow-hidden px-2 py-3 px-md-4">
            {/* Desktop View */}
            <div className="d-none d-md-block">
                {ComponentMap[currentTab]?.desktop || FallbackComponent}
            </div>

            {/* Mobile View */}
            <div className="d-block d-md-none">
                {ComponentMap[currentTab]?.mobile || FallbackComponent}
            </div>
        </div>
    );
}

// Reusable No Keypad Found Message
const NoKeypadMessage = () => (
    <div className="px-5">
        <InfoBanner>
            <span className="text-secondary fs-7">No keypad found</span>
        </InfoBanner>
    </div>
);
