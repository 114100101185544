import React, { useMemo, useState } from "react";

import { propertyFilterStatus, propertyType } from "../../static/Data";
import Button from "../../components/Button";

import axios from "axios";
import {
  Button as BootstrapButton,
  Dropdown,
  Form,
  Modal,
} from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

import Sidebar from "../../components/SideBar";
import PagePagination from "../../components/PagePagination";
import { IoIosInformationCircle, IoIosCheckmarkCircle } from "react-icons/io";
import NoProperties from "../../assests/images/Delet 2.svg";

//------------alplify imports----------------//
import { Auth } from "aws-amplify";
import { useGeneral } from "../../context/GeneralContext";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { Center, Loader } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getProperties } from "../../api/properties";
import UnstyledButton from "../../components/UnstyledButton";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { AppButton } from "../../designSystem/AppButton";
import FormModal from "../../components/Modals/FormModal";
import { FaChevronDown, FaChevronUp, FaCirclePlus } from "react-icons/fa6";
import PageHeader from "../../components/Headers/PageHeader";
import NotificationsButton from "../../components/Notifications/NotificationsButton";
import PropertyListCard from "./components/PropertyListCard";
import searchIcon from "../../assests/images/search-icon.png";
import { MdKeyboardArrowDown } from "react-icons/md";

const initState = {
  filterBy: {
    search: "",
    status: ["active"],
    classification: [],
  },
  sort: {
    by: null,
    order: false,
  },
};

const initStateString = JSON.stringify({
  ...initState.filterBy,
  status: [],
});

const PropertiesList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setAlertModal } = useGeneral();
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const [actualPage, setActualPage] = useState(1);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [isRestored, setRestored] = useState(false);
  const [isDuplicated, setDuplicated] = useState(false);
  const [propertyIdToArchiveOrRestore, setPropertyIdToArchiveOrRestore] =
    useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const [filterBy, setFilterBy] = useState(initState.filterBy);
  const [appliedFilters, setAppliedFilters] = useState(initState.filterBy);

  const [sortBy, setSortBy] = useState(initState.sort);
  const params = useParams();
  const location = useLocation();
  const isEditPage = location.pathname.includes("edit");

  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formData, setFormData] = useState({
    location: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const queryKey = useMemo(() => {
    return [
      "properties",
      actualPage,
      appliedFilters.search,
      appliedFilters.status.join(","),
      appliedFilters.classification.join(","),
      sortBy.by,
      sortBy.order,
    ];
  }, [actualPage, appliedFilters, sortBy]);

  const {
    data = { properties: [], unfilteredProps: [], totalPages: 0 },
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      try {
        const filteredResponse = await getProperties({
          page: actualPage,
          filters: appliedFilters,
          sort: sortBy,
        });

        const unfilteredResponse = await getProperties({
          page: actualPage,
          filters: {},
          sort: sortBy,
        });

        return {
          properties: filteredResponse.properties,
          unfilteredProps: unfilteredResponse.properties,
          totalPages: filteredResponse.totalPages,
        };
      } catch (error) {
        console.error("Error fetching properties:", error);
        throw error;
      }
    },
    keepPreviousData: true,
  });

  const handleArchiveProperty = (id) => {
    const reqeustBody = {
      propertyStatus: "archived",
    };
    Auth.currentSession().then((token) => {
      axios
        .put(
          `${process.env.REACT_APP_NODE_API_URL}/api/property/archive/${id}`,
          reqeustBody,
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            const updatedProperties = data.properties.map((property) => {
              if (property._id === id) {
                return { ...property, status: "archived" };
              }
              return property;
            });
            queryClient.setQueryData(queryKey, {
              properties: updatedProperties,
            });

            setIsArchived(true);
            setTimeout(() => setIsArchived(false), 3000);
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error archiving property",
            });
          }
        })
        .catch((error) => {
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error archiving property",
          });
        })
        .finally(refetch);
    });
  };

  const handleRestoreProperty = (id) => {
    const reqeustBody = {
      propertyStatus: "active",
    };
    Auth.currentSession().then((token) => {
      axios
        .put(
          `${process.env.REACT_APP_NODE_API_URL}/api/property/restore/${id}`,
          reqeustBody,
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            const updatedProperties = data.properties.map((property) => {
              if (property._id === id) {
                return { ...property, status: "active" };
              }
              return property;
            });
            queryClient.setQueryData(queryKey, {
              properties: updatedProperties,
            });

            setRestored(true);
            setTimeout(() => setRestored(false), 3000);
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error restoring property",
            });
          }
        })
        .catch((error) => {
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error restoring property",
          });
        })
        .finally(refetch);
    });
  };

  const handleDuplicateProperty = async (id) => {
    try {
      const token = await Auth.currentSession();
      const response = await axios.put(
        `${process.env.REACT_APP_NODE_API_URL}/api/property/duplicate/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );

      // Redirect to edit the new duplicate property
      if (response.data?._id) {
        navigate(`/property/edit/${response.data._id}`);
      }

      setDuplicated(true);
      setTimeout(() => setDuplicated(false), 3000);
    } catch (error) {
      console.error("Error duplicating property:", error);
      setAlertModal({
        show: true,
        title: "Error",
        content: "Error duplicating property",
      });
    } finally {
      refetch();
    }
  };

  const handleFilter = (value, type) => {
    const typeValues = filterBy[type];
    if (typeValues.includes(value)) {
      const updatedValues = typeValues.filter((item) => item !== value);
      setFilterBy({ ...filterBy, [type]: updatedValues });
    } else {
      setFilterBy({ ...filterBy, [type]: [...typeValues, value] });
    }
  };

  const handleSort = (by) => {
    if (by === sortBy.by) return setSortBy({ by, order: !sortBy.order });
    return setSortBy({ by, order: true });
  };

  const handleNextPage = (nextPage) => {
    if (data.totalPages >= nextPage && nextPage > 0) {
      setActualPage(nextPage);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setError(false);
  };

  const handleClose = () => {
    setShowImportModal(false);
    setError(false);
    setValidationError(false);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError(true);
      return;
    }

    try {
      const parsedData = await new Promise((resolve) => {
        Papa.parse(selectedFile, {
          complete: (result) => {
            resolve(result.data);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      });

      if (
        parsedData.some(
          (item) =>
            !item.address ||
            !item.aptOrSuite ||
            !item.city ||
            !item.state ||
            !item.zipCode
        )
      ) {
        setValidationError(true);
        return;
      }

      setShowImportModal(false);
      setValidationError(false);
      await submitData(parsedData);
    } catch (error) {
      console.error("Error parsing CSV:", error);
      setError(true);
    }
  };

  const mapUtilityValue = (value) =>
    value === "Included In Rent" ? "included" : "tenant";
  const convertToSecond = (ele) => Number(ele?.split(" ")[0]) * 60 * 1000;
  const mapParkingType = (type) => {
    switch (type) {
      case "Street Parking (Standard)":
        return "streetStandard";
      case "Street Parking (Residential Permit Enforced)":
        return "streetResidential";
      case "Uncovered Parking":
        return "uncovered";
      case "Covered Parking":
        return "covered";
      case "Secured Access Garage":
        return "secured";
      default:
        return "";
    }
  };

  const handleFormModal = (property) => {
    setSelectedProperty(property);
    setShowShareModal(true);
  };

  const handleModalClose = () => {
    setShowShareModal(false);
    setIsVerified(false);
    setIsSubmitted(false);
    setFormData({ fullName: "", email: "", phoneNumber: "" });
    setPhoneError("");
    setEmailError("");
    setSelectedProperty(null);
  };

  const submitData = async (data) => {
    if (!data.length) return;
    for (const jsonData of data) {
      let payload = {
        amenitiesAndFeatures: {
          floorPlanHighlights: [],
          kitchenFeatures: [],
          buildingFeatures: [],
        },
        unit: jsonData.aptOrSuite ? jsonData.aptOrSuite?.toString() : "",
        propertyType: jsonData.propertyType || "apartment",
        category: jsonData.rentOrSale || "rent",
        sqft: jsonData.sqft ? jsonData.sqft?.toString() : "",
        bedrooms: jsonData.numberOfBedrooms
          ? jsonData.numberOfBedrooms?.toString()
          : "",
        bathrooms: jsonData.numberOfBathrooms
          ? jsonData.numberOfBathrooms?.toString()
          : "",
        propertyDescription: jsonData.propertyDescription || "",
        utilities: {
          water: mapUtilityValue(jsonData.water)
            ? mapUtilityValue(jsonData.water)
            : "tenant",
          electricity: mapUtilityValue(jsonData.electricity)
            ? mapUtilityValue(jsonData.electricity)
            : "tenant",
          gas: mapUtilityValue(jsonData.gas)
            ? mapUtilityValue(jsonData.gas)
            : "tenant",
          trash: mapUtilityValue(jsonData.trash)
            ? mapUtilityValue(jsonData.trash)
            : "tenant",
          sewage: mapUtilityValue(jsonData.sewage)
            ? mapUtilityValue(jsonData.sewage)
            : "tenant",
          notes: jsonData.utilitiesNotes || "",
        },
        parking: {
          type: mapParkingType(jsonData.parkingType)
            ? mapParkingType(jsonData.parkingType)
            : "streetResidential",
          spacesNumber: jsonData.numberOfParkingSpaces
            ? jsonData.numberOfParkingSpaces?.toString()
            : "",
          monthlyCostPerSpace: jsonData.monthlyCostPerSpace
            ? jsonData.monthlyCostPerSpace?.toString()
            : "",
          included: jsonData.parkingIncludedInRent,
          notes: jsonData.parkingNotes || "",
        },
        pets: {
          allowed:
            jsonData.petsAllowed === "No pets allowed" ? true : false || true,
          dogs: {
            allowed:
              jsonData.petsAllowed === "Yes, dogs allowed" ? true : false,
            weight: jsonData.dogsWeightLimit
              ? jsonData.dogsWeightLimit?.toString()
              : "",
            maxAllowed: jsonData.dogsMaxNumberOfDogsAllowed
              ? jsonData.dogsMaxNumberOfDogsAllowed?.toString()
              : "",
          },
          cats: {
            allowed:
              jsonData.petsAllowed === "Yes, cats allowed" ? true : false,
            weight: jsonData.catsWeightLimit
              ? jsonData.catsWeightLimit?.toString()
              : "",
            maxAllowed: jsonData.catsMaxNumberOfCatsAllowed
              ? jsonData.catsMaxNumberOfCatsAllowed?.toString()
              : "",
          },
          deposit: jsonData.petDeposit ? jsonData.petDeposit?.toString() : "",
          monthlyPetRent: jsonData.monthlyPetRent
            ? jsonData.monthlyPetRent?.toString()
            : "",
          notes: jsonData.petPolicyNotes?.toString() || "",
        },
        rentalApplicationForm: {
          url: jsonData.applicationURL
            ? jsonData.applicationURL?.toString()
            : "",
          fee: jsonData.applicationFee
            ? jsonData.applicationFee?.toString()
            : "",
          instructions: jsonData.applicationInstructions?.toString() || "",
          enable: jsonData.rentalApplicationFormAndInstructions,
        },
        timeInstructions: convertToSecond(jsonData.advanceProspectsTime),
        company: {
          name: jsonData.propertyManagementGroup || "",
          website: jsonData.companyWebsite || "",
          phone: jsonData.companyPhoneNumber
            ? jsonData.companyPhoneNumber?.toString()
            : "",
          contactInformation:
            jsonData.includeCompanyContactInformationInProspectCommunication,
        },
        leasingAgent: {
          firstName: jsonData.agentFirstName || "",
          lastName: jsonData.agentLastName || "",
          phone: jsonData.agentPhoneNumber
            ? jsonData.agentPhoneNumber?.toString()
            : "",
          email: jsonData.agentEmail || "",
          contactInformation:
            jsonData.includeAgentContactInformationInProspectCommunication,
        },
        propertyManager: {
          onSite:
            jsonData.propertyOrBuildingManagerOnSite === "Yes" ? true : false,
          firstName: jsonData.managerFirstName || "",
          lastName: jsonData.managerLastName || "",
          phone: jsonData.managerPhoneNumber
            ? jsonData.managerPhoneNumber?.toString()
            : "",
          email: jsonData.managerEmail || "",
          contactInformation:
            jsonData.includeManagerContactInformationInProspectCommunication,
        },
        maintenanceManager: {
          onSite: jsonData.maintenanceManagerOnSite === "Yes" ? true : false,
          firstName: jsonData.maintenanceManagerFirstName || "",
          lastName: jsonData.maintenanceManagerLastName || "",
          phone: jsonData.maintenanceManagerPhoneNumber
            ? jsonData.maintenanceManagerPhoneNumber?.toString()
            : "",
          email: jsonData.maintenanceManagerEmail || "",
          contactInformation:
            jsonData.includeMaintenanceManagerContactInformationInProspectCommunication,
        },
        kit: null,
        price: jsonData.monthlyRent ? jsonData.monthlyRent?.toString() : "",
        deposit: jsonData.securityDeposit
          ? jsonData.securityDeposit?.toString()
          : "",
        shortAddress: jsonData.address || "",
        city: jsonData.city || "",
        zipCode: jsonData.zipCode || "",
        state: jsonData.state || "",
        address: jsonData.address || "",
        dateAvailableTs: jsonData.availableMoveInDate
          ? new Date(
              jsonData.availableMoveInDate.replace(
                /(\d{2})-(\d{2})-(\d{4})/,
                "$3-$1-$2"
              )
            ).getTime()
          : new Date().getTime(),
        primaryImage: "",
        prospectIdentityVerification: jsonData.idOrDriversLicenseVerification,
        doorUnlockLink: jsonData.doorUnlockLink,
        leaseTermOptions: [
          jsonData.leaseTermOptions ? jsonData.leaseTermOptions : "",
        ],
        propertyAccessCodes: {
          buildingCode: jsonData.buildingAccessCode || "",
          elevatorCode: jsonData.elevatorCode || "",
          instructions: jsonData.additionalInstructions || "",
          enable: jsonData.propertyAccessCodesAndInstructions,
        },
      };

      let methodForm = "";
      let url = "";
      if (isEditPage) {
        methodForm = `put`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${params.id}`;
      } else {
        methodForm = `post`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;
      }
      Auth.currentSession().then((token) => {
        axios({
          method: methodForm,
          url: url,
          data: payload,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
          .then((response) => {
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: "Success",
                content: "Property saved successfully",
              });
              navigate(`/property/${params.id}`);
            } else {
              setAlertModal({
                show: true,
                title: "Success",
                content: "Property created successfully",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: "Error",
                content: "Error in saving the property",
              });
            } else {
              setAlertModal({
                show: true,
                title: "Error",
                content: "Error in creating the property",
              });
            }
          });
      });
    }
  };


  const handleEnterInSearch = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setAppliedFilters({
        ...appliedFilters,
        search: filterBy.search,
      });
      setActualPage(1);
    }
  };

  // console.log({ showArchiveModal, propertyIdToArchiveOrRestore });

  return (
    <Sidebar>
      {
        // MARK: Import Modal
      }
      <Modal
        centered
        show={showImportModal}
        size="lg"
        dialogClassName="modal-dialog"
      >
        <Modal.Header>
          <Modal.Title>Import CSV File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mt-2">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          {error && (
            <label style={{ color: "red" }}>Import your CSV file</label>
          )}
          {validationError && (
            <label style={{ color: "red" }}>
              Fields address, aptOrSuite, city, state, zipCode are empty.
            </label>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex flex-column gap-3 flex-grow-1">
        {isArchived ? (
          <div className="copied">
            <p>
              {" "}
              <IoIosCheckmarkCircle
                style={{ color: "#00BA08" }}
                size={14}
              />{" "}
              Property Archived
            </p>
          </div>
        ) : (
          <></>
        )}
        {isRestored ? (
          <div className="copied">
            <p>
              {" "}
              <IoIosCheckmarkCircle
                style={{ color: "#00BA08" }}
                size={14}
              />{" "}
              Property Restored
            </p>
          </div>
        ) : (
          <></>
        )}
        {isDuplicated ? (
          <div className="copied">
            <p>
              {" "}
              <IoIosCheckmarkCircle
                style={{ color: "#00BA08" }}
                size={14}
              />{" "}
              Property Duplicated
            </p>
          </div>
        ) : (
          <></>
        )}
        {/*
              MARK: Header
        */}
        <PageHeader>
          <h1 className="color-black w-content fs-4">Properties</h1>

          <div className="d-flex gap-2 w-content">
            <Dropdown className=" align-items-center d-flex w-content">
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="border no-arrow d-flex align-items-center justify-content-center rounded-3 border h-100 gap-1"
                drop="none"
              >
                <FaCirclePlus size={20} className=" text-secondary" />
                <MdKeyboardArrowDown size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/property/add")}>
                  Add manually
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowImportModal(true)}>
                  Import
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <NotificationsButton />
          </div>
          <div className="d-lg-none d-flex gap-3">
            <div className="position-relative w-100">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={filterBy.search}
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    search: e.target.value,
                  });
                }}
                onKeyDown={handleEnterInSearch}
                className="w-100"
              />

              <UnstyledButton
                className="search-btn translate-middle-y"
                onClick={() =>
                  setAppliedFilters({
                    ...appliedFilters,
                    search: filterBy.search,
                  })
                }
              >
                <img src={searchIcon} className="search-icon" alt="search" />
              </UnstyledButton>
            </div>

            <Dropdown
              className=" align-items-center d-flex "
              show={showFilterDropdown}
            >
              <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className="border no-arrow   d-flex align-items-center justify-content-center"
                drop="none"
                onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                style={{
                  background: "#5271FF",
                  height: "40.38px",
                  width: "40.38px",
                  color: "white",
                  borderRadius: "100%",
                }}
              >
                <TbAdjustmentsHorizontal size={30} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  onClick={(e) => e.stopPropagation()}
                  className="no-bg"
                >
                  <span className="fw-bold fs-7">Type</span>
                  <div className="d-flex flex-column border-top pt-2 mt-2">
                    {propertyType.map((type, i) => (
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id={`default-${type.value}`}
                        label={type.label}
                        value={type.value}
                        onChange={() =>
                          handleFilter(type.value, "classification")
                        }
                        checked={filterBy.classification.includes(type.value)}
                        className="border-bottom pb-2"
                      />
                    ))}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  onClick={(e) => e.stopPropagation()}
                  className="no-bg d-flex flex-column"
                >
                  <strong>Status</strong>
                  <div className="d-flex flex-column border-top pt-2 mt-2">
                    {propertyFilterStatus.map((status, i) => (
                      <Form.Check
                        key={i}
                        type="checkbox"
                        id={`default-${status.value}`}
                        label={status.label}
                        value={status.value}
                        onChange={() => handleFilter(status.value, "status")}
                        checked={filterBy.status.includes(status.value)}
                        className="border-bottom pb-2"
                      />
                    ))}
                  </div>
                </Dropdown.Item>

                <div className="d-flex flex-column justify-content-center align-items-center gap-2 col-12">
                  <UnstyledButton
                    className="text-delet-blue fw-bold"
                    onClick={() => {
                      setShowFilterDropdown(false);
                      setAppliedFilters(filterBy);
                    }}
                  >
                    Apply filters
                  </UnstyledButton>

                  <UnstyledButton
                    className={`${
                      initStateString !== JSON.stringify(filterBy)
                        ? "text-delet-blue"
                        : "text-body-tertiary"
                    }`}
                    onClick={() => {
                      setAppliedFilters({
                        ...initState.filterBy,
                        status: [],
                      });
                      setFilterBy({
                        ...initState.filterBy,
                        status: [],
                      });
                      setShowFilterDropdown(false);
                    }}
                  >
                    Clear all
                  </UnstyledButton>
                </div>
              </Dropdown.Menu>
            </Dropdown>

          </div>
        </PageHeader>
        {
          //MARK: Mobile, Filters
        }

        <>
          {data.unfilteredProps?.length > 0 && data ? (
            <div className="px-4 px-lg-5 d-flex flex-column gap-4">
              {/*
              MARK: Desktop filters
            */}

              <div className="align-items-center d-none d-lg-flex justify-content-between">
                <div className="col-5 position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search properties"
                    value={filterBy.search}
                    onChange={(e) => {
                      if (e.target.value === "")
                        setAppliedFilters({
                          ...appliedFilters,
                          search: "",
                        });

                      setFilterBy({
                        ...filterBy,
                        search: e.target.value,
                      });
                    }}
                    onKeyDown={handleEnterInSearch}
                    className=""
                  />
                  <BootstrapButton
                    className="search-btn translate-middle-y"
                    onClick={() =>
                      setAppliedFilters({
                        ...appliedFilters,
                        search: filterBy.search,
                      })
                    }
                  >
                    <img
                      src={searchIcon}
                      className="search-icon"
                      alt="search"
                    />
                  </BootstrapButton>
                </div>
                <div className="d-flex gap-3">
                  <UnstyledButton
                    className={`close-button mt-0 ${
                      initStateString !== JSON.stringify(filterBy)
                        ? "text-delet-blue"
                        : "text-body-tertiary"
                    }`}
                    onClick={() => {
                      setAppliedFilters({
                        ...initState.filterBy,
                        status: [],
                      });
                      setFilterBy({
                        ...initState.filterBy,
                        status: [],
                      });
                      setShowFilterDropdown(false);
                    }}
                  >
                    Clear all
                  </UnstyledButton>

                  <Dropdown show={showFilterDropdown}>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      className="border no-arrow h-100 px-5 w-100 d-flex gap-1 align-items-center"
                      drop="none"
                      onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                    >
                      <TbAdjustmentsHorizontal size={18} />
                      Filter By
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={(e) => e.stopPropagation()}
                        className="no-bg"
                      >
                        <span className="fw-bold fs-7">Type</span>
                        <div className="d-flex flex-column border-top pt-2 mt-2">
                          {propertyType.map((type) => (
                            <Form.Check
                              type="checkbox"
                              id={`default-${type.value}`}
                              label={type.label}
                              value={type.value}
                              key={type.value}
                              onChange={() =>
                                handleFilter(type.value, "classification")
                              }
                              checked={filterBy.classification.includes(
                                type.value
                              )}
                              className="border-bottom pb-2"
                            />
                          ))}
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        onClick={(e) => e.stopPropagation()}
                        className="no-bg d-flex flex-column"
                      >
                        <strong>Status</strong>
                        <div className="d-flex flex-column border-top pt-2 mt-2">
                          {propertyFilterStatus.map((status) => (
                            <Form.Check
                              type="checkbox"
                              id={`default-${status.value}`}
                              label={status.label}
                              value={status.value}
                              key={status.value}
                              onChange={() =>
                                handleFilter(status.value, "status")
                              }
                              checked={filterBy.status.includes(status.value)}
                              className="border-bottom pb-2"
                            />
                          ))}
                        </div>
                      </Dropdown.Item>

                      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                        <UnstyledButton
                          className="text-delet-blue fw-bold"
                          onClick={() => {
                            setShowFilterDropdown(false);
                            setActualPage(1);
                            setAppliedFilters(filterBy);
                          }}
                        >
                          Apply filters
                        </UnstyledButton>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              {isLoading ? (
                <Center h={100}>
                  <Loader />
                </Center>
              ) : isError ? (
                <div>Error getting properties</div>
              ) : (
                //MARK:  Desktop Table
                <section className="">
                  <div className="d-none d-lg-flex row justify-content-lg-between border-bottom pt-4">
                    <h3
                      className="col-lg-4 col-xxl-3 table-h"
                      onClick={() => handleSort("address")}
                    >
                      Property Details{" "}
                      <FaChevronDown
                        size={12}
                        className={sortBy.order ? "" : "rotate-180"}
                      />
                    </h3>
                    <h3
                      className="col-lg-1 table-h text-center d-flex align-items-center gap-1 justify-content-center cursor-pointer"
                      onClick={() => handleSort("price")}
                    >
                      Price{" "}
                      <FaChevronDown
                        size={12}
                        className={sortBy.order ? "" : "rotate-180"}
                      />
                    </h3>
                    <h3
                      className="col-lg-1 table-h text-center d-flex justify-content-center align-items-center"
                      // onClick={() => handleSort("leads")}
                    >
                      Leads
                    </h3>
                    <h3
                      className={`col-lg-1 table-h text-xxl-center px-lg-0 d-flex align-items-center gap-1 justify-content-center cursor-pointer ${
                        sortBy.by !== "listedTs" && "text-nowrap"
                      }`}
                      onClick={() => handleSort("listedTs")}
                    >
                      Listed date{" "}
                      <div>
                        <FaChevronDown
                          size={12}
                          className={sortBy.order ? "" : "rotate-180"}
                        />
                      </div>
                    </h3>
                    <h3
                      className="col-lg-2 table-h text-center d-flex align-items-center gap-1 justify-content-center cursor-pointer"
                      onClick={() => handleSort("status")}
                    >
                      Status{" "}
                      {sortBy.by === "status" && (
                        <FaChevronUp
                          size={12}
                          className={sortBy.order ? "" : "rotate-180"}
                        />
                      )}
                    </h3>
                    <h3 className="col-lg-1 table-h text-center"> </h3>
                  </div>
                  <div>
                    {data.properties.map((property) => (
                      <PropertyListCard
                        key={property._id}
                        property={property}
                        handleFormModal={handleFormModal}
                        queryKey={queryKey}
                        setShowArchiveModal={setShowArchiveModal}
                        setPropertyIdToArchiveOrRestore={
                          setPropertyIdToArchiveOrRestore
                        }
                        setShowRestoreModal={setShowRestoreModal}
                        setShowDuplicateModal={setShowDuplicateModal}
                      />
                    ))}
                  </div>
                </section>
              )}
              {data?.totalPages > 1 && (
                <div className="py-3">
                  <PagePagination
                    totalPages={data.totalPages}
                    actualPage={actualPage}
                    handlePageChange={handleNextPage}
                  />
                </div>
              )}
            </div>
          ) : isLoading ? (
            <Center h={500}>
              <Loader />
            </Center>
          ) : isError ? (
            <div>Error there are no properties</div>
          ) : //Code for the no properties page
          !isArchived && !isRestored ? (
            <div className="d-flex flex-column gap-3 justify-content-center align-items-center align-items-center flex-grow-1">
              <img
                src={NoProperties}
                className="no-property-img"
                alt="no-properties"
              />
              <h2 className="text-center color-gray fs-6">
                <span className="fw-semibold fs-6">Oops!</span> <br /> It looks
                like you don't have any properties yet.
              </h2>
              <AppButton
                className="px-5 small-text"
                onClick={() => navigate("/property/add")}
              >{`Add New Property >`}</AppButton>
            </div>
          ) : (
            <Center h={500}>
              <Loader />
            </Center>
          )}
        </>
      </div>

      {showArchiveModal && (
        <div
          className={`modal fade ${showArchiveModal ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: showArchiveModal ? "block" : "none" }}
        >
          {/* Modal backdrop */}
          {showArchiveModal && (
            <div className="modal-backdrop fade show z-1"></div>
          )}

          <div className="modal-dialog modal-dialog-centered z-2">
            <div className="modal-content px-4 py-2">
              {/* Modal body */}
              <div className="modal-body">
                <div className="w-100 pt-1 d-flex flex-column align-items-center gap-3">
                  <IoIosInformationCircle size={50} color="#5271ff" />
                  <h5 className="fs-5 fw-bold mb-0 text-dark">
                    You're About to Archive This Property
                  </h5>
                  <p className="fs-6 fw-lighter">
                    Are you sure you want to proceed?
                  </p>
                </div>
              </div>

              {/* Modal footer */}
              <div className="modal-footer border-0 pt-0">
                <div className="d-flex gap-3 w-100">
                  <AppButton
                    as="button"
                    hierarchy="secondary"
                    onClick={() => setShowArchiveModal(false)}
                    className="w-50 text-dark"
                  >
                    Close
                  </AppButton>
                  <AppButton
                    as="button"
                    className="w-50"
                    onClick={() => {
                      handleArchiveProperty(propertyIdToArchiveOrRestore);
                      setShowArchiveModal(false);
                    }}
                  >
                    Archive
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRestoreModal && (
        <div
          className={`modal fade ${showRestoreModal ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: showRestoreModal ? "block" : "none" }}
        >
          {/* Modal backdrop */}
          {showRestoreModal && (
            <div className="modal-backdrop fade show z-1"></div>
          )}

          <div className="modal-dialog modal-dialog-centered z-2">
            <div className="modal-content px-4 py-2">
              {/* Modal body */}
              <div className="modal-body">
                <div className="w-100 pt-1 d-flex flex-column align-items-center gap-3">
                  <IoIosInformationCircle size={50} color="#5271ff" />
                  <h5 className="fs-5 fw-bold mb-0 text-dark">
                    You're About to Restore This Property
                  </h5>
                  <p className="fs-6 fw-lighter">
                    Are you sure you want to proceed?
                  </p>
                </div>
              </div>

              {/* Modal footer */}
              <div className="modal-footer border-0 pt-0">
                <div className="d-flex gap-3 w-100">
                  <AppButton
                    as="button"
                    hierarchy="secondary"
                    onClick={() => setShowRestoreModal(false)}
                    className="w-50 text-dark"
                  >
                    Close
                  </AppButton>
                  <AppButton
                    as="button"
                    className="w-50"
                    onClick={() => {
                      handleRestoreProperty(propertyIdToArchiveOrRestore);
                      setShowRestoreModal(false);
                    }}
                  >
                    Restore
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDuplicateModal && (
        <div
          className={`modal fade ${showDuplicateModal ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: showDuplicateModal ? "block" : "none" }}
        >
          {/* Modal backdrop */}
          {showDuplicateModal && (
            <div className="modal-backdrop fade show z-1"></div>
          )}

          <div className="modal-dialog modal-dialog-centered z-2">
            <div className="modal-content px-4 py-2">
              {/* Modal body */}
              <div className="modal-body">
                <div className="w-100 pt-1 d-flex flex-column align-items-center gap-3">
                  <IoIosInformationCircle size={50} color="#5271ff" />
                  <h5 className="fs-5 fw-bold mb-0 text-dark">
                    You're About to Duplicate This Property
                  </h5>
                  <p className="fs-6 fw-lighter">
                    Are you sure you want to proceed?
                  </p>
                </div>
              </div>

              {/* Modal footer */}
              <div className="modal-footer border-0 pt-0">
                <div className="d-flex gap-3 w-100">
                  <AppButton
                    as="button"
                    hierarchy="secondary"
                    onClick={() => setShowDuplicateModal(false)}
                    className="w-50 text-dark"
                  >
                    Close
                  </AppButton>
                  <AppButton
                    as="button"
                    className="w-50"
                    onClick={() => {
                      handleDuplicateProperty(propertyIdToArchiveOrRestore);
                      setShowDuplicateModal(false);
                    }}
                  >
                    Duplicate
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <FormModal
        show={showShareModal}
        onHide={() => handleModalClose()}
        isVerified={isVerified}
        isSubmitted={isSubmitted}
        setIsVerified={setIsVerified}
        setIsSubmitted={setIsSubmitted}
        formData={formData}
        setFormData={setFormData}
        emailError={emailError}
        setEmailError={setEmailError}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
        property={selectedProperty}
      />
    </Sidebar>
  );
};

export default PropertiesList;
