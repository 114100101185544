import { useInfiniteQuery } from "@tanstack/react-query";

export const useCompaniesInfiniteQuery = ({
  sortField,
  sortDirection,
  debouncedSearchTerm,
  limit,
  getCompaniesList
}) => {
  return useInfiniteQuery({
    queryKey: [
      "companies",
      sortField,
      sortDirection,
      debouncedSearchTerm,
      limit,
    ],
    queryFn: ({ pageParam }) =>
      getCompaniesList({
        pageParam,
        sortField,
        sortDirection,
        searchTerm: debouncedSearchTerm,
        limit,
      }),
    getNextPageParam: (lastPage) =>
      lastPage.currentPage < lastPage.totalPages
        ? lastPage.nextPage
        : undefined,
    initialPageParam: 1,
  });
};