import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getProperties = async ({ page, limit, filters, sort, select, isAdmin = false }) => {
  const adminApi = isAdmin ? "/admin" : "";
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/property${adminApi}/search`;

  const requestBody = {
    page,
    limit,
    filters,
    sort,
    select,
  };

  try {
    const auth = await Auth.currentSession();
    const response = await axios.post(baseUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};

export const getAllProperties = async ({ fields }) => {
  const auth = await Auth.currentSession();
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/all?fields=${fields}`;

  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });
    const properties = await res.data;
    return properties;
  } catch (error) {
    console.error("ERROR GETTING PROPERTIES: ", error)
  }
};

export const getAllAdminProperties = async ({ fields }) => {
  const auth = await Auth.currentSession();
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/all/admin?fields=${fields}`;

  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });
    const properties = await res.data;
    return properties;
  } catch (error) {
    console.error("ERROR GETTING PROPERTIES: ", error)
  }
};

export const getPublicProperty = async (propertyId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/public/property/${propertyId}`;
  const res = await fetch(url);
  const property = await res.json();

  return property;
};

export const getBuilding = async (buildingId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/public/building/${buildingId}`;

  const property = await axios.get(url);
  return property;
};

export const getActiveProperties = async (agentId, properties) => {
  const url = properties
    ? `${process.env.REACT_APP_NODE_API_URL
    }/api/property/public/properties?properties=${properties.toString()}`
    : `${process.env.REACT_APP_NODE_API_URL}/api/property/public/agent/${agentId}`;

  const res = await axios.get(url);
  return res.data.list;
};

export const patchProperty = async (propertyId, updatedFields) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${propertyId}`
  try {
    const token = await Auth.currentSession();

    const res = await axios.patch(url, updatedFields, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
      },
    })

    return res.data

  } catch (error) {
    throw error
  }
}

export const patchStatus = async (propertyId, newStatus) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/status/${propertyId}/${newStatus}`
  try {
    const token = await Auth.currentSession();
    const res = await axios.patch(url, {}, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
      },
    })

    return res.data

  } catch (error) {
    throw error
  }
}

export const updatePropertyKit = async (property, kit) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${property}/kit`;

  const token = await Auth.currentSession();
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    },
    body: JSON.stringify({ kit }),
  });

  if (!response.ok) {
    throw new Error('Error updating property kit');
  }

  const data = await response.json();
  return data;
};

export const createProperty = async (propertyInfo) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;

  try {
    const token = await Auth.currentSession();
    const res = await axios({
      method: 'POST',
      url,
      data: propertyInfo,
      headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` },
    });

    return res.data;
  } catch (error) {
    console.error('ERROR CREATING PROPERTY: ', error);
  }
};

//Admin endpoints

export const adminGetAllProperties = async ({ userId }) => {
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/property/admin/${userId}`;

  try {
    const auth = await Auth.currentSession();
    const response = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};