import { PieChart } from "@mantine/charts";
import {
    Card,
    Group,
    Flex,
    Text,
    useMantineTheme,
    Center,
    Loader,
    Space,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { formatPieChartData } from "./utils";
import { getPropertiesStatus } from "../../api/dashboard";

export interface PieData {
    name: string;
    value: number;
    color: string;
}

export default function CardPieChart() {
    // Data fetching states for Pie Chart
    const [pieData, setPieData] = useState<PieData[] | null>(null);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const data: Record<string, number> =
                    await getPropertiesStatus();
                const pieData = formatPieChartData(data);
                const total =
                    pieData?.reduce(
                        (acc: number, item: PieData) => acc + item.value,
                        0
                    ) ?? 0;
                setTotalCount(total);
                setPieData(pieData);
            } catch (error) {
                console.error("Error fetching property status count:", error);
                setPieData(null);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Mantine theme colors
    const theme = useMantineTheme();

    return (
        <Card
            shadow="md"
            px={{ base: 15, md: "xl" }}
            py={{ base: 20, md: "xl" }}
            radius="md"
            withBorder
            style={{ height: "100%" }}
        >
            <div className="d-flex justify-content-between align-items-center">
                {/* Title mobile */}
                <Text
                    className="d-block d-md-none"
                    size="md"
                    fw={600}
                    c="gray.7"
                >
                    Current Properties
                </Text>

                {/* Title desktop */}
                <Text
                    className="d-none d-md-block"
                    size="xl"
                    fw={600}
                    c="gray.7"
                >
                    Current Properties
                </Text>

                <Text size="18px" fw={500} c="gray.7">
                    {totalCount}
                </Text>
            </div>

            {/* Check if pieData is available */}
            {isLoading ? (
                <Center style={{ height: "100px" }}>
                    <Loader color="indigo" />
                </Center>
            ) : pieData && pieData.length > 0 ? (
                <>
                    {pieData.length === 1 && <Space h="lg" />}

                    {/* Pie Chart */}
                    <PieChart
                        data={pieData}
                        size={130}
                        withTooltip
                        withLabels={pieData.length > 1}
                        withLabelsLine={false}
                        labelsPosition="outside"
                        labelsType="percent"
                        strokeWidth={0}
                        mx="auto"
                    />

                    {pieData.length === 1 && <Space h="lg" />}

                    {/* Legend */}
                    <Flex
                        rowGap="5px"
                        columnGap="md"
                        justify="center"
                        align="center"
                        wrap="wrap"
                        mx="auto"
                    >
                        {pieData.map((item) => {
                            // Resolve Mantine colors (e.g., 'indigo.6') explicitly
                            const [colorName, shade] = item.color.split(".");
                            const resolvedColor =
                                theme.colors[colorName]?.[Number(shade)] ||
                                item.color;

                            return (
                                <Group key={item.name} gap="xs">
                                    {/* Color Circle */}
                                    <div
                                        style={{
                                            width: 8,
                                            height: 8,
                                            backgroundColor: resolvedColor,
                                            borderRadius: "50%",
                                        }}
                                    />
                                    {/* Name */}
                                    <Text size="xs">{item.name}</Text>
                                </Group>
                            );
                        })}
                    </Flex>
                </>
            ) : (
                // No Data Available Message
                <Center style={{ height: "100px" }}>
                    <Text ta="center" c="gray.6">
                        No properties available
                    </Text>
                </Center>
            )}
        </Card>
    );
}
