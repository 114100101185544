import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import getImageUrl from "../../../../helper/checkImageFormat";
import { IoIosArrowBack } from "react-icons/io";
import UnstyledButton from "../../../../components/UnstyledButton";
import { FaRegCirclePlay } from "react-icons/fa6";
import { VIDEO_EXTENSIONS } from "../../../../constants";
import VideoLoader from "../../../../components/VideoLoader";

function useItemsToShow() {
  const [itemsToShow, setItemsToShow] = useState(3);

  useEffect(() => {
    function handleResize() {
      setItemsToShow(window.innerWidth >= 992 ? 4 : 3);
    }

    // Initial check
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return itemsToShow;
}

const InstructionsMediaModal = ({ mediaArray }) => {
  const itemsToShow = useItemsToShow();
  const [show, setShow] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isVideo = (url) => {
    return VIDEO_EXTENSIONS.some((ext) => url.includes(`.${ext}`));
  };

  const getVisibleIndexes = () => {
    const totalItems = mediaArray.length;

    if (totalItems <= itemsToShow) {
      return mediaArray.map((_, i) => i);
    }

    let start = Math.max(0, currentMedia - Math.floor(itemsToShow / 2));
    const end = Math.min(totalItems, start + itemsToShow);

    start = Math.max(0, end - itemsToShow);

    return Array.from({ length: end - start }, (_, i) => start + i);
  };

  return (
    <>
      <UnstyledButton onClick={handleShow}>
        <span className="text-delet-blue fw-medium fs-7">
          <FaRegCirclePlay /> WATCH HOW TO GET IN: VIDEOS & PHOTOS
        </span>
      </UnstyledButton>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="instructions-media-modal"
      >
        <Modal.Body className="p-4 bg-transparent">
          <div className="w-100 pb-2 show-container no-select">
            {isVideo(mediaArray[currentMedia]) ? (
              <VideoLoader
                file={{ url: getImageUrl(mediaArray[currentMedia]) }}
                controls
                width="100%"
                height="100%"
              />
            ) : (
              <img
                src={getImageUrl(mediaArray[currentMedia])}
                alt={`Media ${currentMedia}`}
                className="w-100 h-100 object-fit-contain rounded"
              />
            )}
          </div>
          <div className="row justify-content-center row-gap-2 row-gap-lg-3 row-cols-3 row-cols-lg-4 position-relative">
            {getVisibleIndexes().map((originalIndex) => {
              const mediaItem = mediaArray[originalIndex];
              const url = getImageUrl(mediaItem);

              return (
                <div
                  className="d-flex justify-content-center instructions-media-container no-select"
                  key={originalIndex}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentMedia(originalIndex);
                  }}
                >
                  {isVideo(url) ? (
                    <VideoLoader file={{ url }} width="100%" height="100%" />
                  ) : (
                    <img
                      src={url}
                      alt={`Media ${originalIndex + 1}`}
                      className="w-100 h-100 rounded"
                    />
                  )}
                </div>
              );
            })}
            {currentMedia > 0 ? (
              <div className="position-absolute instructions-arrow back w-content cursor-pointer top-50 translate-middle-y">
                <IoIosArrowBack
                  size={20}
                  color="white"
                  draggable="false"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentMedia(currentMedia - 1);
                  }}
                  onDrag={(e) => e.preventDefault()}
                />
              </div>
            ) : null}

            {currentMedia < mediaArray.length - 1 ? (
              <div className="position-absolute instructions-arrow next w-content cursor-pointer bottom-50 translate-middle-y">
                <IoIosArrowBack
                  size={20}
                  color="white"
                  draggable="false"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentMedia(currentMedia + 1);
                  }}
                />
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InstructionsMediaModal;
