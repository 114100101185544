export default function TableSkeleton() {
    return (
        <div className="d-flex flex-column gap-4 pt-4">
            {[...Array(20)].map((_, i) => (
                <p
                    key={i}
                    className="card-text placeholder-glow d-flex gap-2 pe-3"
                >
                    {[...Array(6)].map((_, i) => (
                        <span
                            key={i}
                            className="placeholder col placeholder-sm rounded-1 bg-primary"
                        ></span>
                    ))}
                </p>
            ))}
        </div>
    );
}
