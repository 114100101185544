import { Auth } from "aws-amplify";
import axios from "axios";

export const postCreateBuilding = async (building) => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/structure/admin";

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .post(url, building, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const getAdminBuildingList = async ({ userId }) => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/structure/user/" + userId;

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const getAdminBuilding = async (id) => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/structure/admin/" + id;

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const patchBuilding = async (building) => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/structure/admin";

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .patch(url, building, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}

export const getAllBuildingsList = async () => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/structure/admin";

  try {
    const token = await Auth.currentSession()

    const res = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })

    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}