import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { adminGetAllProperties } from '../../api/properties';

export const usePropertiesList = ({ userId }) => {
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['admin', 'properties', userId],
    queryFn: () => adminGetAllProperties({ userId }),
    retry: (failureCount, error) => {
      if (error?.response?.status === 401) {
        Auth.signOut();
        navigate('/admin/login');
        return false;
      }
      return failureCount < 3;
    },
    onError: (error) => {
      if (error?.response?.status !== 401) {
        console.error('Failed to fetch users:', error);
      }
    },
    enabled: Boolean(userId)
  });
};