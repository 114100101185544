import html2pdf from "html2pdf.js";
import { ActionIcon } from "@mantine/core";
import { FiDownload } from "react-icons/fi";

export default function DownloadButton({ dashboardRef }) {
    // Trigger PDF dashboard download
    const handleDownload = () => {
        const element = dashboardRef.current;

        // Configure html2pdf.js options
        const options = {
            margin: 0, // Remove margins for full-width rendering
            filename: "delet_dashboard.pdf", // Name of the downloaded file
            image: { type: "jpeg", quality: 1.0 }, // High image quality
            html2canvas: { scale: 3, useCORS: true }, // Higher scale for better quality
            jsPDF: {
                unit: "px",
                format: [element.offsetWidth, element.offsetHeight],
            },
        };

        // Generate and download the PDF
        html2pdf().set(options).from(element).save();
    };

    return (
        <ActionIcon
            size="input-lg"
            radius="md"
            variant="default"
            aria-label="Download button"
            onClick={handleDownload}
        >
            <FiDownload size={20} color="gray" />
        </ActionIcon>
    );
}
