import { Table } from "@tanstack/react-table";
import { PropertyShare } from "./types";
import { exportToCSV } from "./utils";
import { Dropdown } from "react-bootstrap";
import { DatePickerInput } from "@mantine/dates";
import { MultiSelect } from "@mantine/core";
import { Search, Download } from "react-feather";
import { BsSliders2 } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";

interface EmailHistoryFiltersProps {
    table: Table<PropertyShare>;
    propertyOptions: { value: string; label: string }[];
}

export default function EmailHistoryFilters({
    table,
    propertyOptions,
}: EmailHistoryFiltersProps) {
    // Date filter
    const dateFilter = (table.getColumn("createdAt")?.getFilterValue() as {
        start: Date | null;
        end: Date | null;
    }) || { start: null, end: null };

    const handleStartDateChange = (date: Date | null) => {
        table
            .getColumn("createdAt")
            ?.setFilterValue({ start: date, end: dateFilter.end });
    };

    const handleEndDateChange = (date: Date | null) => {
        let adjusted: Date | null = null;
        if (date) {
            adjusted = new Date(date);
            adjusted.setHours(23, 59, 59, 999);
        }
        table
            .getColumn("createdAt")
            ?.setFilterValue({ start: dateFilter.start, end: adjusted });
    };

    // Properties filter
    const propertyFilter =
        (table.getColumn("location")?.getFilterValue() as string[]) || [];

    const handlePropertyFilterChange = (values: string[]) => {
        table.getColumn("location")?.setFilterValue(values);
    };

    // Clear all filters
    const handleClearAll = () => {
        table.resetGlobalFilter();
        table.resetColumnFilters();
    };

    return (
        <div className="mb-4 d-flex justify-content-between align-items-center gap-2 gap-md-3">
            {/* Search input */}
            <div className="position-relative flex-grow-1">
                <Search
                    size={14}
                    className="position-absolute top-50 start-0 translate-middle-y text-muted"
                    style={{ marginLeft: "15px" }}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={table.getState().globalFilter || ""}
                    onChange={(e) => table.setGlobalFilter(e.target.value)}
                    style={{ paddingLeft: "40px" }}
                />
            </div>

            {/* Clear All (button desktop) */}
            <button
                className="d-none d-lg-block btn bg-transparent text-delet-blue fw-medium"
                onClick={handleClearAll}
            >
                Clear All
            </button>

            {/* Filters */}
            <Dropdown>
                <Dropdown.Toggle
                    variant="light"
                    bsPrefix="dropdown px-2 px-md-4 border border-secondary"
                >
                    <div className="d-flex align-items-center gap-2">
                        <BsSliders2 size="16px" />
                        <span className="d-none d-lg-inline fw-semibold">
                            Filter by
                        </span>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ minWidth: "350px", zIndex: 1 }}>
                    <div className="px-3 py-2">
                        {/* Date filter */}
                        <div className="row gx-3 mb-3">
                            <p className="col-12 text-center mb-2 fw-semibold">
                                Date
                            </p>
                            <DatePickerInput
                                className="col-12 col-md-6 mb-2 mb-md-0"
                                leftSection={
                                    <CiCalendar size={20} stroke="1.5" />
                                }
                                leftSectionPointerEvents="none"
                                valueFormat="DD/MM/YYYY"
                                placeholder="Start"
                                value={dateFilter.start}
                                onChange={handleStartDateChange}
                                clearable
                            />

                            <DatePickerInput
                                className="col-12 col-md-6"
                                leftSection={
                                    <CiCalendar size={20} stroke="1.5" />
                                }
                                leftSectionPointerEvents="none"
                                placeholder="End"
                                valueFormat="DD/MM/YYYY"
                                value={dateFilter.end}
                                onChange={handleEndDateChange}
                                clearable
                            />
                        </div>

                        {/* Properties filter */}
                        <div className="row">
                            <p className="col-12 text-center mb-2 fw-semibold">
                                Properties
                            </p>
                            <div className="col-12">
                                <MultiSelect
                                    data={propertyOptions}
                                    placeholder="Select properties"
                                    value={propertyFilter}
                                    onChange={handlePropertyFilterChange}
                                    searchable
                                />
                            </div>
                        </div>

                        {/* Clear All (button mobile) */}
                        <div className="d-flex d-lg-none row mt-3 justify-content-center">
                            <button
                                className="w-content btn bg-transparent text-delet-blue fs-7 fw-medium border-0 p-0"
                                onClick={handleClearAll}
                            >
                                Clear All
                            </button>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            {/* Button export to CSV */}
            <button
                className="px-2 px-md-4 btn bg-delet text-white fw-semibold"
                onClick={() => exportToCSV(table)}
            >
                <Download size={16} className="d-block d-lg-none" />
                <span className="d-none d-lg-block">Export</span>
            </button>
        </div>
    );
}
