import React, { useMemo } from "react";
import propertyDummyImage from "../../../assests/images/6-thumb.png";
import { CiLocationOn } from "react-icons/ci";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import getImageUrl from "../../../helper/checkImageFormat";
import { Card } from "react-bootstrap";

function InstructionsCard({ property, startTime }) {
  const startDate = useMemo(() => new Date(startTime * 1000), [startTime]);

  return (
    <Card className="p-3 m-0 rounded shadow-lg instructions-card h-content">
      <div className="row">
        <div className="img-container">
          <img
            src={getImageUrl(property.images?.[0]) || propertyDummyImage}
            alt="property"
            className="h-100 w-100 object-fit-cover"
          />
        </div>

        <div className="col d-flex flex-column justify-content-center gap-3">
          {/* Location */}
          <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-1 pb-lg-3">
            <span className="text-body-tertiary small-text d-flex gap-1 align-items-center fs-8 fw-medium">
              <CiLocationOn className="" />
              Location
            </span>
            <span className="fs-8 text-end">{property?.unit ? `${property.shortAddress}, Unit ${property.unit}, ${property.city}, ${property.state} ${property.zipCode}` : `${property.address}`}</span>
          </div>

          {/* Date */}
          <div className="d-flex align-items-center justify-content-between gap-3 border-bottom pb-1 pb-lg-3">
            <span className="text-body-tertiary small-text d-flex gap-1 align-items-center fs-8 fw-medium">
              <GoCalendar className="" />
              On
            </span>
            <span className="fs-8 text-end">{`${startDate.getDate()} ${startDate.toLocaleString(
              "default",
              {
                month: "long",
              }
            )}, ${startDate.getFullYear()}`}</span>
          </div>

          {/* Time */}
          <div className="d-flex align-items-center justify-content-between gap-3">
            <span className="text-body-tertiary small-text d-flex gap-1 align-items-center fs-8 fw-medium">
              <FaRegClock />
              At
            </span>

            <span className="fs-8 text-end">
              {startDate.toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default InstructionsCard;
