import React, { FormEvent, useState } from 'react';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './step3/Step3';
import Step4 from './Step4/Step4';
import Step5 from './Step5/Step5';
import meeting from '../../../../assests/images/Step1.svg';
import greeting from '../../../../assests/images/Step0.svg';
import keys from '../../../../assests/images/Step2.svg';
import checklist from '../../../../assests/images/Step3.svg';
import innovation from '../../../../assests/images/Step4.svg';
import movein from '../../../../assests/images/Step5.svg';
import Logo from '../../../../assests/images/logo-dark.svg'
import PropertySelect from './PropertySelect/PropertySelect';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getActiveProperties, getPublicProperty } from '../../../../api/properties';
import { AppButton } from '../../../../designSystem/AppButton';
import { StepNumberLabel } from './StepNumberLabel';
import style from './style.module.scss';
import check from '../../../../assests/images/check.svg';

import useGaTracker from '../../../../hooks/useGaTracker';
import { createStep1, createStep2, createStep3, createStep4, createStep5 } from '../../../../api/bookings';
import { Card } from '../../../../designSystem/Card';

import { OnlyOnePropertySteps } from '../OnlyOnePropertySteps';

import { Modal } from 'react-bootstrap';
import { RiErrorWarningFill } from "react-icons/ri";
import { set } from 'date-fns';

// Type for booking props remains the same

type bookingProps = {
  agent: string;
  propertyId: string;
  localTimeRequested: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;
  privacyPolicy?: 'true' | 'false';
  idVerification?: boolean;
  contactId?: string;
  bookingId?: string;
  document: File;
  documentBack?: File;
  face?: File;
  verified?: boolean;
  timezone: string;
}


function StepsContainer() {
  useGaTracker();

  const [searchParams] = useSearchParams();
  const { propertyId, agentId } = useParams();
  const [inputValues, setInputValues] = useState<Partial<bookingProps>>({
    agent: agentId,
  });

  const [alertModal, setAlertModal] = useState({
    show: false,
    title: '',
    content: '',
  });

  const navigate = useNavigate();
  const searchProperties = searchParams.get('properties');
  const [checked, setChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({
    fullName: '',
    phone: '',
    email: '',
    document: '',
    privacyPolicy: '',
  })

  const [isLoadingStep, setIsLoadingStep] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    data: properties,
    isLoading: loadingProperties,
    isError: propertyError,
  } = useQuery({
    queryKey: propertyId
      ? ['property', propertyId]
      : ['properties', agentId],
    queryFn: async () => {
      const res = propertyId
        ? await getPublicProperty(propertyId)
        : await getActiveProperties(agentId, searchProperties);
      return res;
    },
  });

  const selectedProperty = !Array.isArray(properties)
    ? properties
    : properties.length === 1
      ? properties[0]
      : properties.find(property => property._id === inputValues?.propertyId) || null;

  const onNext = async () => {
    setButtonLoading(true);
    setTimeout(() => setButtonLoading(false), 500)

    if (step === 0) {
      setStep(prevStep => prevStep + 1)
    }
    if (step === 1) {
      if (!inputValues.firstName || !inputValues.lastName) {
        setErrors(prev => ({ ...prev, fullName: 'Both First & Last Name are required' }));
        setButtonLoading(false);
        return;
      }
      await handleStep1(inputValues);
    } else if (step === 2) {

      const phoneRegex = /^\d{10}$/i;
      if (inputValues.phone && !phoneRegex.test(inputValues.phone as string)) {
        setErrors(prev => ({ ...prev, phone: 'Incomplete or invalid phone number e.g. 3109137313' }));
        setButtonLoading(false);
        return;
      }
      await handleStep2(inputValues);
    } else if (step === 3) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(inputValues.email as string)) {
        setErrors(prev => ({ ...prev, email: 'Email is required' }));
        setButtonLoading(false);
        return;
      }
      await handleStep3(inputValues);
    } else if (step === 4) {
      if (!inputValues.localTimeRequested) {
        setErrors(prev => ({ ...prev, localTimeRequested: 'Time is required' }));
        setButtonLoading(false);
        return;
      }
      await handleStep4(inputValues);
    } else if (step === 5) {
      if (!inputValues.document) {
        setErrors(prev => ({ ...prev, document: 'Document is required' }));
        setButtonLoading(false);
        return;
      }
      await handleStep5(inputValues);
    }
  };


  // Step 1 Mutation
  const { mutateAsync: handleStep1 } = useMutation({

    mutationFn: (input: Partial<bookingProps>) => createStep1(input),
    onSuccess: (data) => {
      setInputValues(prev => ({ ...prev, bookingId: data.bookingId, contactId: data.contactId }));
      setIsLoadingStep(true);
      setLoaderMessage(`Welcome ${inputValues.firstName} ${inputValues.lastName}`);
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep(prevStep => prevStep + 1);
      }, 1000);

    },
    onError: () => {
      setErrors(prev => ({ ...prev, fullName: 'A First and Last Name is required' }));
    },
  });

  // Step 2 Mutation
  const { mutateAsync: handleStep2 } = useMutation({
    mutationFn: (input: Partial<bookingProps>) => createStep2(input),
    onSuccess: () => {
      setIsLoadingStep(true);
      setLoaderMessage(`Thanks ${inputValues.firstName}! Just a few more steps.`);
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep(prevStep => prevStep + 1);
      }, 1000);

    },
    onError: () => {
      setErrors(prev => ({ ...prev, phone: 'Incomplete or invalid phone number e.g. 3109137313' }));
    },
  });

  // Step 3 Mutation
  const { mutateAsync: handleStep3 } = useMutation({
    mutationFn: (input: Partial<bookingProps>) => createStep3(input),
    onSuccess: () => {
      setIsLoadingStep(true);
      setLoaderMessage(`You're doing amazing ${inputValues.firstName}!`);
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep(prevStep => prevStep + 1);
      }, 1000);
    },
    onError: () => {
      setErrors(prev => ({ ...prev, email: 'Incomplete or invalid email' }));
    },
  });

  // Step 4 Mutation
  const { mutateAsync: handleStep4 } = useMutation({
    mutationFn: (input: Partial<bookingProps>) => createStep4(input),
    onSuccess: () => {
      setIsLoadingStep(true);
      setLoaderMessage(`Perfect! We've almost got you booked!`);
      setTimeout(() => {
        setIsLoadingStep(false);
        setStep(prevStep => prevStep + 1);
      }, 1000);
    },
    onError: () => {
      setErrors(prev => ({ ...prev, general: 'There was an issue with Step 4. Please try again.' }));
    },
  });

  // Step 5 Mutation
  const { mutateAsync: handleStep5, isPending: isLoadingBooking } = useMutation({
    mutationFn: (input: Partial<bookingProps>) => createStep5(input),
    onSuccess: (booking: any) => {
      navigate(`/book/confirmation/${booking.booking}`);
    },
    onError: () => {
      setErrors(prev => ({ ...prev, privacyPolicy: 'Please check the Terms & Conditions in order to proceed' }));
    },
  });

  const onPrev = () => {
    setStep(step - 1);
    setErrors({
      fullName: '',
      phone: '',
      email: '',
      document: '',
      privacyPolicy: '',
    })
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    setInputValues(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (checked ? value : '') : value,

    }));
    if (type === 'checkbox') {
      setChecked(checked);
    }
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));

  };

  const defineCircleContent = (labelStep: number) => {
    if (labelStep < step) {
      return (
        <img
          src={check}
          alt=''
        />
      );
    } else {
      return String(labelStep);
    }
  };

  const handleNextDisable = () => {
    if (!inputValues) return true
    if (step === 1 && !inputValues.firstName && !inputValues.lastName) {
      return true;

    }
    // if (step === 2 && !inputValues.phone) {
    //   return true;

    // }
    if (step === 3 && !inputValues.email) {
      return true;

    }
    if (step === 4 && !inputValues.localTimeRequested) {
      return true;
    }
    if (step === 5 && selectedProperty.idVerification.active === true) {
      if(inputValues.verified !== true){
        return true;
      }
    }
    if (step === 0 && !inputValues.propertyId) {
      return true;
    }
    return false;
  };

  const isPhoneNumberValid = () => {
    const phoneRegex = /^\d{10}$/;
    const phoneWithDashesRegex = /^(\d{3}-\d{3}-\d{4})$/;

    if (!inputValues.phone) {
      handlePhoneNumberConfirmation();
      return;
    }

    if (phoneRegex.test(inputValues.phone)) {
      onNext();
    }

    else if (phoneWithDashesRegex.test(inputValues.phone)) {

      setErrors(prev => ({
        ...prev,
        phone: 'Phone number with dashes is not allowed. Please enter 10 digits without dashes.'
      }));
    }

    else {

      setErrors(prev => ({
        ...prev,
        phone: 'Invalid phone number format. Please enter a valid 10-digit number (no dashes).'
      }));
    }
  };


  const handlePhoneNumberConfirmation = () => {

    if (!inputValues.phone) {
      setAlertModal({
        show: true,
        title: 'Are you sure?',
        content: 'Please note that the information will be sent to you via email only.',
      });
    } else {

      onNext();
    }
  };


  const handleProceedWithEmail = () => {
    setAlertModal({ ...alertModal, show: false });
    onNext();
  };

  //MARK: Whole Booking
  return loadingProperties ? (
    <div className='d-flex flex-column align-items-center justify-content-center' style={{ marginTop: '150px' }}>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='spinner-border m-5' style={{ width: '3rem', height: '3rem', color: '#5271ff' }} role='status'></div>
      </div>
      <header className={style.header}>
        <h1 className={`text-center ${style.heading}`}>
          We are retrieving all the information.
        </h1>
        <Card.Title className={style.cardTitle}>
          Please hold on a moment.
        </Card.Title>
      </header>
    </div>
  ) : propertyError ? (
    <div>Error getting properties</div>
  ) : !Array.isArray(properties) ? (
    <OnlyOnePropertySteps property={properties} />
  ) : properties.length === 1 ? (
    <OnlyOnePropertySteps property={properties[0]} />
  ) : (
    <div className={style.layoutGrid}>
      <div className={style.layout}>

        <div>
          <button
            className={`${step === 0 ? 'd-none' : style.backButton}`}
            onClick={onPrev}
            disabled={step === 0}
            type="button"
          >
            {"< Back"}
          </button>
        </div>

        <div className={style.layoutSteps}>
          {isLoadingStep ? (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: '300px', fontFamily: 'Poppins', fontSize: '20px' }}>
              <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}></div>
              <p className="mt-3" style={{ fontFamily: 'Poppins', fontSize: '16px' }}>{loaderMessage}</p>
            </div>
          ) : (
            <>
              {step === 0 ?
                <>
                  <h3 className={style.headerTitleZero}>Select Your Dream Home</h3>
                  <p className={style.headerSubtitleZero}>Every great journey starts with a single setup</p>
                </>
                : ''}
              {step === 1 ?
                <>
                  {/* <h3 className={style.headerTitle}>Choose A Property</h3> */}
                  <p className={style.headerSubtitle}>First Name and Last Name</p>

                </>
                : ''}
              {step === 2 ?
                <>
                  {/* <h3 className={style.headerTitle}>Choose A Time</h3> */}
                  <p className={style.headerSubtitle}>Phone Number</p>
                </>
                : ''}
              {step === 3 ?
                <>
                  {/* <h3 className={style.headerTitle}>Identity Verification</h3> */}
                  <p className={style.headerSubtitle}>Email</p>
                </>
                : ''}
              {step === 4 ?
                <>
                  {/* <h3 className={style.headerTitle}>Identity Verification</h3> */}
                  <p className={style.headerSubtitle}>Pick a Date & Time</p>
                </>
                : ''}
              {step === 5 ?
                <>
                  {/* <h3 className={style.headerTitle}>Identity Verification</h3> */}
                  <p className={style.headerSubtitle}>Upload Your ID</p>
                </>
                : ''}

              <div className={step === 0 ? "d-none" : `${style.stepLabels}`}>
                <StepNumberLabel
                  className={`${step !== 1 && style.inactive} `}
                  circleContent={defineCircleContent(1)}
                  state={step === 1 ? 'selected' : step > 1 ? 'completed' : 'default'}
                  stepNumber={1}
                />
                <StepNumberLabel
                  className={`${step !== 2 && style.inactive} `}
                  circleContent={defineCircleContent(2)}
                  state={step === 2 ? 'selected' : step > 2 ? 'completed' : 'default'}
                  stepNumber={2}
                />
                <StepNumberLabel
                  className={`${step !== 3 && style.inactive} `}
                  circleContent={defineCircleContent(3)}
                  state={step === 3 ? 'selected' : step > 3 ? 'completed' : 'default'}
                  stepNumber={3}
                />
                <StepNumberLabel
                  className={`${step !== 4 && style.inactive} `}
                  circleContent={defineCircleContent(4)}
                  state={step === 4 ? 'selected' : step > 4 ? 'completed' : 'default'}
                  stepNumber={4}
                />
                <StepNumberLabel
                  className={`${step !== 5 && style.inactive} `}
                  circleContent={defineCircleContent(5)}
                  state={step === 5 ? 'selected' : step > 5 ? 'completed' : 'default'}
                  stepNumber={5}
                />
              </div>
              <form onSubmit={handleSubmit} >
                {step === 0 ? (
                  <PropertySelect
                    selectedId={inputValues?.propertyId}
                    currentStep={step}
                    properties={properties}
                    onChange={handleInputChange}
                    checked={checked}
                  />
                ) :
                  step === 1 ? (
                    <Step1
                      face={inputValues.face}
                      className={style.container}
                      currentStep={step}
                      onChange={handleInputChange}
                      idVerification={selectedProperty.idVerification}
                      verified={inputValues.verified}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  ) : step === 2 ? (
                    <Step2
                      document={inputValues.document}
                      documentBack={inputValues.documentBack}
                      face={inputValues.face}
                      className={style.container}
                      currentStep={step}
                      onChange={handleInputChange}
                      idVerification={selectedProperty.idVerification}
                      verified={inputValues.verified}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  ) : step === 3 ? (
                    <Step3
                      document={inputValues.document}
                      documentBack={inputValues.documentBack}
                      face={inputValues.face}
                      className={style.container}
                      currentStep={step}
                      onChange={handleInputChange}
                      idVerification={selectedProperty.idVerification}
                      verified={inputValues.verified}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  ) : step === 4 ? (
                    <Step4
                      className={style.container}
                      currentStep={step}
                      propertyId={inputValues.propertyId}
                      onChange={handleInputChange}
                      agent={agentId}
                      timezone={selectedProperty.timezone ?? "America/Los_Angeles"}
                    />
                  ) : (
                    <Step5
                      document={inputValues.document}
                      documentBack={inputValues.documentBack}
                      face={inputValues.face}
                      className={style.container}
                      currentStep={step}
                      onChange={handleInputChange}
                      idVerification={selectedProperty.idVerification}
                      verified={inputValues.verified}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  )

                }

                <div className={`${style.buttons} ${style.container}`}>
                  <AppButton
                    disabled={handleNextDisable() || buttonLoading}
                    className={style.button}
                    as='button'
                    type='button'
                    onClick={step !== 2
                      ? onNext
                      : isPhoneNumberValid}
                  >
                    {isLoadingBooking || buttonLoading ? <div className="spinner-border spinner-border-sm" role="status"></div> : <span className='d-flex fs-6 text-center align-items-center'>
                      {step === 0 ? 'Lets Get Started!' : step !== 5 ? 'Continue' : 'Schedule My Tour'}
                    </span>}

                  </AppButton>
                </div>
              </form>
              {/* React Bootstrap Modal */}
              <Modal show={alertModal.show} onHide={() => setAlertModal({ ...alertModal, show: false })} centered>

                <div className={style.procModal}>
                  <RiErrorWarningFill size={50} color="#5271FF" />
                  <h2 className={style.modalTitle}>You're about to schedule a tour without providing your phone number</h2>
                  <p className={style.modalDesc}>{alertModal.content}</p>

                  <div className='d-flex flex-row gap-3'>
                    <AppButton as='button' className={style.modalButton} hierarchy='secondary' onClick={() => setAlertModal({ ...alertModal, show: false })}>
                      Cancel
                    </AppButton>
                    <AppButton as="button" className={style.modalButton} onClick={handleProceedWithEmail}>
                      Proceed with Email
                    </AppButton>
                  </div>
                </div>
              </Modal>
            </>
          )}

        </div>
      </div>
      <div className={style.layoutImages}>
        <div className={step === 0 ? style.boxBackgroundZero : style.boxBackground}>
          {step === 0 ? (
            <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
              <img src={greeting} alt="" />
              <img src={Logo} width={107} height={25} alt="" />
            </div>
          ) :

            step === 1 ?
              (
                <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
                  <img src={meeting} alt="" />
                  <img src={Logo} width={107} height={25} alt="" />
                </div>
              ) :
              step === 2 ?
                (
                  <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
                    <img src={keys} alt="" />
                    <img src={Logo} width={107} height={25} alt="" />
                  </div>
                ) :
                step === 3 ? (
                  <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
                    <img src={checklist} alt="" />
                    <img src={Logo} width={107} height={25} alt="" />
                  </div>
                ) :
                  step === 4 ? (
                    <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
                      <img src={innovation} alt="" />
                      <img src={Logo} width={107} height={25} alt="" />
                    </div>
                  ) :
                    step === 5 ? (
                      <div className='d-flex flex-column justify-content-center align-items-center gap-5'>
                        <img src={movein} alt="" />
                        <img src={Logo} width={107} height={25} alt="" />
                      </div>
                    )
                      :
                      <></>
          }
        </div>
      </div>
    </div>
  );
}

export default StepsContainer;
