import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Sidebar from "../components/AdminSideBar";
import moment from "moment";

const states = [
  "checking stock",
  "awaiting stock",
  "assembling kit",
  "ready to ship",
  "shipped",
  "awaiting confirmation",
  "confirmed",
  "cancelled",
];

const AddKit = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    name: "",
    notes: "",
    service: "Kit",
    tags: "",
    device_id: "",
    asignedDate: "",
    description: "",
  });

  useEffect(() => {
    getOrderList();
    gethardwarekit();
  }, [getOrderList, gethardwarekit]);

  const onDelete = (e) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = skills.find((val) => val.name === buttonName);
    let filteredArray = skills.filter((item) => item !== removedValue);
    setSkills(filteredArray);
  };

  const handleInputChange = (e) => {
    setForm((prev) => {
      const newForm = { ...prev };
      newForm[e.target.name] = e.target.value;
      return newForm;
    });
  };

  async function getOrderList() {
    try {
      const url = process.env.REACT_APP_NODE_API_URL + "/api/order/admin/list";
      const token = await Auth.currentSession();
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setOrders(response.data);
          } else {
            setError("Error Getting Users");
            setShow(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Getting Users");
            setShow(true);
          }
        });
    } catch (eror) {
      console.log(error);
      navigate("/");
    }
  }

  async function gethardwarekit() {
    try {
      const url =
        process.env.REACT_APP_NODE_API_URL + "/api/hardware?format=list";
      const token = await Auth.currentSession();
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setData(response.data);
          } else {
            setError("Error Getting Hardware");
            setShow(true);
          }
        })
        .catch((error) => {
          setError("Error Getting Hardware");
          setShow(true);
        });
    } catch (eror) {
      console.log(error);
      navigate("/");
    }
  }

  const handleOrderChange = (e) => {
    const index = parseInt(e.target.value);
    setForm((prev) => {
      const newForm = { ...prev };
      newForm.order = orders[index]._id;
      newForm.user = orders[index].user._id;
      return newForm;
    });
  };

  const handleSkills = (skills) => {
    setSkills(skills || []);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(form);

    const data = {
      ...form,
      hardware: skills.map((skill) => skill._id),
      asignedDate: new Date(form.date).getTime()
        ? new Date(form.date).getTime()
        : new Date().getTime(),
    };

    const postNewKit = async () => {
      var url = process.env.REACT_APP_NODE_API_URL + "/api/kits";
      const token = await Auth.currentSession();
      axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            setError("");
            setShow(true);
          }
        })
        .catch((error) => {
          setError("Error Creating Kit");
          setShow(true);
        });
    };

    postNewKit();
  };
  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Create Kit</h1>
        </div>
        <Form>
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <Card>
                <Card.Header as="h5">Kit Details</Card.Header>
                <Card.Body>
                  <Form method="post" onSubmit={onSubmit}>
                    <Form.Group className="mb-20" controlId="Full-name">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label> Name</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="name"
                            type="text"
                            value={form.name}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Lockbox-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Description</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="description"
                            type="text"
                            value={form.description}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Lockbox-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Notes</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="notes"
                            type="text"
                            value={form.notes}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-20" controlId="Mobile">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>tags</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="tags"
                            type="text"
                            value={form.tags}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Mobile">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Device ID</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="device_id"
                            type="text"
                            value={form.device_id}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Email">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>State</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Select
                            name="state"
                            onChange={handleInputChange}
                            required
                            className="text-capitalize"
                          >
                            <option selected={true} disabled="disabled">
                              Select state
                            </option>
                            {states.map((state) => (
                              <>
                                <option id={state} value={state}>
                                  {state}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20 d-none" controlId="order">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Allocate Order</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Select
                            name="order"
                            onChange={handleOrderChange}
                          >
                            <option selected={true} disabled="disabled">
                              Select Order
                            </option>
                            {orders.map((order, index) => (
                              <>
                                <option id={order?._id} value={index}>
                                  {order.user?.firstName} {order.user?.lastName}
                                  |{" "}
                                  {order.user?.company
                                    ? order.user?.company.name
                                    : ""}{" "}
                                  |
                                  {" " +
                                    moment(order.createdAt).format("MMM Do YY")}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Building-manager">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Service Type</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Select
                            name="service"
                            onChange={handleInputChange}
                            value={form.service}
                          >
                            <option selected="true" disabled="disabled">
                              Select Service
                            </option>
                            <option value="Kit">Kit</option>
                            <option value="Kit plus installation">
                              Kit Plus Installation
                            </option>
                            <option value="Leasing Agency">
                              Leasing Agency
                            </option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-20" controlId="Mobile">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Associate Hardware</Form.Label>
                        </Col>
                        <Col lg={5}>
                          {skills === "" ? (
                            <Form.Label>No hardware selected</Form.Label>
                          ) : (
                            skills.map((element, index) => (
                              <>
                                <Form.Control
                                  placeholder="Enter text"
                                  value={element.name}
                                />

                                <Button
                                  className="btn btn-primary waves-effect waves-light"
                                  name={element.name}
                                  onClick={onDelete}
                                >
                                  Delete
                                </Button>
                              </>
                            ))
                          )}
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Buzzer-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Hardware Search</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Select
                            options={data}
                            onChange={handleSkills}
                            getOptionValue={(option) => option.name}
                            getOptionLabel={(option) =>
                              option.kit
                                ? option.name + " ( " + option.kit.name + " )"
                                : option.name + " ( Available )"
                            }
                            value={skills}
                            isMulti
                          ></Select>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Lockbox-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Date Assigned</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="date"
                            type="date"
                            value={form.date}
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Save Kit
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Create Kit Successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate("/admin/orders");
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default AddKit;
