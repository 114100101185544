import React, { useState } from "react";

const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <span
      className={`small-text ${expanded ? "" : "text-truncate-2-lines"}`}
      onClick={() => setExpanded(!expanded)}
      style={{ cursor: "pointer" }}
    >
      {text}
    </span>
  );
};

export default ExpandableText;
