import { useState, useEffect } from "react";
import {
    Card,
    Box,
    Stack,
    Text,
    Space,
    Button,
    Center,
    Loader,
} from "@mantine/core";
import { IoPersonOutline } from "react-icons/io5";
import { useViewportSize } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { getNextShowings } from "../../api/dashboard";

interface RawEventData {
    startTime: number;
    contact: {
        firstName: string;
        lastName: string;
    };
    property: {
        address: string;
        shortAddress: string;
        city: string;
        state: string;
        zipCode: string;
    };
}

interface EventData extends RawEventData {
    date: string;
    time: string;
}

export default function CardCalendarList() {
    const [events, setEvents] = useState<EventData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { width } = useViewportSize();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const data: RawEventData[] = await getNextShowings();
            const formattedData = data.map((event) => {
                // Convert Unix timestamp to Date
                const dateObj = new Date(event.startTime * 1000);

                return {
                    ...event,
                    date: dateObj.toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                    }), // "Jan 25"
                    time: dateObj
                        .toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                        })
                        .replace("AM", "am")
                        .replace("PM", "pm"), // "04:00 pm"
                };
            });

            setEvents(formattedData);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    return (
        <Card
            shadow="md"
            px={{ base: 15, md: "xl" }}
            py={{ base: 20, md: "xl" }}
            radius="md"
            withBorder
        >
            {/* Title mobile */}
            <Text className="d-block d-md-none" size="md" fw={600} c="gray.7">
                Next Showings
            </Text>

            {/* Title desktop */}
            <Text className="d-none d-md-block" size="xl" fw={600} c="gray.7">
                Next Showings
            </Text>

            <Space h={{ base: "xs", md: "lg" }} />

            {/* Calendar List */}
            <Stack h={{ base: 350, md: 325 }} gap={0}>
                {isLoading ? (
                    <Center style={{ height: "100%" }}>
                        <Loader color="indigo" />
                    </Center>
                ) : events && events.length > 0 ? (
                    events.map((event: EventData, index: number) => {
                        return (
                            <div
                                key={index}
                                className={`d-flex gap-2 py-3 ${
                                    index > 0 ? "border-top" : ""
                                }`}
                                style={{
                                    fontSize: width > 768 ? "16px" : "12px",
                                }}
                            >
                                <Box
                                    w={{ base: 5, md: 7 }}
                                    h={{ base: 5, md: 7 }}
                                    miw={{ base: 5, md: 7 }}
                                    mih={{ base: 5, md: 7 }}
                                    mt={{ base: 16, md: 8 }}
                                    bg="indigo.6"
                                    style={{
                                        borderRadius: "50%",
                                        aspectRatio: "1 / 1",
                                    }}
                                />
                                <div className="d-flex flex-column flex-grow-1 overflow-hidden">
                                    <Text
                                        inherit
                                        className="d-none d-md-block"
                                        c="gray.7"
                                        fw={500}
                                        truncate
                                    >
                                        {event.property.address}
                                    </Text>

                                    <Text
                                        inherit
                                        className="d-block d-md-none"
                                        c="gray.7"
                                        fw={500}
                                        truncate
                                    >
                                        {event.property.shortAddress}
                                    </Text>

                                    <Text
                                        inherit
                                        className="d-block d-md-none"
                                        c="gray.7"
                                        fw={500}
                                        truncate
                                    >
                                        {event.property.city}
                                        {event.property.state &&
                                            `, ${event.property.state}`}
                                        {event.property.zipCode &&
                                            `, ${event.property.zipCode}`}
                                    </Text>

                                    <div className="d-flex gap-3">
                                        <div className="d-flex gap-1 gap-md-2 align-items-center flex-nowrap">
                                            <Text
                                                inherit
                                                c="gray.8"
                                                fw={500}
                                                style={{
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {event.date}
                                            </Text>
                                            <Box
                                                className="d-block d-md-none"
                                                w={3}
                                                h={3}
                                                miw={3}
                                                mih={3}
                                                mt={3}
                                                bg="black"
                                                style={{
                                                    borderRadius: "50%",
                                                    aspectRatio: "1 / 1",
                                                    marginBottom: "2px",
                                                }}
                                            />
                                            <Text
                                                inherit
                                                c="gray.8"
                                                fw={500}
                                                style={{
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {event.time}
                                            </Text>
                                        </div>
                                        <div className="d-flex flex-grow-1 align-items-center justify-content-start justify-content-md-end">
                                            <IoPersonOutline
                                                className="d-block d-md-none me-1"
                                                size={12}
                                                color="#868e96"
                                            />
                                            <Text
                                                inherit
                                                c="gray.6"
                                                truncate="end"
                                            >
                                                {event.contact.firstName}{" "}
                                                {event.contact.lastName}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <Text c="gray.6" ta="center">
                        No upcoming events
                    </Text>
                )}
            </Stack>
            <Space h="lg" />

            <Link to="/showings" style={{ textDecoration: "none" }}>
                <Button fullWidth color="indigo" size="lg">
                    <Text fw={700}>Calendar</Text>
                </Button>
            </Link>
        </Card>
    );
}
