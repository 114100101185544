import React, { MouseEventHandler } from 'react'

type UnstyledButtonProps = {
  children: React.ReactElement | string,
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

function UnstyledButton({ children, className, ...props }: UnstyledButtonProps) {
  return (
    <button className={"border-0 bg-white " + className} type="button" {...props}>{children}</button>
  )
}

export default UnstyledButton