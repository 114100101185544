import React from "react";
import { Center, Loader } from "@mantine/core";
import { Link } from "react-router-dom";
import getImageUrl from "../../../../helper/checkImageFormat";
import { IoMdPerson } from "react-icons/io";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import SelectBookingStatus from "./SelectBookingStatus";
import { format } from "date-fns";
import dummyImageId from "../../../../assests/images/ID.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const renderTooltip = (props) => (
  <Tooltip id="email-tooltip" {...props} className="text-nowrap w-content">
    {props.data.email}
  </Tooltip>
);

const LeadsTable = ({
  isLoading,
  leads = [],
  bookingsUpdating,
  updateStatus,
}) => {
  const handleImageContextMenu = (e) => {
    e.preventDefault(); 
  };

  return isLoading ? (
    <Center mt={20}>
      <Loader />
    </Center>
  ) : (
    <ul className="d-flex flex-column list-unstyled pt-0 pt-lg-4">
      <li className="border-bottom border-1 pt-2 d-none d-lg-flex row ">
        <h4 className="col-2 table-h">ID</h4>
        <h4 className="col-lg-3 table-h">Personal Information</h4>
        <h4 className="col table-h">Latest Showing</h4>
        <h4 className="col table-h">Showing Status</h4>
        <h4 className="col table-h">Added On</h4>
      </li>
      {leads.map((element, index) => (
        <li
          key={element._id}
          className="border-bottom border-1 py-3 row justify-content-xxl-start row-gap-3 gap-2"
        >
          <div className="col-12 d-lg-none text-uppercase text-secondary">
            ADDED ON {format(element.createdAt, "yyyy/MM/dd")}
          </div>

          <div className="col-4 col-lg-2">
            <Link to={`/admin/leads-details/${element.contact._id}`} onContextMenu={handleImageContextMenu}>
              <img
                src={getImageUrl(
                  element.contact.document ??
                    element.contact.idImage ??
                    dummyImageId
                )}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src = dummyImageId;
                }}
                className="id-image-w id-image-h object-fit-contain"
                alt="identification"
                style={{
                  filter: "blur(1.5px)"
                }}
                onContextMenu={handleImageContextMenu}
              />
            </Link>
          </div>

          <div className="d-flex flex-column justify-content-center col col-lg-3 ">
            <p to="#" className="contact-info text-truncate">
              <IoMdPerson className="icon" />
              {element.contact.firstName} {element.contact.lastName}
            </p>

            <Link
              to={`tel:${element.contact.phone}`}
              className="contact-info text-truncate"
            >
              <MdLocalPhone className="icon" />
              {element.contact.phone}
            </Link>

            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) =>
                renderTooltip({
                  ...props,
                  data: { email: element.contact.email },
                })
              }
            >
              <Link
                to={`mailto:${element.contact.email}`}
                className="contact-info text-truncate w-content"
              >
                <MdEmail className="icon" />
                {element.contact.email}
              </Link>
            </OverlayTrigger>
          </div>

          <div className="d-flex flex-column col justify-content-center">
            <span className="d-lg-none small-text text-body-secondary">
              LATEST SHOWING
            </span>
            <div className="d-block latest-showing">
              <p className="date-time">
                {element.startTime
                  ? format(
                      new Date(element.startTime * 1000),
                      "MMM dd, yyyy hh:mm a"
                    )
                  : "No showing"}
              </p>
            </div>
            <div className="prop-add pt-2">
              {element.property
                ? `${element.property.shortAddress}${
                    element.property.unit
                        ? ` Unit ${element.property.unit}`
                        : ""
                  }, ${element.property.city} ${element.property.state} `
                : "PROPERTY NOT FOUND"}
            </div>
          </div>

          <div className="d-flex flex-column gap-2 align-items-end align-items-lg-start col-5 col-lg justify-content-center pe-none">
            <SelectBookingStatus
              value={element.status}
              isLoading={bookingsUpdating[element._id]}
              updateStatus={updateStatus}
              bookingId={element._id}
            />
          </div>

          <div className="d-none d-lg-flex flex-column text-secondary col-lg justify-content-center">
            <span>{format(new Date(element.createdAt), "MMM dd, yyyy")}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default LeadsTable;
