import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import axios from "axios";
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    CellContext,
    Row,
} from "@tanstack/react-table";
import { formatProperty, formatDate } from "./utils";
import { PropertyShare } from "./types";

export const useEmailHistoryTable = () => {
    // Fetch email history
    const {
        data = [],
        error,
        isPending,
        isFetching,
    } = useQuery({
        queryKey: ["account", "email-history"],
        queryFn: async (): Promise<PropertyShare[]> => {
            // Get authorization header
            const auth = await Auth.currentSession();
            const token = auth.getIdToken().getJwtToken();
            const headers = { Authorization: `Bearer ${token}` };

            // Get all history of share property link
            const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/share-link`;
            const response = await axios.get(url, { headers });
            return response.data || [];
        },
    });

    // Column definitions
    const columns = useMemo(
        () => [
            {
                id: "location",
                header: "Location",
                accessorFn: (row: PropertyShare) =>
                    formatProperty(row.property),
                filterFn: (
                    row: Row<PropertyShare>,
                    columnId: string,
                    filterValue: string[]
                ) => {
                    const rowValue = row.getValue<string>(columnId);
                    if (!filterValue || filterValue.length === 0) return true;
                    return filterValue.includes(rowValue);
                },
                sortingFns: "alphanumeric",
            },
            {
                id: "user",
                header: "User",
                accessorKey: "user.email",
            },
            {
                id: "name",
                header: "Name",
                accessorKey: "name",
            },
            {
                id: "email",
                header: "Email",
                accessorKey: "email",
            },
            {
                id: "phone",
                header: "Phone Number",
                accessorKey: "phone",
            },
            {
                id: "createdAt",
                header: "Date",
                accessorFn: (row: PropertyShare) => new Date(row.createdAt),
                cell: (info: CellContext<PropertyShare, Date>) =>
                    formatDate(info.getValue()),
                filterFn: (
                    row: Row<PropertyShare>,
                    columnId: string,
                    filterValue: { start: Date | null; end: Date | null }
                ) => {
                    const rowDate = row.getValue<Date>(columnId);
                    if (filterValue.start && rowDate < filterValue.start)
                        return false;
                    if (filterValue.end && rowDate > filterValue.end)
                        return false;
                    return true;
                },
                sortingFns: "datetime",
            },
        ],
        []
    );

    // Create the table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: { pageIndex: 0, pageSize: 10 },
            sorting: [
                { id: "location", desc: false },
                { id: "user", desc: false },
                { id: "createdAt", desc: false },
            ],
        },
    });

    const propertyOptions = useMemo(() => {
        const uniqueLocations = new Set<string>();
        data.forEach((row: PropertyShare) => {
            // Use the formatted property string (same as accessorFn)
            const formatted = formatProperty(row.property);
            uniqueLocations.add(formatted);
        });
        return Array.from(uniqueLocations).map((loc) => ({
            value: loc,
            label: loc,
        }));
    }, [data]);

    return {
        table,
        propertyOptions,
        error,
        isPending,
        isFetching,
    };
};
