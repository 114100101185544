import { format } from "date-fns";
import React from "react";

const codeTypes = {
  timeLimit: "temporary",
  urgent: "emergency",
};

const MobileCodeRow = ({
  code,
  handleSelect,
  selected,
  disabled,
  dailyCode,
}) => {
  return (
    <div
      className={`d-flex flex-column gap-3 p-3 border-bottom ${
        dailyCode ? "bg-primary bg-opacity-10" : ""
      }`}
      key={code.id}
    >
      <div className="d-flex justify-content-between">
        <span className="w-content d-flex align-items-center px-2 mb-1">
          <input
            type="checkbox"
            value={code.id}
            onChange={(e) => handleSelect(code, e.target.checked)}
            checked={selected.some(
              (selectedCode) => selectedCode.id === code.id
            )}
            disabled={disabled || dailyCode}
            className={dailyCode ? "opacity-0" : ""}
          />
        </span>
        <span
          className={`col text-truncate d-flex align-items-center ${
            dailyCode ? "fw-bold text-delet-blue" : ""
          }`}
        >
          {!dailyCode ? code.name : "Today's code"}
        </span>
        <span className="col text-truncate d-flex align-items-center justify-content-end text-secondary">
          CODE: {code.password}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <div className="col align-items-center d-flex align-items-center">
          <span
            className={`'text-truncate text-capitalize rounded-5 w-content bg-opacity-10 px-4 py-2 code-status-${code.status} fw-medium`}
          >
            {code.status === "normal" ? "Active" : code.status}
          </span>
        </div>
        <span className="col text-truncate text-capitalize d-flex align-items-center justify-content-end">
          {codeTypes[code.type] ?? code.type}
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span
          className={`col text-truncate d-flex align-items-center ${
            code.type === "permanent" ? "" : ""
          }`}
        >
          {["permanent", "urgent"].includes(code.type) ? (
            format(new Date(code.createTime), "MMM d, yyyy h:mm a")
          ) : ["timeLimit", "disposable"].includes(code.type) ? (
            code.startTime ? (
              <div className="d-flex flex-column text-secondary">
                {format(new Date(code.startTime), "MMM d, yyyy h:mm a")}
              </div>
            ) : (
              "Couldn't get start time"
            )
          ) : (
            ""
          )}
        </span>
        <span className="d-flex justify-content-center text-secondary">~</span>
        <span
          className={`col text-truncate d-flex align-items-center justify-content-end ${
            code.type === "permanent" ? "" : ""
          }`}
        >
          {["permanent", "urgent"].includes(code.type) ? (
            "∞"
          ) : ["timeLimit", "disposable"].includes(code.type) ? (
            code.endTime ? (
              <div className="d-flex flex-column text-secondary">
                {format(new Date(code.endTime), "MMM d, yyyy h:mm a")}
              </div>
            ) : (
              "Couldn't get end time"
            )
          ) : (
            ""
          )}
        </span>
      </div>
    </div>
  );
};

export default MobileCodeRow;
