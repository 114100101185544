import { useState } from "react";
import { DrawerModalContainer } from "../../../../../designSystem/DrawerModalContainer";
import propertyDummyImage from "../../../../../assests/images/1-placeholder.png";
import { PropertyDetail } from "../../PropertyDetail";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import getImageUrl from "../../../../../helper/checkImageFormat";

function PropertySelect({
  currentStep,
  properties,
  onChange,
  selectedId,
  checked,
}) {
  const [detailProperty, setDetailProperty] = useState(null);
  const navigate = useNavigate();

  const onOpen = (propertyId) => {
    navigate(`/tour-property/${propertyId}`);
  };

  const onClose = (e) => {
    e?.stopPropagation();
    setDetailProperty(null);
  };

  return (
    <div className={`${currentStep !== 0 ? "d-none" : ""} ${style.container}`}>
      <span className={style.companyName}>{properties.company?.name}</span>

      {/* Property Table with Fixed Height and Scrollable */}
      <div className={style.tableContainer}>
        <table className={style.propertyTable}>
          <tbody className={style.scrollableBody}>
            {properties?.map((property) => (
              <tr key={property._id}>
                <td className={style.checkColumn}>
                  <input
                    type="checkbox"
                    name="propertyId"
                    onChange={onChange}
                    property={property}
                    id={property._id}
                    value={property._id}
                    checked={selectedId === property._id ? checked : false}
                    selectedId={selectedId}
                    className={style.checkInput}
                  />
                </td>
                <td className={style.addressColumn}>
                  <div className={style.mainAddress}>
                    {property.shortAddress}
                  </div>
                  <div className={style.cityAddress}>
                    {property.city}, {property.state} {property.zipCode}
                  </div>
                  <button
                    className={style.detailsButton}
                    onClick={() => onOpen(property._id)}
                  >
                    {"See Details >"}
                  </button>
                </td>
                <td className={style.imageColumn}>
                  <img
                    src={
                      getImageUrl(property.images?.[0]) || propertyDummyImage
                    }
                    alt="property"
                    className={style.propertyImage}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DrawerModalContainer isOpen={!!detailProperty} onClose={onClose}>
        {!!detailProperty ? (
          <PropertyDetail property={detailProperty} onClose={onClose} />
        ) : null}
      </DrawerModalContainer>
    </div>
  );
}

export default PropertySelect;
