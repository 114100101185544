import { Button, Stack} from "@mantine/core";
import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Sidebar from "../../components/SideBar";
import { useNavigate } from "react-router-dom";

const OrderSuccess = () => {
  const navigate = useNavigate();
  const handleSeeOrders = () => {
    navigate("/kits/orders");
  };
  return (
    <Sidebar>
      <div style={{marginTop:"250px"}}>
        <Stack align="center" m={100}>
          
          <IoIosCheckmarkCircle
            style={{
              width: 60,
              height: 60,
              color: "#5271FF",
            }}
          />
          <p style={{color: '#4C4D52', fontFamily: 'Poppins', fontSize: "20px", fontWeight:"800"}} >
            Your Order Was Placed Successfully
          </p>
          <p style={{color: '#8C8C8C', fontFamily: 'Poppins', fontSize: "16px", fontWeight:"300", width: "40%", textAlign: 'center'}}>
            Once your order is confirmed, you will be able to select the shipping
            address for each kit.
          </p>
          <Button color={"#5271FF"} mt={25} onClick={handleSeeOrders} w={'15%'}>
            <strong>See orders</strong>
          </Button>
        </Stack>
      </div>
    </Sidebar>
  );
};

export default OrderSuccess;
