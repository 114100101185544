import { useState } from "react";
import { Button } from "react-bootstrap";
import { TfiReload } from "react-icons/tfi";
import GenericModal from "../../../../../../../components/Modals";
import CodeCreation from "./CodeCreation";
import { Filters } from "../utils/types";

interface CodeToolbarProps {
    hardware: {
        id: string;
        vendor: string;
        type: string;
    };
    filters: Filters;
    handleFilters: (name: keyof Filters, value: string) => void;
    refetch: () => void;
    isLoading: boolean;
}

export default function CodeToolbar({
    hardware,
    filters,
    handleFilters,
    refetch,
    isLoading,
}: CodeToolbarProps) {
    const [modal, setModal] = useState({
        show: false,
        body: <p>Create new code ...</p>,
        title: "",
        hideCloseButton: true,
    });

    const handleCloseModal = () => {
        setModal({ ...modal, show: false });
    };

    const handleCreateCode = () => {
        setModal({
            show: true,
            body: (
                <CodeCreation
                    hardware={hardware}
                    handleCloseModal={handleCloseModal}
                />
            ),
            title: "ACCESS CODE CONFIGURATION",
            hideCloseButton: true,
        });
    };

    return (
        <div className="container-fluid p-0">
            <div className="d-flex justify-content-between align-items-center gap-2 gap-md-3 pt-2 pb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name, code, start or end date"
                    value={filters.text}
                    onChange={(e) => handleFilters("text", e.target.value)}
                />
                <Button
                    className="btn-outline-dark bg-delet border-0 py-2 px-2 px-md-3"
                    onClick={refetch}
                    disabled={isLoading}
                >
                    <TfiReload color="white" size={17} strokeWidth={0.5} />
                </Button>
                <Button
                    className="btn-outline-dark py-2 px-2 px-md-4 px-lg-5 fw-semibold text-light-black text-nowrap"
                    variant="light"
                    onClick={handleCreateCode}
                    disabled={isLoading}
                >
                    Create New Code
                </Button>
            </div>

            <GenericModal {...modal} handleClose={handleCloseModal} />
        </div>
    );
}
