import React, { createContext, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useAuth } from './AuthProvider';
import { useSocket } from './SocketContext';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { isAuthenticated, tokens } = useAuth();
  const socket = useSocket();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = user.attributes;
      const groups = user.signInUserSession.idToken.payload['cognito:groups'];
      const userInfo = {
        name: attributes.name,
        familyName: attributes.family_name,
        email: attributes.email,
        roles: groups,
        _id: attributes["custom:_id"]
      };

      const response = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/users/profile`, {
        headers: {
          Authorization: `Bearer ${tokens.idToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }

      const dbUserData = await response.json();

      if (socket && dbUserData._id) {
        socket.emit('join', dbUserData._id);
      }
      const profile = {
        ...userInfo,
        ...dbUserData,
      }

      profile.isSuperadmin = profile.roles.includes("superadmin");
      profile.isManager = profile.isSuperadmin || profile.roles.includes("manager");
      profile.isOwner = profile.roles.includes("owner")
      profile.isAdmin = profile.roles.includes("admin") || profile.isOwner

      return profile;
    },
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (socket && data?.company?._id) {
      socket.emit('join', data.company._id);

      return () => {
        socket.emit('leave', data?.company?._id);
      };
    }
  }, [data?.company?._id, socket]);

  return (
    <ProfileContext.Provider value={{ data, isLoading, error, refetch }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};