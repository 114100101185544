import { Auth } from "aws-amplify";
import axios from "axios";

interface Hardware {
    id: string;
    vendor: string;
    type: string;
}

interface AccessCode {
    codeType: string;
    codeName: string;
    startTimestamp: number;
    endTimestamp: number | null;
}

export const createAccessCode = async (
    hardware: Hardware,
    accessCode: AccessCode
) => {
    // Build url
    const url = `${process.env.REACT_APP_NODE_API_URL}/api/${hardware.vendor}/${hardware.type}/${hardware.id}/codes`;

    // Get authentication token
    const auth = await Auth.currentSession();
    const headers = {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
    };

    // Request create
    const response = await axios.post(url, accessCode, { headers });
    return response.data;
};
