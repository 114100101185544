import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Sidebar from "../components/AdminSideBar";

const types = ["flat", "percentage"];

const OrderFormRow = ({
  form,
  index,
  users,
  handleUserSelect,
  selectedUsers,
  handleInputChange,
}) => {

  const generateCode = async () => {
    const token = await Auth.currentSession();
    const res = await axios.get(`${process.env.REACT_APP_NODE_API_URL}/api/discounts/generate-code`, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    })
    handleInputChange('code', res.data, index)
  }

  return (
    <Row>
    <Col xxl={12} xl={12} lg={12}>
      <Card>
        <Card.Header as="h5">Discount</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2} className="d-flex gap-3 align-items-center pb-2">
                  <Form.Label>Code</Form.Label>
                  <Button onClick={generateCode}>Generate</Button>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="code"
                    type="text"
                    value={form.code}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="type">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Type</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Select
                    name="type"
                    onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
                    className="text-capitalize"
                    required
                  >
                    <option selected={true} disabled="disabled">
                      Select type
                    </option>
                    {types.map((type) => (
                      <>
                        <option id={type} value={type}>
                          {type}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Amount</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="amount"
                    type="number"
                    value={form.amount}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Usage Limit</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="usageLimit"
                    type="number"
                    value={form.usageLimit}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Expiration date</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="expirationDate"
                    type="date"
                    value={form.expirationDate}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Buzzer-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>
                    {"(Optional) Users who can use this code"}
                  </Form.Label>
                </Col>

                <Col lg={10}>
                  <Select
                    options={users}
                    onChange={(users) => handleUserSelect(users, index)}
                    getOptionValue={(kit) => kit._id}
                    getOptionLabel={(kit) =>
                      `${kit.firstName} ${kit.lastName} ${kit.email}`
                    }
                    value={selectedUsers}
                    isMulti
                  ></Select>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  </Row>
  )
};

const CreateDiscount = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [formRows, setFormRows] = useState([
    {
      usageLimit: 1,
    },
  ]);

  const handleInputChange = (field, value, index) => {
    setFormRows((prev) => {
      const newForm = [...prev];
      newForm[index][field] = value;
      return newForm;
    });
  };

  const handleUserSelect = (users, index) => {
    setFormRows((prev) => {
      const newForm = [...prev];
      newForm[index].validFor = users.map(user => user._id);
      return newForm;
    });
  };

  const addRow = () =>
    setFormRows((prev) => {
      const newRows = [...prev];
      newRows.push({
        service: "",
        notes: "",
        usageLimit: 1,
      });
      return newRows;
    });

  useEffect(() => {
    getUsersList();
  }, []);

  async function getUsersList() {
    try {
      var url = process.env.REACT_APP_NODE_API_URL + `/api/users/admin/list`;
      const token = await Auth.currentSession();
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => setUsers(response.data))
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Getting Kit");
          }
        });
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const postNewDiscount = async () => {
      try {
        var url = process.env.REACT_APP_NODE_API_URL + "/api/discounts";
        const token = await Auth.currentSession();
        const response = await axios.post(url, formRows, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setError("");
          setShow(true);
          return response.data;
        }
      } catch (error) {
        console.log("ERROR: ", error)
        setError("Error creating discounts");
        setShow(true);
        return null;
      }
    };

    postNewDiscount();
  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Create code</h1>
        </div>
        <Form method="post" onSubmit={handleSubmit}>
          {formRows.map((form, index) => (
            <OrderFormRow
              key={"row-" + index}
              form={form}
              index={index}
              users={users}
              handleUserSelect={handleUserSelect}
              selectedUsers={selectedUsers[index]}
              handleInputChange={handleInputChange}
            />
          ))}
          <div className=" d-flex gap-5 justify-content-center pb-5">
            <Button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={handleSubmit}
            >
              Create discount
            </Button>

            <Button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={addRow}
            >
              Add row
            </Button>
          </div>
        </Form>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Create Kit Successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default CreateDiscount;
