import { createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useAuth } from "./AuthProvider";

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const socketRef = useRef(null);
  const { isAuthenticated, tokens } = useAuth();

  useEffect(() => {
    // Only proceed if authenticated
    if (!isAuthenticated || !tokens) {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
        setSocket(null);
      }
      return;
    }

    // Don't recreate if socket already exists and is connected
    if (socketRef.current && socketRef.current.connected) {
      return;
    }

    // Create new socket connection
    const newSocket = io(process.env.REACT_APP_NODE_API_URL, {
      auth: { token: tokens.idToken },
      transports: ['websocket', 'polling']
    });

    newSocket.on("connect", () => {
      setSocket(newSocket);
    });

    socketRef.current = newSocket;

    // Cleanup function
    return () => {
      if (isAuthenticated === false) {
        // Only disconnect on logout
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
          setSocket(null);
        }
      }
    };
  }, [isAuthenticated, tokens]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socket = useContext(SocketContext);
  if (!socket) {
    console.warn(
      "useSocket must be used within a SocketProvider and after authentication"
    );
  }
  return socket;
};
