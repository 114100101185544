import style from "./style.module.scss";
import { RiErrorWarningFill } from "react-icons/ri";
import PropTypes from "prop-types";

function Step3({
  currentStep,
  onChange,
  className,
  errors,
}) {
  return (
    <div
      className={`${currentStep !== 3 ? "d-none" : ""} ${className} ${
        style.container
      }`}
    >

        <h3 className={style.headerTitle}>What's Your Email?</h3>
        <p className={style.headerSubtitle}>We promise. No spam. Just apartment magic</p>
        <div className={style.inputContainer}>

        <label className={style.label}>Email</label>
          <input
            className={!errors.email ? style.input : style.inputError}
            name="email"
            onChange={onChange}
            placeholder="Email"
            type="email"
          />
          {errors.email ? (
            <div className={style.error}>
              <RiErrorWarningFill
                size={12}
                color="red"
                style={{ marginRight: "5px" }}
              />
              {errors.email}
            </div>
          ) : (
            ""
          )}
        </div>
    </div>
  );
}

Step3.propTypes = {
  document: PropTypes.object,
  documentBack: PropTypes.object,
  face: PropTypes.object,
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  idVerification: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
  errors: PropTypes.shape({
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    privacy: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default Step3;
