import { useState } from "react";
import { useProperty } from "../../../../context/PropertyContext";
import GenericModal from "../../../../components/Modals";
import { Toaster } from "react-hot-toast";
import DeletKit from "./components/DeletKit";
import EntrySystem from "./components/EntrySystem";
import SystemTabs from "./components/SystemTabs";

const System = ({ reloadHardware }) => {
    const { property, setProperty } = useProperty();
    const [tabValue, setTabValue] = useState(0);
    const [modal, setModal] = useState({
        show: false,
    });

    const handleCloseModal = () => {
        setModal({ ...modal, show: false });
    };

    return (
        <section className="py-3 px-3 flex-grow-1">
            <Toaster />

            <SystemTabs tabValue={tabValue} setTabValue={setTabValue} />

            {tabValue === 0 && (
                <DeletKit
                    property={property}
                    setProperty={setProperty}
                    reloadHardware={reloadHardware}
                    setModal={setModal}
                    handleCloseModal={handleCloseModal}
                />
            )}

            {tabValue === 1 && (
                <EntrySystem
                    property={property}
                    setProperty={setProperty}
                    reloadHardware={reloadHardware}
                />
            )}

            <GenericModal {...modal} handleClose={handleCloseModal} />
        </section>
    );
};

export default System;
