import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate()

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    phone: '',
    role: 'agent',
  })
  const [error, setError] = useState('')
  const [modelshow, setModelShow] = useState(false)
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  var pattern = new RegExp(
    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
  )
  useEffect(() => {
    if (localStorage.getItem('platformDashToken')) {
      navigate('/properties')
    }
  }, [navigate])
  const onFormInputChange = (event) => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    if (form.email === '' || form.password === '') {
      setError('Please fill out all fields')
      setModelShow(true)
    } else if (!emailRegex.test(form.email)) {
      setError('Please enter a valid email address')
      setModelShow(true)
    } else if (!pattern.test(form.phone)) {
      setError('Please enter a valid phone number')
      setModelShow(true)
    } else if (form.phone.length !== 10) {
      setError('Please enter a valid phone number')
      setModelShow(true)
    } else {
      try {
        var url = process.env.REACT_APP_NODE_API_URL + '/api/users/register'
        await axios
          .post(url, {
            firstName: form.firstname,
            lastName: form.lastname,
            email: form.email,
            password: form.password,
            phone: form.phone,
            role: 'agent',
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.token) {
                setModelShow(true)
                setError('')
              }
            } else {
              setError('Something Went Wrong')
              setModelShow(true)
            }
          })
          .catch((error) => {
            if (error.response) {
                setError('Something Went Wrong')
                setModelShow(true)
            } else {
                setError('Something Went Wrong')
                setModelShow(true)
            }
          })
      } catch (error) {
        if (error.statusCode === 401) {
            setError('Something Went Wrong')
            setModelShow(true)
        } else {
            setError('Something Went Wrong')
            setModelShow(true)
        }
      }
    }
  }
  return (
    <>
      <div className="page-main-head align-items-center">
        <Row className="align-items-center">
          <Col md={12}>
            <Form method="post" onSubmit={onSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="text"
                  value={form.email}
                  onChange={onFormInputChange}
                  name="email"
                  placeholder="Enter email"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={form.firstname}
                  onChange={onFormInputChange}
                  name="firstname"
                  placeholder="Enter First Name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={form.lastname}
                  onChange={onFormInputChange}
                  name="lastname"
                  placeholder="Enter Last Name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  maxLength={10}
                  minLength={10}
                  name="phone"
                  value={form.phone}
                  onChange={onFormInputChange}
                  placeholder="Enter Phone Number"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={form.password}
                  onChange={onFormInputChange}
                  placeholder="Password"
                  name="password"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              <span>Already have an account ?</span>
              <Link to="/">Login</Link>
            </Form>
          </Col>
        </Row>
      </div>
      {modelshow ? (
        <Modal show={modelshow}>
          {error !== '' ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body> Registration Successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate('/')
                    setModelShow(false)
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default Register
