import { Auth } from 'aws-amplify';

export const onlineStatus = (subscriptions, property) =>
  property.kit &&
  property.kit.device_id &&
  (subscriptions[property.kit.device_id] ? 'online' : 'offline');

export const propertyBookingLink = async (property) => {
  try {
    const userInfo = await Auth.currentAuthenticatedUser();
    const customId = userInfo.attributes['custom:_id'];
    const link = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
    return link;
  } catch (error) {
    console.error('Error getting authenticated user:', error);
    throw error;
  }
};
