import { useDashboardContext } from "./DashboardContext";
import { Link } from "react-router-dom";
import { useHover } from "@mantine/hooks";
import {
    Card,
    Center,
    Flex,
    Space,
    Text,
    Loader,
} from "@mantine/core";
import CardHeader from "./CardHeader";
import CardInsight from "./CardInsight";

function calculatePercentageChange(current: number, past: number) {
    if (current === past) return { value: 0, direction: "Up" };
    if (past === 0) return { value: 100, direction: "Up" };
    return {
        value: Math.round(Math.abs(((current - past) / past) * 100) * 10) / 10,
        direction: current > past ? "Up" : "Down",
    };
}

interface CardMetricProps {
    to: string;
    Icon: React.ElementType;
    iconColor: string;
    iconBgColor: string;
    metricType: keyof ReturnType<typeof useDashboardContext>["metrics"];
    title: string;
}

export default function CardMetric({
    to,
    Icon,
    iconColor,
    iconBgColor,
    metricType,
    title,
}: CardMetricProps) {
    // Mantine hover
    const { hovered, ref } = useHover();

    // Get dashboard metrics data
    const { metrics, rangeValue, isLoading } = useDashboardContext();

    // Get rate percentage
    const metricData = metrics[metricType] ?? {
        current: 0,
        past: 0,
    };
    const { current, past } = metricData;
    const rate = calculatePercentageChange(current ?? 0, past ?? 0);

    return (
        <Link to={to} style={{ textDecoration: "none" }}>
            <Card
                ref={ref}
                shadow="md"
                px={{ base: 15, md: "xl" }}
                py={{ base: 20, md: "xl" }}
                radius="md"
                withBorder
                style={{
                    height: "100%",
                    backgroundColor: hovered ? "#f7f7f7" : "white",
                    transition: "background-color 0.2s ease-in-out",
                }}
            >
                {/* Render the Title */}
                <CardHeader
                    Icon={Icon}
                    iconColor={iconColor}
                    iconBgColor={iconBgColor}
                    title={title}
                />

                <Space h={{ base: "md", md: "xl" }} />

                {/* Render the Metric Value */}
                {isLoading ? (
                    <Center style={{ height: "100%" }}>
                        <Loader size="sm" color="indigo" />
                    </Center>
                ) : rate ? (
                    <Flex
                        direction="row"
                        justify="flex-start"
                        align="center"
                        gap={{ base: "xs", md: "md" }}
                    >
                        {/* Metric number mobile */}
                        <Text
                            className="d-block d-md-none"
                            size="28px"
                            fw={600}
                            c="gray.8"
                        >
                            {current}
                        </Text>

                        {/* Metric number desktop */}
                        <Text
                            className="d-none d-md-block"
                            size="40px"
                            fw={600}
                            c="gray.8"
                        >
                            {current}
                        </Text>

                        <CardInsight rate={rate} rangeValue={rangeValue} />
                    </Flex>
                ) : (
                    // No Data Available Message
                    <Center style={{ height: "100px" }}>
                        <Text ta="center" c="gray.6">
                            No data available
                        </Text>
                    </Center>
                )}
            </Card>
        </Link>
    );
}
