import { PieData } from "./CardPieChart";

// Validate date input before fetching
export const areValidDates = (
    startDate: Date | null,
    endDate: Date | null
): boolean => {
    const isValidDate = (date: Date | null) =>
        date instanceof Date && !isNaN(date.getTime());

    const bothDatesValid = isValidDate(startDate) && isValidDate(endDate);
    // Enable no dates: Fetch all data
    // const noDatesProvided = !startDate && !endDate;
    //return bothDatesValid || noDatesProvided;
    return bothDatesValid;
};

// Calculates Percentage Change
export const calculatePercentageChange = (current: number, past: number) => {
    if (current === past) return { value: 0, direction: "Up" };
    if (past === 0) return { value: 100, direction: "Up" };
    return {
        value: Math.round(Math.abs(((current - past) / past) * 100) * 10) / 10,
        direction: current > past ? "Up" : "Down",
    };
};

// Format Pie Chart Data
export const formatPieChartData = (
    data: Record<string, number> | null
): PieData[] | null => {
    if (!data || Object.keys(data).length === 0) {
        return null;
    }

    const statuses: Record<string, { label: string; color: string }> = {
        active: { label: "Active", color: "#5271FF" },
        inactive: { label: "Inactive", color: "#D9D9D9" },
        rented: { label: "Rented", color: "#565E7F" },
        sold: { label: "Sold", color: "violet.6" },
        "application received": { label: "Application", color: "#8D9DE3" },
        "lease agreement sent": {
            label: "Lease Agreement",
            color: "#B7C0E6",
        },
    };

    const pieChartData: PieData[] = Object.entries(data)
        .filter(([key, value]) => statuses[key] && Number(value) > 0)
        .map(([key, value]) => ({
            name: statuses[key]?.label ?? key,
            value: Number(value),
            color: statuses[key]?.color ?? "#FFFFFF",
        }));

    return pieChartData.length > 0 ? pieChartData : null;
};
