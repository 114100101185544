import { format } from "date-fns";
import { AccessCode } from "../utils/types";

const codeTypes: Record<string, string> = {
    urgent: "emergency",
    timeLimit: "temporary",
    hourly: "temporary",
    daily: "temporary",
    onetime: "one-time",
};

interface TableListProps {
    codeList: AccessCode[];
}

export default function TableList({ codeList }: TableListProps) {
    return (
        <div className="container-fluid p-0">
            {codeList.map((code: AccessCode) => {
                // Codes without end
                const noEndTypes = ["permanent"];
                const isForever = noEndTypes.includes(code.type);

                // Dates formatting
                const startDate = code.start
                    ? format(new Date(code.start), "MM/dd/yyyy")
                    : "";
                const startTime = code.start
                    ? format(new Date(code.start), "hh:mm a")
                    : "";
                const endDate = code.end
                    ? format(new Date(code.end), "MM/dd/yyyy")
                    : "";
                const endTime = code.end
                    ? format(new Date(code.end), "hh:mm a")
                    : "";

                return (
                    <div className="row row-cols-6 align-items-center gy-0 gx-3 py-3 border-bottom fw-normal">
                        {/* Name */}
                        <div className="col">
                            <p>{code.name}</p>
                        </div>
                        {/* Code */}
                        <div className="col">
                            <p>{code.code}</p>
                        </div>
                        {/* Status */}
                        <div className="col">
                            <span
                                className={`${
                                    code.status === "expired" ||
                                    code.type === "onetime"
                                        ? "code-status-expired"
                                        : "code-status-normal"
                                } text-truncate text-capitalize rounded-5 w-content bg-opacity-10 px-4 py-2 fw-medium`}
                            >
                                {code.type === "onetime"
                                    ? "One-Time"
                                    : code.status === "expired"
                                    ? "Expired"
                                    : "Active"}
                            </span>
                        </div>
                        {/* Type */}
                        <div className="col">
                            <p className="text-capitalize">
                                {codeTypes[code.type] ?? code.type}
                            </p>
                        </div>
                        {/* Start */}
                        <div className="col">
                            {code.start ? (
                                <div>
                                    <p>{startDate}</p>
                                    <p className="fs-8 text-secondary">
                                        {startTime}
                                    </p>
                                </div>
                            ) : (
                                <p>Couldn't get start</p>
                            )}
                        </div>
                        {/* End */}
                        <div className="col">
                            {isForever ? (
                                <p>&infin;</p>
                            ) : code.end ? (
                                <div>
                                    <p>{endDate}</p>
                                    <p className="fs-8 text-secondary">
                                        {endTime}
                                    </p>
                                </div>
                            ) : (
                                <p>Couldn't get end</p>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
