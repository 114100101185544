import { useEmailHistoryTable } from "./useEmailHistoryTable";
import EmailHistoryFilters from "./EmailHistoryFilters";
import EmailHistoryTable from "./EmailHistoryTable";
import EmailHistoryPagination from "./EmailHistoryPagination";

export default function EmailHistory() {
    const { table, propertyOptions, error, isPending, isFetching } =
        useEmailHistoryTable();

    // Display a loading state
    if (isPending || isFetching) {
        return (
            <div className="p-5 d-flex justify-content-center align-items-center">
                <div className="spinner-border text-delet-blue" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    // Display an error message if fetch fails
    if (error) {
        return (
            <div className="p-5 text-center">
                <p>Error loading email history.</p>
            </div>
        );
    }

    return (
        <section className="px-2 py-3 p-md-5">
            {/* Filters and export CSV */}
            <EmailHistoryFilters
                table={table}
                propertyOptions={propertyOptions}
            />

            {/* Table rendering */}
            <EmailHistoryTable table={table} />

            {/* Pagination */}
            <EmailHistoryPagination table={table} />
        </section>
    );
}
