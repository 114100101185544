import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { fetchKits } from "../../../../api/kits";
import { updatePropertyKit } from "../../../../api/properties";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { design } from "../../../../constants";
import { FaCircleCheck } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";

const SelectKit = ({
  labelText,
  handleCancel,
  updateProperty,
  propertyId,
  reloadHardware,
}) => {
  const [selectedKit, setSelectedKit] = useState(null);
  const MySwal = withReactContent(Swal);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user");
  const {
    data: kits = [],
    isLoading,
  } = useQuery({
    queryKey: ["user-kits-list", userId],
    queryFn: () => fetchKits(userId),
  });

  const { mutateAsync: handleChangeKit, isPending } = useMutation({
    mutationFn: async (kitId) => await updatePropertyKit(propertyId, kitId),
    onSuccess: (data) => {
      updateProperty({ kit: data.newKit });
      reloadHardware(data.newKit.hardware);
      handleCancel();
      MySwal.fire({
        title: "Your kit has been successfully changed",
        iconHtml: <FaCircleCheck color={design.deleteMainColor} />,
        html: `<p class='text-body-tertiary fs-6'>You assigned a new kit to ${data.newKit.property.address}`,
        confirmButtonText: "Close",
        confirmButtonColor: design.deleteMainColor,
        reverseButtons: true,
        customClass: {
          popup: "p-3 rounded-3 w-content",
          title: "pt-0 fs-5",
          htmlContainer: "pt-2 text-center",
          actions: "gap-3 w-100 flex-nowrap flex-row-reverse",
          confirmButton:
            "bg-white text-black border border-secondary-subtle rounded-3 w-content px-5",
          icon: "border-0 my-0",
        },
      }).then(async (res) => {
        if (res.isConfirmed) {
        }
      });
    },
    onError: (error) => console.error(error),
  });

  const sortedKits = useMemo(() => {
    return (
      [...kits]
        .sort((a, b) => {
          if (!a.property && b.property) return -1;
          if (a.property && !b.property) return 1;
          if (!a.property && !b.property) return 0;
          return a.property.shortAddress.localeCompare(b.property.shortAddress);
        })
    );
  }, [kits]);

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      padding: "5px 15px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "transparent"
        : provided.backgroundColor,
      border: "1.5px solid",
      borderColor: state.isFocused ? "#5271FF" : "transparent",
      borderRadius: "2px",
      margin: "10px 0",
      padding: "0px",
    }),
  };

  return (
    <div className="d-flex flex-column gap-3">
      <h4 className="text-dark text-center fw-bold fs-5 mb-0">Select Kit</h4>
      {labelText ? (
        <span className="text-body-tertiary fs-7 text-center mb-2">
          {labelText}
        </span>
      ) : null}
      <Select
        options={sortedKits}
        styles={customStyles}
        value={selectedKit}
        isDisabled={isLoading || isPending}
        onChange={(kit) => setSelectedKit(kit)}
        getOptionValue={(kit) => kit.name}
        getOptionLabel={(kit) => kit.name}
        formatOptionLabel={(kit) => {
          return (
            <div
              className="container-fluid rounded-1 p-2"
              style={{
                backgroundColor: kit.property ? "inherit" : "#F3F6F8CC",
              }}
            >
              <div className="row">
                <div className="col-12 col-lg-9">
                  {kit.property ? (
                    <span className="fs-6 text-secondary">
                      {kit.property.shortAddress}
                      {kit.property.unit
                        ? ` Unit ${kit.property.unit}`
                        : ""}, {kit.property.city}, {kit.property.state}{" "}
                      {kit.property.zipCode}
                    </span>
                  ) : (
                    <span className="fs-6 text-delet-blue fw-medium">
                      Available
                    </span>
                  )}
                </div>
                <div className="col-12 col-lg-3">
                  <span className="fs-6 text-dark">{kit.name}</span>
                </div>
              </div>
            </div>
          );
        }}
      />
      <div className="d-flex gap-3">
        <Button
          onClick={handleCancel}
          variant="light"
          className="border border-secondary-subtle w-100 py-2"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleChangeKit(selectedKit._id)}
          className="w-100 bg-delet border-0 py-2"
          disabled={isPending}
        >
          Change property kit
        </Button>
      </div>
    </div>
  );
};

export default SelectKit;
