import InfoBanner from "../../../../../../../components/Banners/InfoBanner";
import HardwareList from "../../../../../components/HardwareList";
import ListWrapper from "../../../../../../KitHistory/components/ListWrapper";
// Desktop version
import CodeHistory from "../../../../../components/Keypad/CodeHistory";
import LockHistory from "../../../../../../../components/Notifications/LockHistory";
// Mobile version
import MobileCodeHistory from "../../../../../components/Keypad/Mobile-CodeHistory";
import MobileLockHistory from "../../../../../../../components/Notifications/MobileLockHistory";

export default function DeletKitContent({
    property,
    lockId,
    lockVendor,
    keypadId,
    currentTab,
    handleCreateCode,
}) {
    // Mapping components for both desktop and mobile views
    const ComponentMap = {
        info: {
            desktop: <HardwareList hardwareList={property?.kit?.hardware} />,
            mobile: <HardwareList hardwareList={property?.kit?.hardware} />,
        },
        codes: {
            desktop: keypadId ? (
                <CodeHistory
                    keypadId={keypadId}
                    handleCreateCode={handleCreateCode}
                    vendor={lockVendor}
                />
            ) : (
                <NoKeypadMessage />
            ),
            mobile: keypadId ? (
                <MobileCodeHistory
                    keypadId={keypadId}
                    handleCreateCode={handleCreateCode}
                    vendor={lockVendor}
                />
            ) : (
                <NoKeypadMessage />
            ),
        },
        "kit-history": {
            desktop: (
                <ListWrapper
                    id={property?.kit?._id}
                    hideFields={["user", "assignedUser"]}
                />
            ),
            mobile: (
                <ListWrapper
                    id={property?.kit?._id}
                    hideFields={["user", "assignedUser"]}
                />
            ),
        },
        "lock-history": {
            desktop: keypadId ? (
                <LockHistory lockId={lockId} />
            ) : (
                <NoKeypadMessage />
            ),
            mobile: keypadId ? (
                <MobileLockHistory lockId={lockId} />
            ) : (
                <NoKeypadMessage />
            ),
        },
    };

    // Default Component (fallback)
    const FallbackComponent = <div>Coming soon...</div>;

    return (
        <div className="w-100 px-2 py-3 px-md-4 ">
            {/* Desktop View */}
            <div className="d-none d-md-block">
                {ComponentMap[currentTab]?.desktop || FallbackComponent}
            </div>

            {/* Mobile View */}
            <div className="d-block d-md-none">
                {ComponentMap[currentTab]?.mobile || FallbackComponent}
            </div>
        </div>
    );
}

// Reusable No Keypad Found Message
const NoKeypadMessage = () => (
    <div className="px-5">
        <InfoBanner>
            <span className="text-secondary fs-7">No keypad found</span>
        </InfoBanner>
    </div>
);
