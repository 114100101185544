import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiSolidDoorOpen } from "react-icons/bi";
import { FaQuestion } from "react-icons/fa";
import { MdSensorDoor } from "react-icons/md";
import { TbDoorOff } from "react-icons/tb";

const DoorStatus = ({ status, bridgeStatus }) => {
  const renderTooltip = (props) => (
    <Tooltip id="door-status-tooltip" className="status-tooltip" {...props}>
      {status === "open" && bridgeStatus === "online"
        ? "The door is currently opened"
        : status === "closed" && bridgeStatus === "online"
        ? "The door is currently closed"
        : status === "unknown" && bridgeStatus === "online"
        ? "Unknown door status"
        : "Sensor is not available"}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ hide: 700 }}
      overlay={renderTooltip}
      offset={[0, 5]}
    >
      <div
        className="rounded-circle align-items-center justify-content-center p-3 bg-light-gray"
        disabled={bridgeStatus === "offline"}
      >
        {bridgeStatus === "online" ? (
          status === "open" ? (
            <BiSolidDoorOpen color="#FF0000" size={25} />
          ) : status === "closed" ? (
            <MdSensorDoor color="white" size={25} />
          ) : status === "unknown" ? (
            <FaQuestion color="white" size={20} />
          ) : (
            <TbDoorOff color="white" size={25} />
          )
        ) : (
          <TbDoorOff color="white" size={25} />
        )}
      </div>
    </OverlayTrigger>
  );
};

export default DoorStatus;
