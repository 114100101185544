import React, { useMemo } from "react";
import Select from "react-select";
import { useCompaniesInfiniteQuery } from "../../../../hooks/companies/useCompaniesInfiniteQuery";

export const CompanySelect = ({
  sortField = "name",
  sortDirection = "asc",
  searchTerm = "",
  limit = 20,
  getCompaniesList,
  value,
  onChange,
  required,
}) => {
  const {
    data,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useCompaniesInfiniteQuery({
    sortField,
    sortDirection,
    debouncedSearchTerm: searchTerm,
    limit,
    getCompaniesList,
  });

  // Format companies data for react-select
  const options = useMemo(() => {
    if (!data?.pages) return [];

    return data.pages.flatMap((page) =>
      page.companies.map((company) => ({
        value: company._id,
        label: company.name,
      }))
    );
  }, [data]);

  // Handle menu scroll to implement infinite scrolling
  const handleMenuScrollToBottom = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      isLoading={isLoading || isFetchingNextPage}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      placeholder="Select a company..."
      isClearable
      noOptionsMessage={() =>
        isError
          ? `Error: ${error?.message || "Failed to load companies"}`
          : "No companies found"
      }
      className="company-select"
      classNamePrefix="company-select"
      required={required}
    />
  );
};
