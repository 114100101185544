import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createProperty,
  getAllProperties,
  updatePropertyKit,
} from "../../../api/properties.js";
import { FaCheckCircle, FaQuestionCircle } from "react-icons/fa";
import MapWithInput from "./Maps/MapWithInput";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { design } from "../../../constants/index.js";
import { updateKitsInCache } from "../../../utils/Kits.js";

function AssignProperty({ kit, closeModal }) {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [pickedProperty, setPickedProperty] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [incorrectAddress, setIncorrectAddress] = useState(false);
  const [search, setSearch] = useState("");

  const {
    data: properties,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["all-my-properties"],
    queryFn: () => getAllProperties({ fields: "address shortAddress unit kit status" }),
  });

  const { mutateAsync: mutateKit, isPending } = useMutation({
    mutationFn: async () => {
      const data = await updatePropertyKit(pickedProperty, kit._id);
      return data;
    },
    onSuccess: async (data) => {
      const { existingKit, newKit } = data;

      await queryClient.cancelQueries(["kits"]);

      const previousKits = queryClient.getQueryData(["kits"]);

      updateKitsInCache(queryClient, newKit, existingKit);

      closeModal();

      return { previousKits };
    },

    onError: (err, newKit, context) => {
      console.log("ERROR: ", err);
      queryClient.setQueryData(["kits"], context.previousKits);
    },
  });

  const { mutate: handleNewProperty } = useMutation({
    mutationFn: async (propertyInfo) => {
      const data = await createProperty(propertyInfo);
      return data;
    },

    onSuccess: async (newProperty) => {
      await queryClient.cancelQueries(["all-my-properties"]);
      const { existingKit, newKit } = newProperty;
      const previousProperties = queryClient.getQueryData([
        "properties",
        kit.user,
      ]);

      queryClient.setQueryData(["all-my-properties"], (prev) => [
        ...prev,
        newProperty,
      ]);

      updateKitsInCache(queryClient, newKit, existingKit);

      MySwal.fire({
        title: "Property Added Successfully",
        html: "<p class='text-body-tertiary fs-6'>You can edit the details at any time",
        iconHtml: <FaCheckCircle color={design.deleteMainColor} />,
        showCancelButton: true,
        cancelButtonText: "Close",
        confirmButtonText: "Edit property",
        confirmButtonColor: design.deleteMainColor,
        reverseButtons: true,
        customClass: {
          icon: "border-0 my-0 py-2",
          popup: "px-5 pt-2 w-content",
          title: "pt-0 text-nowrap",
          htmlContainer: "pt-2",
          actions: "gap-3 w-100 flex-nowrap flex-row-reverse",
          confirmButton: "w-100 rounded-3 fw-bold",
          cancelButton:
            "w-100 bg-white text-black border border-secondary-subtle rounded-3 fw-bold text-secondary",
        },
      }).then((res) => {
        if (res.isConfirmed) navigate(`/property/edit/${newProperty._id}`);
        if (res.dismiss) closeModal();
      });

      setPickedProperty(newProperty._id);

      return { previousProperties };
    },

    onError: (err, newProperty, context) => {
      console.log("ERROR: ", err);
      queryClient.setQueryData(["all-my-properties"], context.previousProperties);
      MySwal.fire({
        title: "There was an error creating the property, please try again",
        icon: "error",
        timer: 1500,
      });
    },
  });

  const handleSelectPlace = (googlePlace) => {
    console.log(googlePlace)
    setSelectedPlace(googlePlace);
    const { address_components } = googlePlace;
    if (
      !address_components.some((component) =>
        component.types.includes("street_number")
      )
    ) {
      setIncorrectAddress(true);
    } else {
      setIncorrectAddress(false);
      MySwal.fire({
        title: "Creating new property",
        html: "<p class='text-body-tertiary fs-6'>A new property will be created with this address and will be assigned to the kit",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Create and assign",
        confirmButtonColor: design.deleteMainColor,
        reverseButtons: true,
        customClass: {
          popup: "px-5 pt-2 rounded-3",
          title: "pt-3 fs-4",
          htmlContainer: "pt-2",
          actions: "gap-3 w-100 flex-nowrap flex-row-reverse",
          confirmButton: "w-100 rounded-3",
          cancelButton:
            "w-100 bg-white text-black border border-secondary-subtle rounded-3",
        },
      }).then(async (res) => {
        if (res.isConfirmed) {
          const propertyInfo = {
            address: googlePlace.formatted_address,
            shortAddress: googlePlace.formatted_address,
            kit: kit._id,
          };

          address_components.forEach((component) => {
            // eslint-disable-next-line default-case
            switch (component.types[0]) {
              case "locality":
                propertyInfo.city = component.long_name;
                break;
              case "administrative_area_level_1":
                propertyInfo.state = component.short_name;
                break;
              case "postal_code":
                propertyInfo.zipCode = component.long_name;
                break;
            }
          });
          handleNewProperty(propertyInfo);
        }
      });
    }
  };

  const handleUpdateKit = () => {
    mutateKit();
  };

  const filteredProperties = properties?.filter(
    (property) =>
      (property.status === "active" || property.status === "application received") &&
    (property.address.includes(search) || property.shortAddress.includes(search))
  );

  return (
    <div className="d-flex flex-column gap-2">
      <h4 className="text-delet-blue text-center pb-3 fs-5">
        Assign Property to {kit.name}
      </h4>
      <h4 className="fs-6 d-flex justify-content-between">
        Add a New Property{" "}
        <FaQuestionCircle fill="gray" className="cursor-pointer d-none" />
      </h4>
      <div>
        <div className="d-flex flex-column gap-2">
          <MapWithInput
            selectedPlace={selectedPlace}
            handleSelectPlace={handleSelectPlace}
            incorrectAddress={incorrectAddress}
          />
        </div>
      </div>
      {isLoading ? (
        <h4>...Loading</h4>
      ) : isError ? (
        <h4>Error getting properties, close this windows and try again</h4>
      ) : (
        <>
          <h4 className="tex-nowrap fs-6 fw-semibold">Select Property</h4>
          <input
            placeholder="Search by address"
            className="form-control px-2 py-1"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {filteredProperties.length > 0 ? (
            <>
              <div
                className="d-flex flex-column gap-2 overflow-y-auto py-2"
                style={{ maxHeight: "30vh" }}
              >
                {filteredProperties.map((property, index) => (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="listGroupRadio"
                      value={property._id}
                      id={property._id}
                      checked={pickedProperty === property._id}
                      onChange={(e) => setPickedProperty(property._id)}
                    />
                    <label
                      className="form-check-label cursor-pointer fs-6"
                      htmlFor={property._id}
                    >
                      {property.unit ? `${property.shortAddress}, Unit ${property.unit}` : property.shortAddress}
                    </label>
                  </div>
                ))}
              </div>
              <button
                className="text-center bg-delet rounded text-white border-0 w-100 px-2 py-2 fw-bold"
                onClick={handleUpdateKit}
                disabled={isPending}
              >
                Save
              </button>
            </>
          ) : (
            <h4 className="text-center text-body-tertiary">
              ...No properties found
            </h4>
          )}
        </>
      )}
    </div>
  );
}

export default AssignProperty;
