import { useQuery } from "@tanstack/react-query";
import { isRouterOnline } from "../../../api/routers";

const RouterStatus = ({ kit, property }) => {
  const {
    data: router = { status: "unavailable" },
    isLoading,
  } = useQuery({
    queryKey: ["router-status", property],
    queryFn: () => isRouterOnline({ kitId: kit?._id }),
    enabled: !!kit,
  });

  return (
    <p className="d-flex gap-1 align-items-center bg-secondary-subtle px-3 py-1 rounded-5 h-content router-status-w">
      <span
        className={`router-status ${
          isLoading
            ? "bg-secondary"
            : router.status === "online"
            ? "circle-completed"
            : router.status === "offline"
            ? "circle-cancelled"
            : "bg-secondary"
        }`}
      ></span>
      <span className="fs-9 text-uppercase d-flex align-items-center lh-base">
        {isLoading ? "Getting status..." : router.status}
      </span>
    </p>
  );
};

export default RouterStatus;
