import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import style from "../Steps/style.module.scss";
import rescheduleStyle from "./style.module.scss";
import check from "../../../../assests/images/check.svg";
import { StepNumberLabel } from "../Steps/StepNumberLabel";
import Step4 from "../Steps/Step4/Step4";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SelectedPropertyCard } from "./SelectedProperyCard";
import {
  getMeetingWithToken,
  rescheduleMeeting,
} from "../../../../api/bookings";
import { AppButton } from "../../../../designSystem/AppButton";
import LoaderRing from "../../../../components/LoaderRing";

import innovation from "../../../../assests/images/Step4.svg";
import Logo from "../../../../assests/images/logo-dark.svg";

import { PropertyDetail } from "../PropertyDetail";
import { DrawerModalContainer } from "../../../../designSystem/DrawerModalContainer";

import { MdLocalPhone, MdEmail } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { CiLocationOn } from "react-icons/ci";
import { BsFillBuildingsFill } from "react-icons/bs";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import { Card } from "../../../../designSystem/Card";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";

const CircleContentChecked = () => <img src={check} alt="" />;

export const RescheduleSteps = () => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const handleInputChange = (date) => {
    setRescheduledDate({
      localTimeRequested: date.target.value,
    });
    console.log("this is the rescheduled date", rescheduledDate);
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["booking", token],
    queryFn: () => getMeetingWithToken(token),
  });

  const [rescheduledDate, setRescheduledDate] = useState({
    localTimeRequested: null,
  });

  const queryClient = useQueryClient();
  const { mutateAsync: rescheduleMutation, isLoading: isRescheduling } =
    useMutation({
      mutationKey: ["booking", token, rescheduledDate],
      mutationFn: ({ token, rescheduledDate }) =>
        rescheduleMeeting(token, rescheduledDate),
      onSuccess: (data) => {
        queryClient.invalidateQueries(["booking", token]);
        navigate(`/book/confirmation?token=${token}`);
      },
      onError: (error) => {
        console.log("ERROR", error);
      },
    });

  const handleStep4 = () => {
    setNeedsConfirmation(true);
    console.log("this is the rescheduled date", rescheduledDate);
  };

  const handleConfirm = async () => {
    if (rescheduledDate) {
      try {
        await rescheduleMutation({ token, rescheduledDate });
      } catch (error) {
        console.log("ERROR", error);
      }
    }
  };

  const onDetails = () => {
    navigate(`/tour-property/${data?.booking?.property?._id}`);
  };

  const timezone = data?.booking?.property?.timezone;

  return (
    <div className={style.layoutGrid}>
      <div className={`${rescheduleStyle.layoutSteps}`}>
        {needsConfirmation ? (
          <>
            <div className={rescheduleStyle.buttonContainer}>
              {/* <img
                  src='https://developmentapi.delet.com/images/delet-logo.png'
                  alt='delet-logo'
                  className='logo'
                /> */}
              <button
                className={`${style.backButton} mb-5 pb-5`}
                onClick={() => setNeedsConfirmation(false)}
              >
                {"< Back"}
              </button>
            </div>
            <section className={rescheduleStyle.layout}>
              <header className={rescheduleStyle.header}>
                <h1 className={rescheduleStyle.heading}>
                  Confirm Your Personal Info
                </h1>
                <Card.Title className={rescheduleStyle.cardTitle}>
                  Please confirm your personal information before rescheduling
                  your booking. Let us know if any updates are needed
                </Card.Title>
              </header>
              <Card className={rescheduleStyle.card}>
                <article className={rescheduleStyle.information}>
                  <table>
                    <tbody>
                      <tr className={rescheduleStyle.rows}>
                        <td className={rescheduleStyle.leftTd}>
                          <IoMdPerson size={14} /> With
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {data?.booking?.user?.firstName || "NA"}
                        </td>
                      </tr>
                      <tr className={rescheduleStyle.rows}>
                        <td className={rescheduleStyle.leftTd}>
                          <BsFillBuildingsFill size={14} /> Agency
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {data?.booking?.user?.company?.name || "NA"}
                        </td>
                      </tr>
                      <tr className={rescheduleStyle.rows}>
                        <td className={rescheduleStyle.leftTd}>
                          <MdEmail size={14} /> Email
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {data?.booking?.contact?.email}
                        </td>
                      </tr>
                      <tr className={rescheduleStyle.rows}>
                        <td className={rescheduleStyle.leftTd}>
                          <MdLocalPhone size={14} /> Phone Number
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {DataTransferItemList?.booking?.contact?.phone}
                        </td>
                      </tr>
                      <tr className={rescheduleStyle.rows}>
                        <td className={rescheduleStyle.leftTd}>
                          <CiLocationOn size={14} /> Location
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {`${data?.booking?.property?.shortAddress}${
                            data?.booking?.property?.unit
                              ? ` Unit ${data?.booking?.property?.unit}`
                              : ""
                          }, ${data?.booking?.property?.city}, ${
                            data?.booking?.property?.state
                          } ${data?.booking?.property?.zipCode}`}
                        </td>
                      </tr>
                      <tr className={rescheduleStyle.rows}>
                        <td className={rescheduleStyle.leftTd}>
                          <GoCalendar size={14} /> On
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {format(
                            toZonedTime(
                              rescheduledDate.localTimeRequested * 1000,
                              timezone
                            ),
                            "MMMM dd, yyyy"
                          )}
                        </td>
                      </tr>
                      <tr className={rescheduleStyle.bottomRow}>
                        <td className={rescheduleStyle.leftTd}>
                          <FaRegClock size={14} /> At
                        </td>
                        <td className={rescheduleStyle.rightTd}>
                          {format(
                            toZonedTime(
                              rescheduledDate.localTimeRequested * 1000,
                              timezone
                            ),
                            "h:mm a"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
              </Card>

              <div className="d-flex justify-content-center">
                <AppButton as="button" onClick={handleConfirm}>
                  {!isRescheduling ? <></> : <LoaderRing />}
                  {"  "} Confirm and Reschedule
                </AppButton>
              </div>
            </section>
          </>
        ) : (
          <>
            <p className={`${style.headerSubtitle} mt-5 pt-5`}>
              Reschedule Your Showing
            </p>
            <div className={`${style.stepLabels}`}>
              <StepNumberLabel
                className={style.inactive}
                circleContent={<CircleContentChecked />}
                state="completed"
                title="Choose A Property"
              />
              <StepNumberLabel
                className={style.inactive}
                circleContent={<CircleContentChecked />}
                state="completed"
                title="Choose A Property"
              />
              <StepNumberLabel
                className={style.inactive}
                circleContent={<CircleContentChecked />}
                state="completed"
                title="Choose A Property"
              />
              <StepNumberLabel circleContent="4" state="selected" />
              <StepNumberLabel
                stepNumber={5}
                circleContent={<CircleContentChecked />}
                state="default"
                title="Identity Verifications"
              />
            </div>
            {isLoading ? (
              <div>Loading...</div>
            ) : isError ? (
              <div>Error getting booking</div>
            ) : (
              <div className="d-flex flex-column gap-3">
                <SelectedPropertyCard
                  className={`${rescheduleStyle.card}`}
                  property={data?.booking?.property}
                  onSeeDetails={onDetails}
                />

                <div className="px-lg-5 mx-lg-2 mx-4">
                  <Step4
                    className={`${rescheduleStyle.container}`}
                    currentStep={4}
                    propertyId={data?.booking?.property?._id}
                    onChange={handleInputChange}
                    agent={data?.booking?.user?._id}
                    timezone={timezone}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <AppButton
                    as="button"
                    onClick={handleStep4}
                    disabled={!rescheduledDate.localTimeRequested}
                    className={rescheduleStyle.button}
                  >
                    {!isRescheduling ? <></> : <LoaderRing />}
                    {"  "} Continue
                  </AppButton>
                </div>
                <DrawerModalContainer
                  onOpen={onOpen}
                  onClose={onClose}
                  isOpen={open}
                >
                  <PropertyDetail property={data.booking?.property} />
                </DrawerModalContainer>
              </div>
            )}
          </>
        )}
      </div>

      <div className={rescheduleStyle.layoutImages}>
        <div className={style.boxBackground}>
          <div className="d-flex flex-column justify-content-center align-items-center gap-5">
            <img src={innovation} alt="" />
            <img src={Logo} width={107} height={25} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
