import { ReactNode } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import style from './style.module.scss';

type Props = {
  children?: ReactNode;
} & DropzoneOptions;

export const AppDropzone = ({ children, ...dropzoneOptions }: Props) => {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneOptions,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <div
      className={`${style.dropzone} `}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
