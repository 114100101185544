import { useState } from "react";

interface SortBy {
    name: "start" | "end" | "";
    type: "asc" | "desc";
}

const sortByInitState: SortBy = Object.freeze({
    name: "",
    type: "asc",
});

interface Filters {
    status: string[];
    type: string[];
    text: string;
}

export default function useCodeFilters() {
    // Filters and sorting
    const [filters, setFilters] = useState<Filters>({
        status: [],
        type: [],
        text: "",
    });
    const [sortBy, setSortBy] = useState(sortByInitState);

    const handleFilters = (
        name: keyof Filters,
        value: string,
        isChecked?: boolean
    ) => {
        setFilters((prev) => {
            const newFilters: Filters = { ...prev }; // Explicitly define type

            if (name === "text") {
                newFilters.text = value; // Direct assignment for text input
            } else {
                // Ensure `status` and `type` are arrays
                newFilters[name] = isChecked
                    ? [...prev[name], value] // Add value if checked
                    : prev[name].filter((item) => item !== value); // Remove if unchecked
            }

            return newFilters;
        });
    };

    return {
        filters,
        handleFilters,
        sortBy,
        setSortBy,
    };
}
