import { Text, Flex, Box, Group } from "@mantine/core";

interface CardInsightProps {
    rate: {
        value: number;
        direction: string;
    };
    rangeValue: string;
}

export default function CardInsight({ rate, rangeValue }: CardInsightProps) {
    return (
        <Flex
            gap="5"
            direction={{ base: "row", md: "column", xl: "row" }}
            align={{
                base: "center",
                md: "flex-start",
                xl: "center",
            }}
        >
            <Box c={rate.direction === "Up" ? "indigo.7" : "red.7"}>
                <Group gap="5">
                    {rate.direction === "Up" ? (
                        <>
                            {/* Arrow mobile size */}
                            <svg
                                className="d-block d-md-none"
                                width="15"
                                height="8"
                                viewBox="0 0 19 11"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.9828 0.448242L15.0402 2.27527L10.656 6.16867L7.0624 2.97736L0.405273 8.89724L1.67201 10.0222L7.0624 5.23521L10.656 8.42652L16.3159 3.40818L18.3732 5.23521V0.448242H12.9828Z"
                                    fill="#5271FF"
                                />
                            </svg>

                            {/* Arrow desktop size */}
                            <svg
                                className="d-none d-md-block"
                                width="20"
                                height="12"
                                viewBox="0 0 19 11"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.9828 0.448242L15.0402 2.27527L10.656 6.16867L7.0624 2.97736L0.405273 8.89724L1.67201 10.0222L7.0624 5.23521L10.656 8.42652L16.3159 3.40818L18.3732 5.23521V0.448242H12.9828Z"
                                    fill="#5271FF"
                                />
                            </svg>
                        </>
                    ) : (
                        <>
                            {/* Arrow mobile size */}
                            <svg
                                className="d-block d-md-none"
                                width="16"
                                height="14"
                                viewBox="0 0 23 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.9819 15.0215L17.0392 13.1945L12.655 9.30106L9.06142 12.4924L2.4043 6.57249L3.67104 5.44755L9.06142 10.2345L12.655 7.04321L18.3149 12.0615L20.3722 10.2345V15.0215H14.9819Z"
                                    fill="#FF0004"
                                />
                            </svg>

                            {/* Arrow desktop size */}
                            <svg
                                className="d-none d-md-block"
                                width="22"
                                height="19"
                                viewBox="0 0 23 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.9819 15.0215L17.0392 13.1945L12.655 9.30106L9.06142 12.4924L2.4043 6.57249L3.67104 5.44755L9.06142 10.2345L12.655 7.04321L18.3149 12.0615L20.3722 10.2345V15.0215H14.9819Z"
                                    fill="#FF0004"
                                />
                            </svg>
                        </>
                    )}

                    {/* Metric % mobile */}
                    <Text className="d-block d-md-none" size="12px">
                        {rate.value}%
                    </Text>

                    {/* Metric % desktop */}
                    <Text className="d-none d-md-block" size="md">
                        {rate.value}%
                    </Text>
                </Group>
            </Box>

            {/* Metric insight mobile */}
            <Text className="d-block d-md-none" size="12px" c="gray.7">
                {rate.direction} from past{" "}
                {rangeValue === "Weekly" ? "7" : "30"} days
            </Text>

            {/* Metric insight desktop */}
            <Text className="d-none d-md-block" size="md" c="gray.7">
                {rate.direction} from past{" "}
                {rangeValue === "Weekly" ? "7" : "30"} days
            </Text>
        </Flex>
    );
}
