import { Link } from "react-router-dom";
import { useHover } from "@mantine/hooks";
import LazyImgWithPlaceholder from "../../components/Images/LazyImgWithPlaceholder";
import propertyDummyImage from "../../assests/images/6-thumb.png";
import getImageUrl from "../../helper/checkImageFormat";

interface SinglePropertyProps {
    property: {
        _id: string;
        images: string[];
        primaryImage: string;
        classification: string;
        shortAddress: string;
        city: string;
        state: string;
        zipCode: string;
        price: number;
        bookingCount: number;
        showCount: number;
    };
}

export default function SingleProperty({ property }: SinglePropertyProps) {
    // Mantine hover
    const { hovered, ref } = useHover();

    return (
        <Link
            to={`/property/${property._id}?activeTab=details`}
            style={{ textDecoration: "none", color: "inherit" }}
        >
            <div
                ref={ref}
                className="row gx-0 gx-lg-4 d-lg-flex justify-content-lg-between align-items-center gap-2 gap-lg-0 border-top mx-0 py-3"
                style={{
                    backgroundColor: hovered ? "#f7f7f7" : "transparent",
                    transition: "background-color 0.2s ease-in-out",
                }}
            >
                <div className="col-12 col-lg-4">
                    <div className="d-flex align-items-center">
                        <LazyImgWithPlaceholder
                            width={70}
                            height={70}
                            border={3}
                            placeholder={propertyDummyImage}
                            src={
                                getImageUrl(property.images?.[0]) ??
                                getImageUrl(property.primaryImage)
                            }
                            alt="property"
                        />

                        {/* Property info mobile */}
                        <div className="d-flex d-lg-none flex-column justify-content-between flex-grow-1 align-self-stretch pt-1 ms-2 ms-lg-3">
                            <span
                                className="text-uppercase"
                                style={{ color: "#979797", fontSize: "11px" }}
                            >
                                {property.classification}
                            </span>
                            <span
                                className="fw-medium"
                                style={{ fontSize: "12px" }}
                            >
                                {property.shortAddress}
                            </span>
                            <span
                                style={{ color: "#979797", fontSize: "11px" }}
                            >
                                {property.city}
                                {property.state && `, ${property.state}`}
                                {property.zipCode && `, ${property.zipCode}`}
                            </span>
                        </div>

                        {/* Property info desktop */}
                        <div className="d-none d-lg-flex flex-column justify-content-between flex-grow-1 align-self-stretch pt-1 ms-3">
                            <span
                                className="text-uppercase"
                                style={{ color: "#979797", fontSize: "13px" }}
                            >
                                {property.classification}
                            </span>
                            <span
                                className="fw-medium"
                                style={{ fontSize: "14px" }}
                            >
                                {property.shortAddress}
                            </span>
                            <span
                                style={{ color: "#979797", fontSize: "13px" }}
                            >
                                {property.city}
                                {property.state && `, ${property.state}`}
                                {property.zipCode && `, ${property.zipCode}`}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-2 d-none d-lg-flex justify-content-between align-items-center">
                    <p className="d-block d-lg-none fw-medium">Price:</p>
                    <p>
                        {property.price
                            ? `$${new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                              }).format(property.price)}`
                            : "N/A"}
                    </p>
                </div>
                <div className="col-12 col-lg-2 d-none d-lg-flex justify-content-between align-items-center">
                    <p className="d-block d-lg-none fw-medium">Leads:</p>
                    <p>{property.bookingCount}</p>
                </div>
                <div className="col-12 col-lg-2 d-none d-lg-flex justify-content-between align-items-center">
                    <p className="d-block d-lg-none fw-medium">Scheduled:</p>
                    <p>{property.bookingCount}</p>
                </div>
                <div className="col-12 col-lg-2 d-none d-lg-flex justify-content-between align-items-center">
                    <p className="d-block d-lg-none fw-medium">Scheduled:</p>
                    <p>{property.showCount}</p>
                </div>
            </div>
        </Link>
    );
}
