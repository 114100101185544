import Select from "react-select";
import { Controller } from "react-hook-form";
import { usePropertiesList } from "../../../../hooks/properties/usePropertiesAdminList";
import { useEffect } from "react";

function PropertiesSelect({ control, watch, setValue, error }) {
  const userId = watch("user");

  const {
    data: properties = [],
    isLoading,
    error: errorGettingProperties,
  } = usePropertiesList({ userId });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "properties" && value.properties?.length > 0) {
        const firstProperty = value.properties[0];

        setValue("address.street", firstProperty.shortAddress);
        setValue("address.city", firstProperty.city);
        setValue("address.state", firstProperty.state);
        setValue("address.zipCode", firstProperty.zipCode);
      }
    });

    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  return (
    <>
      <Controller
        name="properties"
        control={control}
        defaultValue={[]}
        // rules={{
        //   required: "Please select at least one property",
        // }}
        render={({ field }) => (
          <Select
            {...field}
            isMulti
            options={properties}
            getOptionLabel={(option) => option.address}
            getOptionValue={(option) => option.address}
            classNamePrefix="select"
            placeholder="Select properties..."
            isClearable
            isSearchable
            isInvalid={!!error}
            isDisabled={isLoading || errorGettingProperties}
          />
        )}
      />
      {error && <div className="text-danger small mt-1">{error.message}</div>}
    </>
  );
}

export default PropertiesSelect;
