import React from "react";
import { Button } from "react-bootstrap";

const ActionButton = ({ children, ...props }) => {
  return (
    <Button
      className="icon-button rounded bg-delet d-flex align-items-center justify-content-center p-0 position-relative"
      {...props}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
