import React, { useEffect, useState } from "react";
import {
  Group,
  rem,
  Button,
  Flex,
  Title,
  Badge,
  ActionIcon,
  Menu,
  CopyButton,
  Tooltip,
} from "@mantine/core";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoMdOpen } from "react-icons/io";
import { MdOutlineModeEdit } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";

import Sidebar from "../components/AdminSideBar";
import PageHeader from "../../../components/Headers/PageHeader";
import { Auth } from "aws-amplify";
import { MdAdd } from "react-icons/md";
import UseHttp from "../../../hooks/UseHttp";
import { MdDelete } from "react-icons/md";
import BasicTable from "../../../components/Tables/BasicTable";
import { LuCopy, LuCheck } from "react-icons/lu";
import { saveAs } from "file-saver";
import { useProfile } from "../../../context/UserProfileContext";

const UserList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: profile } = useProfile();

  const company = searchParams.get("company");

  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [refetchUser, setRefetchUsers] = useState(0);

  const { isLoading, error, requestData } = UseHttp();
  const {
    isLoading: loadingDeleteUser,
    error: errorDeleteUser,
    requestData: deleteUser,
  } = UseHttp();

  const fetchUsers = ({ company }) => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${
          process.env.REACT_APP_NODE_API_URL
        }/api/users/admin/list?format=minimal&company=${company ?? ""}`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      requestData(requestConfig, (response) => {
        setData(response);
        setSortedData(response);
      });
    });
  };
  const exportUsers = async () => {
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/users/export`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "users";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });
  };

  const handleActivateUser = (id) => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${id}/activate`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
        method: "PATCH",
      };

      requestData(requestConfig, (response) => {
        fetchUsers();
      });
    });
  };

  const saveUsersAsCSV = (userData) => {
    const csvData = new Blob([userData], { type: "text/csv;charset=utf-8" });
    console.log("csvdata:,", csvData);
    saveAs(csvData, "users.csv");
  };

  useEffect(() => {
    fetchUsers({ company });
  }, [refetchUser, company]);

  const handleDelete = (id) => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${id}`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
        method: "DELETE",
      };
      deleteUser(requestConfig, (response) => {
        setRefetchUsers(refetchUser + 1);
      });
    });
  };

  const roleColors = {
    agent: "blue",
    org: "violet",
    admin: "pink",
  };

  const columns = [
    {
      header: "Name",
      rowTitle: (row) => row.firstName + " " + row.lastName,
      cell: (row) => row.firstName + " " + row.lastName,
      width: "",
      sortBy: "lastName",
      sorting: ["lastName"],
    },
    {
      header: "Email",
      rowTitle: (row) => row.email,
      cell: (row) => (
        <Flex align={"center"}>
          {row.email}
          <CopyButton value={row.email} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <LuCheck style={{ width: rem(10) }} />
                  ) : (
                    <LuCopy style={{ width: rem(10) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
      ),
      width: "",
      sortBy: "email",
      sorting: ["email"],
    },
    {
      header: "Company",
      rowTitle: (row) => row.company?.name,
      cell: (row) => row.company?.name,
      width: "",
      sortBy: "company",
      sorting: ["company", "name"],
    },
    {
      header: "App role",
      cell: (row) => {
        if (!row.active) {
          return (
            <Button color="#25CC2B" onClick={() => handleActivateUser(row._id)}>
              Approve
            </Button>
          );
        }
        return (
          <Badge color={roleColors[row.role?.toLowerCase()]} variant="light">
            {row.role}
          </Badge>
        );
      },
      width: "",
      sortBy: "role",
      sorting: ["role"],
    },
    {
      header: "Admin panel role",
      cell: (row) =>
        row.adminPanelRole && (
          <Badge
            color={roleColors[row.adminPanelRole?.toLowerCase()]}
            variant="light"
          >
            {row.adminPanelRole}
          </Badge>
        ),
      width: "",
      sortBy: "adminPanelRole",
      sorting: ["adminPanelRole"],
    },
    {
      header: "",
      cell: (row) => (
        <Group gap={0}>
          <ActionIcon
            variant="subtle"
            color="gray"
            onClick={() => navigate(`/admin/user/details/${row._id}`)}
          >
            <IoMdOpen
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          </ActionIcon>
          {profile?.isSuperadmin && (
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon variant="subtle" color="gray">
                  <HiDotsVertical
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <MdOutlineModeEdit
                      style={{ width: rem(16), height: rem(16) }}
                      stroke={1.5}
                    />
                  }
                  onClick={() => navigate(`/admin/user/edit-user/${row._id}`)}
                >
                  Edit user
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <MdDelete
                      style={{ width: rem(16), height: rem(16) }}
                      stroke={1.5}
                    />
                  }
                  onClick={() => handleDelete(row._id)}
                >
                  Delete user
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      ),
      width: 100,
    },
  ];
  return (
    <>
      <Sidebar>
        
      <PageHeader>
        <h4>Users</h4>
      </PageHeader>
        
        <BasicTable
          button={
            <>
              <Button
                color="black"
                leftSection={<MdAdd />}
                onClick={() => navigate("/admin/user/create")}
                disabled={!profile?.isSuperadmin}
              >
                New user
              </Button>
              <Button color="black" onClick={exportUsers}>
                Export Users
              </Button>
            </>
          }
          isLoading={isLoading}
          data={data}
          columns={columns}
          setSortedData={setSortedData}
          sortedData={sortedData}
        />
      </Sidebar>
    </>
  );
};

export default UserList;
