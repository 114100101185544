import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Spinner, Container } from "react-bootstrap";

const ApplyRedirect = () => {
    const [searchParams] = useSearchParams();
    const fallbackRedirect = "https://delet.com";

    useEffect(() => {
        const hashCode = searchParams.get("id");
        const isValidHashCode = (hashCode) =>
            /^[a-zA-Z0-9]{16}$/.test(hashCode);

        if (!hashCode || !isValidHashCode) {
            window.location.href = fallbackRedirect;
            return;
        }

        const fetchRedirect = async () => {
            try {
                const url = `${process.env.REACT_APP_NODE_API_URL}/api/redirectUrl/hash?hashCode=${hashCode}`;
                const response = await axios.get(url);

                let originalUrl = response.data.originalUrl;

                if (originalUrl) {
                    // Ensure the URL has http or https
                    if (!originalUrl.startsWith("https://")) {
                        originalUrl = `https://${originalUrl}`;
                    }
                    window.location.href = originalUrl;
                } else {
                    window.location.href = fallbackRedirect;
                }
            } catch (err) {
                window.location.href = fallbackRedirect;
            }
        };

        fetchRedirect();
    }, [searchParams]);

    return (
        <Container className="text-center mt-5">
            <Spinner animation="border" role="status" />
            <p className="mt-2">Redirecting...</p>
        </Container>
    );
};

export default ApplyRedirect;
