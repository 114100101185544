import React, { useEffect, useState } from "react";
import { Title } from "@mantine/core";

const OrderConfirmation = ({ orders }) => {
  const [suscription, setSuscription] = useState([]);

  useEffect(() => {
    console.log(orders);
    console.log({ suscription });
    orders.forEach((order) => {
      if (order.serviceType === "kit") {
        const n = order.amount; // Number of elements
        const defaultValue = {
          price: order.individualPrice,
        }; // Default value for each element
        const array = Array(n).fill(defaultValue); // Creates an array of n elements, initialized with defaultValue
        setSuscription((prev) => array);
      } else if (order.serviceType === "installation") {
        const n = order.amount;
        const defaultValue = {
          price: order.individualPrice,
        };
        const array = Array(n)
          .fill(defaultValue)
          .map((kit, index) => ({ ...kit, name: "Kit " + (index + 1) }));

        if (order.kits?.length) {
          order.kits.forEach((kit, index) => {
            array[index] = { ...array[index], name: kit.name };
          });
        }
      } else if (order.serviceType === "removal") {
        const n = order.amount;
        const defaultValue = {
          price: order.individualPrice,
        };
        const array = Array(n)
          .fill(defaultValue)
          .map((kit, index) => ({ ...kit, name: "Kit " + (index + 1) }));

        if (order.kits?.length) {
          order.kits.forEach((kit, index) => {
            array[index] = { ...array[index], name: kit.name };
          });
        }
      }
    });
  }, [orders, suscription]);
  return (
    <>
      <Title order={4} mt={10} mb={20}>
        Order detail
      </Title>
    </>
  );
};

export default OrderConfirmation;
