import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import ShowInstructionsCard from "./components/ShowInstructionsCard";
import ExpiredCode from "./components/ExpiredCode";
import { Card } from "../../designSystem/Card";
import style from "./components/style.module.scss";

function InstructionsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["tour-instructions", token],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/instructions?token=${token}`
      );
      if (!res.ok) {
        const errorData = await res.json();
        throw {
          message: errorData.error,
          rescheduleLink: errorData.rescheduleLink,
        };
      }
      const instructions = await res.json();
      return instructions;
    },
    refetchOnWindowFocus: false,
  });

  if (error) {
    console.log(error.message);
  }

  return (
    <div className="full-screen-container d-flex flex-column">
      <nav class="navbar">
        <div class="container-fluid px-5 py-2">
          <img
            src={process.env.REACT_APP_URL + "/images/delet-logo.png"}
            alt="delet-logo"
            className="logo"
          />
        </div>
      </nav>
      <div className="d-flex justify-content-center flex-grow-1">
        {isLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="spinner-border m-5"
                style={{ width: "3rem", height: "3rem", color: "#5271ff" }}
                role="status"
              ></div>
            </div>
            <header className={style.header}>
              <h3>We are retrieving all the information.</h3>
              <Card.Title className={style.headerSubtitle}>
                Please hold on a moment.
              </Card.Title>
            </header>
          </div>
        ) : error ? (
          <ExpiredCode link={error.rescheduleLink} />
        ) : (
          <ShowInstructionsCard
            booking={data}
            refetch={refetch}
            token={token}
          />
        )}
      </div>
      <footer className="instruction-footer bg-black d-flex justify-content-center align-items-center text-white">
        © Delet 2024. All rights reserved.
      </footer>
    </div>
  );
}

export default InstructionsPage;
