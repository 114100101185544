import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Auth } from "aws-amplify";
import axios from "axios";
import ExpiredCamera from "./ExpiredCamera";
import { Spinner } from "react-bootstrap";
import useViewport from "./../../../hooks/useViewport";
import Logo from "../../../assests/images/Logo.svg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";

const Camera3dEye = ({ camera, updateTrigger = 0, index, showFooter, startTime, stopTime, type = 1, protocol = 2, ...props }) => {
  const [playUrl, setPlayUrl] = useState();
  const [isExpired, setIsExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const { width: screenWidth } = useViewport();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set("recordings", camera.serial);
  newSearchParams.set("selectedCamera", index)
  newSearchParams.set("selectedDay", DateTime.now().toFormat("yyyy-MM-dd"))

  const deviceSerial = camera.model;

  const refresh = useCallback(async () => {
    async function getPlayUrl() {
      const token = await Auth.currentSession();
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API_URL}/api/camera/${deviceSerial}/ezviz-play-url`,
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
          params: {
            startTime,
            stopTime,
            protocol,
            type
          },
        }
      );      

      if (response.status === 200) {
        return response.data;
      } else {
        const errorData = response.data;
        throw new Error(
          JSON.stringify({ message: errorData.message, code: errorData.code })
        );
      }
    }
    setIsLoading(true);
    try {
      const { url } = await getPlayUrl();
      setPlayUrl(url);
      const urlObj = new URL(url);
      const expireTime = urlObj.searchParams.get("expire");
      if (expireTime) {
        const expireInMs = parseInt(expireTime) * 1000 - Date.now();
        if (expireInMs > 0) {
          setIsExpired(false);
          const timeoutId = setTimeout(() => {
            setIsExpired(true);
          }, expireInMs);
          return timeoutId;
        } else {
          setIsExpired(true);
        }
      }
    } catch (error) {
      setIsError(true);
      setErrMsg(error.response.data.message);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  }, [deviceSerial, startTime, stopTime, type]);

  useEffect(() => {
    let timeoutId;

    async function init() {
      timeoutId = await refresh();
    }

    if (deviceSerial) init();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [deviceSerial, refresh]);

  useEffect(() => {
    refresh();
  }, [refresh, updateTrigger]);

  if (!deviceSerial) return null;

  return (
    <>
      {isLoading ? (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : isError ? (
        <>{errMsg}</>
      ) : isExpired ? (
        <ExpiredCamera refresh={refresh} />
      ) : (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: screenWidth < 755 ? "200px" : "100%",
            }}
            className="rounded-3"
          >
            <ReactPlayer
              url={playUrl}
              width="100%"
              stopOnUnmount={true}
              playing={true}
              muted={true}
              controls={true}
              height="100%"
              {...props}
            />
            <div
              style={{
                position: "absolute",
                top: "2%",
                left:
                  screenWidth < 430 ? "2%" : screenWidth < 750 ? "5%" : "2%",
                zIndex: 3,
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: screenWidth < 755 ? 50 : 55,
                  height: "auto",
                  opacity: 0.8,
                }}
              />
            </div>
          </div>
        </>
      )}
      {showFooter ? (<div className="d-flex justify-content-between">
        <span className="py-1 px-3 bg-secondary-subtle w-content rounded-4 text-dark-emphasis fw-semibold small-text">
          CAMERA {isLoading ? "" : isExpired || isError ? "OFF" : "ON"}
        </span>
        <Link to={`${location.pathname}?${newSearchParams.toString()}`} className="d-none">Video Recordings {">"}</Link>
      </div>) : null}
    </>
  );
};

export default Camera3dEye;
