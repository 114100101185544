import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getAgents } from "../../../api/users";

interface Agent {
  firstName: string;
  lastName: string;
  company: {
    name: string;
  };
}

interface GetAgentsResponse {
  totalPages: number;
  agents: Agent[];
}

interface UseAgentsDropdownProps {
  isAdmin: boolean;
  setAgents: Dispatch<SetStateAction<any>>;
}

export const useAgentsDropdown = ({
  isAdmin,
  setAgents,
}: UseAgentsDropdownProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
  } = useInfiniteQuery<GetAgentsResponse>({
    queryKey: ["agents", "calendar"],
    queryFn: ({ pageParam = 1 }) =>
      getAgents({
        page: pageParam,
        limit: 10,
        filters: {},
        sort: {},
        select: "firstName lastName",
        isAdmin,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage.totalPages > pages.length ? pages.length + 1 : undefined,
  });

  useEffect(() => {
    setAgents(data);
  }, [data, setAgents]);

  // Combine all pages into a single array
  const allAgents = useMemo(() => {
    return data?.pages.flatMap((page) => page.agents) || [];
  }, [data]);

  // Pre-compute label once per agent and memoize the result
  const agentsWithLabel = useMemo(() => {
    return allAgents.map((agent) => {
      let label = "";
      if (isAdmin) {
        label += `[${agent.company?.name ?? "No company"}] `;
      }
      label += `${agent.firstName} ${agent.lastName ?? ""}`;
      return { ...agent, label };
    });
  }, [allAgents, isAdmin]);

  // Pre-sort agents by label using default string comparison
  const sortedAgents = useMemo(
    () => {
      if (isAdmin) {
        return [...agentsWithLabel].sort((a, b) => a.label.localeCompare(b.label));
      } else {
        if (agentsWithLabel.length <= 1) return [...agentsWithLabel];
        const [first, ...rest] = [...agentsWithLabel];
        const sortedRest = [...rest].sort((a, b) => a.label.localeCompare(b.label));
        return [first, ...sortedRest];
      }
    },
    [agentsWithLabel, isAdmin]
  );

  // Filter properties based on the search term
  const filteredAgents = useMemo(() => {
    if (!searchTerm.trim()) return sortedAgents;
    const lowerSearch = searchTerm.toLowerCase();
    return sortedAgents.filter((agent) =>
      agent.label.toLowerCase().includes(lowerSearch)
    );
  }, [sortedAgents, searchTerm]);

  // Auto-fetch more pages when search is active and filtered result count is below threshold
  useEffect(() => {
    if (
      searchTerm.trim() &&
      hasNextPage &&
      !isFetchingNextPage &&
      filteredAgents.length < 10
    ) {
      fetchNextPage();
    }
  }, [
    searchTerm,
    filteredAgents,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  ]);

  return {
    searchTerm,
    setSearchTerm,
    filteredAgents,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
  };
};
