import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface UseSelectionProps {
    searchParamKey?: string;
    hasNextPage: boolean;
    fetchNextPage: () => void;
}

export function useSelection({
    searchParamKey = "properties",
    hasNextPage,
    fetchNextPage,
}: UseSelectionProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedItems, setSelectedItems] = useState<string[]>(() => {
        const items = searchParams.get(searchParamKey);
        return items ? items.split(',').filter(Boolean) : [];
      });
    const [scrollableElement, setScrollableElement] =
        useState<HTMLElement | null>(null);

    const addOrRemoveSelectedItem = (selectedItemId: string) => {
        setSelectedItems((prevSelected) => {
            const newArr = prevSelected.includes(selectedItemId)
                ? prevSelected.filter((i) => i !== selectedItemId)
                : [...prevSelected, selectedItemId];

            // Update URL search parameters
            setSearchParams((prevParams) => {
                const newParams = new URLSearchParams(prevParams);
                newParams.set(searchParamKey, newArr.join(","));
                return newParams;
            });
            return newArr;
        });
    };

    // Infinite scroll effect
    useEffect(() => {
        if (!scrollableElement) return;

        const handleScroll = (event: Event) => {
            // Get element variables
            const target = event.currentTarget as HTMLElement;
            const { scrollTop, scrollHeight, clientHeight } = target;

            // Trigger pagination if the user is at the bottom (with a small threshold)
            const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1;
            if (isAtBottom && hasNextPage) {
                fetchNextPage();
            }
        };

        scrollableElement.addEventListener("scroll", handleScroll);
        return () =>
            scrollableElement.removeEventListener("scroll", handleScroll);
    }, [scrollableElement, fetchNextPage, hasNextPage]);

    return {
        selectedItems,
        setSelectedItems,
        addOrRemoveSelectedItem,
        setScrollableElement,
    };
}
