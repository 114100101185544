import Sidebar from "../../components/SideBar";
import Orders from "./tabs/Orders";
import { useNavigate, useParams } from "react-router-dom";
import KitsTab from "./tabs/KitsTab";
import { useState } from "react";
import { setHours, setMinutes, setSeconds } from "date-fns";
import PageHeader from "../../components/Headers/PageHeader";
import NotificationsButton from "../../components/Notifications/NotificationsButton";

const initState = {
  filterBy: {
    search: "",
    dateRange: {
      from: null,
      to: null,
    },
  },
};

const Kits = () => {
  const [filterBy, setFilterBy] = useState(initState.filterBy);

  const { tab } = useParams();
  const navigate = useNavigate();

  const handleChange = (dates) => {
    const [start, end] = dates;
    const endOfDay = end
      ? setHours(setMinutes(setSeconds(end, 59), 59), 23)
      : end;
    setFilterBy((prev) => ({
      ...prev,
      dateRange: {
        from: start,
        to: endOfDay,
      },
    }));
  };

  return (
    <Sidebar>
      <section className="d-flex flex-column gap-2">
        <PageHeader>
          <div className="d-flex justify-content-between">
            <h1 className="fs-4">Kits</h1>
            <NotificationsButton />
          </div>
        </PageHeader>

        <ul className="nav nav-underline px-4 px-lg-5">
          <li className="nav-item">
            <button
              style={{
                color: !tab || tab === "kits" ? "#5271ff" : "grey",
              }}
              className={`nav-link ${
                (!tab || tab === "kits") && "active text-delet-blue"
              }`}
              type="button"
              onClick={() => navigate("/kits/kits")}
            >
              KITS
            </button>
          </li>

          <li className="nav-item d-none">
            <button
              style={{
                color: tab === "orders" ? "#5271ff" : "grey",
              }}
              className={`nav-link ${
                tab === "orders" && "active text-delet-blue"
              }`}
              onClick={() => navigate("/kits/orders")}
            >
              ORDERS
            </button>
          </li>
        </ul>

        {tab === "orders" ? (
          <Orders />
        ) : (
          <KitsTab
            filterBy={filterBy}
            setFilterBy={setFilterBy}
            handleChange={handleChange}
          />
        )}
      </section>
    </Sidebar>
  );
};
export default Kits;
