import React from "react";
import { Modal } from "react-bootstrap";

type GenericModalProps = {
    show: boolean;
    handleClose: () => void;
    title: string;
    body: React.ReactElement;
    hideCloseButton?: boolean;
    size?: "sm" | "lg" | "xl" | undefined;
};

const GenericModal = ({
    show,
    handleClose,
    title,
    body,
    hideCloseButton,
    size = undefined
}: GenericModalProps) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            centered={true}
            contentClassName="p-3"
            size={size}
        >
            {title ? (
                <Modal.Header
                    closeButton={!hideCloseButton}
                    className="border-0"
                >
                    <Modal.Title className="text-secondary fw-semibold">
                        {title}
                    </Modal.Title>
                </Modal.Header>
            ) : null}
            <Modal.Body>{body}</Modal.Body>
        </Modal>
    );
};

export default GenericModal;
