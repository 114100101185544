import { useDashboardContext } from "./DashboardContext";
import { Grid, Flex, Text, Space } from "@mantine/core";
import DownloadButton from "./DownloadButton";
import FilterDate from "./FilterDate";

export default function DashboardHeader({ dashboardRef }) {
    const { rangeValue, dateValue, setDateValue } = useDashboardContext();

    return (
        <Flex
            py="lg"
            direction={{ base: "column", md: "row" }}
            justify="space-between"
        >
            {/* Title mobile */}
            <Text
                className="d-block d-md-none"
                component="h1"
                size="20px"
                fw={700}
                c="gray.8"
            >
                Home
            </Text>

            {/* Title desktop */}
            <Text
                className="d-none d-md-block"
                component="h1"
                size="40px"
                fw={700}
                c="gray.8"
            >
                Home
            </Text>
            <Space h="lg" />

            <Grid>
                <Grid.Col span={{ base: "auto", md: "content" }}>
                    {/* Dates Filter */}
                    <FilterDate
                        dateValue={dateValue}
                        setDateValue={setDateValue}
                        rangeValue={rangeValue}
                    />
                </Grid.Col>
                <Grid.Col span="content">
                    {/* Download Dashboard as PDF */}
                    <DownloadButton dashboardRef={dashboardRef} />
                </Grid.Col>
            </Grid>

            {/* <Flex direction={{ base: "column", md: "row" }} gap="md">
                <FilterDate
                    dateValue={dateValue}
                    setDateValue={setDateValue}
                    rangeValue={rangeValue}
                />

                <Grid>
                    <Grid.Col span={{ base: "auto", md: "content" }}>
                        <FilterRange
                            rangeValue={rangeValue}
                            setRangeValue={setRangeValue}
                        />
                    </Grid.Col>
                    <Grid.Col span="content">
                        <DownloadButton dashboardRef={dashboardRef} />
                    </Grid.Col>
                </Grid>
            </Flex> */}
        </Flex>
    );
}
