import { TYPES_MAP } from "../constants";

export const getFileTypeFromUrl = (url) => {
  const extension = url?.split('.').pop()?.split(/[?#]/)[0].toLowerCase();

  return TYPES_MAP[extension] || 'application/octet-stream';
};

export const formatAddress = ({ shortAddress, city, state, zipCode, unit }) => {
  let addressParts = [shortAddress];

  if (unit) {
    addressParts.push(`Unit ${unit}`);
  }

  let cityStateZip = [city, state, zipCode].filter(Boolean).join(', ');

  if (cityStateZip) {
    addressParts.push(cityStateZip);
  }

  return addressParts.join(', ');
}

export const getFullAddress = (addressComponents) => {
  const { street, shortAddress, unit, city, state, zipCode } = addressComponents
  const parts = [shortAddress ?? street, unit ? `Unit ${unit}` : undefined, city, state, zipCode];
  return parts.filter(Boolean).join(", ");
};