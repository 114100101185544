import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useQueryClient } from '@tanstack/react-query';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isLoading: true,
    tokens: null
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        await refreshTokens();
        setAuthState(prev => ({ ...prev, isAuthenticated: true, isLoading: false }));
      } catch (error) {
        setAuthState({ isAuthenticated: false, isLoading: false, tokens: null });
      }
    };

    checkAuth()

    const hubListener = Hub.listen('auth', ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case 'signIn':
          setAuthState(prev => ({ ...prev, isAuthenticated: true }));
          refreshTokens();
          break;
        case 'signOut':
          setAuthState({ isAuthenticated: false, isLoading: false, tokens: null });
          queryClient.clear(); // Clear all queries
          break;
      }
    });

    return () => hubListener();
  }, [queryClient]);

  const refreshTokens = async () => {
    try {
      const session = await Auth.currentSession();
      setAuthState(prev => ({
        ...prev,
        tokens: {
          idToken: session.getIdToken().getJwtToken(),
          accessToken: session.getAccessToken().getJwtToken()
        }
      }));
    } catch (error) {
      console.error('Error refreshing tokens:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ ...authState, refreshTokens }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};