import React from "react";
import { Modal } from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { IoMdClose, IoMdPerson, IoMdCheckmark } from "react-icons/io";
import { Card } from "../../designSystem/Card";
import style from "./style.module.scss";
import { AppButton } from "../../designSystem/AppButton";
import { Auth } from "aws-amplify";
import { useState } from "react";
import copy from "copy-to-clipboard";
import ReactGA from "react-ga4";
import { MdEmail, MdLocalPhone, MdOutlineLocationOn } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";

const FormModal = (props) => {
  const {
    show,
    onHide,
    isVerified,
    isSubmitted,
    setIsVerified,
    setIsSubmitted,
    formData,
    setFormData,
    emailError,
    setEmailError,
    phoneError,
    setPhoneError,
    property,
  } = props;
  const [isCopied, setIsCopied] = useState(false);

  const reportShareLink = (category, label) => {
    ReactGA.event({
      action: "share_link",
      category: category,
      label: label,
    });
  };

  const removeCopyBanner = () => {
    setIsCopied(false);
  };

  const shareProperty = () => {
    if (!property._id) {
      Auth.currentAuthenticatedUser().then((userInfo) => {
        const customId = userInfo.attributes["custom:_id"];
        const link = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
        copy(link);
        reportShareLink("property/list", property.address);
        setIsCopied(true);
        setTimeout(removeCopyBanner, 1500);
      });
    } else {
      Auth.currentAuthenticatedUser().then((userInfo) => {
        const customId = userInfo.attributes["custom:_id"];
        const link = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
        copy(link);
        reportShareLink("property/list", property.address);
        setIsCopied(true);
        setTimeout(removeCopyBanner, 1500);
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleVerify = (e) => {
    e.preventDefault();
    let isValid = true;
    if (formData.email !== "") {
      if (!validateEmail(formData.email)) {
        setEmailError("Incomplete or Invalid email address");
        isValid = false;
      } else {
        setEmailError("");
      }
    }

    if (formData.phoneNumber !== "") {
      if (!validatePhone(formData.phoneNumber)) {
        setPhoneError("Incomplete or Invalid phone number");
        isValid = false;
      } else {
        setPhoneError("");
      }
    }
    if (isValid) {
      setIsVerified(true);
    }
  };
  const handleFinalSubmit = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      const customId = userInfo.attributes["custom:_id"];
      const LINKTOSHARE = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
      if (formData.email !== "") {
        const emailResponse = await fetch(
          `${process.env.REACT_APP_NODE_API_URL}/api/linkNotification/sendPropertyEmail`,
          {
            // mode: 'no-cors',
            method: "POST",
            body: JSON.stringify({
              to: formData.email,
              fullName: formData.fullName,
              link: LINKTOSHARE,
              propertyAddress: `${property.shortAddress}${
                property.unit ? ` Unit ${property.unit}` : ""
              }, ${property.city}, ${property.state} ${property.zipCode}`,
            }),
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        if (!emailResponse.ok) {
          throw new Error("Network response was not ok.");
        }
      }

      if (formData.phoneNumber !== "") {
        const textResponse = await fetch(
          `${process.env.REACT_APP_NODE_API_URL}/api/linkNotification/sendPropertyText`,
          {
            method: "POST",
            body: JSON.stringify({
              to: formData.phoneNumber,
              fullName: formData.fullName,
              link: LINKTOSHARE,
              propertyAddress: `${property.shortAddress}${
                property.unit ? ` Unit ${property.unit}` : ""
              }, ${property.city}, ${property.state} ${property.zipCode}`,
            }),
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        if (!textResponse.ok) {
          throw new Error("Network response was not ok.");
        }
      }

      // Get authorization header
      const auth = await Auth.currentSession();
      const headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      };

      // Store submission in database
      const storeUrl = `${process.env.REACT_APP_NODE_API_URL}/api/property/share-link`;
      const storeResponse = await fetch(storeUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property: property._id,
          name: formData.fullName ?? "",
          email: formData.email ?? "",
          phone: formData.phoneNumber ?? "",
        })
      })

      if (!storeResponse.ok) {
        throw new Error("Store response was not ok.");
      }

      setIsSubmitted(true);
    } catch (error) {
      console.error("Error during final submit:", error);
    }
  };

  const handleNextDisabled = () => {
    if (formData.fullName === "") return true;
    if (formData.email === "" && formData.phoneNumber === "") return true;

    return false;
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  const onPrev = () => {
    setIsVerified(false);
  };
  if (!property) return <div></div>;
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={`${style.container}`}
    >
      <button
        className={`border-0 bg-transparent position-absolute ${style["close-btn"]} z-1`}
        onClick={() => onHide()}
      >
        <IoMdClose color="#979797" size={20} />
      </button>
      <div
        className="position-relative py-5 px-3"
      >
        {isCopied ? (
          <div className={`${style.copied} translate-middle-x`}>
            <p>
              {" "}
              <IoIosCheckmarkCircle
                style={{ color: "#00BA08" }}
                size={14}
              />{" "}
              Property Link Copied Successfully
            </p>
          </div>
        ) : (
          <></>
        )}

        <div className="d-flex flex-column justify-content-center align-items-center w">
          {isVerified && isSubmitted ? (
            <>
              <h4
                style={{
                  fontFamily: "Poppins",
                  color: "#5271ff",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Property Link Sent
              </h4>
              <p
                style={{
                  fontFamily: "Poppins",
                  color: "#979797",
                  fontSize: "small",
                }}
              >
                We've sent the property link to your client
              </p>
            </>
          ) : isVerified ? (
            <>
              <h4 className="fw-bold text-delet-blue">Share Property Link</h4>
              <p
                style={{
                  fontFamily: "Poppins",
                  color: "#979797",
                  fontSize: "small",
                }}
              >
                Please check your client's information
              </p>
            </>
          ) : (
            <>
              <h4 className="fw-bold text-delet-blue">Share Property Link</h4>
              <p className="color-gray fs-7 text-nowrap">
                Fill in your client's details, and we'll send them the property
                link
              </p>
            </>
          )}
        </div>

        <div className="py-3">
          <Card className="d-flex flex-column gap-2 col-10 w-100">
            {!isVerified ? (
              <>
                <div className="position-relative w-100 opacity-50 fw-bold">
                  <p
                    className={`${style.iconLoc} d-flex gap-1 align-items-center`}
                  >
                    <MdOutlineLocationOn size={16} /> Location
                  </p>
                  <input
                    className={style.inputLoc}
                    onChange={handleChange}
                    value={`${property.shortAddress}${
                      property.unit ? ` Unit ${property.unit}` : ""
                    }, ${property.city}, ${property.state} ${property.zipCode}`}
                    name="location"
                    type="text"
                    readOnly
                  />
                </div>
                <div className={style.inputContainer}>
                  <p className={style.icon}>
                    <IoMdPerson size={16} /> Full Name
                  </p>
                  <input
                    className={style.input}
                    onChange={handleChange}
                    value={formData.fullName}
                    name="fullName"
                    type="text"
                  />
                </div>
                <div className={style.inputContainer}>
                  {!emailError ? (
                    <>
                      <p className={style.icon}>
                        <MdEmail size={16} /> Email
                      </p>
                      <input
                        className={style.input}
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        type="email"
                      />
                    </>
                  ) : (
                    <>
                      <p className={style.iconError}>
                        <MdEmail size={16} /> Email
                      </p>
                      <input
                        className={style.inputError}
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        type="email"
                      />
                      <div className={style.error}>
                        <RiErrorWarningFill
                          size={15}
                          color="red"
                          style={{ marginRight: "5px" }}
                        />{" "}
                        {emailError}
                      </div>
                    </>
                  )}
                </div>
                <div className={style.inputContainer}>
                  {!phoneError ? (
                    <>
                      <p
                        className={`${style.icon} d-flex align-items-center`}
                      >
                        <MdLocalPhone size={16} /> Phone Number
                      </p>
                      <input
                        className={style.input}
                        onChange={handleChange}
                        value={formData.phoneNumber}
                        name="phoneNumber"
                        type="number"
                        pattern="\d{10}"
                      />
                    </>
                  ) : (
                    <>
                      <p className={style.iconError}>
                        <MdLocalPhone size={16} /> Phone
                      </p>
                      <input
                        className={style.input}
                        onChange={handleChange}
                        value={formData.phoneNumber}
                        name="phoneNumber"
                        type="number"
                        pattern="\d{10}"
                      />
                      <div className={style.error}>
                        <RiErrorWarningFill
                          size={15}
                          color="red"
                          style={{ marginRight: "5px" }}
                        />{" "}
                        {phoneError}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <table>
                  <tbody>
                    <tr className={style.rows}>
                      <td className={style.leftTd}>
                        <CiLocationOn size={16} /> Location
                      </td>
                      <td className={style.rightTd}>
                        {`${property.shortAddress}${
                          property.unit ? ` Unit ${property.unit}` : ""
                        }, ${property.city}, ${property.state} ${
                          property.zipCode
                        }`}
                      </td>
                    </tr>
                    <tr className={style.rows}>
                      <td className={style.leftTd}>
                        <IoMdPerson size={16} /> Full Name
                      </td>
                      <td className={style.rightTd}>{formData.fullName}</td>
                    </tr>
                    <tr className={style.rows}>
                      <td className={style.leftTd}>
                        <MdEmail size={16} /> Email
                      </td>
                      <td className={style.rightTd}>
                        {formData.email !== "" ? formData.email : "NA"}
                      </td>
                    </tr>
                    <tr className={style.bottomRow}>
                      <td className={style.leftTd}>
                        <p
                          className={`d-flex align-items-center text-nowrap`}
                        >
                          <MdLocalPhone size={16} /> Phone Number
                        </p>
                      </td>
                      <td className={style.rightTd}>
                        {formData.phoneNumber !== ""
                          ? formData.phoneNumber
                          : "NA"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </Card>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center gap-2 px-5">
          {isVerified &&
          isSubmitted &&
          formData.email !== "" &&
          formData.phoneNumber !== "" ? (
            <>
              <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                <p style={{ fontFamily: "Poppins", fontSize: "small" }}>
                  <IoMdCheckmark color="#5271ff" /> Sent to{" "}
                  {formData.phoneNumber}
                </p>
                <p style={{ fontFamily: "Poppins", fontSize: "small" }}>
                  <IoMdCheckmark color="#5271ff" /> Sent to {formData.email}
                </p>
              </div>
            </>
          ) : isVerified &&
            isSubmitted &&
            formData.email === "" &&
            formData.phoneNumber !== "" ? (
            <>
              <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                <p style={{ fontFamily: "Poppins", fontSize: "small" }}>
                  <IoMdCheckmark color="#5271ff" /> Sent to{" "}
                  {formData.phoneNumber}
                </p>
              </div>
            </>
          ) : isVerified &&
            isSubmitted &&
            formData.email !== "" &&
            formData.phoneNumber === "" ? (
            <>
              <div className="d-flex flex-column justify-content-center align-items-center pb-5">
                <p style={{ fontFamily: "Poppins", fontSize: "small" }}>
                  <IoMdCheckmark color="#5271ff" /> Sent to {formData.email}
                </p>
              </div>
            </>
          ) : isVerified ? (
            <>
              <AppButton
                as="button"
                className="w-100"
                style={{ backgroundColor: "black" }}
                onClick={handleFinalSubmit}
              >
                {"Share Property Link >"}
              </AppButton>
              <AppButton
                as="button"
                className="w-100"
                onClick={onPrev}
                hierarchy="secondary"
                type="button"
              >
                {"< Go Back"}
              </AppButton>
            </>
          ) : (
            <>
              <AppButton
                as="button"
                onClick={handleVerify}
                className="w-100"
                disabled={handleNextDisabled()}
              >
                {"Continue >"}
              </AppButton>
              <AppButton
                as="button"
                className="w-100"
                hierarchy="secondary"
                type="button"
                disabled
              >
                {"< Go Back"}
              </AppButton>
              <div className="d-flex justify-content-center align-items-center">
                {/* <button className="mb-5 border-0 bg-transparent"onClick={shareProperty}>Copy Property Link</button> */}
              </div>
            </>
          )}
          <div className="d-flex justify-content-center align-items-center">
            <button className="border-0 bg-transparent" onClick={shareProperty}>
              Copy Property Link
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
