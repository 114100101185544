import { useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { getIglooLockboxList } from "../../../../api/lockbox";

const IglooIdSelect = ({ name, label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const {
    data: lockboxList,
    isLoading,
    //isError,
  } = useQuery({
    queryKey: ["igloo-lockboxes"],
    queryFn: getIglooLockboxList,
  });

  const handleChange = (selectedOption) => {
    setFieldValue("lockbox.id", selectedOption.id);
  };

  const getValue = () => {
    const lockbox = lockboxList?.find((lockbox) => lockbox.id === field.value);
    return lockbox ? { value: lockbox.id, label: lockbox.name } : null;
  };

  const formatOptionLabel = ({ value, name }, { context }) => {
    if (context === 'value') {
      return value;
    } else {
      return name;
    }
  };

  return (
    <>
      <Select
        {...props}
        id={name}
        name={name}
        options={lockboxList}
        getOptionLabel={(lockbox) => lockbox.name}
        getOptionValue={(lockbox) => lockbox.id}
        value={getValue()}
        onChange={handleChange}
        onBlur={() => field.onBlur({ target: { name } })}
        className="h-100 text"
        isDisabled={isLoading}
        formatOptionLabel={formatOptionLabel}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default IglooIdSelect;
