import { Modal } from "react-bootstrap";

interface ImagePreviewModalProps {
    show: boolean;
    imageUrl: string;
    onHide: () => void;
}

export default function ImagePreviewModal({
    show,
    imageUrl,
    onHide,
}: ImagePreviewModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            dialogClassName="border-0 bg-transparent"
            contentClassName="border-0 bg-transparent"
        >
            <Modal.Body className="p-0 bg-transparent position-relative">
                <img
                    src={imageUrl}
                    alt="Preview"
                    style={{ width: "100%", height: "auto", display: "block" }}
                />
                <button
                    onClick={onHide}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "18px",
                        background: "transparent",
                        border: "none",
                        color: "#fff",
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        cursor: "pointer",
                    }}
                    aria-label="Close preview"
                >
                    &#x2715;
                </button>
            </Modal.Body>
        </Modal>
    );
}
