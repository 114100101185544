import { Auth } from "aws-amplify";
import axios from "axios";

export const getCompaniesList = async ({ pageParam = 1, sortField = 'name', sortDirection = 'asc', searchTerm = '', limit = 10 }) => {

  const token = await Auth.currentSession()
  const response = await axios.get(process.env.REACT_APP_NODE_API_URL + '/api/companies/superadmin/list', {
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    },
    params: {
      page: pageParam,
      limit,
      sortField,
      sortDirection,
      search: searchTerm
    }
  });

  // Return both the data and pagination info needed for next page
  return {
    companies: response.data.companies,
    totalCount: response.data.totalCount,
    currentPage: pageParam,
    totalPages: Math.ceil(response.data.totalCount / limit),
    nextPage: pageParam + 1
  };
};