import "./MultiselectSearch.scss"
import React from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { PiHouse } from "react-icons/pi";
import { FaAngleDown } from "react-icons/fa6";


export const MultiselectSearch = ({
  data,
  selectedProperties,
  handleSelectedProperties,
  disable
}) => {
  return (
    <Multiselect
      options={data}
      displayValue="displayValue"
      showCheckbox={true}
      emptyRecordMsg="No properties found"
      avoidHighlightFirstOption
      hidePlaceholder
      // showArrow
      // customArrow={false}
      onSelect={handleSelectedProperties}
      onRemove={handleSelectedProperties}
      selectedValues={selectedProperties}
      disable={disable}
      
    />
  );
};
export const MultiselectPropertySearch = ({
  data,
  selectedProperties,
  handleSelectedProperties,
  disable
}) => {
  return (
    <Multiselect
      options={data}
      displayValue="shortAddress"
      showCheckbox={true}
      emptyRecordMsg="No properties found"
      avoidHighlightFirstOption
      hidePlaceholder
      showArrow
      customArrow={<PiHouse className="select-icon" />}
      onSelect={handleSelectedProperties}
      onRemove={handleSelectedProperties}
      selectedValues={selectedProperties}
      disable
    />
  );
};
