import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getCompanies = async ({ page, limit, filters, sort, select, isAdmin = false }) => {
  const adminApi = isAdmin ? "/superadmin" : "";
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/companies${adminApi}/search`;

  const requestBody = {
    page,
    limit,
    filters,
    sort,
    select,
  };

  try {
    const auth = await Auth.currentSession();
    const response = await axios.post(baseUrl, requestBody, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};

export const postCreateCompany = async (company) => {
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/companies/create`;
  try {
    const auth = await Auth.currentSession();
    const response = await axios.post(baseUrl, company, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
        "Content-Type": "multipart/form-data"
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
}

export const getCompany = async (companyId) => {
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/companies/get/${companyId}`;

  try {
    const auth = await Auth.currentSession();
    const response = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching company:', error);
    throw error;
  }
};

export const patchUpdateCompany = async ({ updatedFields, companyId }) => {
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/companies/update/${companyId}`;
  try {
    const auth = await Auth.currentSession();
    const response = await axios.patch(baseUrl, updatedFields, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
        "Content-Type": "multipart/form-data"
      },
    });

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
}