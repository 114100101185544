import { Link } from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaClockRotateLeft } from "react-icons/fa6";
import useViewport from "../../../../../../../hooks/useViewport";

export default function EntrySystemTabs({
    currentTab,
    systemTabUrl,
}) {
    // Responsive hook
    const { width: screenWidth } = useViewport();

    // Navigation tabs
    const tabs = [
        {
            key: "info",
            label: "System Info",
            icon: <IoMdInformationCircleOutline size={18} />,
        },
        {
            key: "codes",
            label: "Codes History",
            icon: <FaClockRotateLeft size={15} />,
        },
        // {
        //     key: "history",
        //     label: "System History",
        //     icon: <MdOutlineArticle size={20} />,
        // },
    ];    

    // Mobile classes
    let classSelected = "text-primary btn-outline-primary";
    let classNotSelected = "text-dark-emphasis border";
    let classExtra = "";
    let classBorder = "";

    // Desktop classes
    if (screenWidth >= 768) {
        classSelected = "selected";
        classNotSelected = "";
        classExtra = "text-dark-emphasis border-0 text-nowrap";
        classBorder = "border border-top-0";
    }

    return (
        <div className={`${classBorder} d-flex flex-row justify-content-between py-3 flex-md-column justify-content-md-start w-md-content px-md-3 gap-md-3`}>
            {tabs.map(({ key, label, icon }) => (
                    <Link
                        key={key}
                        to={systemTabUrl("systemTab", key)}
                        preventScrollReset={true}
                        className={`${classExtra} btn so-option px-3 bg-white d-flex align-items-center gap-2 fw-medium ${
                            currentTab === key ? classSelected : classNotSelected
                        }`}
                    >
                        <span className="d-none d-md-inline">{icon}</span>
                        {label}
                    </Link>
                ))}
        </div>
    );
}
