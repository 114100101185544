import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../components/AdminSideBar";
import CompanyListTable from "./components/CompanyListTable";
import { Button, Container } from "react-bootstrap";
import { getCompaniesList } from "../../../api/delet/companies/get";
import debounce from "lodash/debounce";
import { useCompaniesInfiniteQuery } from "../../../hooks/companies/useCompaniesInfiniteQuery";
import { useProfile } from "../../../context/UserProfileContext";

const AdminCompaniesPage = () => {
  const { data: profile } = useProfile();
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearch = useCallback(
    debounce((term) => {
      setDebouncedSearchTerm(term);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSetSearch(searchTerm);

    return () => {
      debouncedSetSearch.cancel();
    };
  }, [searchTerm, debouncedSetSearch]);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useCompaniesInfiniteQuery({
    sortField,
    sortDirection,
    debouncedSearchTerm,
    limit,
    getCompaniesList,
  });

  // Handle sort change from the table component
  const handleSort = (field, direction) => {
    setSortField(field);
    setSortDirection(direction);
  };

  // Handle search change from the table component
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const onLimitChange = (newLimit) => setLimit(newLimit);

  // Flatten the companies data from all pages
  const allCompanies = data?.pages.flatMap((page) => page.companies) || [];
  const totalCompanies = data?.pages[0]?.totalCount || 0;

  return (
    <Sidebar>
      <div>
        {
          <>
            <CompanyListTable
              companies={allCompanies}
              totalCompanies={totalCompanies}
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={handleSort}
              searchTerm={searchTerm}
              onSearch={handleSearch}
              onRefresh={refetch}
              isLoading={isLoading}
              isError={isError}
              onLimitChange={onLimitChange}
              limit={limit}
              isSuperadmin={profile?.isSuperadmin}
            />

            {hasNextPage && (
              <div className="text-center my-4">
                <Button
                  variant="primary"
                  onClick={() => fetchNextPage()}
                  disabled={isFetchingNextPage}
                >
                  {isFetchingNextPage
                    ? "Loading more..."
                    : "Load More Companies"}
                </Button>
              </div>
            )}
          </>
        }
      </div>
    </Sidebar>
  );
};

export default AdminCompaniesPage;
