import style from './style.module.scss';

type Props = {
  circleContent: React.ReactNode;
  // title: string;
  stepNumber: number;
  state: 'default' | 'selected' | 'completed';
  className?: string;
};

export const StepNumberLabel = ({
  state,
  circleContent,
  stepNumber,
  // title,
  className,
}: Props) => {
  return (
    <div className={`${style.container} ${style[state]} ${className}`}>
      <div className={`${style.circleWrapper} ${stepNumber === 5 ? style.hideLineForStep3 : ''}`}>
        <span className={`${style.circle} ${style.circle1}`}>{circleContent}</span>
        <div className={`${style.line} ${style.line1}`}></div>
      </div>
    </div>
  );
};
