import { Box, Tab, Tabs } from "@mui/material";

function a11yProps(index: number) {
    return {
        id: `system-tab-${index}`,
        "aria-controls": `system-tabpanel-${index}`,
    };
}

interface SystemTabsProps {
    tabValue: number;
    setTabValue: (tab: number) => void;
}

export default function SystemTabs({ tabValue, setTabValue }: SystemTabsProps) {
    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ borderBottom: 2, borderColor: "divider" }} className="mb-4">
            <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab label="DELET KIT" {...a11yProps(0)} />
                <Tab label="ENTRY SYSTEM" {...a11yProps(1)} />
            </Tabs>
        </Box>
    );
}
