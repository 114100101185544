import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { deletePassCodes, getCodeHistory } from "../../../../api/keypad";
import { Button, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { TfiReload } from "react-icons/tfi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { IoIosAlert } from "react-icons/io";
import CodeRowSkeleton from "./CodeRowSkeleton";
import { MAX_NUMBER_CODES } from "../../../../constants";
import MobileCodeRow from "./Mobile-CodeRow";
import { MdDelete } from "react-icons/md";
import { FaCirclePlus } from "react-icons/fa6";

const sortByInitState = Object.freeze({
  name: "",
  type: "asc",
});

const getInitialFilters = () => ({
  status: [],
  type: [],
  text: "",
});

const MobileCodeHistory = ({ keypadId, handleCreateCode, vendor }) => {
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState(getInitialFilters());

  const sortBy = sortByInitState;

  const [selected, setSelected] = useState([]);
  const MySwal = withReactContent(Swal);

  const {
    data: codesList = [],
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ["keypad", keypadId, "code-history"],
    queryFn: async () =>
      await getCodeHistory({ keypadId, filter: "booking_codes", vendor }),
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: deleteCodes, isPending: deletingCodes } = useMutation({
    mutationFn: () => deletePassCodes(selected, keypadId),
    onSuccess: (deletedCodes) => {
      queryClient.setQueryData(["keypad", keypadId, "code-history"], (prev) =>
        prev.filter(
          (code) =>
            !deletedCodes.some((deletedCode) => deletedCode.id === code.id)
        )
      );

      toast.success("Selected codes have been removed successfully");
    },

    onError: (err) => {
      const message = err.message || "Something went wrong.";
      const partiallyDeletedCodes = err.deletedCodes || [];

      if (partiallyDeletedCodes.length > 0) {
        toast.error(
          `Some codes were deleted, but an error occurred: ${message}`
        );
      } else {
        toast.error(`Failed to delete codes: ${message}`);
      }
      console.log("Error deleting codes: ", err);
      console.log("Partially deleted codes: ", partiallyDeletedCodes);
    },
    onSettled: () => {
      setSelected([]);
      setFilters(getInitialFilters());
    },
  });

  if (error) {
    toast.error("Error getting the code history");
    return (
      <div>
        An error occurred trying to get your code history{" "}
        <Button onClick={refetch} disabled={deletingCodes}>
          retry
        </Button>
      </div>
    );
  }

  function isSubstringIgnoringSpaces(mainText, searchText) {
    const cleanMainText = mainText.toLowerCase().replace(/\s+/g, "");
    const cleanSearchText = searchText.toLowerCase().replace(/\s+/g, "");

    return cleanMainText.includes(cleanSearchText);
  }

  const filteredCodes = codesList.filter((code) => {
    const { status, type, text } = filters;
    const { status: codeStatus, type: codeType } = code;
    if (
      !isSubstringIgnoringSpaces(code.name, text) &&
      !isSubstringIgnoringSpaces(code.password, text) &&
      !isSubstringIgnoringSpaces(
        format(new Date(code.startTime ?? code.createTime), "MM/dd/yyyy"),
        text
      ) &&
      (code.endTime
        ? !isSubstringIgnoringSpaces(
            format(new Date(code.endTime), "MM/dd/yyyy"),
            text
          )
        : true)
    )
      return false;
    if (status.length > 0 && !status.includes(codeStatus)) return false;

    if (type.length > 0 && !type.includes(codeType)) return false;

    return true;
  });

  const sortedCodes = sortBy.name
    ? filteredCodes.sort((a, b) => {
        const { name, type } = sortBy;
        const dateA =
          name === "start"
            ? a.startTime ?? a.createTime
            : a.endTime || Infinity;
        const dateB =
          name === "start"
            ? b.startTime ?? b.createTime
            : b.endTime || Infinity;

        if (type === "asc") {
          return dateA - dateB;
        } else if (type === "desc") {
          return dateB - dateA;
        }
        return 0;
      })
    : filteredCodes;

  const handleSelect = (value, checked) => {
    if (checked) {
      setSelected([...selected, value]);
    } else {
      setSelected((prev) => {
        const index = selected.findIndex((code) => code.id === value.id);
        const newSelected = [...prev];
        newSelected.splice(index, 1);
        return newSelected;
      });
    }
  };

  const dailyCode = codesList.find((code) => {
    const today = new Date();
    return (
      code.name ===
      `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`
    );
  });

  const numberOfCodes = codesList.length;
  const diffBeforeMaxCapacity = MAX_NUMBER_CODES - numberOfCodes;
  const isReachingMaxCapacity = diffBeforeMaxCapacity <= 10;

  return (
    <section className="rounded d-flex flex-column ">
      {isReachingMaxCapacity ? (
        <div className="d-flex bg-danger bg-opacity-10 px-3 mb-2 py-3 d-flex gap-2 align-items-center rounded-3">
          <div>
            <IoIosAlert color="red" size="30" />
          </div>
          <p className="fs-7 ">
            <strong>Your code capacity is almost full!</strong> <br />
            <span>
              You are {diffBeforeMaxCapacity} codes away from reaching the
              maximum limit. Please delete any expired codes to make more space
            </span>
          </p>
        </div>
      ) : null}
      <div className="d-flex align-items-center py-2 justify-content-between">
        <div className="col-7">
          <input
            type="text"
            class="form-control py-1"
            placeholder="Search by name or code"
            value={filters.text}
            onChange={(e) => setFilters({ ...filters, text: e.target.value })}
          />
        </div>

        <div className="col-5 d-flex justify-content-around">
          <Button
            className="btn-outline-dark "
            onClick={refetch}
            disabled={isLoading || isFetching || deletingCodes}
          >
            <TfiReload size={"16px"} />
          </Button>
          <Button
            style={{ background: "#1f2327" }}
            className="btn-outline-dark "
            variant="light"
            onClick={handleCreateCode}
            disabled={isLoading || isFetching || deletingCodes}
          >
            <FaCirclePlus size="20px" color="white" />
          </Button>
          <Button
            className="btn-outline-dark "
            disabled={
              selected.length === 0 || isLoading || isFetching || deletingCodes
            }
            onClick={() =>
              MySwal.fire({
                title: "<h4 class='fs-5'>You're About to Remove This Code</h4>",
                html: "<p class='text-body-tertiary fs-6'>Are you sure you want to proceed?<br>Please note that this action cannot be undone.</p>",
                iconHtml: <IoIosAlert color="#5271ff" />,
                showCancelButton: true,
                cancelButtonText: "Close",
                confirmButtonText: "Remove",
                confirmButtonColor: "#5271ff",
                reverseButtons: true,
                customClass: {
                  icon: "border-0 my-0 py-2",
                  popup: "px-5 pt-2",
                  title: "pt-0",
                  htmlContainer: "pt-2",
                  actions: "gap-3 w-100 flex-nowrap",
                  confirmButton: "w-100 py-2 rounded-3",
                  cancelButton:
                    "w-100 bg-white text-black border border-secondary-subtle rounded-3",
                },
              }).then((result) => {
                if (result.isConfirmed) deleteCodes();
              })
            }
          >
            {deletingCodes ? (
              <Spinner animation="border" role="status" as="span" size="sm" />
            ) : (
              <MdDelete size={20} color="red" />
            )}
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center py-2 justify-content-between"></div>
      <div className="code-history-list d-flex flex-column gap-3">
        {isLoading || isFetching ? (
          <div className="d-flex flex-column gap-4">
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
          </div>
        ) : (
          <>
            {dailyCode && (
              <MobileCodeRow
                code={dailyCode}
                handleSelect={handleSelect}
                selected={selected}
                disabled={deletingCodes}
                dailyCode
              />
            )}
            {sortedCodes.map((code) => (
              <MobileCodeRow
                code={code}
                handleSelect={handleSelect}
                selected={selected}
                disabled={deletingCodes}
                key={code.password}
              />
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default MobileCodeHistory;
