import { getFullAddress } from "../helper";

export const design = {
  deleteMainColor: "#5271FF"
}

export const keypadInstructions = {
  switchbot: {
    step2_text: "Enter the code on the keypad and press the Check Button. Wait for a beep, then open the door.",
    keypad: '/images/switchbot/keypad.png',
    kpOpen: '/images/switchbot/kp-open.png',
    kpClose: '/images/switchbot/kp-lock.png'
  },
  yale: {
    step2_text: "Press the Yale button on top of the keypad, enter the code and press the Check button. Wait for a beep, then open the door.",
    keypad: '/images/yale/keypad.png',
    kpOpen: '/images/yale/kp-lock.png',
    kpClose: '/images/yale/kp-open.svg'
  },
  august: {
    step2_text: "Enter the code on the keypad and press the August button.",
    keypad: '/images/august/keypad.png',
    kpOpen: '/images/august/kp-lock.png',
    kpClose: '/images/august/kp-lock.png'
  }
};

export const bookingOptions = [
  { value: "scheduled", label: "Scheduled" },
  { value: "show", label: "Show" },
  { value: "missed", label: "No show" },
  { value: "rescheduled", label: "Rescheduled" },
  { value: "cancelled", label: "Cancelled" },
  { value: "incomplete", label: "Incomplete" },
  { value: "archived", label: "Archived" }
];

export const outcomeOptions = [
  { value: "", label: "Outcome", color: "" },
  { value: "interested", label: "Interested", color: "scheduled" },
  { value: "not interested", label: "Not Interested", color: "cancelled" },
  { value: "send application", label: "Application Sent", color: "rescheduled" },
  { value: "follow up sent", label: "Follow Up Sent", color: "missed" },
  { value: "applied", label: "Applied", color: "show" },
];

export const deviceActions = {
  "LOCK_LOCK": {
    "device": "LOCK",
    "action": "LOCK"
  },
  "LOCK_UNLOCK": {
    "device": "LOCK",
    "action": "UNLOCK"
  },
  "KEYPAD_LOCK": {
    "device": "KEYPAD",
    "action": "LOCK"
  },
  "KEYPAD_UNLOCK": {
    "device": "KEYPAD",
    "action": "UNLOCK"
  },
  "KEYPAD_LOAD": {
    device: "KEYPAD",
    action: "LOAD"
  },
  "KEYPAD_DELETE": {
    device: "KEYPAD",
    action: "DELETE"
  },
  "KEYPAD_UPDATE": {
    device: "KEYPAD",
    action: "UPDATE"
  }
}

export const notificationMessages = {
  // Lock actions (LOCK_LOCK, LOCK_UNLOCK, KEYPAD_LOCK, KEYPAD_UNLOCK)
  'LOCK': ({ action, property }) => `The door was ${action.toLowerCase()}ed at ${getFullAddress(property)}`,
  'UNLOCK': ({ action, property }) => `The door was ${action.toLowerCase()}ed at ${getFullAddress(property)}`,

  // Keypad code actions (KEYPAD_LOAD, KEYPAD_DELETE, KEYPAD_UPDATE)
  'LOAD': ({ property }) => `A new access code was created for ${getFullAddress(property)}`,
  'DELETE': ({ property }) => `An access code was deleted from ${getFullAddress(property)}`,
  'UPDATE': ({ property }) => `An access code was updated for ${getFullAddress(property)}`,
  'OPEN': ({ property }) => `Door at ${getFullAddress(property)} was opened`,
  'CLOSED': ({ property }) => `Door at ${getFullAddress(property)} was closed`
};

export const augustId = {
  autorelock: "Autorelock",
  manuallock: "Manual Lock"
}

export const MAX_NUMBER_CODES = 90

export const CUSTOMER_SUPP_PHONE = "(310) 598-5553"

export const VIDEO_EXTENSIONS = [
  'mp4',
  'mov',
  'avi',
  'mkv',
  'webm',
  '3gp',
  'wmv',
  'flv'
];

export const IMAGE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'svg',
  'tiff',
  'bmp',
  'ico',
  'heic',
  'avif'
];

export const TYPES_MAP = {
  // Images
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  tiff: 'image/tiff',
  bmp: 'image/bmp',
  ico: 'image/x-icon',
  heic: 'image/heic',
  avif: 'image/avif',

  // Videos
  mp4: 'video/mp4',
  mov: 'video/quicktime',
  avi: 'video/x-msvideo',
  mkv: 'video/x-matroska',
  webm: 'video/webm',
  '3gp': 'video/3gpp',
  wmv: 'video/x-ms-wmv',
  flv: 'video/x-flv'
};

export const TIMEZONES = {
  'Pacific/Honolulu': 'Hawaii (GMT-10:00)',
  'America/Anchorage': 'Alaska (GMT-09:00)',
  'America/Los_Angeles': 'Pacific Time (GMT-08:00)',
  'America/Denver': 'Mountain Time (GMT-07:00)',
  'America/Chicago': 'Central Time (GMT-06:00)',
  'America/New_York': 'Eastern Time (GMT-05:00)',
  'America/Halifax': 'Atlantic Time (GMT-04:00)',
  'America/Sao_Paulo': 'São Paulo (GMT-03:00)',
  'Atlantic/South_Georgia': 'Mid-Atlantic (GMT-02:00)',
  'Atlantic/Azores': 'Azores (GMT-01:00)',
  'Europe/London': 'London (GMT+00:00)',
  'Europe/Berlin': 'Berlin (GMT+01:00)',
  'Europe/Helsinki': 'Helsinki (GMT+02:00)',
  'Europe/Moscow': 'Moscow (GMT+03:00)',
  'Asia/Dubai': 'Dubai (GMT+04:00)',
  'Asia/Karachi': 'Karachi (GMT+05:00)',
  'Asia/Dhaka': 'Dhaka (GMT+06:00)',
  'Asia/Bangkok': 'Bangkok (GMT+07:00)',
  'Asia/Singapore': 'Singapore (GMT+08:00)',
  'Asia/Tokyo': 'Tokyo (GMT+09:00)',
  'Australia/Sydney': 'Sydney (GMT+10:00)',
  'Pacific/Noumea': 'New Caledonia (GMT+11:00)',
  'Pacific/Auckland': 'Auckland (GMT+12:00)',
  'America/Buenos_Aires': 'Buenos Aires (GMT-03:00)',
  'America/Bogota': 'Bogotá (GMT-05:00)',
  'America/Lima': 'Lima (GMT-05:00)',
  'America/Caracas': 'Caracas (GMT-04:00)',
  'America/La_Paz': 'La Paz (GMT-04:00)',
  'America/Asuncion': 'Asunción (GMT-04:00, DST GMT-03:00)',
  'America/Santiago': 'Santiago (GMT-04:00, DST GMT-03:00)',
  'America/Montevideo': 'Montevideo (GMT-03:00)',
  'America/Guayaquil': 'Guayaquil (GMT-05:00)'
};

