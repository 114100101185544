import React from 'react'
import { useSearchParams } from 'react-router-dom';
import SelectedCameraCard from './Cameras/components/SelectedCameraCard';
import { DateTime } from 'luxon'
import DayColumn from './Cameras/components/DayColumn';
import Camera3dEye from '../components/Camera3dEye';
import EzvizCamera from '../components/EzvizCamera';

const CameraRecordings = ({ cameras }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCameras = searchParams.get("recordings").split(",")
    const pastSevenDays = Array.from({ length: 7 }, (_, i) =>
        DateTime.now().minus({ days: 6 - i })
    );

    const selectedDay = searchParams.get("selectedDay")

    const today = pastSevenDays[6];

    const selectedCamera = Number(searchParams.get("selectedCamera"))

    const onDateSelect = (date) => {
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('selectedDay', date);
            return newParams;
        });
    }

    const selectCamera = (index) => {
        setSearchParams(prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('selectedCamera', index);
            return newParams;
        });
    }
    const isTodaySelected = selectedDay === today.toFormat("yyyy-MM-dd")

    return (
        <section className='d-flex gap-5 w-100 border flex-grow-1 px-2 pt-3'>
            <div className='d-flex flex-column w-100 gap-3'>
                <div className='d-flex justify-content-end gap-2'>
                    {pastSevenDays.map((date, index) => (
                        <DayColumn key={date} date={date} onSelect={onDateSelect} selected={selectedDay === date.toFormat("yyyy-MM-dd")}/>
                    ))}
                </div>
                {cameras.map((camera, index) => {
                    if (selectedCameras.includes(camera.serial)) {
                        return <SelectedCameraCard camera={camera} onClick={() => selectCamera(index)} />
                    }
                    return null
                })}
            </div>
            <div className='w-100 h-100'><EzvizCamera camera={cameras[selectedCamera]} startTime={`${searchParams.get("selectedDay")}  00:00:00`} stopTime={`${searchParams.get("selectedDay")} 23:59:59`} type={3} protocol={1} /></div>
        </section>
    )
}

export default CameraRecordings