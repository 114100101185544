import React from "react";
import BuildingForm from "../../Buildings/BuildingForm";
import Sidebar from "../components/AdminSideBar";
import { useUsersList } from "../../../hooks/useAdminUserList";
import PageHeader from "../../../components/Headers/PageHeader";
import { useMutation } from "@tanstack/react-query";
import { postCreateBuilding } from "../../../api/building";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../context/UserProfileContext";

function CreateBuildingAdmin() {
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const {
    data: users,
    isLoading: isLoadingUsers,
  } = useUsersList({
    role: JSON.stringify({
      $regex: "owner|superadmin",
      $options: "i",
    }),
  });

  const { mutateAsync: onSubmit, isPending: submitting } = useMutation({
    mutationKey: ["create", "building"],
    mutationFn: (building) => postCreateBuilding(building),
    onSuccess: (data) => navigate(`/admin/building/edit/${data.id}`),
  });

  return (
    <Sidebar>
      <PageHeader>
        <h6>Create building</h6>
      </PageHeader>
      <div className="p-lg-5">
        <BuildingForm
          users={users}
          isLoadingUsers={isLoadingUsers}
          onSubmit={onSubmit}
          submitting={submitting}
          disabled={!profile?.isManager}
        />
      </div>
    </Sidebar>
  );
}

export default CreateBuildingAdmin;
