import { FaLock, FaUnlock } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";

/**
 * status: Bool (Lock, Unlock)
 */
const LockButtonMobile = ({
  status,
  bridgeStatus,
  onClick,
  loading,
  isRefreshing,
  lockError,
  currentLock,
  lockUncalibrated = false,
}) => {
  const renderTooltip = (props) => (
    <Tooltip className="status-tooltip" {...props}>
      {bridgeStatus === "offline" || !currentLock
        ? "Lock Offline"
        : "Updating may take up to 20 seconds"}
    </Tooltip>
  );
  return !currentLock ? (
    <OverlayTrigger
      placement="bottom"
      delay={{ hide: 700 }}
      overlay={renderTooltip}
    >
      <div
        className="rounded-5 d-flex justify-content-center align-items-center"
        style={{
          background: "#BBBBBB",
          height: "41px",
          width: "41px",
        }}
      >
        <FaUnlock size={15} color="white" />
      </div>
    </OverlayTrigger>
  ) : (
    <OverlayTrigger
      placement="bottom"
      delay={{ hide: 700 }}
      overlay={renderTooltip}
    >
      <div style={{ display: "inline-block" }}>
        <Button
          className={`d-flex align-items-center border-none px-0   justify-content-end ${
            status ? "" : "flex-row-reverse"
          } ${isRefreshing && loading ? "rotate" : ""}`}
          style={{ backgroundColor: "rgb(234, 234, 235)", border: "none" }}
          onClick={onClick}
          disabled={
            lockUncalibrated ||
            loading ||
            lockError !== "" ||
            bridgeStatus === "offline"
          }
        >
          {
            <>
              <span
                className={`rounded-5 d-flex justify-content-center align-items-center ${
                  bridgeStatus === "offline"
                    ? ""
                    : status
                    ? "bg-green"
                    : "bg-red"
                }`}
                style={{
                  background: bridgeStatus === "offline" ? "#BBBBBB" : "",
                  height: "41px",
                  width: "41px",
                }}
              >
                {status ? (
                  <FaUnlock size={15} color="white" />
                ) : (
                  <FaLock size={15} color="white" />
                )}
              </span>
            </>
          }
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default LockButtonMobile;
