import { Card } from '../../../../../designSystem/Card';
import placeHolderImage from '../../../../../assests/images/1-placeholder.png';
import style from './style.module.scss';
import getImageUrl from '../../../../../helper/checkImageFormat';

export const SelectedPropertyCard = ({ property, className, onSeeDetails }) => {
  return (
    <Card className={`${style.card} ${className}`}>
      <div className={`${style.infoGroup} mb-2`}>
        <img
          src={getImageUrl(property?.images?.[0]) ?? placeHolderImage ?? placeHolderImage}
          alt=''
          className={style.img}
        />
        <div className='d-flex flex-column'>
          {/* <Card.SubTitle>Booking for</Card.SubTitle> */}
          <Card.Title className={style.titleCard}>{`${property?.shortAddress}${property?.unit ? ` Unit ${property?.unit}` : ''}`}</Card.Title>
          <Card.SubTitle className={style.subtitleCard}>{property?.city}, {property?.state} {property?.zipCode}</Card.SubTitle>
        </div>
      </div>
      <Card.Link
        className={style.link}
        onClick={onSeeDetails}
        style={{ marginLeft: 'auto' }}
        as='button'
      >
        See details
      </Card.Link>
    </Card>
  );
};
