import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useUsersList } from "../../../../hooks/useAdminUserList";
import Select from "react-select";
import { Link } from "react-router-dom";

const CompanyForm = ({ initState, onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initState || {
      name: "",
      units: "",
      phone: "",
      email: "",
      description: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      users: "",
    },
  });

  const {
    data: users = [],
    isLoading: isLoadingUsers,
  } = useUsersList({});

  return (
    <Card className="shadow-sm">
      <Card.Header as="h5" className="bg-primary text-white">
        Company Information
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Company Name */}
          <Form.Group className="mb-3">
            <Form.Label>Company Name*</Form.Label>
            <Form.Control
              type="text"
              {...register("name", { required: "Company name is required" })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Logo and ID Image */}
          <Row className="mb-3">
            <Col md={6} className="d-none">
              <Form.Group>
                <Form.Label>Company Logo</Form.Label>
                <Form.Control
                  type="file"
                  {...register("logoUrl")}
                  placeholder="COMPANY LOGO"
                />
                <Form.Text className="text-muted">
                  Upload your company logo
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Units</Form.Label>
                <Form.Select {...register("units")}>
                  <option value="0-1,000 units">0-1,000 units</option>
                  <option value="1,000-10,000 units">1,000-10,000 units</option>
                  <option value="10,000+ units">10,000+ units</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Business ID Image</Form.Label>
                <Form.Control type="file" {...register("idImage")} />
                <Form.Text className="text-muted">
                  Upload an image of your business ID
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          {/* Units */}

          {/* Contact Details */}
          <Card className="mb-3 d-none">
            <Card.Header as="h6">Contact Information</Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      {...register("email", {
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" {...register("phone")} />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* Address */}
          <Card className="mb-3 d-none">
            <Card.Header as="h6">Address</Card.Header>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>Street</Form.Label>
                <Form.Control type="text" {...register("address.street")} />
              </Form.Group>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" {...register("address.city")} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>State/Province</Form.Label>
                    <Form.Control type="text" {...register("address.state")} />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("address.postalCode")}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      {...register("address.country")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Form.Group className="mb-3 d-none">
            <Form.Label>Add users to company</Form.Label>
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti
                  options={users.map((user) => ({
                    value: user._id,
                    label: user.email,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={isLoadingUsers}
                  placeholder="Select user(s)"
                  {...field}
                  onChange={(selectedOptions) => {
                    // Store only the _id values in the form
                    field.onChange(
                      selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : []
                    );
                  }}
                  // Transform value from array of IDs to array of objects for react-select
                  value={
                    field.value
                      ? field.value
                          .map((id) => {
                            const user = users.find((u) => u._id === id);
                            return user
                              ? { value: user._id, label: user.email }
                              : null;
                          })
                          .filter(Boolean)
                      : []
                  }
                />
              )}
            />
          </Form.Group>

          {/* Description */}
          <Form.Group className="mb-3 d-none">
            <Form.Label>Company Description</Form.Label>
            <Form.Control as="textarea" rows={3} {...register("description")} />
          </Form.Group>

          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <Button
              as={Link}
              variant="secondary"
              className="me-md-2"
              to="/admin/company/list"
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              Save Company
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CompanyForm;
