import { Box, Tab, Tabs } from "@mui/material";

interface LeadsTabsProps {
    tabValue: string;
    handleTabChange: (_event: React.SyntheticEvent, newValue: string) => void;
}

export function LeadsTabs({ tabValue, handleTabChange }: LeadsTabsProps) {
    return (
        <Box sx={{ borderBottom: 2, borderColor: "divider" }} className="mb-3">
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab value="leads" label="LEADS" />
                <Tab value="incomplete" label="INCOMPLETE" />
            </Tabs>
        </Box>
    );
}
