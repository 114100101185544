import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { getLockNotifications } from "../../api/notifications";
import Loading from "../Banners/Loading";
import { format } from "date-fns-tz";
import { augustId, notificationMessages } from "../../constants";
import { useInView } from "react-intersection-observer";

const MobileLockHistory = ({ lockId }) => {

  const queryKey = ["lock-history"];
  const {
    data: notifications,
    isLoading,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam = 1 }) => getLockNotifications(pageParam, lockId),
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasMore ? pages.length + 1 : undefined,
  });

  const observer = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <span>Error getting notifications</span>
  ) : (
    <div className="overflow-x-auto">
      <ul className="d-flex flex-column list-unstyled ps-3">
   
        <li className="row border-bottom py-2 justify-content-between align-items-center">
          <h4 className="col-3 text-center" style={{ fontSize: '14px' }}>Date</h4>
          <h4 className="col-3 text-center" style={{ fontSize: '14px' }}>Action</h4>
          <h4 className="col-3 text-center" style={{ fontSize: '14px' }}>Message</h4>
          <h4 className="col-3 text-center" style={{ fontSize: '14px' }}>User</h4>
        </li>
        {notifications.pages.map((notificationsPage, page) =>
          notificationsPage.data.map((notification, index) => {
            const [device, action] = notification.type.split("_");
            return (
              <li
                className="row border-bottom py-2 justify-content-between align-items-center"
                ref={
                  page === notifications.pages.length - 1 &&
                  index === notificationsPage.data.length - 1
                    ? observer.ref
                    : undefined
                }
                key={notification.id}
              >
                <span className="col-3 d-flex align-items-center text-center" style={{ fontSize: '10px' }}>
                  {format(notification.createdAt * 1000, "MMMM d,", {
                    timeZone: "America/Los_Angeles",
                  })}
                  <br />
                  {format(notification.createdAt * 1000, "yyyy 'at' h:mm a zzz", {
                    timeZone: "America/Los_Angeles",
                  })}
                </span>

                <div className="col-3">
                  <span
                    className={`rounded-5 d-flex justify-content-center align-items-center text-capitalize h-content py-1 px-3 fw-medium action-${action} d-block`}
                    style={{ fontSize: '10px' }}
                  >
                    {action.toLowerCase()}ed
                  </span>
                </div>

                <span className="col-3 d-flex align-items-center text-center" style={{ fontSize: '10px' }}>
                  {notificationMessages[action]({
                    action,
                    property: notification.property,
                  })}
                </span>

                <span className="col-3 d-flex align-items-center" style={{ fontSize: '10px', wordBreak: 'break-word' }}>
                  {augustId[notification.metadata.user.firstName] ??
                    notification.metadata.user.firstName}
                </span>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default MobileLockHistory;
