import { Form, Dropdown } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { useProfile } from "../../../../context/UserProfileContext";
import UnstyledButton from "../../../../components/UnstyledButton";
import { useAgentsDropdown } from "../../hooks/useAgentsDropdown";
import { useSelection } from "../../hooks/useSelection";
import { useEffect } from "react";

const SelectAgentsDropdown = ({
  title,
  handleAgentsApplyFilter,
  handleAgentsClearFilter,
  isAdmin = false,
  gettingBookings,
  setAgents,
}) => {
  const session = useProfile();
  
  // Hook to fetch data and search
  const {
    searchTerm,
    setSearchTerm,
    filteredAgents,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useAgentsDropdown({ isAdmin, setAgents });
  
  // Hook to handle selection and searchParams
  const {
    selectedItems,
    setSelectedItems,
    addOrRemoveSelectedItem,
    setScrollableElement,
  } = useSelection({
    searchParamKey: "agents",
    fetchNextPage,
    hasNextPage,
  });

  useEffect(() => {
    if (!isAdmin) {
      handleAgentsApplyFilter();
    }
  }, [selectedItems, handleAgentsApplyFilter, isAdmin]);

  return (
    <Dropdown autoClose="outside" className="no-arrow">
      <Dropdown.Toggle
        id="dropdown-basic"
        className="bg-white border border-1 text-body-tertiary fw-medium rounded-2 h-100"
      >
        <FiUser size={20} />{" "}
        <span className="d-none d-lg-inline-block">{title}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="scrollable-container"
        className="p-3"
        style={{
          width: isAdmin ? "320px" : "210px",
        }}
      >
        {isAdmin && (
          <>
            <span className="mb-3 fw-semibold color-gray d-inline-block">
              Agents
            </span>
            
            {/* Search Input */}
            <div className="position-relative mb-3">
              <FaSearch 
                style={{marginLeft: "15px"}}
                className="position-absolute top-50 start-0 translate-middle-y fs-8 text-muted" 
              />
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search agents..."
                style={{ paddingLeft: "35px" }}
              />
            </div>
          </>
        )}

        <div className="d-flex flex-column-reverse flex-lg-column">
          <div
              ref={(el) => setScrollableElement(el)}
              style={{ maxHeight: "200px" }}
              className="overflow-y-scroll"
            >
            {isLoading ? (
              <div className="d-flex justify-content-center py-4">
                <div className="spinner-border text-delet-blue" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column gap-3 px-1">
                {filteredAgents.map((agent, index) => (
                    <Form.Check
                      key={agent._id}
                      type="checkbox"
                      id={`agent-${index}`}
                      className={`agent-${index}`}
                      label={agent.label}
                      onChange={() => {
                        addOrRemoveSelectedItem(agent._id);
                      }}
                      checked={selectedItems.includes(agent._id)}
                      disabled={
                        gettingBookings ||
                        (agent._id === session?.data?._id &&
                          Boolean(session?.data?.adminId))
                      }
                    />
                  ))
                }
              </div>
            )}
            {isFetchingNextPage && (
              <div className="d-flex justify-content-center py-4">
                <div className="spinner-border text-delet-blue" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
          {isAdmin && (
            <div className="d-flex flex-lg-column justify-content-center justify-content-lg-start gap-3 py-3 pb-lg-0">
              <UnstyledButton
                onClick={handleAgentsApplyFilter}
                className="text-delet-blue fw-semibold"
              >
                Apply filters
              </UnstyledButton>
              <UnstyledButton
                onClick={() => {
                  handleAgentsClearFilter();
                  setSelectedItems([]);
                }}
                className="text-body-tertiary"
              >
                Clear all
              </UnstyledButton>
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectAgentsDropdown;
