import React from 'react';
import { DateTime } from 'luxon';

const DayColumn = ({ date, onSelect, selected = true }) => {
  const luxonDate = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  const dayName = luxonDate.toFormat('ccc');
  const dayNumber = luxonDate.toFormat('d');
  const formattedDate = luxonDate.toFormat('yyyy-MM-dd')

  return (
    <div className={`border px-3 py-3 text-center cursor-pointer rounded-3 d-flex flex-column w-content justify-content-center align-items-center ${selected ? 'border-primary-subtle' : ''}`} onClick={() => onSelect(formattedDate)}>
      <span className="fw-bold w-content bor">{dayName}</span>
      <span className='w-content'>{dayNumber}</span>
    </div>
  );
};

export default DayColumn;