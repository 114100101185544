import React from "react";
import BuildingForm from "../../Buildings/BuildingForm";
import Sidebar from "../components/AdminSideBar";
import { useUsersList } from "../../../hooks/useAdminUserList";
import PageHeader from "../../../components/Headers/PageHeader";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAdminBuilding, patchBuilding } from "../../../api/building";
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from "../../../context/UserProfileContext";

function EditBuildingAdmin() {
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const params = useParams();
  const { id } = params;
  const {
    data: users,
    isLoading: isLoadingUsers,
  } = useUsersList({
    role: JSON.stringify({
      $regex: "owner|superadmin",
      $options: "i",
    }),
  });

  const {
    data: building,
    isLoading: loadingBuilding,
    isFetching: fetchingBuilding,
    error: errorGettingBuilding,
  } = useQuery({
    queryKey: ["building", id, "admin"],
    queryFn: () => getAdminBuilding(id),
  });

  const { mutateAsync: onSubmit, isPending: submitting } = useMutation({
    mutationKey: ["edit", "building", id],
    mutationFn: (updatedBuilding) => {
      // Convert empty string parentBuilding to null
      if (updatedBuilding.parentStructure === "") {
        updatedBuilding.parentStructure = null;
      }

      // Use only properties ids
      updatedBuilding.properties = updatedBuilding.properties?.map(
        (property) => property._id
      );

      // Use only hardware ids
      updatedBuilding.hardware = updatedBuilding.hardware?.map(
        (hardware) => hardware._id
      );

      // Find removed properties based on properties before update
      const removedProperties = building.properties
        ?.filter(
          (property) => !updatedBuilding.properties.includes(property._id)
        )
        .map((property) => property._id);

      // Find removed hardware based on hardware list before update
      const removedHardware = building.hardware
        ?.filter((hardware) => !updatedBuilding.hardware.includes(hardware._id))
        .map((hardware) => hardware._id);

      // Perform building update
      return patchBuilding({
        ...updatedBuilding,
        removedProperties,
        removedHardware,
      });
    },
    onSuccess: () => navigate("/admin/building"),
  });

  return (
    <Sidebar>
      <PageHeader>
        <h6>Edit building</h6>
      </PageHeader>
      <div className="p-lg-5">
        <BuildingForm
          users={users}
          isLoadingUsers={isLoadingUsers}
          onSubmit={onSubmit}
          submitting={submitting}
          loadingBuilding={loadingBuilding}
          fetchingBuilding={fetchingBuilding}
          building={building}
          disabled={!profile?.isManager}
        />
      </div>
    </Sidebar>
  );
}

export default EditBuildingAdmin;
