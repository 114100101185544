import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { getLockNotifications } from "../../api/notifications";
import Loading from "../Banners/Loading";
import { format } from "date-fns-tz";
import { augustId, notificationMessages } from "../../constants";
import { useInView } from "react-intersection-observer";

export default function LockHistory ({ lockId }) {

  const queryKey = ["lock-history"];
  const {
    data: notifications,
    isLoading,
    isFetchingNextPage,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam = 1 }) => getLockNotifications(pageParam, lockId),
    getNextPageParam: (lastPage, pages) =>
      lastPage.hasMore ? pages.length + 1 : undefined,
  });

  const observer = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <span>Error getting notifications</span>
  ) : (
    <ul className="d-flex flex-column list-unstyled">
      <li className="row g-0 px-2 m-0 border-bottom py-2 justify-content-lg-between justify-content-xxl-start gap-xxl-5">
        <span className="col-lg-2 table-h fw-semibold fs-6 mb-0">Date</span>
        <span className="col-lg-2 table-h fw-semibold fs-6 mb-0">Action</span>
        <span className="col-lg-4 col-xxl-3 table-h fw-semibold fs-6 mb-0">Message</span>
        <span className="col-2 table-h fw-semibold fs-6 mb-0">User</span>
      </li>
      {notifications.pages.map((notificationsPage, page) =>
        notificationsPage.data.map((notification, index) => {
          const [device, action] = notification.type.split("_");
          return (
            <li
              className="row border-bottom py-3 justify-content-lg-between justify-content-xxl-start gap-xxl-5"
              ref={
                page === notifications.pages.length - 1 &&
                index === notificationsPage.data.length - 1
                  ? observer.ref
                  : undefined
              }
            >
              <span className="col-lg-2 d-flex align-items-center text-nowrap">
                {format(notification.createdAt * 1000, "MMMM d,", {
                  timeZone: "America/Los_Angeles",
                })}
                <br />
                {format(notification.createdAt * 1000, "yyyy 'at' h:mm a zzz", {
                  timeZone: "America/Los_Angeles",
                })}
              </span>

              <div className="col-lg-2">
                <span
                  className={`rounded-5 d-flex justify-content-center align-items-center text-capitalize h-content py-2 px-4 fw-medium action-${action} d-block`}
                >
                  {action.toLowerCase()}ed
                </span>
              </div>

              <span className="col-lg-4 col-xxl-3 d-flex align-items-center">
                {notificationMessages[action]({
                  action,
                  property: notification.property,
                }) ?? "Unknown"}
              </span>

              <span className="col-2 d-flex align-items-center">
                {augustId[notification.metadata.user.firstName] ??
                  notification.metadata.user.firstName}
              </span>
            </li>
          );
        })
      )}
    </ul>
  );
};
