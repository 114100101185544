import { useState } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./style.module.scss";
import "./react-datepicker.scss";
import { useGetMonthAvailability } from "../../../../../api/useQueryHooks/useGetMonthAvailability";
import { toZonedTime, format, fromZonedTime } from "date-fns-tz";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Form } from "react-bootstrap";
import { TIMEZONES } from "../../../../../constants";

function Step4({
  currentStep,
  propertyId,
  onChange,
  className,
  agent,
  timezone,
}) {
  const { agentId } = useParams();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState("");
  const selectedMonth = selectedDate?.getMonth();
  const selectedYear = selectedDate?.getFullYear();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const todayDate = toZonedTime(new Date(), timezone);

  const now = {
    time: todayDate.getTime(),
    month: todayDate.getMonth(),
    year: todayDate.getFullYear(),
    day: todayDate.getDay(),
  };

  const { data, isError } = useGetMonthAvailability(
    propertyId,
    selectedMonth ?? now.month,
    selectedYear ?? now.year,
    agent ?? agentId
  );

  if (propertyId === "")
    return <div className={style.noProperty}>No property selected</div>;

  if (isError)
    return (
      <div>
        There was an error loading the data, please reload and try again
      </div>
    );

  const filterDates = (date) => {
    if (!data?.settings) {
      return false;
    }

    const dayNames = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const dayOfWeek = date.getDay();
    const dayConfig = data?.settings.bookings.dow[dayNames[dayOfWeek]];

    return dayConfig.available;
  };
  const isAvailable = (selectedDate) => {
    const startOfDay = new Date(selectedDate);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(selectedDate);
    endOfDay.setHours(23, 59, 59, 999);

    for (
      let time = startOfDay;
      time <= endOfDay;
      time.setMinutes(time.getMinutes() + 15)
    ) {
      if (filterTimes(new Date(time))) {
        return true;
      }
    }

    return false;
  };
  const filterTimes = (date) => {
    if (date.getTime() < toZonedTime(new Date(), timezone)) {
      return false;
    }

    if (!data?.settings) {
      return false;
    }

    const dayNames = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const dayOfWeek = date.getDay();
    const { availability } = data?.settings.bookings.dow[dayNames[dayOfWeek]];

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const totalMinutes = hours * 60 + minutes;
    const isAvailable = availability.some((schedule) => {
      return (
        totalMinutes >= schedule.startTime && totalMinutes <= schedule.stopTime
      );
    });

    return isAvailable;
  };

  const filterExactDates = (selectedDate, excludeDates) => {
    return excludeDates?.filter((excludeDate) => {
      return (
        excludeDate.getDay() === selectedDate.getDay() &&
        excludeDate.getMonth() === selectedMonth &&
        excludeDate.getFullYear() === selectedYear
      );
    });
  };

  const excludeTimes = (selectedDate) => {
    const excludeDates = data?.excludedDates?.map((unixDate) =>
      toZonedTime(new Date(unixDate), timezone)
    );
    const filteredDates = filterExactDates(selectedDate, excludeDates);
    return filteredDates;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(format(time, "h:mm a"));

    onChange({
      target: {
        name: "localTimeRequested",
        value: Math.round(fromZonedTime(time, timezone).getTime() / 1000),
      },
    });

    setShowTimePicker(false);
  };

  return (
    <div className={`${currentStep !== 4 ? "d-none" : ""} `}>
      <div className={style.container}>
        <h3 className={style.headerTitle}>When Works Best For You?</h3>
        <p className={style.headerSubtitle}>
          The best time for a fresh start is now
        </p>

        <Form.Group className="w-100">
          <Form.Label className={style.label}>Timezone</Form.Label>
          <Form.Control
            type="text"
            value={TIMEZONES[timezone]}
            disabled
            className="bg-light text-secondary small-text"
          />
        </Form.Group>

        <div className={style.inputContainer}>
          <label htmlFor="Date" className={style.label}>
            Date
          </label>
          <button
            className={style.icon}
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            <GoCalendar size={16} />
          </button>
          <input
            name="Date"
            placeholder="Pick Date"
            className={style.inputBox}
            value={selectedDate?.toLocaleDateString()}
            onClick={() => {
              setShowDatePicker(!showDatePicker);
              setShowTimePicker(false);
            }}
          />
          {showDatePicker && (
            <div className={style.datePickerWrapper}>
              <DatePicker
                onChange={handleDateChange}
                inline
                selected={selectedDate}
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                minDate={toZonedTime(new Date(), timezone)}
                filterDate={filterDates}
              />
            </div>
          )}
        </div>
        <div className={style.inputContainer}>
          <label htmlFor="Time" className={style.label}>
            Time
          </label>
          <button
            className={style.icon}
            onClick={() => setShowTimePicker(!showTimePicker)}
          >
            <FaRegClock size={16} />
          </button>
          <input
            name="Time"
            placeholder="Pick Time"
            className={style.inputBox}
            value={selectedTime}
            onClick={() => {
              setShowTimePicker(!showTimePicker);
              setShowDatePicker(false);
            }}
            disabled={!selectedDate}
          />
          {showTimePicker && (
            <div className={style.timePickerWrapper}>
              {isAvailable(selectedDate) ? (
                <DatePicker
                  selected={selectedDate}
                  onChange={handleTimeChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  filterTime={filterTimes}
                  excludeTimes={excludeTimes(selectedDate)}
                  inline
                />
              ) : (
                <div className="copied-prop">
                  <p>
                    <IoIosCheckmarkCircle
                      style={{ color: "#00BA08" }}
                      size={18}
                    />{" "}
                    There are no available times for the date selected
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Step4;
