import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoCameraOutline, IoCloseCircleSharp } from "react-icons/io5";
import getImageUrl from "../helper/checkImageFormat";
import { getFileTypeFromUrl } from "../helper";
import { IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from "../constants";
import VideoLoader from "./VideoLoader";
import ImagePreviewModal from "./ImagePreviewModal";

const StyledInputImages = styled.div`
  border: 2px dashed #919295;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  background-color: ${(props) =>
    props.isDragOver ? "#f0f0f0" : "transparent"};
  border-color: ${(props) => (props.isDragOver ? "#5d5fef" : "#99999980")};
  transition: all 0.3s ease;
`;

const StyledPreview = styled.div({
  width: "100px",
});

const StyledImage = styled.img({
  maxWidth: "100%",
  height: "auto",
});

const StyledUploadButton = styled.label({
  display: "flex",
  borderRadius: "100%",
  padding: "16px 16px",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: "600",
  letterSpacing: "0.16px",
  backgroundColor: "#5D5FEF",
  color: "#FFFFFF",
  cursor: "pointer",
  marginBottom: "15px", // Added margin for spacing between button and text
});

const StyledCentredSelector = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2px",
  padding: "30px 0px", // Added padding for vertical spacing
});

const InputImages = ({
  description,
  name,
  watch,
  setValue,
  register,
  filesMessage = "Only PNGs or JPGs Max. 50MB",
  isSaving,
  setError,
  clearErrors,
  errors,
}) => {
  const getFileExtension = (file) => {
    if (file.type) return file.type;
    const match = file.name.toLowerCase().match(/\.([a-zA-Z0-9]+)$/);
    return match ? match[1] : "";
  };

  const [images, setImages] = useState([]);
  const [inputKey, setInputKey] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const files = watch(name);

  const handleMediaUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    const updatedFiles = newFiles.map((file) => {
      if (!file.type) {
        const extension = getFileExtension(file);
        if (VIDEO_EXTENSIONS.includes(extension)) {
          return new File([file], file.name, { type: `video/${extension}` });
        } else if (IMAGE_EXTENSIONS.includes(extension)) {
          return new File([file], file.name, { type: `image/${extension}` });
        }
      }
      return file;
    });
    const maxSize = 100 * 1024 * 1024;
    const allowedTypes = ["image/", "video/"];
    let totalSize = files.reduce((acc, file) => (file.size ?? 0) + acc, 0);
    for (let file of updatedFiles) {
      let fileType = getFileExtension(file).toLowerCase();
      const isAllowedType = allowedTypes.some((type) =>
        fileType.includes(type)
      );

      if (!isAllowedType) {
        setError(name, {
          type: "manual",
          message: "Only images and videos are allowed.",
        });
        return;
      }

      totalSize += file.size;

      if (totalSize > maxSize) {
        setError(name, {
          type: "manual",
          message: "Files size exceeds the 100MB limit.",
        });
        return;
      }
      clearErrors(name);
    }

    const uniqueFiles = updatedFiles.filter(
      (newFile) =>
        !files.some((existingFile) => existingFile.name === newFile.name)
    );

    const newImages = uniqueFiles?.map((file) => ({
      type: file.type,
      url: URL.createObjectURL(file),
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
    setValue(name, [...files, ...uniqueFiles], {
      shouldValidate: true,
      shouldDirty: true,
    });
    setInputKey((prevKey) => prevKey + 1);
  };

  const handleDeleteImage = (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    const newImages = images.filter((_, i) => i !== index);
    const newFiles = files.filter((_, i) => i !== index);
    setImages(newImages);
    setValue(name, newFiles, { shouldValidate: true, shouldDirty: true });
  };

  useEffect(() => {
    const initialImages = files?.map((file) => {
      if (typeof file === "string") {
        return {
          url: getImageUrl(file),
          type: getFileTypeFromUrl(file),
          isVideo: VIDEO_EXTENSIONS.some((ext) => file.includes(`.${ext}`)),
        };
      }
      return {
        url: URL.createObjectURL(file),
        type: file.type,
        isVideo: file.type.startsWith("video/"),
      };
    });
    setImages(initialImages);
  }, [files]);

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);

    const uniqueFiles = droppedFiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    );

    if (uniqueFiles.length > 0) {
      handleMediaUpload({ target: { files: uniqueFiles } });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDropImage = (index) => {
    if (draggedIndex === null) return;

    const reorderedImages = [...images];
    const [movedImage] = reorderedImages.splice(draggedIndex, 1);
    reorderedImages.splice(index, 0, movedImage);
    setImages(reorderedImages);

    const newFiles = [...files];
    const [movedFile] = newFiles.splice(draggedIndex, 1);
    newFiles.splice(index, 0, movedFile);
    setValue(name, newFiles, { shouldValidate: true, shouldDirty: true });

    setDraggedIndex(null);
  };

  const handleImageClick = (file) => {
    if (!file.isVideo) {
      setPreviewImage(file.url);
    }
  };

  return (
    <>
      <label htmlFor="InputImages">{description}</label>
      <StyledInputImages
        isDragOver={isDragOver}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <StyledCentredSelector>
          <StyledUploadButton htmlFor="uploadImageButton">
            <IoCameraOutline color="white" size={30} />
          </StyledUploadButton>
          <input
            key={inputKey}
            id="uploadImageButton"
            className="d-none"
            type="file"
            accept="image/*, video/*"
            multiple
            {...register(name)}
            onChange={handleMediaUpload}
          />
          <span className="body-s py-2">
            <span className="text-delet-blue fw-semibold">Drag</span> and{" "}
            <span className="text-delet-blue fw-semibold">drop</span> Files
          </span>
          <span className="body-xs text-body-tertiary">{filesMessage}</span>
        </StyledCentredSelector>
        <div className="d-flex flex-wrap gap-4 justify-content-center">
          {images.map((file, index) => (
            <StyledPreview
              key={index}
              onDrop={() => handleDropImage(index)}
              onDragOver={(e) => e.preventDefault()}
              className="position-relative input-img-container"
            >
              <button
                className="position-absolute remove-media-btn z-1"
                onClick={(event) => handleDeleteImage(event, index)}
              >
                <IoCloseCircleSharp size={14} color="rgba(13, 18, 28, 0.5)" />
              </button>

              {file.isVideo ? (
                <VideoLoader
                  file={file}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  preload="metadata"
                  externalLoading={isSaving}
                  width="100%"
                  height="100%"
                />
              ) : (
                <StyledImage
                  draggable
                  onClick={() => handleImageClick(file)}
                  onDragStart={() => handleDragStart(index)}
                  src={file.url}
                  alt={`File ${index + 1}`}
                  className="w-100 h-100 object-fit-cover rounded cursor-pointer"
                />
              )}
            </StyledPreview>
          ))}
        </div>
        {previewImage && (
          <ImagePreviewModal
            show={!!previewImage}
            imageUrl={previewImage}
            onHide={() => setPreviewImage(null)}
          />
        )}
      </StyledInputImages>
      {errors[name] && <p className="text-danger">{errors[name].message}</p>}
    </>
  );
};

export default InputImages;
