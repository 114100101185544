import React from "react";
import { PiVideoCameraSlashBold } from "react-icons/pi";
import { TbRefresh } from "react-icons/tb";
import UnstyledButton from "../../../components/UnstyledButton";

const ExpiredCamera = ({ refresh }) => {
  return (
    <div className="bg-dark d-flex flex-column justify-content-center align-items-center text-white text-center rounded">
      <PiVideoCameraSlashBold color="white" size={30} />
      <p className="fw-bold">
        Camera session expired.
        <br />
        Press here to resume live feed.
      </p>
      <UnstyledButton className="text-delet-blue fw-bold d-flex align-items-center gap-1 bg-transparent" onClick={refresh}>
        <TbRefresh color="#5271FF" size={20} /> Refresh
      </UnstyledButton>
    </div>
  );
};

export default ExpiredCamera;
