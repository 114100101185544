import { augustId } from "../../constants";

// In a utils file
export const formatNotificationTitle = (notification) => {
  const { type } = notification;
  const [device, action] = type.split("_");
  const userName = notification.metadata?.user?.firstName
    ? (augustId[notification.metadata.user.firstName] ?? notification.metadata.user.firstName)
    : "unknown";

  switch (action) {
    case "LOCK":
      return `Lock ${notification.device?.name} at ${notification.property?.address} was locked by ${userName} ${device === "KEYPAD" ? "code" : ""}`;
    case "UNLOCK":
      return `Lock ${notification.device?.name} at ${notification.property?.address} was unlocked by ${userName} ${device === "KEYPAD" ? "code" : ""}`;
    case "LOAD":
      return `New access code name: ${notification.metadata.user.firstName} - Code: ${notification.metadata.pin.code}`;
    case "DELETE":
      return `Access code deleted: ${notification.metadata.user.firstName} code: ${notification.metadata.pin.code}`;
    case "OPEN":
      return `Door at ${notification.property?.address} was opened`;
    case "CLOSED":
      return `Door at ${notification.property?.address} was closed`;
    default:
      return "Unknown notification";
  }
};