import { Table, flexRender } from "@tanstack/react-table";
import { PropertyShare } from "./types";
import { ChevronUp, ChevronDown } from "react-feather";

interface EmailHistoryTableProps {
    table: Table<PropertyShare>;
}

export default function EmailHistoryTable({ table }: EmailHistoryTableProps) {
    return (
        <table className="w-100">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="border-bottom">
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} className="px-2 py-3">
                                {header.isPlaceholder ? null : (
                                    <div
                                        className="d-flex align-items-center"
                                        onClick={header.column.getToggleSortingHandler()}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: <ChevronUp size={16} />,
                                            desc: <ChevronDown size={16} />,
                                        }[
                                            header.column.getIsSorted() as
                                                | "asc"
                                                | "desc"
                                        ] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="border-bottom">
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="px-2 py-3 fs-7">
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
