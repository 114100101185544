export default function HardwareList ({ hardwareList }) {
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex border-bottom border-secondary py-2 px-1">
        <span className="col-6 col-lg-3 fw-semibold fs-6 table-h">TYPE</span>
        <span className="col fw-semibold fs-6 table-h">NAME</span>
      </div>
      {hardwareList?.map((hardware) => (
        <div className="d-flex py-2 px-1 border-bottom" key={hardware._id}>
          <span className="col-6 col-lg-3 text-capitalize">{hardware.category}</span>
          <span className="col text-capitalize">{hardware.name}</span>
        </div>
      ))}
    </div>
  );
};