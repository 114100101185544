import { useSearchParams } from "react-router-dom";
import EntrySystemHeader from "./components/EntrySystemHeader";
import EntrySystemTabs from "./components/EntrySystemTabs";
import EntrySystemContent from "./components/EntrySystemContent";
import { useEffect, useState } from "react";
import axios from "axios";
const { Auth } = require("aws-amplify");

export default function EntrySystem({ property, setProperty, reloadHardware }) {
    // ===========================
    // Query params for navigation
    // ===========================
    const [searchParams] = useSearchParams();
    const currentTab = searchParams.get("systemTab") || "info";

    const systemTabUrl = (param, value) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("activeTab", "system");
        newSearchParams.set(param, value);
        return `?${newSearchParams.toString()}`;
    };

    // =================
    // Get hardware info
    // =================
    const [hardware, setHardware] = useState(null);
    const [hardwareList, setHardwareList] = useState(null);

    useEffect(() => {
        const getHardwareByStructure = async () => {
            if (!property?.structure) return;

            try {
                const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/structure/${property.structure}`;
                const token = await Auth.currentSession();
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token
                            .getIdToken()
                            .getJwtToken()}`,
                        "Content-Type": "application/json",
                    },
                });

                const hardwareList = response.data;
                if (hardwareList.length > 0) {
                    // Set hardware list
                    setHardwareList(hardwareList);

                    // Set main hardware (now is lockbox)
                    const mainHardware = hardwareList.find(
                        (hardware) => hardware.category === "lockbox"
                    );
                    setHardware({
                        id: mainHardware?.lockbox?.id,
                        vendor: mainHardware?.lockbox?.vendor,
                        name: mainHardware?.name,
                        type: "lock",
                    });
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const statusCode = error.response?.status;
                    const message =
                        error.response?.data?.response?.message ||
                        error.message;
                    console.error(
                        `Error: ${message}, StatusCode: ${
                            statusCode ?? "Unknown"
                        }`
                    );
                }
                console.error(
                    "An unexpected error occurred getting hardware by structure."
                );
            }
        };
        getHardwareByStructure();
    }, [property.structure]);

    return (
        <>
            <EntrySystemHeader
                hardware={hardware}
                property={property}
                setProperty={setProperty}
                reloadHardware={reloadHardware}
            />

            {hardware && hardwareList && (
                <div className="d-flex flex-column flex-md-row">
                    <EntrySystemTabs
                        currentTab={currentTab}
                        systemTabUrl={systemTabUrl}
                    />
                    <EntrySystemContent
                        hardware={hardware}
                        hardwareList={hardwareList}
                        currentTab={currentTab}
                    />
                </div>
            )}
        </>
    );
}
