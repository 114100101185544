import React, { useState } from "react";
import moment from "moment";
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import AssignProperty from "./AssignProperty";
import { statusClassMap } from "../../../helper/kits";
import StatusText from "./StatusText";
import { RiPencilLine } from "react-icons/ri";
import { design } from "../../../constants";
import { Link } from "react-router-dom";
import PagePagination from "../../../components/PagePagination";
import { useProfile } from "../../../context/UserProfileContext";
import UnstyledButton from "../../../components/UnstyledButton";

function KitsTable({
  kits,
  setSelectedKits,
  selectedKits,
  setModal,
  closeModal,
}) {
  const session = useProfile();
  const [selectAll, setSelectAll] = useState(false);
  const [sortBy, setSortBy] = useState({});

  const [page, setPage] = useState(1);
  const kitsPerPage = 11;

  const startIndex = (page - 1) * kitsPerPage;
  const endIndex = startIndex + kitsPerPage;

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      const allKits = kits.map((kit) => kit._id);
      setSelectedKits(allKits);
    } else {
      setSelectedKits([]);
    }
  };

  const handleSelectKit = (checked, kit) => {
    setSelectAll(false);

    setSelectedKits((prev) => {
      const newSelection = [...prev];
      if (checked) {
        newSelection.push(kit._id);
      } else {
        const index = prev.indexOf(kit._id);
        if (index > -1) newSelection.splice(index, 1);
      }
      return newSelection;
    });
  };

  const handleSort = (type) => {
    setSortBy({ [type]: !sortBy[type] });
  };

  const showAssignProperty = (kit) => {
    setModal({
      show: true,
      content: <AssignProperty kit={kit} closeModal={closeModal} />,
      title: "",
    });
  };

  const sortedKits = kits.sort((a, b) => {
    const [field, order] = Object.entries(sortBy)[0] || [];
    if (!field) return 0;

    if (field === "name" || field === "property" || field === "status") {
      const fieldA =
        field === "property"
          ? a.property
            ? a.property.shortAddress
            : ""
          : a[field];
      const fieldB =
        field === "property"
          ? b.property
            ? b.property.shortAddress
            : ""
          : b[field];
      if (fieldA < fieldB) return order ? -1 : 1;
      if (fieldA > fieldB) return order ? 1 : -1;
      return 0;
    }

    if (field === "date") {
      if (!a.assignedDate && b.assignedDate) return 1;
      if (a.assignedDate && !b.assignedDate) return -1;

      if (a.assignedDate < b.assignedDate) return order ? -1 : 1;
      if (a.assignedDate > b.assignedDate) return order ? 1 : -1;
      return 0;
    }

    return 0;
  });

  const totalPages = Math.ceil(sortedKits.length / kitsPerPage);

  const currentPageKits = sortedKits.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <table
        class="table flex-grow-1 d-flex flex-column px-4 px-lg-5 py-3"
        style={{ fontFamily: "Poppins", width: "97%" }}
      >
        <thead className="">
          <tr className="d-flex mw-100 border-bottom gap-2">
            <th className="col-2 col-xxl-3 border-bottom-0 d-flex gap-3">
              <input
                class="form-check-input"
                type="checkbox"
                checked={selectedKits.length === kits.length}
                id="flexCheckDefault"
                onChange={handleSelectAll}
              />
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center table-h text-nowrap"
                onClick={() => handleSort("name")}
              >
                Kit Number{" "}
                {sortBy.name === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.name ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>
            <th className="col border-bottom-0 d-flex align-items-center d-none">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center"
                onClick={() => handleSort("status")}
              >
                Status{" "}
                {sortBy.status === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.status ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>
            <th className="col-5 col-xxl-4 border-bottom-0 d-flex align-items-center">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center table-h"
                onClick={() => handleSort("property")}
              >
                Property{" "}
                {sortBy.property === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.property ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>

            <th className="col-3 border-bottom-0 d-flex align-items-center">
              <button
                className="border-0 bg-white d-flex gap-2 align-items-center table-h"
                onClick={() => handleSort("date")}
              >
                Activation Date{" "}
                {sortBy.date === undefined ? (
                  <MdKeyboardArrowDown size={30} />
                ) : sortBy.date ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </th>

            <th className="col border-bottom-0 d-flex align-items-center"></th>
          </tr>
        </thead>
        <tbody className="flex-grow-1">
          {currentPageKits.length === 0 ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <h4 className="text-secondary">...No kits to show</h4>
            </div>
          ) : (
            currentPageKits.map((kit) => (
              <tr className="d-flex mw-100 flex-grow-1 gap-2 py-2 border-bottom">
                <td className="col-2 col-xxl-3 border-bottom-0 d-flex gap-3 text-nowrap">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={selectAll || selectedKits.includes(kit._id)}
                    id="flexCheckDefault"
                    onChange={(e) => handleSelectKit(e.target.checked, kit)}
                  />{" "}
                  {kit.name}
                </td>

                <td className="col border-bottom-0 d-none">
                  <StatusText status={statusClassMap[kit.state]} />
                </td>

                <td className="col-5 col-xxl-4 border-bottom-0 ">
                  {kit.property ? (
                    <button
                      className="border-0 bg-white d-flex align-items-center justify-content-between w-100 text-start"
                      onClick={() => showAssignProperty(kit)}
                    >
                      <span className="text-nowrap text-truncate">
                        {`${kit.property.shortAddress}, ${
                          kit.property.unit ? `Unit ${kit.property.unit},` : ""
                        } ${kit.property.city} ${kit.property.zipCode}`}
                      </span>{" "}
                      <RiPencilLine
                        color={design.deleteMainColor}
                        size={25}
                        style={{ marginRight: "30px" }}
                      />
                    </button>
                  ) : (
                    <UnstyledButton
                      className={`d-flex gap-1 align-items-center text-delet-blue ${
                        session?.data?.roles.includes("agent") && "opacity-50"
                      }`}
                      onClick={() => showAssignProperty(kit)}
                      disabled={session?.data?.roles.includes("agent")}
                    >
                      <div className="sign-icon">
                        <span className="">+</span>
                      </div>
                      Assign property
                    </UnstyledButton>
                  )}
                </td>

                <td className="col-3 border-bottom-0">
                  {kit.assignedDate
                    ? moment(kit.assignedDate).format("MMMM Do, YYYY")
                    : "Not active"}
                </td>

                {session?.data?.roles.some((role) =>
                  ["admin", "owner"].includes(role)
                ) && (
                  <td className="col-2 border-bottom-0">
                    <Link
                      className="btn btn-primary me-5 bg-delet border-0 text-nowrap"
                      to={`/kits/history/${kit._id}`}
                    >
                      Kit History
                    </Link>
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>

      {totalPages > 1 ? (
        <div className="py-3">
          <PagePagination
            totalPages={totalPages}
            actualPage={page}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : null}
    </>
  );
}

export default KitsTable;
