import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoIosNotifications } from "react-icons/io";
import NotificationsTable from "../../pages/Notifications/components/NotificationsTable";
import { Link } from "react-router-dom";
import { useSocket } from "../../context/SocketContext";
import NewNotificationsCounter from "./NewNotificationsCounter";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUnreadCount } from "../../api/notifications";

const queryKey = ["unread-notifications-count"];

const NotificationsButton = () => {
  const socket = useSocket();
  const queryClient = useQueryClient();

  const [show, setShow] = useState(false);
  const { data: unread, isLoading } = useQuery({
    queryKey: queryKey,
    queryFn: getUnreadCount,
  });

  useEffect(() => {
    socket?.on("newNotification", (notification) => {
      queryClient.setQueryData(queryKey, (prevCount) => ({
        ...prevCount,
        count: prevCount.count + 1,
      }));
    });

    return () => {
      socket?.off("newNotification");
    };
  }, [socket]);

  return (
    <Dropdown className=" align-items-center d-flex" show={show}>
      {!isLoading && unread?.count > 0 && (
        <NewNotificationsCounter newNotifications={unread?.count} />
      )}
      <Dropdown.Toggle
        variant="light"
        id="dropdown-basic"
        className="border no-arrow d-flex align-items-center justify-content-center rounded-3 border header-button"
        drop="none"
        onClick={() => setShow(!show)}
      >
        <IoIosNotifications className="text-secondary h-100 w-100" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-0">
        <Dropdown.Item
          onClick={(e) => e.stopPropagation()}
          className="no-bg px-0"
        >
          <div className="notifications-btn-container px-4 py-3">
            <NotificationsTable hideFilters />
          </div>
        </Dropdown.Item>

        <div className="px-4 py-3 col-12">
          <Link
            className="text-delet-blue fw-bold text-decoration-none"
            to="/notifications"
          >
            See all Notifications
          </Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsButton;
