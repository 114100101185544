import React from "react";
import Sidebar from "../components/AdminSideBar";
import PageHeader from "../../../components/Headers/PageHeader";
import { Link } from "react-router-dom";
import BuildingsTable from "./components/BuildingsTable";
import { Button } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getAllBuildingsList } from "../../../api/building";
import { useProfile } from "../../../context/UserProfileContext";

const AdminBuildingsList = () => {
  const { data: profile } = useProfile();
  const {
    data: buildings = [],
    isLoading: isLoadingBuildings,
  } = useQuery({
    queryKey: ["buildings", "admin"],
    queryFn: getAllBuildingsList,
  });

  
  return (
    <Sidebar>
      <PageHeader>
        <h4 className="w-content m-0">Buildings list</h4>
        <Button
          as={Link}
          to={`/admin/building/create`}
          variant="primary"
          size="sm"
          className="w-content"
          disabled={!profile?.isManager}
        >
          New
        </Button>
      </PageHeader>

      <div className="px-3 pt-3">
        <BuildingsTable
          buildings={buildings}
          isLoading={isLoadingBuildings}
          isManager={profile?.isManager}
        />
      </div>
    </Sidebar>
  );
};

export default AdminBuildingsList;
