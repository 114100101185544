import React, { useEffect, useState } from "react";

import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ColorRing } from "react-loader-spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";

import { useProfile } from "../../../context/UserProfileContext";
import Sidebar from "../components/AdminSideBar";
import PageHeader from "../../../components/Headers/PageHeader";

const UserDetails = () => {
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const [modelshow, setModelShow] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [Loader, setLoader] = useState(false);
  const params = useParams();

  useEffect(() => {
    const id = params.id;
    if (id) {
      const getUserDetails = async (id) => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${id}`;
          const token = await Auth.currentSession();

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          });
          if (response.data) {
            setList(response.data);
            setLoader(true);
          } else {
            setError("Error getting User");
            setModelShow(true);
          }
        } catch (error) {
          console.log(error);
          if (error.response) {
            if (error?.response?.status === 401) {
              Auth.signOut()
                .then(() => {
                  navigate("/admin/login");
                })
                .catch((error) => {
                  console.log("Error signing out: ", error);
                });
            } else if (error.response.status === 404) {
              if (error.response.data.message) {
                setError(error.response.data.message);
                setModelShow(true);
              } else {
                setError(error.message);
                setModelShow(true);
              }
            } else {
              setError("Error getting User");
              setModelShow(true);
            }
          } else {
            setError(error);
            setModelShow(true);
          }
        }
      };
      getUserDetails(id);
    } else {
      navigate("/");
    }
  }, [navigate, params.id]);

  const handleDelete = async (e) => {
    try {
      e.preventDefault();

      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${params.id}`;
      const token = await Auth.currentSession();

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });

      if (response.status === 200) {
        setModelShow(false);
        setDelete(true);
      } else {
        setError("Error Deleting User");
        setModelShow(true);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        setError("Error Deleting User");
        setModelShow(true);
      }
    }
  };

  return (
    <>
      <Sidebar>
        <div className="">
          <Row className="align-items-center row-gap-2 mb-3">
            <PageHeader>
              <h4 className="color-gray">
                {list.firstName} {list.lastName}
              </h4>
            </PageHeader>

            {profile?.isSuperadmin && (
              <>
                <Col xl={2}>
                  <div className="d-flex align-items-center justify-content-md-end">
                    <div className="d-flex">
                      <Link
                        to={`/admin/user/password/${list._id}`}
                        size="sm"
                        className="btn btn-primary btn-sm"
                        style={{ backgroundImage: "none", paddingLeft: 10 }}
                      >
                        Change Password
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col xl={2}>
                  <div className="d-flex align-items-center justify-content-md-end">
                    <div className="d-flex">
                      <Link
                        to={`/admin/user/edit-user/${list._id}`}
                        size="sm"
                        className="btn btn-primary btn-sm"
                      >
                        Edit User
                      </Link>
                    </div>
                  </div>
                </Col>
              </>
            )}
            {profile?.isSuperadmin && (
              <Col xl={2}>
                <div className="d-flex align-items-center justify-content-md-end">
                  <div className="d-flex">
                    <Link
                      onClick={() => setModelShow(true)}
                      className="btn btn-danger"
                    >
                      Delete User
                    </Link>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <Row className="mt-30">
          {Loader === false ? (
            <>
              <div style={{ textAlign: "center" }}>
                <ColorRing
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#1F60AE",
                    "#5C79FF",
                    "#455DC8",
                    "#889DFF",
                    "#5271FF",
                  ]}
                />
              </div>
            </>
          ) : (
            <Col xl={12} lg={12}>
              <Card>
                <Card.Header as="h5">Personal information</Card.Header>
                <Card.Body>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Full name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.firstName}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Mobile"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Last name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.lastName}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Email"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Mobile</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <a
                          href="tel:202-555-0153"
                          className="table-phone phonenumber-w-ico link-w-ico color-black"
                        >
                          {list.phone}
                        </a>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Email</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <a
                          href="mailo:funky.jen.123@gmail.com"
                          className="table-mail mail-w-ico link-w-ico"
                        >
                          {list.email}
                        </a>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Role</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>{list.role}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Description</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>{list.description}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Company Name</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.company ? list.company.name : ""}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Sidebar>
      {modelshow && (
        <Modal show={modelshow}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Delete User</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>

              <Modal.Body>Are you sure you want to delete user?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      )}
      {Delete && (
        <Modal show={Delete}>
          <>
            <Modal.Header>
              <Modal.Title>Deleted</Modal.Title>
            </Modal.Header>

            <Modal.Body>User Deleted Successfully</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate("/admin/user/list");
                  setDelete(false);
                }}
              >
                Okay
              </Button>
            </Modal.Footer>
          </>
        </Modal>
      )}
    </>
  );
};

export default UserDetails;
