import React from "react";
import StepsContainer from "./components/Steps";
import { RescheduleSteps } from "./components/RescheduleSteps";

function BookingPage({ reschedule }) {
  return (
    <div className="px-3">
      {reschedule ? <RescheduleSteps /> : <StepsContainer />}
    </div>
  );
}

export default BookingPage;
