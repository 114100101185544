import { useRef } from "react";
import { Box, Grid, Space } from "@mantine/core";
import { FaRegClock, FaDoorClosed, FaTools } from "react-icons/fa";
import {
    IoMdPerson,
    IoMdCheckmarkCircleOutline,
    IoMdCloseCircleOutline,
} from "react-icons/io";
import { DashboardProvider } from "./DashboardContext";
import DashboardHeader from "./DashboardHeader";
import Sidebar from "../../components/SideBar";
import CardMetric from "./CardMetric";
import CardLineChart from "./CardLineChart";
import CardCalendarList from "./CardCalendarList";
import CardPieChart from "./CardPieChart";
import CardTopProperties from "./CardTopProperties";

function DashboardPage() {
    // Reference to export PDF
    const dashboardRef = useRef(null);

    return (
        <Sidebar>
            <DashboardProvider>
                <Box ref={dashboardRef} p={{ base: 20, md: 40 }} maw={1600}>
                    {/* Dashboard Header */}
                    <DashboardHeader dashboardRef={dashboardRef} />
                    <Space h={{ base: "sm", md: "xl" }} />

                    {/* Dashboard Content */}
                    <Grid gutter={{ base: "md", md: "xl" }}>
                        {/* Metrics 3-group */}
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CardMetric
                                to="/leads"
                                metricType="scheduledTours"
                                Icon={FaRegClock}
                                iconColor="violet.6"
                                iconBgColor="violet.0"
                                title="Scheduled Tours"
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CardMetric
                                to="/property/list"
                                metricType="propertyAmount"
                                Icon={IoMdCheckmarkCircleOutline}
                                iconColor="green.6"
                                iconBgColor="green.0"
                                title="New Properties"
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <CardMetric
                                to="/leads"
                                metricType="leads"
                                Icon={IoMdPerson}
                                iconColor="orange.6"
                                iconBgColor="orange.0"
                                title="Leads"
                                metric={24}
                            />
                        </Grid.Col>

                        {/* Area Chart */}
                        <Grid.Col span={{ base: 12, lg: 8 }}>
                            <CardLineChart />
                        </Grid.Col>

                        {/* Calendar List */}
                        <Grid.Col span={{ base: 12, lg: 4 }}>
                            <CardCalendarList />
                        </Grid.Col>

                        {/* Metrics 4-group */}
                        <Grid.Col span={{ base: 12, lg: 8 }}>
                            <Grid gutter={{ base: "md", md: "xl" }}>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <CardMetric
                                        to="/notifications"
                                        metricType="doorOpenedWithDelet"
                                        Icon={FaDoorClosed}
                                        iconColor="violet.6"
                                        iconBgColor="violet.0"
                                        title="Door Opened with Delet"
                                        metric={12}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <CardMetric
                                        to="/kits"
                                        metricType="kitsInstalled"
                                        Icon={FaTools}
                                        iconColor="pink.6"
                                        iconBgColor="pink.0"
                                        title="Kits Installed"
                                        metric={18}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <CardMetric
                                        to="/notifications"
                                        metricType="doorOpenedManually"
                                        Icon={FaRegClock}
                                        iconColor="yellow.6"
                                        iconBgColor="yellow.0"
                                        title="Door Opened Manually"
                                        metric={38}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <CardMetric
                                        to="/kits"
                                        metricType="kitsRemoved"
                                        Icon={IoMdCloseCircleOutline}
                                        iconColor="red.6"
                                        iconBgColor="red.0"
                                        title="Kits Removed"
                                        metric={4}
                                    />
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>

                        {/* Pie Chart */}
                        <Grid.Col span={{ base: 12, lg: 4 }}>
                            <CardPieChart />
                        </Grid.Col>

                        {/* Top Properties */}
                        <Grid.Col span={12}>
                            <CardTopProperties />
                        </Grid.Col>
                    </Grid>
                </Box>
            </DashboardProvider>
        </Sidebar>
    );
}

export default DashboardPage;
