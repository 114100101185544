import React, { useRef } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Flex,
  Group,
  Modal,
  Text,
  Textarea,
} from "@mantine/core";
import { LuMinus, LuPlus } from "react-icons/lu";
import { useDisclosure } from "@mantine/hooks";

const OrderCard = React.memo(({ order, index, setCardProp, removeCard }) => {
  const notesRef = useRef(null);
  const [opened, { close }] = useDisclosure(false);

  const handleNotes = () => {
    setCardProp(index, "notes", notesRef.current.value);
    close();
  };

  const getSubtotal = (quantity) => quantity * order.individualPrice;

  const handleQuantityChange = (value) => {
    if (value < 0) return;
    setCardProp(index, "amount", value);
    setCardProp(index, "price", getSubtotal(value));
  };

  const description = {
    kit: "The kit includes a DELET hub with internet connection, a set of 3 cameras, a remotely controlled lock with keypad and access to the portal.",
    installation:
      "We will arrange the installation for the number of kits you select.",
    removal: "We will arrange the removal for the number of kits you select.",
  };
  return (
    <>
      <Card
        withBorder
        radius="md"
        p="lg"
        className="shadow-lg h-100 d-flex flex-column gap-2"
      >
        <Group justify="space-between">
          <Text size="lg" fw={800} tt="capitalize">
            {order.serviceType === "kit" ? "DELET kit 2.0" : order.serviceType}
          </Text>
        </Group>

        <Text>{description[order.serviceType]}</Text>

        <div className="d-flex align-items-end gap-2 justify-content-end h-100">
          <ActionIcon
            onClick={() => handleQuantityChange(order.amount - 1)}
            variant="filled"
            color={"#000"}
            size="md"
          >
            <LuMinus />
          </ActionIcon>
          <input
            type="number"
            value={order.amount}
            min={0}
            className="d-inline col-4 border-0 text-center"
            onChange={(e) => handleQuantityChange(e.target.value)}
          />
          <ActionIcon
            onClick={() => handleQuantityChange(order.amount + 1)}
            variant="filled"
            color={"#000"}
            size="md"
          >
            <LuPlus />
          </ActionIcon>
        </div>
      </Card>

      <Modal opened={opened} onClose={close} title="Order notes">
        <Textarea
          data-autofocus
          placeholder="Add notes"
          mt="md"
          minRows={6}
          resize="vertical"
          ref={notesRef}
          autosize
          defaultValue={order?.notes}
        />
        <Flex justify="flex-end" mt={10}>
          <Button onClick={handleNotes}>Save</Button>
        </Flex>
      </Modal>
    </>
  );
});

export default OrderCard;
