import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getKitHistory } from "../../../helper/kitHistory";
import List from "./List";

const MAX_KITS_PER_PAGE = 20;

export default function ListWrapper({
  id,
  header: Header = () => {},
  all = false,
  hideFields = [],
}) {
  const [page, setPage] = useState(1);

  const startIndex = (page - 1) * MAX_KITS_PER_PAGE;

  const { data } = useQuery({
    queryKey: [id, startIndex, all],
    queryFn: async () => {
      if (id) {
        const data = await getKitHistory(id, {
          startIndex,
          limit: MAX_KITS_PER_PAGE,
          all,
        });
        const { kit, kitHistory, totalKitHistoryEntries } = data || {};
        if (!kit || !kitHistory) return {};
        let newHistory = [];
        if (hideFields.includes("user")) {
          newHistory = kitHistory.filter((kits) =>
            kits.action.includes("PROPERTY")
          );
        } else {
          newHistory = kitHistory;
        }
        const totalPages = Math.ceil(
          totalKitHistoryEntries / MAX_KITS_PER_PAGE
        );
        return { kit, newHistory, totalPages };
      }
      return {};
    },
    refetchOnWindowFocus: false,
  });

  const { kit, newHistory = [], totalPages = 1 } = data || {};

  let pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (!kit) return null;

  function Pagination() {
    return (
      <ul className="pagination mb-0 mx-auto d-flex justify-content-center">
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => setPage((page) => page - 1)}
            disabled={page === 1}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {pageNumbers.map((pageNumber) => (
          <li key={`pageNumber${pageNumber}`} className="page-item">
            <button
              className={`page-link ${pageNumber === page && "fw-bold"}`}
              onClick={() => setPage(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => setPage((page) => page + 1)}
            disabled={page >= totalPages}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    );
  }

  return (
    <>
      <Header kit={kit} />
      <List kit={kit} kitHistory={newHistory} hideFields={hideFields} />
      <Pagination />
    </>
  );
}
