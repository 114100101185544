import React from "react";
import { IoIosAlert } from "react-icons/io";

const InfoBanner = ({
  children,
  iconProps = { color: "#5271FF", size: 30 },
  background = "bg-primary",
}) => {
  return (
    <div
      className={`${background} bg-opacity-10 px-4 py-2 d-flex gap-4 align-items-center rounded-3`}
    >
      <IoIosAlert color={iconProps.color} size={iconProps.size} />
      {children}
    </div>
  );
};

export default InfoBanner;
