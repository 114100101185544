import React, { useRef,  } from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  color: #000;
  
  font-size: 16px;
  font-weight: ${(props) =>
    props.variantType === "primary"
      ? "700"
      : props.variantType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;
  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const StyledSpan = styled.span`
  padding-left: 8px;
  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const StyledCheckboxInput = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #66676b;
  margin-top: 1px;
  box-shadow: none;
  &:checked {
    background-color: #5d5fef;
    border: 2px solid #5d5fef;
    box-shadow: none;
    //add a background image here with a white checkmark and background color of #5D5FEF
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 0 23 19'%3E%3Cpath d='M2.0 10.5L6.5 15.0L18 4' stroke='%23fff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' fill='none'/%3E%3C/svg%3E");
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #5d5fef;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #eaeaeb;
    border: 2px solid #eaeaeb;
  }
`;

const InputCheckboxArray = ({
  disabled = false,
  name,
  watch,
  setValue,
  register,
  options,
  error = {},
  changedValue,
  ...props
}) => {

  const itemsArray = useRef([]);
  itemsArray.current = watch(`${name}`)


  return (
    <div className="d-flex flex-column gap-1">
      {props.label && (
        <StyledLabel
          variantType={props.variantType}
          disabled={disabled}
          className="body-s-bold"
          htmlFor={name}
        >
          {props.label}
        </StyledLabel>
      )}
      {options.map((option, index) => (
        <label key={index} htmlFor={`${name}-${index}`}>
          <StyledCheckboxInput
            className="form-check-input"
            type="checkbox"
            value={option.value}
            checked={itemsArray.current?.includes(option.value)}
            {...register(name)}
            {...props}
            disabled={disabled}
            id={`${name}-${index}`}
          />
          <StyledSpan disabled={disabled}>{option.name}</StyledSpan>
        </label>
      ))}
      {error[name] && <span className="error">{error[name]?.message}</span>}
    </div>
  );
};

export default InputCheckboxArray;
