import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const ProspectNameInputs = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get values directly from URL
  const firstName = searchParams.get("firstName") || "";
  const lastName = searchParams.get("lastName") || "";

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (value) {
        newParams.set("firstName", value);
      } else {
        newParams.delete("firstName");
      }
      return newParams;
    });
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (value) {
        newParams.set("lastName", value);
      } else {
        newParams.delete("lastName");
      }
      return newParams;
    });
  };

  return (
    <>
      <Form.Group controlId="prospectFirstName">
        <Form.Control
          type="text"
          placeholder="Enter first name"
          value={firstName}
          onChange={handleFirstNameChange}
          className="rounded-2 border"
        />
      </Form.Group>

      <Form.Group controlId="prospectLastName">
        <Form.Control
          type="text"
          placeholder="Enter last name"
          value={lastName}
          onChange={handleLastNameChange}
          className="rounded-2 border"
        />
      </Form.Group>

    </>
  );
};

export default ProspectNameInputs;