import React from "react";
import NotificationTitle from "./NotificationTitle";
import { format } from "date-fns-tz";
import { FaLock, FaUnlock } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { TbDeviceRemote } from "react-icons/tb";

const NotificationCard = React.forwardRef(
  ({ notification, page, index, markAsRead, ...props }, ref) => {
    const action = notification.type.split("_")[1];
    const { ref: inViewRef } = useInView({
      threshold: 0.5,
      triggerOnce: true,
      skip: notification.read,
      onChange: (inView) => {
        if (inView && !notification.read) {
          markAsRead({
            notificationId: notification._id,
            pageIndex: page,
            notificationIndex: index,
          });
        }
      },
    });

    const setRefs = React.useCallback(
      (node) => {
        if (typeof ref === "function") ref(node);
        else if (ref) ref.current = node;
        if (!notification.read) inViewRef(node);
      },
      [ref, inViewRef, notification.read]
    );

    return (
      <div
        className={`py-3 d-flex align-items-center border-bottom gap-3 ${
          notification.read ? "text-secondary" : ""
        }`}
        key={notification._id}
        ref={setRefs}
        {...props}
      >
        <div className="notification-icon-container">
          {action === "UNLOCK" ? (
            <FaUnlock size={24} color="white" className="notification-icon" />
          ) : action === "LOCK" ? (
            <FaLock size={24} color="white" className="notification-icon" />
          ) : (
            <TbDeviceRemote
              size={30}
              color="white"
              className="notification-icon"
            />
          )}
        </div>

        <div className="d-flex align-items-center gap-3 col">
          <p className="w-100 d-flex flex-column gap-2">
            <NotificationTitle notification={notification} />
            <span className="fs-7 text-body-tertiary d-flex gap-3">
              {format(
                new Date(notification.createdAt * 1000),
                "MMM d hh:mm aaa"
              )}{" "}
              <span>•</span>Property
            </span>
          </p>
        </div>

        <div
          className={`d-flex justify-content-end col ${
            notification.read && "d-none"
          }`}
        >
          <span className="circle bg-delet"></span>
        </div>
      </div>
    );
  }
);

export default NotificationCard;
