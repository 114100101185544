import { Table, flexRender } from "@tanstack/react-table";
import { Property } from "./types";

export const formatProperty = (property: Property) => {
    const { shortAddress, street, unit } = property;
    return `${shortAddress ?? street}${unit ? ", Unit " + unit : ""}`;
};

export const formatDate = (localDate: Date) => {
    const formattedDate = localDate.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
    const cleanFormattedDate = formattedDate
        .replace(",", "")
        .replace("am", "AM")
        .replace("pm", "PM");
    return cleanFormattedDate;
};

export function exportToCSV<T>(table: Table<T>): void {
    // Extract headers from the first header group.
    const headerGroup = table.getHeaderGroups()[0];
    const headers = headerGroup.headers.map((header) => {
        const headerContent = flexRender(
            header.column.columnDef.header,
            header.getContext()
        );
        return typeof headerContent === "string" ? headerContent : "";
    });

    // Process rows: for each visible cell, extract a string value.
    const rows = table.getFilteredRowModel().rows.map((row) =>
        row.getVisibleCells().map((cell) => {
            let text: string;
            if (cell.column.id === "createdAt") {
                // For the "Date" column, format the Date object using formatDate.
                text = formatDate(cell.getValue() as Date);
            } else {
                const cellValue = cell.getValue();
                text =
                    typeof cellValue === "string"
                        ? cellValue
                        : String(cellValue);
            }
            // Escape double quotes and wrap the text in quotes
            text = text.replace(/"/g, '""');
            return `"${text}"`;
        })
    );

    // Combine headers and rows to form CSV content.
    const csvContent = [
        headers.join(","), // header row
        ...rows.map((row) => row.join(",")), // data rows
    ].join("\n");

    // Create a Blob from the CSV content and trigger a download.
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "export.csv";
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
