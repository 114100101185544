const imageUrl = process.env.REACT_APP_IMAGE_PATH;
function getImageUrl(url) {
  if (!Boolean(url)) return
  if (typeof url === 'string' && url.startsWith('data:image')) {
    return url;
  }

  if (typeof url === 'string' && /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/.test(url)) {
    return `data:image/png;base64,${url}`;
  }

  if (url?.startsWith('https://')) {
    return url;
  }

  return `${imageUrl}${url}`;
}
export default getImageUrl