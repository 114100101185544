import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LazyImgWithPlaceholder from "../../../components/Images/LazyImgWithPlaceholder";
import CopyButton from "../../Properties/components/CopyButton";
import propertyDummyImage from "../../../assests/images/6-thumb.png";
import getImageUrl from "../../../helper/checkImageFormat";
import RouterStatus from "../../Properties/components/RouterStatus";
import { FiUpload } from "react-icons/fi";
import { format } from "date-fns-tz";
import PropertyStatusSelect from "../../Properties/components/PropertyStatusSelect";
import UnstyledButton from "../../../components/UnstyledButton";
import CustomDropdown from "../../../components/Dropdown";
import copy from "copy-to-clipboard";
import {
  propertyArchivedListMenuItems,
  propertyListMenuItems,
} from "../../../static/Data";
import { propertyBookingLink } from "../../../utils/Properties";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useProfile } from "../../../context/UserProfileContext";

const AdminPropertyListCard = ({
  property,
  handleFormModal,
  handleRedirectModal,
  queryKey,
  setShowArchiveModal,
  setPropertyIdToArchiveOrRestore,
  setShowRestoreModal,
  setShowDuplicateModal,
}) => {
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const handleMenuItemClick = async (action, id) => {
    switch (action) {
      case "Edit property details":
        navigate(`/property/edit/${id}`);
        break;
      case "Changes history":
        navigate(`/admin/property/history/${id}`);
        break;
      case "Open prospect booking page":
        const bookingLink = await propertyBookingLink(property);
        window.open(bookingLink, "_blank");
        break;
      case "Copy prospect booking link":
        const link = await propertyBookingLink(property);
        copy(link);
        break;
      case "View all property leads":
        navigate(`/property/${id}?activeTab=leads`);
        break;
      case "Archive property":
        setShowArchiveModal(true);
        setPropertyIdToArchiveOrRestore(id);
        break;
      case "Restore property":
        setShowRestoreModal(true);
        setPropertyIdToArchiveOrRestore(id);
        break;
      case "Duplicate property":
        setShowDuplicateModal(true);
        setPropertyIdToArchiveOrRestore(id);
        break;
      default:
        break;
    }
  };
  return (
    <div className="row justify-content-between py-3 border-bottom">
      <div
        className={`col-lg-3 d-flex gap-3 ${!profile?.isManager && "pe-none"}`}
      >
        <Link
          to={`/admin/property/${property?._id}?user=${property.user?._id}&activeTab=details`}
          className="text-decoration-none text-black d-flex gap-3 align-items-center"
          style={{ display: "contents" }}
        >
          <div className="position-relative d-inline-block ms-0 py-2">
            <LazyImgWithPlaceholder
              width={80}
              height={80}
              border={10}
              placeholder={propertyDummyImage}
              src={
                getImageUrl(property.images?.[0]) ??
                getImageUrl(property.primaryImage)
              }
              alt="property"
            />
          </div>
        </Link>

        <div className="d-flex flex-column justify-content-center gap-1 flex-grow-1 flex-lg-grow-0">
          <div className="d-flex align-items-center">
            <p className="col">
              <span className="lh-1 mw-100 text-truncate">
                {property.shortAddress
                  ? `${property.shortAddress}${
                      property.unit ? ` Unit ${property.unit}` : ""
                    }`
                  : ""}
              </span>
              <br />
              <span className="small-text text-body-tertiary w-100 d-block d-lg-none">
                {property.city || property.state || property.zipCode
                  ? `${property.city}, ${property.state} ${property.zipCode}`
                  : ""}
              </span>
            </p>
            <CustomDropdown
              menuItems={
                property.status === "archived"
                  ? propertyArchivedListMenuItems
                  : propertyListMenuItems
              }
              onMenuItemClick={(action) =>
                handleMenuItemClick(action, property._id)
              }
              icon={<BsThreeDotsVertical size={16} />}
              className="invisible z-1 h-100 text-secondary d-lg-none col-2"
            />
          </div>

          <span className="small-text text-body-tertiary d-none d-lg-block">
            {property.city || property.state || property.zipCode
              ? `${property.city}, ${property.state} ${property.zipCode}`
              : ""}
          </span>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-1 align-items-center">
              <div className="d-lg-none">
                {/* Wrapper with pointer-none to disabled component click */}
                <div className="pe-none">
                  <PropertyStatusSelect
                    property={property}
                    queryKey={queryKey}
                  />
                </div>
              </div>
              <RouterStatus kit={property.kit} property={property._id} />
            </div>
            <div className="d-flex d-lg-none align-items-center gap-1">
              {property.status === "archived" ? (
                <div style={{ width: "16px" }}></div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="d-flex d-lg-none justify-content-between pt-1">
            <span className="xs-text">
              {" "}
              Price:{" "}
              {property.price
                ? `$${new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(property.price)}`
                : "N/A"}
            </span>
            <span className="xs-text">Leads: {property.leadsCount}</span>
            <span className="xs-text">
              Listed on:{" "}
              {property.listedTs
                ? format(property.listedTs, "MM/dd/yyyy")
                : "No Listed Date"}
            </span>
          </div>
        </div>
      </div>

      <span className="col-lg-2 align-items-center justify-content-center d-none d-lg-flex">
        {`[${property.user?.company?.name ?? ""}] ${
          property.user?.email ?? "No user email found"
        }`}
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        <button
          className="btn btn-secondary px-3 py-2 rounded-2"
          onClick={handleRedirectModal}
          disabled={!profile?.isManager}
        >
          Manage ({property.redirectCount ?? 0})
        </button>
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        {property.price
          ? `$${new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(property.price)}`
          : "N/A"}
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        {property.leadsCount}
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        {property.listedTs
          ? format(property.listedTs, "MM/dd/yyyy")
          : "No Listed Date"}
      </span>

      <span className="col-lg-2 align-items-center justify-content-center d-none d-lg-flex">
        <div className="pe-none">
          <PropertyStatusSelect property={property} queryKey={queryKey} />
        </div>
      </span>

      <span className="col-lg-1 align-items-center justify-content-center d-none d-lg-flex">
        <CustomDropdown
          menuItems={[{ label: "Changes history" }]}
          onMenuItemClick={(action) =>
            handleMenuItemClick(action, property._id)
          }
          icon={<BsThreeDotsVertical size={22} />}
          className="z-1 h-100"
        />
      </span>
    </div>
  );
};

export default AdminPropertyListCard;
