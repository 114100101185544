import React, { useRef, useCallback, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Spinner,
  Button,
  Image,
} from "react-bootstrap";
import { formatDistance } from "date-fns";
import getImageUrl from "../../../helper/checkImageFormat";
import Placeholder from "../../../assests/images/1-placeholder-X2.png";
import { getPropertyChangesHistory } from "../../../api/history/get";
import { useParams } from "react-router-dom";
import PageHeader from "../../../components/Headers/PageHeader";

const PropertyHistory = () => {
  const observerTarget = useRef(null);
  const params = useParams();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteQuery({
      queryKey: ["propertyHistory", params.id],
      queryFn: getPropertyChangesHistory,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    });

  // Intersection observer for infinite scrolling
  const intObserver = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  // Set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(intObserver, { threshold: 0.5 });
    const currentTarget = observerTarget.current;

    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [intObserver]);

  // Helper function to format timestamp
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return {
      formattedDate: new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(date),
      formattedTime: new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(date),
      relativeTime: formatDistance(date, new Date(), { addSuffix: true }),
    };
  };

  // Helper to determine badge color based on operation type
  const getOperationBadge = (operation) => {
    const operations = {
      create: "success",
      update: "primary",
      delete: "danger",
    };

    return operations[operation] || "secondary";
  };

  // Helper to handle different types of values
  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return <em className="text-muted">None</em>;
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return <em className="text-muted">Empty list</em>;
      }

      // Check if array contains image paths
      if (typeof value[0] === "string" && value[0].includes("/images/")) {
        return (
          <div className="d-flex gap-2 flex-wrap">
            {value.map((path, idx) => (
              <div key={idx} className="position-relative image-thumbnail">
                <Image
                  src={getImageUrl(path)}
                  thumbnail
                  className="timeline-image"
                  style={{ width: "80px", height: "80px", objectFit: "cover" }}
                  onError={(e) => {
                    e.target.src = Placeholder;
                  }}
                />
              </div>
            ))}
          </div>
        );
      }

      return (
        <ul className="mb-0 ps-3">
          {value.map((item, idx) => (
            <li key={idx}>
              {typeof item === "object" ? JSON.stringify(item) : String(item)}
            </li>
          ))}
        </ul>
      );
    }

    if (typeof value === "object") {
      return <pre className="mb-0">{JSON.stringify(value, null, 2)}</pre>;
    }

    return String(value);
  };

  const renderObjectDiff = (oldValue, newValue) => {
    // Handle non-object cases or when one side is null/undefined
    if (
      !oldValue ||
      !newValue ||
      typeof oldValue !== "object" ||
      typeof newValue !== "object" ||
      Array.isArray(oldValue) ||
      Array.isArray(newValue)
    ) {
      return (
        <Row>
          <Col xs={12} md={6} className="mb-2 mb-md-0">
            <div className="p-2 bg-light rounded">
              <small className="text-muted d-block mb-1">Previous Value:</small>
              <div>{renderValue(oldValue)}</div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="p-2 bg-light rounded">
              <small className="text-muted d-block mb-1">New Value:</small>
              <div>{renderValue(newValue)}</div>
            </div>
          </Col>
        </Row>
      );
    }

    // Find all keys from both objects
    const allKeys = [
      ...new Set([...Object.keys(oldValue), ...Object.keys(newValue)]),
    ];

    // Find changed keys
    const changedKeys = allKeys.filter((key) => {
      // If key only exists in one object, it changed
      if (!(key in oldValue) || !(key in newValue)) return true;

      // Otherwise, check if values differ
      return JSON.stringify(oldValue[key]) !== JSON.stringify(newValue[key]);
    });

    // If nothing changed, show that
    if (changedKeys.length === 0) {
      return <div className="text-muted">No changes</div>;
    }

    return (
      <div className="object-diff">
        {changedKeys.map((key) => {
          const oldVal = key in oldValue ? oldValue[key] : undefined;
          const newVal = key in newValue ? newValue[key] : undefined;

          // Recursively handle nested objects (but not arrays)
          if (
            oldVal &&
            newVal &&
            typeof oldVal === "object" &&
            typeof newVal === "object" &&
            !Array.isArray(oldVal) &&
            !Array.isArray(newVal)
          ) {
            return (
              <div key={key} className="nested-diff mb-3">
                <div className="nested-field fw-bold mb-2">{key}:</div>
                <div className="ps-3">{renderObjectDiff(oldVal, newVal)}</div>
              </div>
            );
          }

          // Handle regular field differences
          return (
            <div key={key} className="mb-3">
              <div className="field-name mb-2 fw-bold">{key}:</div>
              <Row>
                <Col xs={12} md={6} className="mb-2 mb-md-0">
                  <div className="p-2 bg-light rounded">
                    <small className="text-muted d-block mb-1">Previous:</small>
                    <div>
                      {key in oldValue ? (
                        renderValue(oldValue[key])
                      ) : (
                        <em className="text-muted">Not set</em>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="p-2 bg-light rounded">
                    <small className="text-muted d-block mb-1">New:</small>
                    <div>
                      {key in newValue ? (
                        renderValue(newValue[key])
                      ) : (
                        <em className="text-muted">Removed</em>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  };

  // Render loading state
  if (status === "loading") {
    return (
      <Container className="py-5 text-center">
        <Spinner animation="border" variant="primary" />
        <p className="mt-2">Loading history...</p>
      </Container>
    );
  }

  // Render error state
  if (status === "error") {
    return (
      <Container className="py-5">
        <Card className="border-danger">
          <Card.Body className="text-center">
            <Card.Title className="text-danger">Error</Card.Title>
            <Card.Text>
              There was an error loading the property history. Please try again
              later.
            </Card.Text>
            <Button
              variant="outline-danger"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <PageHeader>
        <h4>Property History</h4>
      </PageHeader>

      <div className="timeline-container pt-3">
        {data?.pages.map((page, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {page.data.map((historyItem, itemIndex) => {
              const { formattedDate, formattedTime, relativeTime } =
                formatTimestamp(historyItem.timestamp);

              return (
                <div key={historyItem._id} className="timeline-item mb-4">
                  <Row className="g-0">
                    {/* Date Column */}
                    <Col xs={12} md={2} className="timeline-date">
                      <div className="text-md-end pe-md-4">
                        <h5 className="mb-0">{formattedDate}</h5>
                        <p className="text-muted mb-2">{formattedTime}</p>
                        <p className="text-muted small mb-0">{relativeTime}</p>
                      </div>
                    </Col>

                    {/* Timeline Line */}
                    <Col xs={1} className="d-none d-md-block timeline-line">
                      <div className="timeline-bullet bg-primary"></div>
                    </Col>

                    {/* Content Column */}
                    <Col xs={12} md={9}>
                      <Card className="shadow-sm">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Badge
                            bg={getOperationBadge(historyItem.operation)}
                            className="text-uppercase"
                          >
                            {historyItem.operation}
                          </Badge>
                          <small className="text-muted">
                            ID: {historyItem.documentId}
                          </small>
                        </Card.Header>

                        <Card.Body>
                          {historyItem.changes.map((change, changeIndex) => (
                            <div
                              key={change._id}
                              className={
                                changeIndex < historyItem.changes.length - 1
                                  ? "mb-3 pb-3 border-bottom"
                                  : ""
                              }
                            >
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <h6 className="mb-0 text-capitalize fw-bold">
                                  {change.field}
                                </h6>
                              </div>

                              {/* Use the object diff renderer for objects, standard renderer otherwise */}
                              {change.oldValue &&
                              change.newValue &&
                              typeof change.oldValue === "object" &&
                              typeof change.newValue === "object" &&
                              !Array.isArray(change.oldValue) &&
                              !Array.isArray(change.newValue) ? (
                                renderObjectDiff(
                                  change.oldValue,
                                  change.newValue
                                )
                              ) : (
                                <Row>
                                  <Col xs={12} md={6} className="mb-2 mb-md-0">
                                    <div className="p-2 bg-light rounded">
                                      <small className="text-muted d-block mb-1">
                                        Previous Value:
                                      </small>
                                      <div>{renderValue(change.oldValue)}</div>
                                    </div>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <div className="p-2 bg-light rounded">
                                      <small className="text-muted d-block mb-1">
                                        New Value:
                                      </small>
                                      <div>{renderValue(change.newValue)}</div>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          ))}

                          <div className="mt-3 pt-2 border-top">
                            <small className="text-muted">
                              <span className="fw-bold">Collection:</span>{" "}
                              {historyItem.collectionName}
                            </small>
                            {historyItem.user && (
                              <small className="text-muted ms-3">
                                <span className="fw-bold">User:</span>{" "}
                                {`${historyItem.user.firstName} ${historyItem.user.lastName}`}
                              </small>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </React.Fragment>
        ))}

        {/* Loading indicator and intersection observer target */}
        <div ref={observerTarget} className="text-center py-3">
          {isFetchingNextPage && (
            <Spinner animation="border" size="sm" variant="primary" />
          )}

          {!hasNextPage && data?.pages[0]?.data.length > 0 && (
            <p className="text-muted">End of history</p>
          )}

          {data?.pages[0]?.data.length === 0 && (
            <p className="text-muted">No history records found</p>
          )}
        </div>
      </div>

      {/* Manual load more button as fallback */}
      {hasNextPage && !isFetchingNextPage && (
        <div className="text-center mt-3">
          <Button
            variant="outline-primary"
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
          >
            Load More
          </Button>
        </div>
      )}

      {/* CSS for timeline */}
      <style jsx>{`
        .timeline-container {
          position: relative;
        }

        .timeline-item {
          position: relative;
        }

        .timeline-line {
          position: relative;
        }

        .timeline-line:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          width: 2px;
          background-color: #dee2e6;
        }

        .timeline-bullet {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          z-index: 1;
          border: 2px solid white;
        }

        .timeline-image {
          transition: transform 0.2s;
        }

        .timeline-image:hover {
          transform: scale(1.05);
        }

        @media (max-width: 767.98px) {
          .timeline-date {
            margin-bottom: 1rem;
          }
        }
      `}</style>
    </Container>
  );
};

export default PropertyHistory;
