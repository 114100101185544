import { useQuery } from "@tanstack/react-query";
import { getCodeHistory } from "../utils/helpers";

interface Hardware {
    id: string;
    vendor: string;
    type: string;
}

export default function useCodeHistory(hardware: Hardware) {
    const {
        data: codeList = [],
        isLoading,
        isFetching,
        error,
        refetch,
    } = useQuery({
        queryKey: [hardware.type, hardware.id, "code-history"],
        queryFn: async () => await getCodeHistory(hardware),
        refetchOnWindowFocus: false,
    });

    // const queryClient = useQueryClient();
    // const { mutateAsync: deleteCodes, isPending: deletingCodes } = useMutation({
    //     mutationFn: () => deletePassCodes([], keypadId),
    //     onSuccess: () => {
    //         queryClient.invalidateQueries(["keypad", "keypadId", "code-history"]);
    //         toast.success("Selected codes have been removed successfully");
    //     },
    //     onError: (err) => {
    //         toast.error(
    //             `Failed to delete codes: ${
    //                 err.message || "Something went wrong."
    //             }`
    //         );
    //     },
    // });

    return {
        codeList,
        isLoading,
        isFetching,
        error,
        refetch,
        // deleteCodes,
        // deletingCodes,
    };
}
