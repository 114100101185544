import { Form, Dropdown } from "react-bootstrap";
import { PiHouseLineLight } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";
import UnstyledButton from "../../../../components/UnstyledButton";
import { usePropertiesDropdown } from "../../hooks/usePropertiesDropdown";
import { useSelection } from "../../hooks/useSelection";

const SelectPropertiesDropdown = ({
  title,
  handleApplyFilters,
  handleClearAllFilters,
  isAdmin = false
}) => {
  // Hook to fetch data and search
  const {
    searchTerm,
    setSearchTerm,
    propertiesWithFullAddress,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
  } = usePropertiesDropdown({ isAdmin });

  // Hook to handle selection and searchParams
  const {
    selectedItems,
    setSelectedItems,
    addOrRemoveSelectedItem,
    setScrollableElement,
  } = useSelection({
    searchParamKey: "properties",
    fetchNextPage,
    hasNextPage,
  });

  return (
    <Dropdown autoClose="outside" className="no-arrow properties-dropdown">
      <Dropdown.Toggle
        id="dropdown-basic"
        className="bg-white border border-1 text-body-tertiary fw-medium rounded-2 h-100"
      >
        <PiHouseLineLight size={20} />{" "}
        <span className="d-none d-lg-inline-block">{title}</span>
      </Dropdown.Toggle>

      {/* Container */}
      <Dropdown.Menu className="px-4 py-3">
        <span className="fw-semibold color-gray d-inline-block mb-3">
          Properties
        </span>

        {/* Search Input */}
        {isAdmin && (
          <div className="position-relative mb-3">
            <FaSearch 
              style={{marginLeft: "15px"}}
              className="position-absolute top-50 start-0 translate-middle-y fs-8 text-muted" 
            />
            <Form.Control
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search properties..."
              style={{ paddingLeft: "35px" }}
            />
          </div>
        )}

        {/* Loading spinner */}
        {isLoading && (
          <div className="d-flex justify-content-center py-4">
            <div className="spinner-border text-delet-blue" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {/* Error message */}
        {isError && (
          <p className="d-flex justify-content-center py-4">
            Error loading properties.
          </p>
        )}
        
        {/* Filter properties */}
        {!isLoading && !isError && (
          <>
            {/* Select properties */}
            <div className="d-flex flex-column-reverse flex-lg-column">
              <div
                ref={(el) => setScrollableElement(el)}
                style={{ maxHeight: "400px" }}
                className="overflow-y-scroll mb-3"
              >
                {propertiesWithFullAddress.map((property) => (
                  <div key={property._id} className="px-1 py-3 border-bottom">
                    <Form.Check
                      className="text-wrap"
                      type="checkbox"
                      id={`checkbox-${property._id}`}
                      label={
                        <span className="cursor-pointer">
                          {property.fullAddress}
                        </span>
                      }
                      checked={selectedItems.includes(property._id)}
                      onChange={() => addOrRemoveSelectedItem(property._id)}
                    />
                  </div>
                ))}
                {isFetchingNextPage && (
                  <div className="d-flex justify-content-center py-3">
                    <div className="spinner-border text-delet-blue" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>

              {/* Action buttons */}
              <div className="d-flex flex-lg-column justify-content-center justify-content-lg-start gap-3 py-3 py-lg-0">
                <UnstyledButton
                  onClick={handleApplyFilters}
                  className="text-delet-blue fw-semibold"
                >
                  Apply filters
                </UnstyledButton>
                <UnstyledButton
                  onClick={() => {
                    handleClearAllFilters();
                    setSelectedItems([]);
                  }}
                  className="text-body-tertiary"
                >
                  Clear all
                </UnstyledButton>
              </div>
            </div>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectPropertiesDropdown;
