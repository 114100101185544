import { ReactNode } from "react";
import { Card, Flex, Group, Text } from "@mantine/core";
import { PiCalendarCheckLight, PiWarningCircle } from "react-icons/pi";
import {
    IoMdCheckmarkCircleOutline,
    IoMdCloseCircleOutline,
} from "react-icons/io";

const BOOKINGS: {
    key: keyof ChartData["totalCount"];
    label: string;
    color: string;
    icon: ReactNode;
}[] = [
    {
        key: "scheduled",
        label: "Scheduled",
        color: "#5271FF",
        icon: <PiCalendarCheckLight size={18} />,
    },
    {
        key: "show",
        label: "Showed Up",
        color: "#9499A1",
        icon: <IoMdCheckmarkCircleOutline size={18} />,
    },
    {
        key: "missed",
        label: "Didn't Show Up",
        color: "#6D6C80",
        icon: <PiWarningCircle size={18} />,
    },
    {
        key: "cancelled",
        label: "Cancelled",
        color: "#BCC5E9",
        icon: <IoMdCloseCircleOutline size={18} />,
    },
];

interface ChartData {
    dailyCount: {
        date: string;
        scheduled: number;
        show: number;
        missed: number;
        cancelled: number;
    }[];
    totalCount: {
        scheduled: number;
        show: number;
        missed: number;
        cancelled: number;
    };
}

interface CardLineChartCountsProps {
    chartData: ChartData | null;
}

export default function CardLineChartCounts({
    chartData,
}: CardLineChartCountsProps) {
    if (!chartData || !chartData.totalCount) return null;

    return (
        <Flex gap={{ base: "sm", md: "xl" }} direction="column" w="100%">
            {BOOKINGS.map(({ key, label, color, icon }) => (
                <Card
                    key={key}
                    w="100%"
                    shadow="xs"
                    padding="md"
                    radius="md"
                    withBorder
                    bg="#DEE2E61A"
                >
                    <Flex direction="column" align="flex-start">
                        <Group c={color} gap="5px">
                            {icon}
                            <Text size="xs" fw={500}>
                                {label}
                            </Text>
                        </Group>
                        <Text style={{ paddingLeft: "2px" }} c="gray.8">
                            {chartData.totalCount[key] ?? 0} people
                        </Text>
                    </Flex>
                </Card>
            ))}
        </Flex>
    );
}
