import { Form, Dropdown } from "react-bootstrap";
import { BsBuildings } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import UnstyledButton from "../../../components/UnstyledButton";
import { useSelection } from "../../Showings/hooks/useSelection";
import { useCompaniesDropdown } from "../../Showings/hooks/useCompaniesDropdown";

const SelectCompaniesDropdown = ({
    title,
    toggleClassName,
    handleCompaniesApplyFilter,
    handleCompaniesClearFilter,
    isAdmin = false,
}) => {
    // Hook to fetch data and search
    const {
        searchTerm,
        setSearchTerm,
        allCompanies,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
    } = useCompaniesDropdown({ isAdmin });

    // Hook to handle selection and searchParams
    const {
        selectedItems,
        setSelectedItems,
        addOrRemoveSelectedItem,
        setScrollableElement,
    } = useSelection({
        searchParamKey: "companies",
        fetchNextPage,
        hasNextPage,
    });

    return (
        <Dropdown autoClose="outside" className="no-arrow properties-dropdown">
            <Dropdown.Toggle
                variant="light"
                id="dropdown-basic"
                className={toggleClassName}
            >
                <BsBuildings size={17} />{" "}
                <span className="d-none d-lg-inline-block">{title}</span>
            </Dropdown.Toggle>

            {/* Container */}
            <Dropdown.Menu className="px-4 py-3">
                <span className="fw-semibold color-gray d-inline-block mb-3">
                    {title}
                </span>

                {/* Search Input */}
                {isAdmin && (
                    <div className="position-relative mb-3">
                        <FaSearch
                            style={{ marginLeft: "15px" }}
                            className="position-absolute top-50 start-0 translate-middle-y fs-8 text-muted"
                        />
                        <Form.Control
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search companies..."
                            style={{ paddingLeft: "35px" }}
                        />
                    </div>
                )}

                {/* Loading spinner */}
                {isLoading && (
                    <div className="d-flex justify-content-center py-4">
                        <div
                            className="spinner-border text-delet-blue"
                            role="status"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                {/* Error message */}
                {isError && (
                    <p className="d-flex justify-content-center py-4">
                        Error loading companies.
                    </p>
                )}

                {/* Filter companies */}
                {!isLoading && !isError && (
                    <>
                        {/* Select companies */}
                        <div className="d-flex flex-column-reverse flex-lg-column">
                            <div
                                ref={(el) => setScrollableElement(el)}
                                style={{ maxHeight: "400px" }}
                                className="overflow-y-scroll mb-3"
                            >
                                {allCompanies.map((property) => (
                                    <div
                                        key={property._id}
                                        className="px-1 py-3 border-bottom"
                                    >
                                        <Form.Check
                                            className="text-wrap"
                                            type="checkbox"
                                            id={`checkbox-${property._id}`}
                                            label={
                                                <span className="cursor-pointer">
                                                    {property.name}
                                                </span>
                                            }
                                            checked={selectedItems.includes(
                                                property._id
                                            )}
                                            onChange={() =>
                                                addOrRemoveSelectedItem(
                                                    property._id
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                                {isFetchingNextPage && (
                                    <div className="d-flex justify-content-center py-3">
                                        <div
                                            className="spinner-border text-delet-blue"
                                            role="status"
                                        >
                                            <span className="visually-hidden">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Action buttons */}
                            <div className="d-flex flex-lg-column justify-content-center justify-content-lg-start gap-3 py-3 py-lg-0">
                                <UnstyledButton
                                    onClick={handleCompaniesApplyFilter}
                                    className="text-delet-blue fw-semibold"
                                >
                                    Apply filters
                                </UnstyledButton>
                                <UnstyledButton
                                    onClick={() => {
                                        handleCompaniesClearFilter();
                                        setSelectedItems([]);
                                    }}
                                    className="text-body-tertiary"
                                >
                                    Clear all
                                </UnstyledButton>
                            </div>
                        </div>
                    </>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SelectCompaniesDropdown;
