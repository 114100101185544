import {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from "react";
import {
    getDoorOpened,
    getKitsInstalledRemoved,
    getLeads,
    getPropertyAmount,
    getScheduledTours,
    getShowingsTotal,
} from "../../api/dashboard";

// Each metric data has current and past value
interface MetricData {
    current: number | null;
    past: number | null;
}

// Define the shape of the context state
interface DashboardContextType {
    rangeValue: string;
    setRangeValue: (value: string) => void;
    dateValue: [Date | null, Date | null];
    setDateValue: (value: [Date | null, Date | null]) => void;
    metrics: Record<string, MetricData>;
    isLoading: boolean;
}

// Create the context with default values
const DashboardContext = createContext<DashboardContextType | undefined>(
    undefined
);

// Provider component
export function DashboardProvider({ children }: { children: ReactNode }) {
    // ======================
    // === States Section ===
    // ======================

    // Filters
    const [rangeValue, setRangeValue] = useState("Custom");
    const [dateValue, setDateValue] = useState<[Date | null, Date | null]>(
        () => {
            const today = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - 7);
            return [sevenDaysAgo, today];
        }
    );

    // All metrics
    const [metrics, setMetrics] = useState<Record<string, MetricData>>({});

    // Loading flag
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // =============================
    // === Dynamic Data Fetching ===
    // =============================

    useEffect(() => {
        // Validate dates input before fetching
        const [startDate, endDate] = dateValue;
        if (!startDate || !endDate) return;

        // Ensure dates range is valid
        const MAX_RANGE_DAYS = 60;
        const diffInDays = (endDate.getTime() - startDate.getTime()) / 86400000;
        if (diffInDays > MAX_RANGE_DAYS) return;

        // Determine offset days (7 days for Weekly, 30 for Monthly)
        const offsetDays = rangeValue === "Weekly" ? 7 : 30;

        // Compute past dates only if both dates exist
        const pastStartDate = new Date(
            startDate.getTime() - offsetDays * 24 * 60 * 60 * 1000
        );
        const pastEndDate = new Date(
            endDate.getTime() - offsetDays * 24 * 60 * 60 * 1000
        );

        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Fetch data
                const [
                    cd1,
                    pd1,
                    cd2,
                    pd2,
                    cd3,
                    pd3,
                    cd4,
                    pd4,
                    cd5,
                    pd5,
                    cd6,
                    pd6,
                ] = await Promise.all([
                    getScheduledTours(startDate, endDate),
                    getScheduledTours(pastStartDate, pastEndDate),
                    getPropertyAmount(startDate, endDate),
                    getPropertyAmount(pastStartDate, pastEndDate),
                    getLeads(startDate, endDate),
                    getLeads(pastStartDate, pastEndDate),
                    getDoorOpened(startDate, endDate),
                    getDoorOpened(pastStartDate, pastEndDate),
                    getKitsInstalledRemoved(startDate, endDate),
                    getKitsInstalledRemoved(pastStartDate, pastEndDate),
                    getShowingsTotal(startDate, endDate),
                    getShowingsTotal(pastStartDate, pastEndDate),
                ]);

                // Store results
                setMetrics({
                    scheduledTours: {
                        current: cd1.scheduledTours ?? 0,
                        past: pd1.scheduledTours ?? 0,
                    },
                    propertyAmount: {
                        current: cd2.propertyAmount ?? 0,
                        past: pd2.propertyAmount ?? 0,
                    },
                    leads: {
                        current: cd3.leads ?? 0,
                        past: pd3.leads ?? 0,
                    },
                    doorOpenedWithDelet: {
                        current: cd4.deletUnlockCount ?? 0,
                        past: pd4.deletUnlockCount ?? 0,
                    },
                    doorOpenedManually: {
                        current: cd4.manualUnlockCount ?? 0,
                        past: pd4.manualUnlockCount ?? 0,
                    },
                    kitsInstalled: {
                        current: cd5.kitsInstalled ?? 0,
                        past: pd5.kitsInstalled ?? 0,
                    },
                    kitsRemoved: {
                        current: cd5.kitsRemoved ?? 0,
                        past: cd5.kitsRemoved ?? 0,
                    },
                    showings: {
                        current: cd6.totalCount ?? 0,
                        past: pd6.totalCount ?? 0,
                    },
                });
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
                setMetrics({});
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [rangeValue, dateValue]);

    return (
        <DashboardContext.Provider
            value={{
                rangeValue,
                setRangeValue,
                dateValue,
                setDateValue,
                metrics,
                isLoading,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
}

// Custom hook for using the context
export function useDashboardContext() {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error(
            "useDashboardContext must be used within a DashboardProvider"
        );
    }
    return context;
}
