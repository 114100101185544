import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import {
    Modal,
    Button,
    Form,
    Spinner,
    Alert,
    InputGroup,
    Row,
    Col,
    Container,
} from "react-bootstrap";

const ManageRedirectUrl = ({ show, onHide, propertyId }) => {
    const [hashCode, setHashCode] = useState("");
    const [originalUrl, setOriginalUrl] = useState("");
    const [redirectCount, setRedirectCount] = useState(0);
    const [copied, setCopied] = useState(false);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const isReadOnly = hashCode && !editing;

    useEffect(() => {
        const fetchExistingRedirect = async () => {
            if (show && propertyId) {
                setFetching(true);
                setError(null);
                try {
                    const auth = await Auth.currentSession();
                    const headers = {
                        Authorization: `Bearer ${auth
                            .getIdToken()
                            .getJwtToken()}`,
                    };

                    const url = `${process.env.REACT_APP_NODE_API_URL}/api/redirectUrl?propertyId=${propertyId}`;
                    const { data } = await axios.get(url, { headers });

                    if (data?.hashCode && data?.originalUrl) {
                        setHashCode(data.hashCode);
                        setOriginalUrl(data.originalUrl);
                        setRedirectCount(data.redirectCount);
                        setEditing(false);
                    } else {
                        setHashCode("");
                        setOriginalUrl("");
                        setRedirectCount(0);
                        setEditing(false);
                    }
                } catch (error) {
                    setError("Failed to fetch existing redirect URL.");
                    console.error("Error fetching redirect URL:", error);
                    setHashCode("");
                    setOriginalUrl("");
                    setRedirectCount(0);
                    setEditing(false);
                } finally {
                    setFetching(false);
                }
            }
        };

        fetchExistingRedirect();
    }, [show, propertyId]);

    const handleGenerate = async () => {
        setLoading(true);
        setError(null);
        try {
            const auth = await Auth.currentSession();
            const headers = {
                Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
            };

            const url = `${process.env.REACT_APP_NODE_API_URL}/api/redirectUrl`;
            const { data } = await axios.post(
                url,
                { originalUrl, propertyId },
                { headers }
            );

            setHashCode(data.hashCode);
            setEditing(false);
        } catch (error) {
            setError("Error generating redirect URL.");
            console.error("Error generating URL:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = () => {
        const fullRedirect = `${process.env.REACT_APP_URL}/apply?id=${hashCode}`;
        navigator.clipboard.writeText(fullRedirect);

        // Show "Copied!" text
        setCopied(true);

        // Reset back to "Copy" after 1 second
        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <Modal
            contentClassName="px-3 py-2"
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="fs-5">Manage Redirect URL</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {fetching ? (
                    <div className="text-center my-4">
                        <Spinner
                            style={{ color: "#0d6efd" }}
                            animation="border"
                            role="status"
                        />
                        <p className="mt-2">Loading redirect data...</p>
                    </div>
                ) : error ? (
                    <Alert variant="danger" className="m-0">
                        {error}
                    </Alert>
                ) : (
                    <Container className="my-3">
                        {isReadOnly && (
                            <p className="fs-6 fw-semibold mb-3">
                                Redirect count: {redirectCount}
                            </p>
                        )}
                        <p className="fs-6 fw-semibold mb-2">
                            {isReadOnly
                                ? "Delet redirect URL:"
                                : "Enter original URL:"}
                        </p>
                        {isReadOnly ? (
                            <InputGroup>
                                <Form.Control
                                    readOnly
                                    value={`${process.env.REACT_APP_URL}/apply?id=${hashCode}`}
                                    className="w-100"
                                />
                            </InputGroup>
                        ) : (
                            <Form>
                                <Form.Group controlId="originalUrl">
                                    <Form.Control
                                        type="text"
                                        placeholder="https://example.com"
                                        value={originalUrl}
                                        onChange={(e) =>
                                            setOriginalUrl(e.target.value)
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                handleGenerate();
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Form>
                        )}
                    </Container>
                )}
            </Modal.Body>

            {/* Buttons in the footer - Only 2 should appear at a time */}
            <Modal.Footer className="w-100">
                <Row className="gx-4 gy-2 w-100">
                    {isReadOnly ? (
                        // Copy & Edit Buttons (Appear in read-only mode)
                        <>
                            <Col xs={12} sm={6}>
                                <Button
                                    variant="secondary"
                                    size="lg"
                                    className="w-100"
                                    onClick={() => setEditing(true)}
                                >
                                    Edit
                                </Button>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button
                                    size="lg"
                                    className="w-100 bg-delet"
                                    onClick={handleCopy}
                                    disabled={copied}
                                >
                                    {copied ? "Copied!" : "Copy"}
                                </Button>
                            </Col>
                        </>
                    ) : (
                        // Cancel & Generate Buttons (Appear in edit mode)
                        <>
                            <Col xs={12} sm={6}>
                                <Button
                                    variant="secondary"
                                    size="lg"
                                    className="w-100"
                                    onClick={onHide}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button
                                    size="lg"
                                    className="w-100 bg-delet"
                                    onClick={handleGenerate}
                                    disabled={loading || !originalUrl}
                                >
                                    {loading ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                            />
                                            Generating...
                                        </>
                                    ) : (
                                        "Generate URL"
                                    )}
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ManageRedirectUrl;
