import { Table } from "@tanstack/react-table";
import { PropertyShare } from "./types";
import { ChevronLeft, ChevronRight } from "react-feather";

interface EmailHistoryPaginationProps {
    table: Table<PropertyShare>;
}

export default function EmailHistoryPagination({
    table,
}: EmailHistoryPaginationProps) {
    return (
        <div className="d-flex justify-content-center align-items-center mt-4 gap-1">
            {/* Left Arrow for previous page */}
            <button
                className="btn border-0"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
            >
                <ChevronLeft className="text-muted" size={18} />
            </button>

            {/* Page Number Buttons */}
            {Array.from({ length: table.getPageCount() }, (_, pageIndex) => (
                <button
                    key={pageIndex}
                    className={`btn fw-medium ${
                        pageIndex === table.getState().pagination.pageIndex
                            ? "text-delet-blue"
                            : "text-muted"
                    }`}
                    onClick={() => table.setPageIndex(pageIndex)}
                >
                    {pageIndex + 1}
                </button>
            ))}

            {/* Right Arrow for next page */}
            <button
                className="btn border-0"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
            >
                <ChevronRight className="text-muted" size={18} />
            </button>
        </div>
    );
}
