import { useEffect, useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getCompanies } from "../../../api/companies";

interface Company {
    name: string;
}

interface GetCompaniesResponse {
    totalPages: number;
    companies: Company[];
}

interface UseCompaniesDropdownProps {
    isAdmin: boolean;
}

export const useCompaniesDropdown = ({
    isAdmin,
}: UseCompaniesDropdownProps) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] =
        useState<string>(searchTerm);

    useEffect(() => {
        const delay = 300;
        const handler = setTimeout(
            () => setDebouncedSearchTerm(searchTerm),
            delay
        );
        return () => clearTimeout(handler);
    }, [searchTerm]);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
    } = useInfiniteQuery<GetCompaniesResponse>({
        queryKey: ["companies", "calendar", debouncedSearchTerm],
        queryFn: ({ pageParam = 1 }) =>
            getCompanies({
                page: pageParam,
                limit: 10,
                filters: { search: debouncedSearchTerm },
                sort: { by: "name", order: 1 },
                select: ["name"],
                isAdmin,
            }),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) =>
            lastPage.totalPages > pages.length ? pages.length + 1 : undefined,
    });

    // Combine all pages into a single array
    const allCompanies = useMemo(() => {
        return data?.pages.flatMap((page) => page.companies) || [];
    }, [data]);

    // Auto-fetch more pages when search is active and filtered result count is below threshold
    useEffect(() => {
        if (
            searchTerm.trim() &&
            hasNextPage &&
            !isFetchingNextPage &&
            allCompanies.length < 10
        ) {
            fetchNextPage();
        }
    }, [
        searchTerm,
        allCompanies,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    ]);

    return {
        searchTerm,
        setSearchTerm,
        allCompanies,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
    };
};
