import { useQuery } from '@tanstack/react-query';
import { Card } from '../../../../designSystem/Card';
import { format } from 'date-fns';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { getMeeting, getMeetingWithToken } from '../../../../api/bookings';
import style from './style.module.scss';
import check from '../../../../assests/images/check.svg';
import { ReactSVG } from 'react-svg';
import { MdLocalPhone, MdEmail } from 'react-icons/md';
import { IoMdPerson } from 'react-icons/io';
import { CiLocationOn } from "react-icons/ci";
import { BsFillBuildingsFill } from "react-icons/bs";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import { toZonedTime } from 'date-fns-tz';

export const ConfirmationPage = () => {
  const { meetingId } = useParams();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token")

  const {
    data: booking,
    isFetching: isLoading,
    isError,
  } = useQuery({
    queryKey: ['booking', meetingId ?? token],
    queryFn: (): any => meetingId ? getMeeting(meetingId) : getMeetingWithToken(token),
  });

  const startTime = toZonedTime(booking?.booking?.startTime * 1000, booking?.booking?.property?.timezone)
  const styleCheckIcon = (svg: SVGElement) => {
    svg.querySelector('path')?.setAttribute('stroke', '#979797');
  };

  return isLoading ? (
    <div className='d-flex flex-column align-items-center justify-content-center' style={{ marginTop: '250px' }}>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='spinner-border m-5' style={{ width: '3rem', height: '3rem', color: '#5271ff' }} role='status'></div>
      </div>
      <header className={style.header}>
        <h1 className={style.heading}>
          We are Scheduling Your Tour
        </h1>
        <Card.Title className={style.cardTitle}>
          Please hold on a moment while we finalize your details. We'll confirm everything shortly!
        </Card.Title>
      </header>
    </div>
  ) : isError ? (
    <strong>Error...</strong>
  ) : (
    <section className={style.layout}>
      <header className={style.header}>
        <h1 className={style.heading}>
          Booking{' '}
          {booking.action === 'reschedule'
            ? 'Rescheduled'
            : 'Created'}
        </h1>
        <Card.Title className={style.cardTitle}>
          Your booking was successfully{' '}
          {booking.action === 'reschedule'
            ? 'rescheduled'
            : 'created'}
          !
        </Card.Title>
      </header>
      <Card className={style.card}>

        <article className={style.information}>

          <table>
            <tbody>

              <tr className={style.rows}>
                <td className={style.leftTd}><IoMdPerson size={14} /> With</td>
                <td className={style.rightTd}>{booking?.booking?.user?.firstName || 'NA'}</td>
              </tr>
              <tr className={style.rows}>
                <td className={style.leftTd}><BsFillBuildingsFill size={14} /> Agency</td>
                <td className={style.rightTd}>{booking?.booking?.user?.company?.name || 'NA'}</td>
              </tr>
              <tr className={style.rows}>
                <td className={style.leftTd}><MdEmail size={14} /> Email</td>
                <td className={style.rightTd}>{booking?.booking?.contact?.email}</td>
              </tr>
              <tr className={style.rows}>
                <td className={style.leftTd}><MdLocalPhone size={14} /> Phone Number</td>
                <td className={style.rightTd}>{booking?.booking?.contact?.phone}</td>
              </tr>
              <tr className={style.rows}>
                <td className={style.leftTd}><CiLocationOn size={14} /> Location</td>
                <td className={style.rightTd}>{`${booking?.booking?.property?.shortAddress}${booking?.booking?.property?.unit ? ` Unit ${booking?.booking?.property?.unit}` : ''}, ${booking?.booking?.property?.city}, ${booking?.booking?.property?.state} ${booking?.booking?.property?.zipCode}`}</td>
              </tr>
              <tr className={style.rows}>
                <td className={style.leftTd}><GoCalendar size={14} /> On</td>
                <td className={style.rightTd}>{format(startTime, 'MMMM dd, yyyy')}</td>
              </tr>
              <tr className={style.bottomRow}>
                <td className={style.leftTd}><FaRegClock size={14} /> At</td>
                <td className={style.rightTd}>{format(startTime, 'h:mm a')}</td>
              </tr>
            </tbody>
          </table>

        </article>

      </Card>
      <div className={style.notificationStatusGroup}>
        <p className={style.notificationStatus}>
          <ReactSVG
            beforeInjection={styleCheckIcon}
            src={check}
          />{' '}
          Sent{' '}
          to {booking?.booking?.contact?.phone || 'NA'}
        </p>
        <p className={style.notificationStatus}>
          <ReactSVG
            beforeInjection={styleCheckIcon}
            src={check}
          />{' '}
          Sent{' '}
          to {booking?.booking?.contact?.email || 'NA'}
        </p>
      </div>
      <p className={style.bottomText}>
        Do you want to make changes?
        <br />
        You can{' '}
        <Link
          className={style.link}
          to={`${booking?.link}`}
        >
          reschedule your booking here
        </Link>
      </p>
    </section>
  );
};
