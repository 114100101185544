// EditCompany.jsx
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { getCompany, patchUpdateCompany } from "../../../api/companies";
import toast from "react-hot-toast";
import Sidebar from "../components/AdminSideBar";
import PageHeader from "../../../components/Headers/PageHeader";
import CompanyForm from "./components/CompanyForm";

const EditCompanyPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Fetch company data
  const { data: company, isLoading: isLoadingCompany } = useQuery({
    queryKey: ["company", id],
    queryFn: () => getCompany(id),
  });

  // Update company mutation
  const updateCompanyMutation = useMutation({
    mutationFn: (formData) => {
      // Create a FormData object to handle file uploads
      const submitData = new FormData();

      // Add regular form fields
      submitData.append("name", formData.name);
      submitData.append("units", formData.units);
      submitData.append("phone", formData.phone || "");
      submitData.append("email", formData.email || "");
      submitData.append("description", formData.description || "");
      submitData.append("users", formData.users || "");

      // Add nested address fields
      if (formData.address) {
        Object.keys(formData.address).forEach((key) => {
          if (formData.address[key]) {
            submitData.append(`address[${key}]`, formData.address[key]);
          }
        });
      }

      // Add file uploads only if new files were selected
      if (
        formData.logoUrl &&
        formData.logoUrl.length > 0 &&
        typeof formData.logoUrl !== "string"
      ) {
        submitData.append("logoUrl", formData.logoUrl[0]);
      }

      if (
        formData.idImage &&
        formData.idImage.length > 0 &&
        typeof formData.idImage !== "string"
      ) {
        submitData.append("idImage", formData.idImage[0]);
      }

      return patchUpdateCompany({ updatedFields: submitData, companyId: id });
    },
    onSuccess: () => {
      toast.success("Company updated successfully!");
      queryClient.invalidateQueries(["company", id]);
      navigate("/admin/company/list");
    },
    onError: (error) => {
      toast.error(`Failed to update company: ${error.message}`);
    },
  });

  const onSubmit = (data) => {
    updateCompanyMutation.mutate(data);
  };

  return (
    <Sidebar>
      <PageHeader>
        <h6>Edit Company</h6>
      </PageHeader>
      <div className="px-lg-5 py-3">
        {isLoadingCompany ? (
          <div>Loading...</div>
        ) : (
          <CompanyForm
            initState={company}
            onSubmit={onSubmit}
            isLoading={updateCompanyMutation.isPending}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default EditCompanyPage;
