import { Auth } from "aws-amplify"
import axios from "axios"

export const getIglooLockboxList = async () => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/igloo/devices`

  try {
    const token = await Auth.currentSession();

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    });

    const lockboxList = res.data.payload
      .filter(item => item.type === "Lock")
      .map(item => ({
        id: item.deviceId,
        name: item.deviceName
      }));

    return lockboxList;
  } catch (error) {
    console.error(error)
  }
}

export const cleanExpiredAccessCodes = async (deviceId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/igloo/lock/${deviceId}/codes`;

  try {
    const token = await Auth.currentSession();

    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    });

    return response.data;

  } catch (error) {
    console.error(error)
  }
}