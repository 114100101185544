import { DatePickerInput } from "@mantine/dates";
import { useEffect, useState } from "react";
import { CiCalendar } from "react-icons/ci";

type DatesRangeValue = [Date | null, Date | null];

interface FilterDateProps {
    dateValue: DatesRangeValue;
    setDateValue: React.Dispatch<React.SetStateAction<DatesRangeValue>>;
    rangeValue: string;
}

export default function FilterDate({
    dateValue,
    setDateValue,
    rangeValue,
}: FilterDateProps) {
    // Date limits
    const [minDate, setMinDate] = useState<Date | undefined>(undefined);
    const [maxDate, setMaxDate] = useState<Date>(new Date());

    // Default Dates
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    useEffect(() => {
        // Ensure rangeValue is valid & avoid infinite loops
        const MAX_RANGE_DAYS = 60;
        const DEFAULT_RANGE_DAYS = 7;
        const offset: Record<string, number> = { Weekly: 7, Monthly: 30 };

        // Default dates
        if (!dateValue[0] && !dateValue[1]) {
            const today = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - DEFAULT_RANGE_DAYS);
            setDateValue([sevenDaysAgo, today]);

            // Date limits
            setMinDate(undefined);
            setMaxDate(new Date());
        }

        if (rangeValue !== "Custom" && dateValue[0]) {
            const daysToAdd = offset[rangeValue];

            // Only update if endDate is missing OR if rangeValue changed
            if (
                !dateValue[1] ||
                dateValue[1].getTime() !==
                    dateValue[0].getTime() + daysToAdd * 86400000
            ) {
                const endDate = new Date(dateValue[0]);
                endDate.setDate(endDate.getDate() + daysToAdd);
                setDateValue([dateValue[0], endDate]);
            }
        }

        if (rangeValue === "Custom") {
            // If only one day selected
            if (dateValue[0] && !dateValue[1]) {
                const newMinDate = new Date(dateValue[0]);
                const newMaxDate = new Date(dateValue[0]);
                newMinDate.setDate(newMinDate.getDate() - MAX_RANGE_DAYS);
                newMaxDate.setDate(newMaxDate.getDate() + MAX_RANGE_DAYS);
                setMinDate(newMinDate);
                setMaxDate(
                    new Date(Math.min(newMaxDate.getTime(), Date.now()))
                );
            }

            // If both days selected
            if (dateValue[0] && dateValue[1]) {
                setMinDate(undefined);
                setMaxDate(new Date());
            }
        }

        /*
        if (rangeValue === "Custom" && dateValue[0] && dateValue[1]) {
            const diffInDays =
                (dateValue[1].getTime() - dateValue[0].getTime()) / 86400000;

            if (diffInDays > MAX_RANGE_DAYS) {
                const maxCustomEndDate = new Date(dateValue[0]);
                maxCustomEndDate.setDate(
                    maxCustomEndDate.getDate() + MAX_RANGE_DAYS
                );
                setDateValue([dateValue[0], maxCustomEndDate]);
            }
        }
        */
    }, [dateValue, setDateValue, rangeValue]);

    return (
        <DatePickerInput
            allowSingleDateInRange
            leftSection={<CiCalendar size={25} stroke="1.5" />}
            leftSectionPointerEvents="none"
            type="range"
            size="lg"
            radius="md"
            w={{ base: "100%", md: 220 }}
            valueFormat="MMM DD"
            placeholder="Pick dates range"
            minDate={minDate}
            maxDate={maxDate}
            value={dateValue}
            defaultDate={today}
            onChange={setDateValue}
            className="dashboard-date-picker-range"
            styles={{
                input: {
                    fontSize: "var(--mantine-spacing-md)",
                    color: "var(--mantine-color-gray-6)",
                },
            }}
        />
    );
}
