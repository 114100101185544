import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const LeaseTermRadioGroup = ({
  disabled = false,
  name,
  watch,
  setValue,
  register,
  options = ["6-months", "12-months", "24-months"],
  error,
  changedValue,
  ...props
}) => {
  const watchedValue = watch(name);
  const selectedValue = Array.isArray(watchedValue)
    ? watchedValue[0]
    : watchedValue;
  const [customMonth, setCustomMonth] = useState(
    !selectedValue || options.includes(selectedValue)
      ? ""
      : selectedValue.split("-")[0]
  );

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    if (
      selectedValue &&
      selectedValue?.endsWith("-months") &&
      selectedValue !== "6-months" &&
      selectedValue !== "12-months" &&
      selectedValue !== "24-months"
    ) {
      const months = selectedValue.split("-")[0];
      setCustomMonth(months);
    }
  }, [selectedValue]);

  const handleCustomMonthChange = (e) => {
    const value = e.target.value;
    setCustomMonth(value);
    if (value) {
      setValue(name, `${value}-months`);
    }
  };

  return (
    <Form.Group {...props} className="d-flex flex-column gap-3">
      {options.map((option) => (
        <Form.Check
          key={option}
          type="radio"
          id={`${name}-${option}`}
          name={name}
          label={option.replace("-", " ")}
          value={option}
          checked={selectedValue === option}
          onChange={(e) => setValue(name, e.target.value)}
          disabled={disabled}
          inline
          isInvalid={!!error}
        />
      ))}

      <Form.Check
        className="d-flex align-items-center gap-2"
        type="radio"
        id={`${name}-custom`}
        name={name}
        label={
          <div
            className="d-inline-flex align-items-center w-100 gap-2"
            style={{ position: "relative" }}
          >
            <Form.Control
              type="number"
              min="1"
              value={customMonth}
              onChange={handleCustomMonthChange}
              disabled={disabled || selectedValue !== `${customMonth}-months`}
              isInvalid={!!error}
            />
            <span
              className="text-body-tertiary"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }}
            >
              -months
            </span>
          </div>
        }
        value={`${customMonth}-months`}
        checked={
          selectedValue === `${customMonth}-months` ||
          selectedValue === `${customMonth}-month`
        }
        onChange={(e) => setValue(name, e.target.value)}
        disabled={disabled}
        inline
        isInvalid={!!error}
      />

      {error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default LeaseTermRadioGroup;
