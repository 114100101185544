import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useParams } from "react-router-dom";

import Sidebar from "../components/AdminSideBar";
import { Center, Loader } from "@mantine/core";
import ListAdminWrapper from "../../KitHistory/components/ListAdminWrapper";
import { useProfile } from "../../../context/UserProfileContext";

const KitDetails = () => {
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const [list, setList] = useState([]);
  const [modelshow, setModelShow] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoader] = useState(false);
  const [Delete, setDelete] = useState(false);
  const params = useParams();

  useEffect(() => {
    async function getKitByIdAndToken() {
      try {
        const id = params.id;
        const token = await Auth.currentSession();
        if (!id || !token) {
          navigate("/admin/login");
        }
        getKitDetails(id, token);
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }
    getKitByIdAndToken();
  }, [navigate, params.id]);

  function getKitDetails(id, token) {
    const url = process.env.REACT_APP_NODE_API_URL + "/api/kits/" + id;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setList(response.data);
          setLoader(true);
        } else {
          setError("Error Getting Kit");
          setModelShow(true);
        }
      })
      .catch((error) => {
        setError("Error Getting Kit");
        setModelShow(true);
      });
  }
  const handleDelete = async (e) => {
    e.preventDefault();
    let url;
    let token;
    try {
      url = process.env.REACT_APP_NODE_API_URL + "/api/kits/" + params.id;
      token = await Auth.currentSession();
    } catch (error) {
      console.log(error);
      navigate("/admin/login");
    } finally {
      axios
        .delete(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setModelShow(false);
            setDelete(true);
          } else {
            setError("Error Deleting Kit");
            setModelShow(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Deleting Kit");
            setModelShow(true);
          }
        });
    }
  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={8}>
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h1 className="h4 color-black mr-20">Kit Details</h1>
              </div>
            </Col>
            <Col xl={2}>
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="d-flex">
                  <Link
                    to={`/admin/user/edit-kit/${list._id}`}
                    size="sm"
                    className={`btn btn-primary btn-sm mr-30 ${
                      !profile?.isManager && "d-none"
                    }`}
                  >
                    Edit Kit
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl={2}>
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="d-flex">
                  <Link
                    onClick={() => {
                      setModelShow(true);
                      setError("");
                    }}
                    className={`btn btn-danger  mr-30 ${
                      !profile?.isManager && "d-none"
                    }`}
                  >
                    Delete Kit
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="mt-30">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Header as="h5">Personal information</Card.Header>
              {loading === false ? (
                <Center h={100}>
                  <Loader />
                </Center>
              ) : (
                <Card.Body>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.name}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Mobile"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.description}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Notes</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>{list.notes}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>User</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>
                          {list?.user &&
                            `${list?.user?.firstName} ${list?.user?.lastName} ${list?.user?.email}`}
                        </p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-20" controlId="Mobile">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Associate Hardware</Form.Label>
                      </Col>
                      <Col lg={5}>
                        {list.hardware === "" ? (
                          <Form.Label>No hardware selected</Form.Label>
                        ) : (
                          list?.hardware.map((element, index) => (
                            <>
                              <Form.Label>{element.name}</Form.Label>
                              <br />
                            </>
                          ))
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Tags</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.tags}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Service Type</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.service}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              )}
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header as="h5">Kit History</Card.Header>
              {loading === false ? (
                <Center h={100}>
                  <Loader />
                </Center>
              ) : (
                <Card.Body>
                  <ListAdminWrapper id={params?.id} all={true} />
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </Sidebar>
      {modelshow ? (
        <Modal show={modelshow}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Delete Kit</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>

              <Modal.Body>Are you sure you want to delete kit?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
      {Delete ? (
        <Modal show={Delete}>
          <>
            <Modal.Header>
              <Modal.Title>Deleted</Modal.Title>
            </Modal.Header>

            <Modal.Body>Kit Successfully</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate("/admin/kit/list");
                  setDelete(false);
                }}
              >
                Okay
              </Button>
            </Modal.Footer>
          </>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default KitDetails;
