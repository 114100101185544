import React from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Banners/Loading";
import { getFullAddress } from "../../../../helper";

const BuildingsTable = ({ buildings, isLoading, isManager }) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>Type</th>
          <th>Parent Building</th>
          <th>Company</th>
          <th>User</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <Loading />
        ) : (
          buildings.map((building) => (
            <tr key={building._id}>
              <td>{building.name}</td>
              <td>{getFullAddress(building.address)}</td>
              <td>{building.type}</td>
              <td>{building.parentStructure?.name || "N/A"}</td>
              <td>{building.user?.company?.name}</td>
              <td>{building.user?.email}</td>
              <td>
                <Button
                  as={Link}
                  to={`/admin/building/edit/${building._id}`}
                  variant="primary"
                  size="sm"
                  disabled={!isManager}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default BuildingsTable;
