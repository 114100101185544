import { Text, UnstyledButton } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React from "react";

const PaymentMethod = ({ onChange, method, selectedMethod }) => {
  const { ref } = useHover();

  console.log({selectedMethod, value: method.value})

  return (
    <UnstyledButton onClick={() => onChange(method.value)} ref={ref} className="w-25">
      <div className={`shadow-lg h-100 p-3 d-flex flex-column justify-content-center rounded-4 ${selectedMethod === method.value ? 'border border-primary-subtle' : '' }`}>
        <div className="d-flex flex-column align-items-center">
          {React.cloneElement(method.icon, {
            style: { height: 60, width: 60 },
          })}
          <Text fw={500} mb={7} lh={1}>
            {method.name}
          </Text>
          <Text fz="xs" c="dimmed" ta={"center"}>
            {method.description}
          </Text>
        </div>
      </div>
    </UnstyledButton>
  );
};

export default PaymentMethod;
