import React, { useState } from "react";
import UnstyledButton from "../../../components/UnstyledButton";
import StatusText from "./StatusText";
import { statusClassMap } from "../../../helper/kits";
import { FaEdit } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import GenericModal from "../../../components/Modals";
import ShippingAddressForm from "./ShippingAddressForm";
import BillingInfoForm from "./BillingInfoForm";
import { confirmKit, getKitsFromOrder, updateKit } from "../../../api/kits";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const kitStates = [
  "checking stock",
  "awaiting stock",
  "assembling kit",
  "ready to ship",
  "shipped",
  "awaiting confirmation",
  "confirmed",
  "cancelled",
  "returned",
];


const ProductsTable = ({ orderId, isAdmin }) => {
  const queryClient = useQueryClient()
  const [modal, setModal] = useState({
    show: false,
  });

  const {
    data: orderDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["products-from-order", orderId],
    queryFn: () => getKitsFromOrder(orderId),
  });

  const handleCloseModal = () => setModal({ ...modal, show: false });

  const {mutateAsync: updateKitState, isPending: updatingKitState} = useMutation({
    mutationFn: async ({product, newState}) => {
      const { state: currentState } = product;
      if (currentState === newState) return;

      if (currentState !== "confirm" && newState === "confirm") {
        return await confirmKit(product._id, product.invoiceInfo);
      } else {
        return await updateKit(product._id, { state: newState });
      }
    },

    onSuccess: (data) => {
      queryClient.setQueryData(["products-from-order", orderId], (prodsArr) => {
        const newArr = [...prodsArr]
        const productIndex = prodsArr.findIndex(prod => prod._id === data._id)
        newArr[productIndex] = data
        return newArr
      })
    }
    
  });


  return (
    <section className="d-flex flex-column gap-2">
      <div className="d-flex justify-content-between gap-2 px-3">
        <strong className="col">Product</strong>
        <strong className="col">Address</strong>
        <strong className="col">Invoice</strong>
        <strong className="col">Status</strong>
        <p className="col">
          <strong>Track Order</strong>
        </p>
      </div>

      {isLoading ? (
        <span className="fw-bold px-3">Loading...</span>
      ) : isError ? (
        <span>Error getting details, reload and try again</span>
      ) : (
        orderDetails.map((product) => (
          <div className="d-flex justify-content-between gap-2 border-bottom border-1 pb-2 px-3">
            <span className="col my-auto fw-semibold">DELET Kit</span>
            <UnstyledButton
              className="col text-truncate text-delet-blue fw-bold text-start"
              onClick={() =>
                setModal({
                  show: true,
                  body: (
                    <ShippingAddressForm
                      product={product}
                      close={handleCloseModal}
                    />
                  ),
                  title: "Shipping Address",
                })
              }
            >
              {product.shippingAddress ? (
                product.shippingAddress.street
              ) : (
                <span className="text-delet-blue fw-bold d-flex align-items-center gap-1">
                  <IoMdAddCircleOutline size={25} /> Add
                </span>
              )}
            </UnstyledButton>

            <UnstyledButton
              className="col text-truncate text-delet-blue fw-bold text-start"
              onClick={() =>
                setModal({
                  show: true,
                  body: (
                    <BillingInfoForm
                      product={product}
                      close={handleCloseModal}
                    />
                  ),
                  title: "Billing information for this product",
                })
              }
            >
              {product.invoiceInfo ? (
                product.invoiceInfo.name
              ) : (
                <span className="text-delet-blue fw-bold d-flex align-items-center gap-1">
                  <FaEdit /> Edit
                </span>
              )}
            </UnstyledButton>

            <div className="col my-auto">
              {isAdmin ? (
                <select
                  className="form-select text-capitalize"
                  value={product.state}
                  onChange={(e) => updateKitState({ product, newState: e.target.value })}
                  disabled={updatingKitState}
                >
                  {kitStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </select>
              ) : (
                <StatusText
                  status={
                    statusClassMap[product.state] ?? product.state ?? "received"
                  }
                  className="fw-semibold"
                />
              )}
            </div>

            <a
              href={product.shipment?.trackingUrl ?? "#"}
              rel="noreferrer"
              target="_blank"
              className={`col my-auto fw-semibold ${
                product.shipment?.trackingUrl
                  ? ""
                  : "pe-none link-opacity-50 text-decoration-none"
              }`}
            >
              {product.shipment?.trackingUrl ? "Follow" : "No shipment"}
            </a>
          </div>
        ))
      )}

      <GenericModal {...modal} handleClose={handleCloseModal} />
    </section>
  );
};

export default ProductsTable;
