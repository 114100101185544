import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import getImageUrl from "../../../../helper/checkImageFormat";
import propertyDummyImage from "../../../../assests/images/6-thumb.png";
import LazyImgWithPlaceholder from "../../../../components/Images/LazyImgWithPlaceholder";
import { format } from "date-fns-tz";
import { IoOpenOutline } from "react-icons/io5";
import { formatAddress } from "../../../../helper";

const BookingInfo = ({ show, handleClose, booking, isAdmin = false }) => {
  const { contact, property, user } = booking;

  if (!booking || !booking.startTime) {
    return;
  }
  const startTime = new Date(booking?.startTime * 1000);
  const endTime = new Date((booking.startTime + 15 * 60) * 1000);
  const formattedDate = format(startTime, "EEEE, MMMM dd");
  const formattedStartTime = format(startTime, "h:mm a");
  const formattedEndTime = format(endTime, "h:mm a");

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      name="end"
      className="showing-offcanvas"
    >
      <Offcanvas.Header closeButton className="px-4">
        <h4 className="m-0 fw-bold color-gray">Booking</h4>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="">
          <h6 style={{ fontWeight: "600", fontSize: "12px", color: "#9f9f9f" }}>
            DATE AND TIME
          </h6>
          <p className="mb-0 color-gray">
            {formattedDate}
            <br />
            {formattedStartTime} - {formattedEndTime}
          </p>
        </div>

        <div className="">
          <h6
            className="pt-4"
            style={{ fontWeight: "600", fontSize: "12px", color: "#9f9f9f" }}
          >
            LEAD
          </h6>
          <p className="color-gray m-0">
            {contact?.firstName} {contact?.lastName}
          </p>
          <div className="d-block">
            <Link
              to="tel:202-555-0153"
              className=" text-decoration-none color-gray"
            >
              <p className="m-0">{contact?.phone}</p>
            </Link>
          </div>
          <div className="d-block mb-2">
            <Link
              to={`mailto:${contact?.email}`}
              className="text-decoration-none color-gray"
            >
              <p className="m-0">{contact?.email}</p>
            </Link>
          </div>
          {booking.passCode ? <p className="color-gray m-0">
            Passcode: {booking.passCode}
          </p> : null}
        </div>

        <div className="">
          <h6
            className="pt-3 fw-semibold"
            style={{ fontSize: "12px", color: "#9f9f9f" }}
          >
            PROPERTY
          </h6>
          <div className=" d-flex gap-2">
            <div>
              <LazyImgWithPlaceholder
                width={140}
                height={100}
                border={10}
                placeholder={propertyDummyImage}
                src={getImageUrl(property?.images[0])}
                alt="property"
              />
            </div>
            <div className="">
              <p className="lead-name d-block mb-2 text-wrap">
                {formatAddress({
                  shortAddress: property.shortAddress,
                  unit: property.unit,
                  city: property.city,
                  state: property.state,
                  zipCode: property.zipCode,
                })}
              </p>
              {!isAdmin && (
                <Link
                  to={`/property/${[property?._id]}`}
                  className="color-primary"
                  style={{ textDecoration: "none" }}
                >
                  <IoOpenOutline />{" "}
                  <span style={{ fontWeight: "700", fontSize: "14px" }}>
                    {" "}
                    View property
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>

        {isAdmin && (
          <>
            <div className="mt-3">
              <h6
                className="pt-4"
                style={{ fontWeight: "600", fontSize: "12px", color: "#9f9f9f" }}
              >
                COMPANY
              </h6>
              <p className="color-gray">
                {user?.company?.name || "Company not found"}
              </p>
            </div>
            <div>
              <h6
                className="pt-4"
                style={{ fontWeight: "600", fontSize: "12px", color: "#9f9f9f" }}
              >
                USER
              </h6>
              <p className="color-gray">
                {user?.firstName} {user?.lastName}
              </p>
              <div className="d-block mb-2">
                <Link
                  to={`mailto:${user?.email}`}
                  className="text-decoration-none color-gray"
                >
                  <p>{user?.email}</p>
                </Link>
              </div>
            </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default BookingInfo;
