import axios from "axios";

const { Auth } = require("aws-amplify");

export const fetchKits = async (userId) => {
  const token = await Auth.currentSession();
  const requestConfig = {
    url: `${process.env.REACT_APP_NODE_API_URL}/api/kits/user?user=${userId ?? ""}`,
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    },
  };
  const response = await fetch(requestConfig.url, { headers: requestConfig.headers });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const kits = await response.json()
  return kits;
};

export const fetchKitsWithQuery = async (query) => {
  try {
    const url = process.env.REACT_APP_NODE_API_URL + "/api/kits/query";
    const token = await Auth.currentSession();

    const res = await axios.get(url, {
      params: {
        dbQuery: JSON.stringify(query)
      },
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    })

    return res.data

  } catch (error) {
    console.log(error);
  }
}

export const updateKit = async (kitId, updatedFields) => {
  try {
    const url = `${process.env.REACT_APP_NODE_API_URL}/api/kits/${kitId}`;
    const token = await Auth.currentSession();

    const res = await axios.patch(url, updatedFields, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    })


    return res.data

  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const updateMultipleKits = async (kits, updatedFields) => {

  try {
    const url = process.env.REACT_APP_NODE_API_URL + "/api/kits/multiple";
    const token = await Auth.currentSession();

    const res = await axios.patch(url, {
      body: { kits, updatedFields },
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    })

    return res.data

  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const confirmKit = async (kitId, invoiceInfo) => {

  try {
    const url = `${process.env.REACT_APP_NODE_API_URL}/api/kits/${kitId}/confirm`;
    const token = await Auth.currentSession();

    const res = await axios.patch(url, { invoiceInfo }, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    })

    return res.data

  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}

export const getKitsFromOrder = async (orderId) => {
  const token = await Auth.currentSession();
  const requestConfig = {
    url: `${process.env.REACT_APP_NODE_API_URL}/api/order/${orderId}/items`,
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    },
  };
  const response = await fetch(requestConfig.url, { headers: requestConfig.headers });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const kits = await response.json()
  return kits;
}

export const unassignKit = async (kitId) => {
  try {
    const url = `${process.env.REACT_APP_NODE_API_URL}/api/kits/unassign/${kitId}`;
    const token = await Auth.currentSession();

    const res = await axios.patch(url, {}, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    })
    return res.data

  } catch (error) {
    console.log(error)
    throw new Error(error)
  }
}