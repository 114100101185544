import { useEffect, useState } from "react";
import { useDashboardContext } from "./DashboardContext";
import { Card, Space, Text, Center, Loader } from "@mantine/core";
import { getTopProperties } from "../../api/dashboard";
import SingleProperty from "./SingleProperty";

export default function CardTopProperties() {
    const [properties, setProperties] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { dateValue } = useDashboardContext();
    const startDate = dateValue[0];
    const endDate = dateValue[1];

    useEffect(() => {
        // Validate dates input before fetching
        if (!startDate || !endDate) return;

        // Ensure dates range is valid
        const MAX_RANGE_DAYS = 60;
        const diffInDays = (endDate.getTime() - startDate.getTime()) / 86400000;
        if (diffInDays > MAX_RANGE_DAYS) return;

        // Fetch top properties
        const fetchData = async () => {
            setIsLoading(true);
            const data = await getTopProperties(startDate, endDate);
            setProperties(data.properties);
            setIsLoading(false);
        };

        fetchData();
    }, [startDate, endDate]);

    return (
        <Card
            shadow="md"
            px={{ base: 15, md: "xl" }}
            py={{ base: 20, md: "xl" }}
            radius="md"
            withBorder
        >
            {/* Title mobile */}
            <Text className="d-block d-md-none" size="md" fw={600} c="gray.7">
                Top Performance Properties
            </Text>

            {/* Title desktop */}
            <Text className="d-none d-md-block" size="xl" fw={600} c="gray.7">
                Top Performance Properties
            </Text>

            <Space h={{ base: "lg", lg: "xl" }} />

            {isLoading ? (
                // Loading Spinner
                <Center style={{ height: "300px" }}>
                    <Loader color="indigo" />
                </Center>
            ) : properties && properties.length > 0 ? (
                <div style={{ color: "#4C4D52" }}>
                    {/* Table Header desktop */}
                    <div className="d-none d-lg-flex row justify-content-lg-between mx-0">
                        <h3 className="col-lg-4 table-h">Property Details</h3>
                        <h3 className="col-lg-2 table-h">Price</h3>
                        <h3 className="col-lg-2 table-h">Leads</h3>
                        <h3 className="col-lg-2 table-h">Scheduled</h3>
                        <h3 className="col-lg-2 table-h">Completed</h3>
                    </div>

                    {/* Table Header mobile */}
                    <Text
                        className="d-block d-lg-none"
                        size="sm"
                        fw={600}
                        c="gray.8"
                    >
                        Property Details
                    </Text>

                    {/* Table Content: Top Properties */}
                    <div className="mt-2 mt-lg-3">
                        {properties.map((property) => {
                            return (
                                <SingleProperty
                                    key={property._id}
                                    property={property}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : (
                // No Data Available Message
                <Center style={{ height: "100px" }}>
                    <Text ta="center" c="gray.6">
                        No properties available
                    </Text>
                </Center>
            )}
        </Card>
    );
}
