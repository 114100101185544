import { Box, Center, Flex, Text } from "@mantine/core";

interface CardHeaderProps {
    Icon: React.ElementType;
    iconColor: string;
    iconBgColor: string;
    title: string;
}

export default function CardHeader({
    Icon,
    iconColor,
    iconBgColor,
    title,
}: CardHeaderProps) {
    return (
        <Flex align="center" gap="sm">
            <Box c={iconColor} bg={iconBgColor} style={{ borderRadius: "3px" }}>
                <Center w={{ base: 22, md: 28 }} h={{ base: 22, md: 28 }}>
                    <Icon className="d-block d-md-none" size={12} />
                    <Icon className="d-none d-md-block" size={15} />
                </Center>
            </Box>

            {/* Title mobile */}
            <Text className="d-block d-md-none" size="md" fw={600} c="gray.7">
                {title}
            </Text>

            {/* Title desktop */}
            <Text className="d-none d-md-block" size="xl" fw={600} c="gray.7">
                {title}
            </Text>
        </Flex>
    );
}
