import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CiCalendar } from "react-icons/ci";
import { DatePicker } from "@mantine/dates";
import { format } from "date-fns-tz";

const SelectDateDropdown = ({
  handleDateSelect,
  onMonthChange = () => {},
  selectedDate,
  level = "",
  ...props
}) => {
  return (
    <Dropdown autoClose="outside" {...props}>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="bg-white border border-1 text-body-tertiary fw-medium rounded-2 h-100 d-flex gap-2 align-items-center"
      >
        <span>{format(selectedDate, "MM/dd/yy")}</span> <CiCalendar size={20} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="p-3">
        <DatePicker
          value={selectedDate}
          onDateChange={level === "year" ? handleDateSelect : () => {}}
          onChange={handleDateSelect}
          level={level}
          highlightToday
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectDateDropdown;
