import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/SideBar";
import {
  ActionIcon,
  Anchor,
  Center,
  Checkbox,
  Container,
  Group,
  Space,
  Stack,
  Stepper,
  Text,
  Title,
} from "@mantine/core";
import { Button } from "react-bootstrap";
import { IoChevronBackSharp } from "react-icons/io5";
import OrderCardsList from "./OrderCardsList";
import { useListState } from "@mantine/hooks";
import OrderPayment from "./OrderPayment";
import { Auth } from "aws-amplify";
import UseHttp from "../../hooks/UseHttp";
import { useGeneral } from "../../context/GeneralContext";
import { useNavigate } from "react-router-dom";
import OrderConfirmation from "./OrderConfirmation";
import YourCart from "./components/YourCart";

const OrderNewKit = () => {
  const navigate = useNavigate();
  const createItem = (type, amount, ids, kits) => {
    switch (type) {
      case "kit":
        return {
          serviceType: type,
          amount: amount,
          notes: "",
          price: amount * 150,
          priceId: "price_1PMcC5FIjHkIJIZyWid3JSYi",
          individualPrice: 150,
        };

      case "installation":
        return {
          serviceType: type,
          amount: amount,
          notes: "",
          price: amount * 50,
          individualPrice: 50,
          ids: ids,
          kits: kits,
          priceId: "price_1PMcCZFIjHkIJIZyTXP8z7Mk",
        };
      case "removal":
        return {
          serviceType: type,
          amount: amount,
          notes: "",
          price: amount * 50,
          individualPrice: 50,
          ids: ids,
          kits: kits,
          priceId: "price_1PMcCIFIjHkIJIZytbjxXmQJ",
        };
      default:
        return;
    }
  };

  const [orders, handlers] = useListState([
    {
      name: "DELET kit 2.0",
      serviceType: "kit",
      amount: 0,
      notes: "",
      price: 0,
      priceId: "price_1P9tcgFIjHkIJIZyUHKxYvse", //production price: price_1PMcC5FIjHkIJIZyWid3JSYi
      individualPrice: 150,
    },
    {
      name: "Removal",
      serviceType: "removal",
      amount: 0,
      notes: "",
      price: 0,
      individualPrice: 50,
      priceId: "price_1P9rD1FIjHkIJIZyP3gvdXsv", // production price: price_1PMcCIFIjHkIJIZytbjxXmQJ
    },
    {
      name: "Installation",
      serviceType: "installation",
      amount: 0,
      notes: "",
      price: 0,
      individualPrice: 50,
      priceId: "price_1P9rCEFIjHkIJIZyIxdrw4Z2", //production price: price_1PMcCZFIjHkIJIZyTXP8z7Mk
    },
  ]);
  const [openPDF, setOpenPDF] = useState(false);

  const handleReadTerms = () => {
    setOpenPDF(true);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const [paymentMethod, setPaymentMethod] = useState("");

  const append = (value) => handlers.append(value);
  const setCardProp = (index, prop, value) =>
    handlers.setItemProp(index, prop, value);

  const removeCard = (index) => handlers.remove(index);

  const totalPrice = useMemo(() => {
    return orders?.reduce((acc, order) => acc + order.price, 0);
  }, [orders]);

  const totalPriceKits = useMemo(() => {
    return orders
      ?.filter((order) => order.serviceType === "kit")
      .reduce((acc, order) => acc + order.price, 0);
  }, [orders]);

  const totalPriceInstallation = useMemo(() => {
    return orders
      ?.filter((order) => order.serviceType === "installation")
      .reduce((acc, order) => acc + order.price, 0);
  }, [orders]);

  const totalPriceRemoval = useMemo(() => {
    return orders
      ?.filter((order) => order.serviceType === "removal")
      .reduce((acc, order) => acc + order.price, 0);
  }, [orders]);

  const totalKits = useMemo(() => {
    return orders
      ?.filter((order) => order.serviceType === "kit")
      .reduce((acc, order) => acc + order.amount, 0);
  }, [orders]);

  const totalInstallation = useMemo(() => {
    return orders
      ?.filter((order) => order.serviceType === "installation")
      .reduce((acc, order) => acc + order.amount, 0);
  }, [orders]);

  const totalRemoval = useMemo(() => {
    return orders
      ?.filter((order) => order.serviceType === "removal")
      .reduce((acc, order) => acc + order.amount, 0);
  }, [orders]);

  const handleAddKit = (service) => {
    const item = createItem(service, 1);
    append(item);
  };

  const [acceptTerms, handleAcceptTerms] = useState(false);

  const { isLoading, error, requestData: createOrder } = UseHttp();
  const {
    error: errorCoupon,
    requestData: requestCoupon,
  } = UseHttp();

  const { setAlertModal } = useGeneral();

  useEffect(() => {
    if (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: error.title,
        content: "There was an error in our server",
      });
    }
  }, [error, setAlertModal]);

  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [discount, setDiscount] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [discountError, setDiscountError] = useState(null);

  const finalPrice = useMemo(() => {
    return discount?.amount
      ? totalPrice -
          (discount?.type === "percentage"
            ? (totalPrice * discount?.amount) / 100
            : discount?.amount)
      : totalPrice;
  }, [discount?.amount, discount?.type, totalPrice]);

  const handleSetDiscount = () => {
    Auth.currentSession()
      .then((token) => {
        const requestConfig = {
          url: `${process.env.REACT_APP_NODE_API_URL}/api/discounts/${discountCode}`,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        };
        requestCoupon(requestConfig, (response) => {
          setDiscount({
            type: response.type,
            amount: response.amount,
            code: discountCode,
          });
          setDiscountCode("");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDiscountCode = (value) => {
    setDiscountCode(value);
    setDiscountError(null);
  };

  useEffect(() => {
    if (errorCoupon) setDiscountError("Invalid code");
  }, [errorCoupon]);

  const handleSubmit = () => {
    const data = {
      notes: orders[0].notes,
      details: orders,
      paymentMethod,
      acceptTerms,
      finalPrice: finalPrice,
      subscriptionPrice: totalPriceKits,
      discountCode: discount?.code,
    };

    Auth.currentSession()
      .then((token) => {
        createOrder(
          {
            method: "POST",
            url: `${process.env.REACT_APP_NODE_API_URL}/api/order`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
            body: data,
          },
          (response) => {
            console.log('This is the token', token)
            response.stripeUrl
              ? (window.location.href = response.stripeUrl)
              : navigate("/order/success");
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Sidebar>
      <div className="pt-3 px-5 d-flex flex-column gap-2 min-vh-100">
        <Group>
          <Group align="center">
            <ActionIcon
              variant="transparent"
              c={"black"}
              onClick={handleGoBack}
            >
              <IoChevronBackSharp />
            </ActionIcon>
            <Title order={4}>Place order</Title>
          </Group>
        </Group>
        <section className="d-flex flex-column gap-3 pt-2">
          <div>
            <Stepper
              active={active}
              onStepClick={setActive}
              size="xs"
              allowNextStepsSelect={false}
            >
              <Stepper.Step label="Kits" allowStepSelect={false}>
                {!orders?.length ? (
                  <Container my={50}>
                    <Center h={100}>
                      <Stack mt={50}>
                        <Title ta={"center"} c={"dimmed"} order={2} tt={"none"}>
                          Your cart is empty
                        </Title>

                        <Text ta={"center"} c={"dimmed"}>
                          Add items to get started
                        </Text>
                        <Space h="md" />
                        <Button
                          onClick={() => handleAddKit("kit")}
                          color="black"
                          variant="outline"
                        >
                          Add your first kit
                        </Button>
                      </Stack>
                    </Center>
                  </Container>
                ) : (
                  <OrderCardsList
                    orders={orders}
                    setCardProp={setCardProp}
                    removeCard={removeCard}
                  />
                )}
              </Stepper.Step>
              <Stepper.Step label="Payment" allowStepSelect={false}>
                <OrderPayment
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  acceptTerms={acceptTerms}
                  handleAcceptTerms={(value) => handleAcceptTerms(value)}
                  setOpenPDF={setOpenPDF}
                  openPDF={openPDF}
                />
              </Stepper.Step>
              <Stepper.Step label="Confirmation" allowStepSelect={false}>
                <OrderConfirmation
                  discount={discount}
                  totalKits={totalKits}
                  totalPriceKits={totalPriceKits}
                  totalInstallation={totalInstallation}
                  totalRemoval={totalRemoval}
                  totalPriceInstallation={totalPriceInstallation}
                  totalPriceRemoval={totalPriceRemoval}
                  orders={orders}
                />
              </Stepper.Step>
            </Stepper>
          </div>

          <YourCart orders={orders} totalPrice={totalPrice} step={active} />

          {active === 1 && (
            <div className="d-flex gap-1 justify-content-end w-100">
              <Text size="sm">
                I agree to the{" "}
                <Anchor onClick={handleReadTerms}>Terms and Conditions</Anchor>
              </Text>
              <Checkbox
                checked={acceptTerms}
                onChange={(event) =>
                  handleAcceptTerms(event.currentTarget.checked)
                }
                size="sm"
              />
            </div>
          )}

          <div
            className={`pb-3 d-flex ${
              active === 2 ? "justify-content-between" : "justify-content-end"
            }`}
          >
            {active === 2 && (
              <div className="d-flex">
                <input
                  type="text"
                  className="border border-dark-subtle rounded-3 border-end-0 rounded-end-0 ps-1"
                  placeholder="Enter code"
                  value={discountCode}
                  onChange={(e) => handleDiscountCode(e.target.value)}
                  error={discountError}
                />
                <button
                  onClick={handleSetDiscount}
                  className="bg-delet fw-bold fs-6 d-flex justify-content-center align-items-center px-2 py-1 border-0 text-white rounded-3 border-start-0 rounded-start-0"
                >
                  Apply
                </button>
              </div>
            )}
            <div className="d-flex gap-3 align-self-end">
              {active !== 0 ? (
                <Button
                  onClick={prevStep}
                  className="bg-white border border-dark-subtle fw-bold fs-5 d-flex justify-content-center align-items-center px-5 py-1 text-black rounded-3"
                >
                  Back
                </Button>
              ) : null}
              {active !== 2 ? (
                <Button
                  onClick={nextStep}
                  className="bg-delet fw-bold fs-5 d-flex justify-content-center align-items-center px-5 py-2 border-0 text-white rounded-3"
                  disabled={
                    !totalPrice ||
                    (active === 1 && (!paymentMethod || !acceptTerms))
                  }
                >
                  Next
                </Button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="bg-delet fw-bold fs-5 d-flex justify-content-center align-items-center px-5 py-1 border-0 text-white rounded-3"
                  loading={isLoading}
                  disabled={paymentMethod === "check" && !acceptTerms}
                >
                  Order
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    </Sidebar>
  );
};

export default OrderNewKit;
