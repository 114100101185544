import React, { useEffect, useState } from "react";

import axios from "axios";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";

import Sidebar from "../components/AdminSideBar";
import PageHeader from "../../../components/Headers/PageHeader";
import { Auth } from "aws-amplify";
import { Center, Loader } from "@mantine/core";

const KitList = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [q, setQ] = useState("");
  const [searchParam] = useState(["name", "description", "tags"]);
  const [modelshow, setModelShow] = useState(false);

  useEffect(() => {
    getKitList();
  }, []);

  async function getKitList() {
    try {
      var url =
        process.env.REACT_APP_NODE_API_URL + "/api/kits/admin/list?format=list";
      const token = await Auth.currentSession();
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setList(response.data);
            setLoader(true);
          } else {
            setError("Error Getting Kits");
            setModelShow(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Getting Kit");
            setModelShow(true);
          }
        });
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

  return (
    <>
      <Sidebar>
        <PageHeader>
          <Row className="align-items-center">
            <Col xl={6}>
                <h4>Kit List</h4>
            </Col>
            <Col xl={5}>
                <Form className="w-100">
                  <Form.Group
                    className="search-control-group d-flex "
                    controlId="search-control"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <Button className="search-btn"></Button>
                  </Form.Group>
                </Form>
            </Col>
            <Col xl={1}>   
                <div>
                  <Link
                    to="/admin/kit/create"
                    size="sm"
                    className="btn btn-primary btn-sm"
                  >
                    NEW
                  </Link>
                </div>
            </Col>
          </Row>
        </PageHeader>
        <div className="filter-alert m-3">
          <p>All Kits ({list.length})</p>
        </div>
        {loading === false ? (
          <Center h={100}>
            <Loader />
          </Center>
        ) : (
          <Table responsive className="property-table m-3">
            <tbody>
              {search(list).map((element, index) => (
                <tr
                  key={element._id}
                  onClick={() => navigate(`/admin/user/kit/${element._id}`)}
                >
                  <td>
                    <span className="order">{index + 1}.</span>
                  </td>
                  {/* <td>
                    <Link
                      to={`/property/${element._id}`}
                      className="properties-details-link"
                    >
                      <span className="position-relative d-inline-block">

                      </span>
                    </Link>
                  </td> */}
                  <td>
                    <Link
                      // to={`/properties-details/${element._id}`}
                      className="properties-details-link"
                    >
                      <p className="table-phone">{element.name}</p>
                    </Link>
                  </td>
                  <td>
                    <span className="table-phone">{element.description}</span>
                  </td>
                  <td>
                    <span>{element.notes}</span>
                  </td>

                  <td>
                    <span className="text-nowrap">
                      <Badge className="rent">{element.tags}</Badge>
                      {/* <span>{element.status}</span> */}
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      {/* <span>{element.hardware}</span> */}
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      {/* <span>{element.state}</span> */}
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      <span className="">
                        {element.user
                          ? `${element.user.firstName} ${element.user.lastName} (${element.user.email})`
                          : ""}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      <span className="">
                        {element.property ? `${element.property.shortAddress}${element.property.unit ? `, Unit ${element.property.unit}` : ''}` : ''}
                      </span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Sidebar>
      {modelshow ? (
        <Modal show={modelshow}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            ""
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default KitList;
