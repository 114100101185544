import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getContacts = async ({ page = 1, order = "desc", limit = 7, tabValue, selectedProperties, selectedCompanies, startDate, endDate, search }) => {
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/contact`;

  const queryParams = new URLSearchParams();
  queryParams.append('page', page);
  queryParams.append('pageLimit', limit);
  queryParams.append('sort', order);
  
  if (tabValue) queryParams.append('tabValue', tabValue);
  if (selectedProperties) queryParams.append('propertyIds', selectedProperties)
  if (selectedCompanies) queryParams.append('companiesIds', selectedCompanies)
  if (startDate) {
    const startTimestamp = new Date(startDate).setHours(0, 0, 0, 0);
    queryParams.append('startTimestamp', startTimestamp.toString());
  }
  if (endDate) {
    const endTimestamp = new Date(endDate).setHours(23, 59, 59, 999);
    queryParams.append('endTimestamp', endTimestamp.toString());
  }
  if (search) queryParams.append('search', search)

  // if (selectedCompanies?.length > 0) {
  //   queryParams.append('companiesIds', selectedCompanies.join(','));
  // }




  const fullUrl = `${baseUrl}?${queryParams.toString()}`;
  try {
    const auth = await Auth.currentSession();
    const res = await axios.get(fullUrl, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });
    const properties = await res.data;
    return properties;
  } catch (error) {
    console.log("ERROR GETTING PROPERTIES: ", error)
  }
};