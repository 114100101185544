export const validateForm = (
    type: string,
    name: string,
    startDate: Date | null,
    startTime: string,
    setError: (error: { field: string; message: string } | null) => void
): boolean => {
    // Required fields validation
    if (!type) {
        setError({ field: "codeType", message: "Code type required" });
        return false;
    }
    if (!name) {
        setError({ field: "codeName", message: "Code name required" });
        return false;
    }
    if (!startDate) {
        setError({ field: "startDate", message: "Start date required" });
        return false;
    }
    if (!startTime) {
        setError({ field: "startTime", message: "Start time required" });
        return false;
    }

    // Reset errors if all required fields are present
    setError(null);
    return true;
};

export const validateEndDate = (
    type: string,
    startDateTime: Date,
    endDate: Date | null,
    endTime: string,
    setError: (error: { field: string; message: string } | null) => void
): boolean => {
    if (type === "temporary") {
        if (!endDate) {
            setError({ field: "endDate", message: "End date required" });
            return false;
        }
        if (!endTime) {
            setError({ field: "endTime", message: "End time required" });
            return false;
        }

        const ONE_YEAR_IN_MS = 365 * 24 * 60 * 60 * 1000;
        const endDateTime = new Date(endDate);
        endDateTime.setHours(parseInt(endTime, 10), 0, 0, 0);

        // Check if endDateTime is at least 1 hour after startDateTime
        if (endDateTime <= startDateTime) {
            setError({
                field: "endTime",
                message: "End must be at least 1 hour after start",
            });
            return false;
        }

        // Check if the difference is greater than 1 year
        if (endDateTime.getTime() - startDateTime.getTime() > ONE_YEAR_IN_MS) {
            setError({
                field: "endDate",
                message:
                    "End cannot be more than 1 year after the start",
            });
            return false;
        }
    }

    return true;
};
