import { useState, useEffect } from "react";

// Current day and hour
const today = new Date();
const currentHour = today.getHours();
today.setHours(0, 0, 0, 0);

export const useCodeCreation = () => {
    // States
    const [type, setType] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [error, setError] = useState<{
        field: string;
        message: string;
    } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [minStartTime, setMinStartTime] = useState<number>(0);

    // Control minimum startTime if startDate is today
    useEffect(() => {
        if (startDate && startDate.toDateString() === today.toDateString()) {
            setMinStartTime(currentHour);
        } else {
            setMinStartTime(0);
        }
    }, [startDate]);

    return {
        type,
        setType,
        name,
        setName,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        minStartTime,
        error,
        setError,
        isLoading,
        setIsLoading,
    };
};
